import { css } from '@emotion/react';
import { Icon, TextField, Button, Box, useMedia, Loader } from '@partstech/ui';
import { ManagementSystemCard } from 'features/shop';
import { ScrollDivider } from './ScrollDivider';
import { useCardsScroll } from './useCardsScroll';
import { useSearchManagementSystem } from './useSearchManagementSystem';
import { useSelectManagementSystem } from './useSelectManagementSystem';

const styles = {
  scrollContainer: css`
    overflow-y: auto;
  `,
};

type Props = {
  selectedManagementSystemId?: string;
  onSubmit: () => void;
  onNotListedSoftwareClick?: () => void;
};

export const SearchManagementSystem = ({ selectedManagementSystemId, onSubmit, onNotListedSoftwareClick }: Props) => {
  const { isMobile } = useMedia();

  const { isScrolling, scrollCards } = useCardsScroll();

  const { changeSearchValue, isLoading, isViewMore, managementSystems, moreButtonClick, showMoreButton } =
    useSearchManagementSystem({ fullCardsSize: 18, shortCardsSize: isMobile ? 10 : 9 });

  const { selectNoSms, selectSystem } = useSelectManagementSystem({ onSuccess: onSubmit });

  if (isLoading) {
    return <Loader size="medium" display="flex" justifyContent="center" alignItems="center" p={5} />;
  }

  return (
    <Box width={{ sm: '100%', md: 132 }} textAlign="center" position="relative">
      <Box width={{ sm: '100%', md: 127 }} mx="auto">
        <TextField
          name="search"
          placeholder="Search for your management system"
          adornmentBefore={<Icon name="search" color="subtleText" />}
          onChange={changeSearchValue}
        />
      </Box>

      <Box mt={1} textAlign="right" background="white" position="relative" zIndex="default">
        <Button variant="text" onClick={selectNoSms}>
          I don’t use one
        </Button>
      </Box>

      {isScrolling && <ScrollDivider />}

      <Box
        css={styles.scrollContainer}
        onScroll={scrollCards}
        maxHeight={{ sm: undefined, md: 'calc(100dvh - 392px)' }}
      >
        <Box
          display="flex"
          justifyContent={{ sm: 'center', md: 'flex-start' }}
          flexWrap="wrap"
          gap={{ sm: 3, md: 4 }}
          mx="auto"
          pt={1}
          px={1}
        >
          {managementSystems.map((managementSystem) => (
            <ManagementSystemCard
              key={managementSystem.id}
              caption={!managementSystem.logo ? managementSystem.name : undefined}
              isActive={selectedManagementSystemId === managementSystem.id}
              logo={managementSystem.logo ?? null}
              onClick={selectSystem(managementSystem.id)}
            />
          ))}
        </Box>

        {showMoreButton && (
          <Box mt={{ sm: 3, md: 4 }}>
            <Button onClick={moreButtonClick}>Show {isViewMore ? 'less' : 'more'} </Button>
          </Box>
        )}

        <Box mt={3}>
          <Button variant="text" onClick={onNotListedSoftwareClick}>
            Don’t see your SMS?
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
