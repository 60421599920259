import { useCallback } from 'react';
import { useDeleteCartLocalInventoryItem } from 'features/cart/api/mutations/deleteCartLocalInventoryItem';
import type { CartOrder } from 'models';

export const useDeleteCartLocalInventoryOrder = () => {
  const [mutate, status] = useDeleteCartLocalInventoryItem();

  const removeLocalInventoryOrder = useCallback(
    (order: CartOrder) => {
      mutate({ ids: order.items.map((item) => item.id) });
    },
    [mutate]
  );

  return [removeLocalInventoryOrder, status] as const;
};
