/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type DeleteLaborRateMutationVariables = Types.Exact<{
  input: Types.DeleteLaborRateInput;
}>;

export type DeleteLaborRateMutation = {
  __typename?: 'Mutation';
  deleteLaborRate?: { __typename?: 'DeleteLaborRatePayload'; id: string } | null;
};

export const DeleteLaborRateDocument = `
    mutation DeleteLaborRate($input: DeleteLaborRateInput!) {
  deleteLaborRate(input: $input) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DeleteLaborRate: build.mutation<DeleteLaborRateMutation, DeleteLaborRateMutationVariables>({
      query: (variables) => ({ document: DeleteLaborRateDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteLaborRateMutation } = injectedRtkApi;
