import { useLocalStorage } from '@partstech/ui/hooks';
import { isEqual } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { LocalStorageKeys } from 'constant';
import { SearchSequenceType } from 'types/search';
import type { SearchSequence, SearchTask } from 'types/search';

const defaultSearchSequence: SearchSequence = {
  id: '',
  title: '',
  tasks: [],
  type: SearchSequenceType.PartTypes,
};

export const useSearchSequenceStorage = () => {
  const [data, setData] = useLocalStorage<SearchSequence>(LocalStorageKeys.SEARCH_SEQUENCE);

  const searchSequence = useMemo(() => {
    if (!data) {
      return defaultSearchSequence;
    }
    const requiredTasks = data.tasks.filter((item) => !item.optional);
    const optionalTasks = data.tasks.filter((item) => item.optional);

    return {
      ...data,
      tasks: [...requiredTasks, ...optionalTasks],
    };
  }, [data]);

  const totalTask = searchSequence.tasks.length;
  const isJobSearchSequence = [SearchSequenceType.Job, SearchSequenceType.CustomJob].includes(searchSequence.type);
  const isDiagramSearchSequence = searchSequence.type === SearchSequenceType.Diagram;

  const getTaskByIndex = useCallback((index: number) => searchSequence.tasks[index] ?? null, [searchSequence.tasks]);

  const markTaskAsCompleted = useCallback(
    (selectedTask: SearchTask) => {
      setData({
        ...searchSequence,
        tasks: searchSequence.tasks.map((task) =>
          isEqual(selectedTask, task) ? { ...task, isCompleted: true } : task
        ),
      });
    },
    [setData, searchSequence]
  );

  const reset = useCallback(() => setData(), [setData]);

  return {
    searchSequence,
    totalTask,
    isJobSearchSequence,
    isDiagramSearchSequence,
    getTaskByIndex,
    markTaskAsCompleted,
    setSearchSequence: setData,
    reset,
  };
};
