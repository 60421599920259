import { sortBy } from '@partstech/ui/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePartTypesWithSubCategory } from 'hooks/partType';
import { PartCategory } from 'models';
import { useCategories } from '../api';
import { MIN_SEARCH_LENGTH } from '../constants';
import { checkPartTypeDisabled, getAllSubCategoriesFromCategories } from '../utils';
import { useToggleCategory } from './useToggleCategory';
import type { PartType, PartSubCategory } from 'models';

type Props = {
  searchValue?: string;
  defaultValue?: PartType[];
  onChange?: (partTypes: PartType[]) => void;
  disabledPartTypeIds?: string[];
};

export const useCategoryOptions = ({ searchValue, defaultValue, onChange, disabledPartTypeIds = [] }: Props = {}) => {
  const shouldStartSearching = searchValue && searchValue.length >= MIN_SEARCH_LENGTH;

  const {
    categories: rawCategories,
    isSuccess: isCategoriesFetched,
    isFetching: isCategoriesFetching,
    findCategory,
    findSubCategory,
    ...props
  } = useCategories();

  const { partTypes: searchedPartTypes } = usePartTypesWithSubCategory({
    search: shouldStartSearching ? searchValue : undefined,
  });

  const [selectedPartTypes, setSelectedPartTypes] = useState<PartType[]>([]);

  useEffect(() => {
    setSelectedPartTypes(defaultValue ?? []);
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) {
      onChange(selectedPartTypes);
    }
  }, [onChange, selectedPartTypes]);

  const { openedCategory, setOpenedCategory, resetCategory } = useToggleCategory<PartCategory>();

  const {
    openedCategory: openedSubCategory,
    setOpenedCategory: setOpenedSubCategory,
    resetCategory: resetSubCategory,
  } = useToggleCategory<PartSubCategory>();

  const categories = useMemo(() => {
    if (openedCategory || openedSubCategory) {
      return [];
    }

    if (shouldStartSearching) {
      return rawCategories.filter((category) => category.isMatchedToString(searchValue));
    }

    return rawCategories;
  }, [openedCategory, openedSubCategory, rawCategories, searchValue, shouldStartSearching]);

  const rawSubCategories = useMemo(() => getAllSubCategoriesFromCategories(rawCategories), [rawCategories]);

  const subCategories = useMemo(() => {
    if (openedSubCategory) {
      return [];
    }

    if (shouldStartSearching) {
      return rawSubCategories?.filter((subCategory) => subCategory.isMatchedToString(searchValue));
    }

    return openedCategory?.subCategories ?? [];
  }, [openedSubCategory, openedCategory, shouldStartSearching, rawSubCategories, searchValue]);

  const partTypes = useMemo(() => {
    if (openedSubCategory) {
      return sortBy(openedSubCategory.partTypes, 'name');
    }

    if (shouldStartSearching) {
      searchedPartTypes.forEach((partType) =>
        partType.setIsDisabled(checkPartTypeDisabled(partType, disabledPartTypeIds))
      );
      return sortBy(searchedPartTypes, 'name');
    }

    return [];
  }, [disabledPartTypeIds, openedSubCategory, searchedPartTypes, shouldStartSearching]);

  const togglePartType = useCallback(
    (selectedPartType: PartType) => {
      setSelectedPartTypes((prevSelectedPartTypes) => {
        const isChecked = prevSelectedPartTypes.some((partType) => partType.id === selectedPartType.id);

        const newSelectedPartTypes = isChecked
          ? prevSelectedPartTypes.filter((partType) => partType.id !== selectedPartType.id)
          : [...prevSelectedPartTypes, selectedPartType];

        onChange?.(newSelectedPartTypes);

        return newSelectedPartTypes;
      });
    },
    [onChange]
  );

  const toggleSubCategory = useCallback(
    (subCategory: PartSubCategory) => {
      setSelectedPartTypes((prevSelectedPartTypes) => {
        const checkedPartTypes = prevSelectedPartTypes.filter(
          (partType) =>
            subCategory.id === partType.subCategoryId &&
            subCategory.partTypes.map((item) => item.id).includes(partType.id)
        );

        const isAllUnchecked = checkedPartTypes.length === 0;

        const newSelectedPartTypes = isAllUnchecked
          ? [...prevSelectedPartTypes, ...subCategory.partTypes]
          : prevSelectedPartTypes.filter(
              (partType) => !subCategory.partTypes.some((selectedPartType) => selectedPartType.id === partType.id)
            );

        onChange?.(newSelectedPartTypes);

        return newSelectedPartTypes;
      });
    },
    [onChange]
  );

  const removeSubCategory = useCallback((subCategory: PartSubCategory) => {
    setSelectedPartTypes((prevSelectedPartTypes) =>
      prevSelectedPartTypes.filter((partType) => partType.subCategoryId !== subCategory.id)
    );
  }, []);

  const openOption = useCallback(
    (option: PartCategory | PartSubCategory) => {
      if (option instanceof PartCategory) {
        setOpenedCategory(option);
        return;
      }

      setOpenedSubCategory(option);
    },
    [setOpenedCategory, setOpenedSubCategory]
  );

  const goBack = useCallback(() => {
    if (openedSubCategory) {
      resetSubCategory();
      return;
    }

    resetCategory();
  }, [openedSubCategory, resetCategory, resetSubCategory]);

  const reset = useCallback(() => {
    resetSubCategory();

    resetCategory();
  }, [resetCategory, resetSubCategory]);

  const isLoaded = useMemo(() => isCategoriesFetched, [isCategoriesFetched]);

  const isFetching = useMemo(() => isCategoriesFetching, [isCategoriesFetching]);

  const findCategoryNameBySubCategory = useCallback(
    (subCategory: PartSubCategory) => {
      if (!subCategory.categoryId || !shouldStartSearching) {
        return '';
      }
      return findCategory(subCategory.categoryId)?.name ?? '';
    },
    [findCategory, shouldStartSearching]
  );

  return {
    ...props,
    categories,
    subCategories,
    selectedPartTypes,
    partTypes,
    togglePartType,
    toggleSubCategory,
    removeSubCategory,
    openOption,
    openedCategory,
    openedSubCategory,
    goBack,
    isLoaded,
    isFetching,
    reset,
    findCategoryNameBySubCategory,
    findSubCategory,
  };
};
