import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './AddLaborItemsToCart.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    AddLaborItemsToCart: {
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
            const { activeCart } = draft;

            if (!activeCart) {
              return;
            }

            data.batchAddLaborItemsToActiveCart?.items?.forEach((cartLaborItem) => {
              const { orderItemId, cartLocalInventoryItemId } = cartLaborItem;

              if (!orderItemId && !cartLocalInventoryItemId) {
                Object.assign(activeCart, {
                  laborItems: [...(activeCart.laborItems || []), cartLaborItem],
                });
              } else if (cartLocalInventoryItemId) {
                activeCart.localInventoryItems?.forEach((inventoryItem) => {
                  if (inventoryItem.id === cartLocalInventoryItemId) {
                    Object.assign(inventoryItem, {
                      laborItems: [...(inventoryItem.laborItems || []), cartLaborItem],
                    });
                  }
                });
              } else {
                activeCart.orders?.forEach((order) => {
                  order.items?.forEach((orderItem) => {
                    if (orderItem.id === orderItemId) {
                      Object.assign(orderItem, {
                        laborItems: [...(orderItem.laborItems || []), cartLaborItem],
                      });
                    }
                  });
                });
              }
            });
          })
        );
      },
    },
  },
});

const { useAddLaborItemsToCartMutation } = api;

export const useAddLaborItemsToCart = () => {
  const [mutate, status] = useAddLaborItemsToCartMutation();

  useMutationStatus({ status });

  return [mutate, status] as const;
};
