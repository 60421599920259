import { PagePermission, Routes } from 'constant';
import type { ModalCommonProps } from '@partstech/ui';
import type { RouteObject } from 'react-router-dom';

type ModalRoute = {
  path: Routes;
  permission: PagePermission;
  customId?: string;
} & RouteObject &
  ModalCommonProps;

/**
 * @info When editing this array don't forget to update constant/routes.ts::ModalRoutes array
 */
export const modalRoutes: ModalRoute[] = [
  {
    path: Routes.ADD_SUPPLIER,
    permission: PagePermission.AUTHORIZED,
    lazy: () => import('pages/AddSupplier').then((module) => ({ Component: module.AddSupplier })),
  },
  {
    path: Routes.EDIT_LABOR,
    permission: PagePermission.AUTHORIZED,
    lazy: () => import('pages/EditLaborModal').then((module) => ({ Component: module.EditLaborModal })),
  },
  {
    path: Routes.DIAGRAM,
    permission: PagePermission.AUTHORIZED,
    lazy: () => import('pages/SearchDiagram').then((module) => ({ Component: module.SearchDiagramPage })),
  },
  {
    path: Routes.SERVICE_GUIDES,
    permission: PagePermission.AUTHORIZED,
    lazy: () => import('pages/ServiceGuides').then((module) => ({ Component: module.ServiceGuides })),
  },
  {
    path: Routes.SAVED_QUOTE,
    permission: PagePermission.AUTHORIZED,
    lazy: () => import('pages/SavedQuotes/SavedCart').then((module) => ({ Component: module.SavedCart })),
  },
  {
    path: Routes.CREATE_CUSTOM_JOB,
    permission: PagePermission.AUTHORIZED,
    lazy: () => import('pages/EditCustomJob').then((module) => ({ Component: module.CreateCustomJobPage })),
  },
  {
    path: Routes.EDIT_CUSTOM_JOB,
    permission: PagePermission.AUTHORIZED,
    lazy: () => import('pages/EditCustomJob').then((module) => ({ Component: module.EditCustomJobPage })),
  },
  {
    path: Routes.MANAGE_CUSTOM_JOBS,
    permission: PagePermission.AUTHORIZED,
    lazy: () => import('pages/ManageCustomJobs').then((module) => ({ Component: module.ManageCustomJobsPage })),
  },
];
