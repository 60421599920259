export enum FullStoryEventNames {
  ORDER_COMPLETED = 'Order Completed',
  STOCK_ORDER_SEARCHED = 'Stock Order Searched',
  STOCK_ORDER_PART_CHANGED = 'Stock Order Part Changed',
  PRODUCT_SEARCHED = 'Products Searched',
  PRODUCT_SEARCH_RESULTS = 'Product Search Results',
  SUPPLIER_SELECTED = 'Supplier Selected',
  PRODUCT_REMOVED = 'Product Removed',
  PRODUCT_ADDED = 'Product Added',
  PRODUCT_ADDING_ERROR = 'Product Adding Error',
  ADD_SUPPLIER_NO_RESULTS = 'Add Supplier No Results',
  ADD_SUPPLIER = 'Add Supplier',
  CUSTOMER_FAILED_QUOTE = 'Customer failed quote',
  MECHANIC_FAILED_QUOTE = 'Mechanic failed quote',
  CREATE_ACCOUNT = 'Create Account',
  CREATE_SHOP = 'Create Shop',
  SELECT_SMS = 'Select SMS',
  VEHICLE_NOT_FOUND = 'Vehicle not found',
  SUPPLIER_API_ERROR = 'Supplier API Error',
  FAILED_TIRE_FETCH = 'Failed to fetch tire in stock',
  REPORT_MISSING_PART = 'Report missing part',
  LABOR_RATE_LIST_CHANGED = 'Labor rate list changed',
  LABOR_TIME_EDITED = 'Labor time edited',
  CUSTOM_JOB_CREATED = 'Custom Job Created',
}
