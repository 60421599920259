import { isNotNull } from '@partstech/ui/utils';
import { useCallback, useEffect, useState } from 'react';
import {
  useLazyGetVehiclesByPlateVin,
  type Vehicle,
  type GetVehiclesByPlateVinQueryVariables,
  createVehicleFromQuery,
} from 'entities/vehicle';

type Props = {
  onChange?: (vehicle: Vehicle) => void;
};

export const useVehicleSearch = <T>({ onChange }: Props) => {
  const { search, vehicles: fetchedVehicles, status } = useLazyGetVehiclesByPlateVin();
  const { isFetching, isSuccess } = status;

  const isSearchLoaded = !isFetching && isSuccess;
  const vehiclesNotFound = isSearchLoaded && fetchedVehicles.length === 0;

  const [vehicles, setVehicles] = useState<Vehicle[]>(fetchedVehicles);

  const clearVehicles = useCallback(() => {
    setVehicles([]);
  }, []);

  const handleSearch = useCallback(
    async (values: T) => {
      const response = await search(values as GetVehiclesByPlateVinQueryVariables).unwrap();

      if (!response.vehicles) {
        return;
      }

      const newVehicles = response.vehicles.map(createVehicleFromQuery).filter(isNotNull);

      const [vehicle] = newVehicles;

      if (newVehicles.length === 1 && onChange && vehicle) {
        onChange(vehicle);
        return;
      }

      setVehicles(newVehicles);
    },
    [onChange, search]
  );

  useEffect(() => {
    setVehicles(fetchedVehicles);
  }, [fetchedVehicles]);

  return { search: handleSearch, vehicles, vehiclesNotFound, isFetching, clearVehicles };
};
