import { CartOrderItemOrigin, type ItemParametersInput } from 'shared/api';
import type { AddToCartPayload } from '../types';

export const getAddToCartInput = (payload: AddToCartPayload): ItemParametersInput => ({
  accountId: String(payload.product.credentialId),
  lineCardId: String(payload.product.lineCardId),
  origin: payload.origin,
  partName: payload.product.title,
  partNumber: payload.product.partNumber?.partNumber || '',
  partNumberId: payload.product.getPartNumberId(),
  partTypeId: payload.product.partType?.id ? String(payload.product.partType.id) : undefined,
  quantity: payload.quantity,
  searchedPartNumber: payload.searchedPartNumber,
  searchResultPosition: payload.origin === CartOrderItemOrigin.Search ? payload.searchResultPosition : undefined,
  availabilityBranch: payload.product.supplier?.allowStoreSelect
    ? payload.selectedStore?.name || payload.product.quote?.lines[0]?.name || undefined
    : undefined,
  storeId: payload.product.supplier?.allowStoreSelect
    ? String(payload.selectedStore?.id || payload.product.quote?.lines[0]?.id || payload.product.store?.id)
    : String(payload.product.store?.id),
  vehicleId: payload.vehicleId,
  vin: payload.vin,
});
