/* eslint-disable boundaries/element-types */
import { createTireConfigurationsFromQuery } from 'factories/createTireConfigurationsFromQuery';
import { createJobsFromQuery } from 'factories/jobs/createJobsFromQuery';
import { Vehicle } from '../model/Vehicle';
import { createDiagramsFromQuery } from './createDiagramsFromQuery';
import type { TiresFragment } from '../api/TiresFragment.generated';

import type { JobFragment, VehicleFragment, DiagramFragment } from 'shared/api';

type Fragment = VehicleFragment & TiresFragment & { diagrams?: DiagramFragment[] | null; jobs?: JobFragment[] | null };

export const createVehicleFromQuery = (
  vehicleFragment: (Fragment & Partial<{ state: string; plate: string; vin: string | null }>) | null | undefined
): Vehicle | null => {
  if (!vehicleFragment) {
    return null;
  }

  const vehicle = new Vehicle({ ...vehicleFragment, regions: vehicleFragment.regions ?? [], vin: vehicleFragment.vin });

  if (vehicleFragment?.tires) {
    vehicle.setTires(createTireConfigurationsFromQuery(vehicleFragment.tires));
  }

  if (vehicleFragment?.diagrams) {
    vehicle.setDiagrams(createDiagramsFromQuery(vehicleFragment.diagrams));
  }

  if (vehicleFragment?.jobs) {
    vehicle.setJobs(createJobsFromQuery(vehicleFragment.jobs));
  }

  return vehicle;
};
