import { Loader } from '@partstech/ui';
import { useEffect } from 'react';
import { Outlet } from 'app/AppRouter/Outlet';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { Routes } from 'constant';
import { usePermissions } from 'entities/permission';
import type { PropsWithChildren } from 'react';

export const ReportsRouteLayout = ({ children }: PropsWithChildren) => {
  const navigate = useAppNavigate();
  const { pathname } = useAppLocation();

  const {
    shop: { advancedReportsAllowed, spendReportAllowed },
    isSuccess: isPermissionsLoaded,
  } = usePermissions();

  const isDashboard = pathname === Routes.REPORTS;
  const isSpendReports = pathname === Routes.REPORTS_SPENDING;
  const isTireReports = pathname.includes(Routes.REPORTS_TIRES);
  const isPartReports = pathname.includes(Routes.REPORTS_PARTS);

  const showReports =
    (isSpendReports && spendReportAllowed) ||
    (isTireReports && advancedReportsAllowed) ||
    (isPartReports && advancedReportsAllowed) ||
    isDashboard;

  useEffect(() => {
    if (isPermissionsLoaded && !showReports) {
      navigate(Routes.REPORTS);
    }
  }, [showReports, isPermissionsLoaded, navigate]);

  if (!isPermissionsLoaded || !showReports) {
    return <Loader size="medium" my={6} display="flex" justifyContent="center" />;
  }

  return <Outlet>{children}</Outlet>;
};
