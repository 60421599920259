import { Box, Typography } from '@partstech/ui';
import { useMemo } from 'react';
import { getTireLoadIndex, getTireSpeedRating, getTireLoadRangePSI } from 'utils';
import type { Attribute } from 'models';

type Props = {
  attribute: Attribute;
};

export const TireSizeTranslation = ({ attribute }: Props) => {
  const loadIndex = useMemo(() => attribute.getChildValue('TiresLoadIndex'), [attribute]);
  const loadIndexDual = useMemo(() => attribute.getChildValue('TiresLoadIndexDual'), [attribute]);
  const speedRating = useMemo(() => attribute.getChildValue('TiresSpeedRating'), [attribute]);
  const loadRange = useMemo(() => attribute.getChildValue('TiresLoadRange'), [attribute]);

  if (!loadIndex && !speedRating && !loadRange) {
    return null;
  }

  return (
    <>
      {loadIndex && (
        <>
          <Typography variant="overline" color="white">
            LOAD INDEX{' '}
          </Typography>
          {loadIndexDual ? (
            <>
              <Typography variant="overline" color="white" component="div">
                Single:{' '}
                <Typography variant="caption" color="white">
                  {loadIndex} - {getTireLoadIndex(loadIndex)}{' '}
                </Typography>
              </Typography>
              <Typography variant="overline" color="white" component="div">
                Dual:{' '}
                <Typography variant="caption" color="white">
                  {loadIndexDual} - {getTireLoadIndex(loadIndexDual)}{' '}
                </Typography>
              </Typography>
            </>
          ) : (
            <Typography variant="overline" color="white" component="div">
              {loadIndex}:{' '}
              <Typography variant="caption" color="white">
                {getTireLoadIndex(loadIndex)}{' '}
              </Typography>
            </Typography>
          )}
          <Box mb={2} />
        </>
      )}
      {loadRange && (
        <>
          <Typography variant="overline" color="white">
            LOAD RANGE{' '}
          </Typography>
          <Typography variant="overline" color="white" component="div">
            {loadRange}:{' '}
            <Typography variant="caption" color="white">
              {getTireLoadRangePSI(loadRange)} PSI{' '}
            </Typography>
          </Typography>
          <Box mb={2} />
        </>
      )}
      {speedRating && (
        <>
          <Typography variant="overline" color="white">
            SPEED RATING{' '}
          </Typography>
          <Typography variant="overline" color="white" component="div">
            {speedRating}:{' '}
            <Typography variant="caption" color="white">
              {getTireSpeedRating(speedRating)}
            </Typography>
          </Typography>
        </>
      )}
    </>
  );
};
