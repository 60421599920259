import { makeContext } from '@partstech/ui/utils';
import type { SignUpStep } from './useSignUpSteps';
import type { ShopFormData } from 'features/shop';
import type { ShopType } from 'shared/api';

type CreateShopContext = {
  activeStep: SignUpStep;
  isDuplicatedShopStep: boolean;
  isMitchell1SignUp: boolean;
  nextStep: SignUpStep | null;
  previousStep: SignUpStep | null;
  selectedShop: null | ShopFormData;
  shopType?: ShopType;
  navigateToNextStep: () => void;
  navigateToPreviousStep: () => void;
  setIsDuplicatedShopStep: (payload: boolean) => void;
  setSelectedShop: (input: null | ShopFormData) => void;
  setShopType: (value: ShopType) => void;
};

export const [useCreateShopContext, CreateShopContextProvider] = makeContext<CreateShopContext>();
