import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './ChangeCartLocalInventoryItemQuantity.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    ChangeCartLocalInventoryItemQuantity: {
      onQueryStarted: async ({ itemId, quantity }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (!data.updateActiveCartLocalInventoryItemQuantity) {
          return;
        }

        dispatch(
          activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
            const localInventoryItem = draft?.activeCart?.localInventoryItems?.find((item) => item.id === itemId);

            if (localInventoryItem) {
              localInventoryItem.quantity = quantity;

              if (
                data.updateActiveCartLocalInventoryItemQuantity &&
                'item' in data.updateActiveCartLocalInventoryItemQuantity
              ) {
                Object.assign(localInventoryItem, { quantity });
              } else {
                Object.assign(localInventoryItem, {
                  quantity,
                  validationError: {
                    errorType: data.updateActiveCartLocalInventoryItemQuantity?.error,
                    message: data.updateActiveCartLocalInventoryItemQuantity?.errorMessage,
                  },
                });
              }
            }
          })
        );
      },
    },
  },
});

const { useChangeCartLocalInventoryItemQuantityMutation } = api;

export const useChangeCartLocalInventoryItemQuantity = () => {
  const [changeQuantity, status] = useChangeCartLocalInventoryItemQuantityMutation();

  useMutationStatus({ status });

  return [changeQuantity, status] as const;
};
