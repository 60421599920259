import { css } from '@emotion/react';
import { Box, Icon, ImageFallback } from '@partstech/ui';
import { memo, useRef } from 'react';
import { useScrollIntoView } from 'shared/lib/react-dom';
import type { Theme } from '@partstech/ui';
import type { MediaFile } from 'models';

const styles = {
  image: css`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  `,
  video: css`
    border: 0;
    width: 100%;
    height: 100%;
  `,
  videoIcon: (theme: Theme) => css`
    position: absolute;
    top: ${theme.sizing(4.5)};
    left: ${theme.sizing(5.5)};
  `,
  button: (isActive: boolean) => (theme: Theme) => css`
    cursor: pointer;
    border: ${isActive ? '2px solid' : '1px solid'};
    border-radius: ${theme.sizing(2)};
    background-color: ${isActive ? theme.color.lightBlue : theme.color.white};
    border-color: ${isActive ? theme.color.primary : theme.color.mono6};

    &:hover {
      cursor: ${isActive ? 'default' : 'pointer'};
      background-color: ${isActive ? theme.color.lightBlue : theme.color.mono6};
    }
  `,
};

type Props = {
  file: MediaFile;
  index: number;
  isSelected: boolean;
  onClick: () => void;
};

export const FileCell = memo(({ file, index, isSelected, onClick }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useScrollIntoView(ref, { block: 'nearest', behavior: 'smooth' }, { skip: !isSelected });

  return (
    <Box
      ref={ref}
      position="relative"
      width={17}
      height={16}
      p={isSelected ? 0.75 : 1}
      css={styles.button(isSelected)}
      data-testid="imageCell"
      className={isSelected ? 'active' : undefined}
      onClick={onClick}
    >
      {file.isVideo && <Icon name="videocam" color="secondary" css={styles.videoIcon} />}

      <ImageFallback src={file.preview} alt={`preview #${index + 1}`} css={styles.image} />
    </Box>
  );
});
