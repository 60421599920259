/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type AddLocalInventoryItemToActiveCartMutationVariables = Types.Exact<{
  item: Types.CartLocalInventoryItemParametersInput;
}>;

export type AddLocalInventoryItemToActiveCartMutation = {
  __typename?: 'Mutation';
  addLocalInventoryItemToActiveCart?:
    | {
        __typename?: 'AddLocalInventoryItemToActiveCartErrorPayload';
        error: Types.AddLocalInventoryItemToActiveCartError;
        errorMessage: Types.Scalars['String']['input'];
      }
    | {
        __typename?: 'AddLocalInventoryItemToActiveCartSuccessPayload';
        localInventoryItem: { __typename?: 'CartLocalInventoryItem'; id: string };
      }
    | null;
};

export const AddLocalInventoryItemToActiveCartDocument = `
    mutation AddLocalInventoryItemToActiveCart($item: CartLocalInventoryItemParametersInput!) {
  addLocalInventoryItemToActiveCart(input: {item: $item}) {
    ... on AddLocalInventoryItemToActiveCartErrorPayload {
      error
      errorMessage
    }
    ... on AddLocalInventoryItemToActiveCartSuccessPayload {
      localInventoryItem {
        id
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    AddLocalInventoryItemToActiveCart: build.mutation<
      AddLocalInventoryItemToActiveCartMutation,
      AddLocalInventoryItemToActiveCartMutationVariables
    >({
      query: (variables) => ({ document: AddLocalInventoryItemToActiveCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddLocalInventoryItemToActiveCartMutation } = injectedRtkApi;
