import { makeContext } from '@partstech/ui/utils';
import type { IdentifyTraits } from '../../lib/identify';
import type { ReferrerInfo } from '../../lib/useReferrerInfo';

type AmbassadorContext = {
  /**
   * Identifies the current user with Ambassador.
   * Returns true if the user was successfully identified.
   */
  identify: (traits: IdentifyTraits) => Promise<boolean>;

  /**
   * Indicates whether the current user has been identified with Ambassador.
   * True when the identify() function has been successfully called.
   */
  isIdentified: boolean;

  /**
   * Error message if any operation with Ambassador API failed.
   * Null when there are no errors.
   */
  error: string | null;

  /**
   * Widget state and control functions.
   */
  widget: {
    /**
     * Indicates whether the Ambassador referral widget is currently visible.
     */
    isVisible: boolean;

    /**
     * Indicates whether the Ambassador widget is in loading state.
     */
    isLoading: boolean;

    /**
     * Closes the referral widget.
     */
    close: () => void;

    /**
     * Opens the referral widget.
     */
    open: (traits: IdentifyTraits) => void;
  };

  /**
   * Information about the referrer who invited the current user.
   * Null if no referral information is available.
   */
  referrerInfo: ReferrerInfo | null;
};

export const [useAmbassador, AmbassadorContextProvider] = makeContext<AmbassadorContext>();
