import { Link, OldList, OldNavigationListItem, Typography } from '@partstech/ui';
import { VehicleRequiredView } from 'components/VehicleRequiredView';
import { OldVehicleMessage } from './OldVehicleMessage';
import { useServiceGuidesOptions } from './useServiceGuidesOptions';

type Props = {
  vehicleId: string | null | undefined;
  vin: string | undefined;
  vehicleYear: number | null | undefined;
  onSelectVehicleClick?: () => void;
};

/** @todo Use OptionsByColumns instead of List when it's reusable */
export const ServiceGuides = ({ vehicleId, vin, vehicleYear, onSelectVehicleClick }: Props) => {
  const { options } = useServiceGuidesOptions(vehicleId ?? '', vin);
  const isOldVehicle = vehicleYear && vehicleYear < 1985;

  return (
    <VehicleRequiredView
      width="100%"
      hasOptions
      hasVehicle={Boolean(vehicleId) && !isOldVehicle}
      onSelectVehicleClick={onSelectVehicleClick}
      noVehicleText={
        isOldVehicle ? <OldVehicleMessage /> : 'A vehicle must be selected in order to view service guides.'
      }
      adornmentBottomText={isOldVehicle ? 'Select New Vehicle' : 'Select Vehicle'}
      noOptionsText=""
      height={104}
      className={isOldVehicle ? 'fs-no-guides-legacy-vehicle' : undefined}
    >
      <OldList size="slim" fullWidth>
        {options.map((option) => (
          <OldNavigationListItem key={option.name} as={<Link to={option.url} data-testid="listItem" />}>
            <Typography component="span">{option.name}</Typography>
          </OldNavigationListItem>
        ))}
      </OldList>
    </VehicleRequiredView>
  );
};
