import { AvailabilityLine } from './AvailabilityLine';

export class FeaturedAvailabilityLine extends AvailabilityLine {
  getMainAvailabilityTitle() {
    if (this.group === 'Home Store') {
      return `In Stock (${this.formattedMaxQuantity})`;
    }

    return `${this.group ?? 'In Stock'} (${this.formattedMaxQuantity})`;
  }

  getNetworkAvailabilityTitle() {
    return `${this.group ?? 'In Network'} (${this.formattedMaxQuantity})`;
  }

  getClosestAvailabilityTitle(): string {
    return this.getMainAvailabilityTitle();
  }
}
