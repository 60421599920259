/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetPartReportsQueryVariables = Types.Exact<{
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  startDate: Types.Scalars['DateTime']['input'];
  storeIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;

export type GetPartReportsQuery = {
  __typename?: 'Query';
  partReports?: {
    __typename?: 'PartReports';
    partChart?: {
      __typename?: 'PartChart';
      partTypesCount: number;
      totalOrders: number;
      totalPrice: Types.Scalars['BigDecimal']['input'];
      totalQuantity: number;
    } | null;
  } | null;
};

export const GetPartReportsDocument = `
    query GetPartReports($endDate: DateTime, $startDate: DateTime!, $storeIds: [ID!]) {
  partReports {
    partChart(endDate: $endDate, startDate: $startDate, storeIds: $storeIds) {
      partTypesCount
      totalOrders
      totalPrice
      totalQuantity
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetPartReports: build.query<GetPartReportsQuery, GetPartReportsQueryVariables>({
      query: (variables) => ({ document: GetPartReportsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPartReportsQuery, useLazyGetPartReportsQuery } = injectedRtkApi;
