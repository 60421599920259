import { api, gExportApi, gUploadApi, shopApi } from 'shared/api';
import type { RootDispatch } from 'store';

export const resetStore = (dispatch: RootDispatch) => {
  dispatch(api.util.resetApiState());
  dispatch(shopApi.util.resetApiState());
  dispatch(gUploadApi.util.resetApiState());
  dispatch(gExportApi.util.resetApiState());

  sessionStorage.clear();
  localStorage.clear();
};
