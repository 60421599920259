/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../../../../shared/api/graphql/graphql';

export type CartLocalInventoryItemFragment = {
  __typename?: 'CartLocalInventoryItem';
  customerPrice?: Types.Scalars['BigDecimal']['input'] | null;
  id: string;
  image?: Types.Scalars['String']['input'] | null;
  listPrice?: Types.Scalars['BigDecimal']['input'] | null;
  localInventoryUID: Types.Scalars['String']['input'];
  orderGroupId: string;
  partName: Types.Scalars['String']['input'];
  partNumber: Types.Scalars['String']['input'];
  partNumberId: Types.Scalars['String']['input'];
  price: Types.Scalars['BigDecimal']['input'];
  quantity: number;
  availableMotorLaborApplications?: Array<{
    __typename?: 'CartLabor';
    duration: Types.Scalars['BigDecimal']['input'];
    id: string;
    name?: Types.Scalars['String']['input'] | null;
    notes?: Array<Types.Scalars['String']['input']> | null;
    parentId?: string | null;
    skillCode: Types.Scalars['String']['input'];
    taxonomyName: Types.Scalars['String']['input'];
    taxonomyAction?: Types.Scalars['String']['input'] | null;
    taxonomyId: string;
    baseLaborTimeDescription?: Types.Scalars['String']['input'] | null;
    descriptions?: Array<Types.Scalars['String']['input']> | null;
    position?: { __typename?: 'Position'; name: Types.Scalars['String']['input'] } | null;
    attributes?: Array<{
      __typename?: 'Attribute';
      name?: Types.Scalars['String']['input'] | null;
      text?: Types.Scalars['String']['input'] | null;
    }> | null;
  }> | null;
  laborItems?: Array<{
    __typename?: 'CartLaborItem';
    cartLocalInventoryItemId?: string | null;
    id: string;
    hours: Types.Scalars['Float']['input'];
    defaultHours: Types.Scalars['Float']['input'];
    vin?: Types.Scalars['String']['input'] | null;
    orderItemId?: string | null;
    name: Types.Scalars['String']['input'];
    notes?: Types.Scalars['String']['input'] | null;
    skill: Types.Scalars['String']['input'];
    identifiers?: { __typename?: 'Mitchell1Identifiers' } | { __typename?: 'MotorIdentifiers'; id: string } | null;
    vehicle?: { __typename?: 'Vehicle'; id: string } | null;
    taxonomy?: {
      __typename?: 'LaborTaxonomy';
      action?: Types.Scalars['String']['input'] | null;
      id: string;
      name: Types.Scalars['String']['input'];
    } | null;
  }> | null;
  partType?: { __typename?: 'PartType'; id: string } | null;
  product?: {
    __typename?: 'CartLocalInventoryProduct';
    hasFitment: Types.Scalars['Boolean']['input'];
    localInventoryUID: string;
    partNumber: Types.Scalars['String']['input'];
    partNumberId: Types.Scalars['String']['input'];
    price?: Types.Scalars['BigDecimal']['input'] | null;
    quantity: number;
    title: Types.Scalars['String']['input'];
    attributes: Array<{
      __typename?: 'CartLocalInventoryProductAttribute';
      name: Types.Scalars['String']['input'];
      values: Array<Types.Scalars['String']['input']>;
    }>;
    descriptions: Array<{
      __typename?: 'CartLocalInventoryProductDescription';
      code: Types.Scalars['String']['input'];
      label: Types.Scalars['String']['input'];
      values: Array<Types.Scalars['String']['input']>;
    }>;
    notes: Array<{ __typename?: 'CartLocalInventoryProductNotes'; group: Array<Types.Scalars['String']['input']> }>;
    variations: Array<{
      __typename?: 'CartLocalInventoryProductVariation';
      variationID: Types.Scalars['String']['input'];
      attributes: Array<{
        __typename?: 'CartLocalInventoryProductAttribute';
        name: Types.Scalars['String']['input'];
        values: Array<Types.Scalars['String']['input']>;
      }>;
      notes: Array<{ __typename?: 'CartLocalInventoryProductNotes'; group: Array<Types.Scalars['String']['input']> }>;
    }>;
    warnings: Array<
      | { __typename?: 'CartLocalInventoryProductWarningAttachment'; attachment: Types.Scalars['String']['input'] }
      | { __typename?: 'CartLocalInventoryProductWarningImage'; path: Types.Scalars['String']['input'] }
      | { __typename?: 'CartLocalInventoryProductWarningText'; text: Types.Scalars['String']['input'] }
    >;
  } | null;
  validationError?: {
    __typename?: 'CartLocalInventoryItemValidationError';
    errorType: Types.CartLocalInventoryItemValidationErrorType;
    message?: Types.Scalars['String']['input'] | null;
  } | null;
  vehicle?: { __typename?: 'Vehicle'; id: string } | null;
};

export const CartLocalInventoryItemFragmentDoc = `
    fragment CartLocalInventoryItem on CartLocalInventoryItem {
  availableMotorLaborApplications @include(if: $withLabor) {
    duration
    id
    name
    notes
    parentId
    skillCode
    taxonomyName
    taxonomyAction
    taxonomyId
    position {
      name
    }
    baseLaborTimeDescription
    descriptions
    attributes {
      name
      text
    }
  }
  customerPrice
  id
  image
  laborItems {
    cartLocalInventoryItemId
    id
    identifiers {
      ... on MotorIdentifiers {
        id
      }
    }
    hours
    defaultHours
    vehicle {
      id
    }
    vin
    orderItemId
    name
    notes
    skill
    taxonomy {
      action
      id
      name
    }
  }
  listPrice
  localInventoryUID
  orderGroupId
  partName
  partNumber
  partNumberId
  partType {
    id
  }
  price
  product {
    attributes {
      name
      values
    }
    descriptions {
      code
      label
      values
    }
    hasFitment
    localInventoryUID
    notes {
      group
    }
    partNumber
    partNumberId
    price
    quantity
    title
    variations {
      attributes {
        name
        values
      }
      notes {
        group
      }
      variationID
    }
    warnings {
      ... on CartLocalInventoryProductWarningAttachment {
        attachment
      }
      ... on CartLocalInventoryProductWarningImage {
        path
      }
      ... on CartLocalInventoryProductWarningText {
        text
      }
    }
  }
  quantity
  validationError {
    errorType
    message
  }
  vehicle {
    id
  }
}
    `;
