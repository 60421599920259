import { createAvailability } from './createAvailability';
import type { Quote } from 'models';
import type { OrderItemProductAvailabilityLine, ProductAvailabilityLine } from 'shared/api';

export const createAvailabilityLineFromQuery = (
  line: ProductAvailabilityLine | OrderItemProductAvailabilityLine,
  storeId?: string,
  accountNickname?: string | null,
  variant?: Quote['variant']
) => {
  const availabilityLineData = {
    ...line,
    address: line.address ?? null,
    group: line.group ?? null,
    id: line.id ?? null,
    quantity: line.quantity,
  };

  const availabilityLine = createAvailability(availabilityLineData, variant);

  if (availabilityLine.id === Number(storeId)) {
    availabilityLine.setNickname(accountNickname);
  }

  return availabilityLine;
};
