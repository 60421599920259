import { CartTotals } from 'models';
import type { CartOrder } from 'models';

export const createCartTotalsFromCartOrders = (
  orders: CartOrder[],
  laborRate?: number,
  laborHours?: number,
  localInventory?: CartOrder | null
) => {
  const parts = orders.map((order) => order.items).flat();

  return new CartTotals({
    partCount: parts.reduce((acc, part) => acc + part.quantity, 0),
    partsSum: parts.reduce((acc, { quantity, price }) => acc + quantity * price, 0),
    partsSumRetail: parts.reduce((acc, { quantity, retailPrice }) => acc + quantity * retailPrice, 0),
    discountSum: orders.reduce((acc, order) => acc + (order.totals?.discountSum || 0), 0),
    fetSum: parts.reduce((acc, { fetCharge, quantity }) => acc + quantity * fetCharge, 0),
    coreChargeSum: parts.reduce((acc, { coreCharge, quantity }) => acc + quantity * coreCharge, 0),
    shippingLines: orders
      .map((order) => ({ name: order.title, price: order.totals?.shippingCharge || 0 }))
      .filter((line) => line.price > 0),
    taxesLines: orders
      .map((order) => ({ name: order.title || '', price: order.totals?.taxesCharge || 0 }))
      .filter((line) => line.price > 0),
    hasDiscount: parts.some((part) => part.bundled && part.bundle && part.bundle.savingsAmount > 0),
    hasParts: parts.some((part) => !part.isTire),
    hasTires: parts.some((part) => part.isTire),
    laborRate,
    laborHours,
    hasLocalInventory: (localInventory?.items || []).length > 0,
    localInventoryCount: localInventory?.items?.reduce((acc, item) => acc + item.quantity, 0),
    localInventorySum: localInventory?.items?.reduce((acc, { quantity, price }) => acc + quantity * price, 0),
    localInventorySumRetail: localInventory?.items?.reduce(
      (acc, { quantity, retailPrice }) => acc + quantity * retailPrice,
      0
    ),
  });
};
