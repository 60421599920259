import { useCallback } from 'react';
import { Routes } from 'constant';
import { useComparedQuote } from 'features/comparedQuote';
import { useSearchSequenceStorage } from 'features/searchSequence';
import { useMutationStatus } from 'shared/api';
import { useChangeActiveShopMembershipMutation } from 'store/mutations/currentUser/memberships/changeActiveShopMembership/ChangeActiveShopMembership.generated';
import { useSubscriptionSearchQuery } from '../../features/subscription/hooks/useSubscriptionSearchQuery';

export const useChangeActiveShopMembership = (redirectUrl?: string) => {
  const { reset: resetQuote } = useComparedQuote();
  const { reset: resetSearchSequence } = useSearchSequenceStorage();
  const { reset: resetSubscriptionSearchQuery } = useSubscriptionSearchQuery();

  const [changeActiveMembership, status] = useChangeActiveShopMembershipMutation();

  const handleChangeSuccess = useCallback(() => {
    resetSubscriptionSearchQuery();
    resetQuote();
    resetSearchSequence();
    window.location.replace(redirectUrl || Routes.DASHBOARD);
  }, [resetSubscriptionSearchQuery, resetQuote, resetSearchSequence, redirectUrl]);

  const changeActiveShopMembership = useCallback(
    async (membershipID: string) => {
      await changeActiveMembership({ input: { membershipID } });
    },
    [changeActiveMembership]
  );

  useMutationStatus({ status, onSuccess: handleChangeSuccess });

  return [changeActiveShopMembership] as const;
};
