import { Box, ModalView, useModal } from '@partstech/ui';
import { PaidFeatureAlias } from '../../constants/features';
import { UpgradeSubscriptionButton } from '../UpgradeSubscriptionButton';
import { FeatureDescription } from './FeatureDescription';

type Props = {
  onClose: () => void;
  feature: PaidFeatureAlias;
};

type SettingsByFeature = Record<
  PaidFeatureAlias,
  {
    upgradeButtonLabel?: string;
    title?: string;
    highlightFeature?: PaidFeatureAlias;
  } | null
>;

const byFeature: SettingsByFeature = {
  [PaidFeatureAlias.Jobs]: {
    upgradeButtonLabel: 'Upgrade subscription',
    title: 'Learn about Jobs',
    highlightFeature: PaidFeatureAlias.Jobs,
  },
  [PaidFeatureAlias.CustomJobs]: {
    upgradeButtonLabel: 'Upgrade subscription',
    title: 'Custom Jobs',
    highlightFeature: PaidFeatureAlias.CustomJobs,
  },
  [PaidFeatureAlias.Labor]: {
    upgradeButtonLabel: 'View subscriptions',
    title: 'Learn about Labor',
  },
  [PaidFeatureAlias.Tires]: {
    upgradeButtonLabel: 'Learn more',
    title: 'Upgrade subscription',
    highlightFeature: PaidFeatureAlias.Tires,
  },
  [PaidFeatureAlias.UserPermissions]: {
    upgradeButtonLabel: `Learn about Complete`,
    title: 'Preview permissions',
    highlightFeature: PaidFeatureAlias.UserPermissions,
  },
  [PaidFeatureAlias.PreferredBrands]: {
    upgradeButtonLabel: 'Upgrade subscription',
    title: 'Learn about Preferred Brands',
    highlightFeature: PaidFeatureAlias.PreferredBrands,
  },
  [PaidFeatureAlias.RetailPricing]: {
    upgradeButtonLabel: 'Learn more',
    title: 'Learn about retail pricing',
    highlightFeature: PaidFeatureAlias.RetailPricing,
  },
  [PaidFeatureAlias.TireComparison]: {
    highlightFeature: PaidFeatureAlias.TireComparison,
  },
  [PaidFeatureAlias.Analytics]: null,
  [PaidFeatureAlias.Support]: null,
};

const getModalTitle = (feature: PaidFeatureAlias) => byFeature[feature]?.title ?? '';
const getUpgradeButtonLabel = (feature: PaidFeatureAlias) => byFeature[feature]?.upgradeButtonLabel ?? '';
const getHighlightFeature = (feature: PaidFeatureAlias) => byFeature[feature]?.highlightFeature;

export const LearnAboutFeatureModal = ({ feature, onClose }: Props) => {
  const { size } = useModal();

  return (
    <ModalView title={getModalTitle(feature)} onClose={onClose}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mb={6} minHeight={35}>
          <FeatureDescription feature={feature} />
        </Box>

        <UpgradeSubscriptionButton
          className={`fs-subscription-upgrade-${feature}`}
          highlightFeature={getHighlightFeature(feature)}
          onClick={onClose}
          fullWidth={size === 'dialog'}
        >
          {getUpgradeButtonLabel(feature)}
        </UpgradeSubscriptionButton>
      </Box>
    </ModalView>
  );
};
