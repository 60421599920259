import { Icon, Avatar, OldList, Box } from '@partstech/ui';
import { Routes } from 'constant';
import { getAddSupplierUrl, getMyShopUrl, getRecentOrdersUrl, getSavedQuotesUrl } from 'utils/url';
import { MembershipsButton } from './MembershipsButton';
import { NavigationItem } from './NavigationItem';
import { SavedQuotesIcon } from './SavedQuotesIcon';
import { NavigationItemKey, useAppNavigationList } from './useAppNavigationList';
import { UserpilotWidgetButton } from './UserpilotWidgetButton';
import type { UseAppNavigationListArgs } from './useAppNavigationList';

type Props = UseAppNavigationListArgs;

export const AppNavigationList = ({ onItemClick }: Props) => {
  const {
    avatar,
    initials,
    fullName,
    profileLink,
    showReportsButton,
    showSavedQuotesButton,
    showAddSupplierButton,
    isOpenMyShop,
    isOpenProfile,
    isOpenRecentOrdersPage,
    isOpenSavedQuotesPage,
    isOpenReportsPage,
    isOpenStockOrderPage,
    isOpenAddSupplierPage,
    isOpenRewardsPage,
    selectItem,
    logOut,
  } = useAppNavigationList({ onItemClick });

  return (
    <Box display="flex" justifyContent="space-between" flexDirection="column" height="100%">
      <OldList gap={4} component="nav">
        <NavigationItem
          title={fullName}
          subtitle="Profile + API key"
          link={profileLink}
          onClick={selectItem(NavigationItemKey.Profile)}
          isActive={isOpenProfile}
          adornmentBefore={
            <Avatar src={avatar} backgroundColor={isOpenProfile ? 'primaryDark' : 'subtleText'} initials={initials} />
          }
          data-testid="profileMenuButton"
        />

        <MembershipsButton onClick={selectItem(NavigationItemKey.Memberships)} />

        {showAddSupplierButton && (
          <NavigationItem
            title="Suppliers"
            link={getAddSupplierUrl('all')}
            onClick={selectItem(NavigationItemKey.Suppliers)}
            adornmentBefore={<Icon name="domain" color="subtleText" />}
            isActive={isOpenAddSupplierPage}
            data-testid="suppliersMenuButton"
          />
        )}

        {showSavedQuotesButton && (
          <NavigationItem
            title="Saved quotes"
            link={getSavedQuotesUrl('carts')}
            onClick={selectItem(NavigationItemKey.SavedQuotes)}
            adornmentBefore={<SavedQuotesIcon />}
            isActive={isOpenSavedQuotesPage}
            data-testid="savedQuotesMenuButton"
          />
        )}

        <NavigationItem
          title="Stock orders"
          link={Routes.STOCK_ORDER_NEW}
          onClick={selectItem(NavigationItemKey.StockOrders)}
          adornmentBefore={<Icon name="fact_check" color="subtleText" />}
          isActive={isOpenStockOrderPage}
          data-testid="stockOrdersMenuButton"
        />

        <NavigationItem
          title="Orders"
          link={getRecentOrdersUrl()}
          onClick={selectItem(NavigationItemKey.Orders)}
          adornmentBefore={<Icon name="shopping_cart" color="subtleText" />}
          isActive={isOpenRecentOrdersPage}
          data-testid="ordersMenuButton"
        />

        {showReportsButton && (
          <NavigationItem
            title="Analytics"
            link={Routes.REPORTS}
            onClick={selectItem(NavigationItemKey.Analytics)}
            adornmentBefore={<Icon name="reporting" color="subtleText" />}
            isActive={isOpenReportsPage}
            data-testid="analyticsMenuButton"
          />
        )}

        <NavigationItem
          title="Settings"
          link={getMyShopUrl('details')}
          onClick={selectItem(NavigationItemKey.Settings)}
          adornmentBefore={<Icon name="settings" color="subtleText" />}
          isActive={isOpenMyShop}
          data-testid="settingsMenuButton"
        />
      </OldList>

      <Box mt={4}>
        <OldList gap={4} component="nav">
          <NavigationItem
            title="Refer & earn"
            link={getMyShopUrl('rewards')}
            onClick={selectItem(NavigationItemKey.Rewards)}
            adornmentBefore={<Icon name="featured_seasonal_and_gifts" color="subtleText" />}
            isActive={isOpenRewardsPage}
            data-testid="rewardsMenuButton"
          />

          <UserpilotWidgetButton onClick={selectItem(NavigationItemKey.Help)} />

          <NavigationItem
            title="Logout"
            onClick={logOut}
            adornmentBefore={<Icon name="logout" color="subtleText" />}
            data-testid="logoutMenuButton"
          />
        </OldList>
      </Box>
    </Box>
  );
};
