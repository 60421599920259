import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback, useMemo } from 'react';
import { ModalWrapper } from '../components/ModalWrapper';
import type { SupplierAccount } from 'entities/supplierAccount';

type Props = {
  canChangeSupplierType: boolean;
  isLoading: boolean;
  onChange: (supplierAccount: SupplierAccount) => void;
  selectedSupplierAccountId: string | null;
  supplierAccounts: SupplierAccount[];
};

export const useSupplierAccountSelector = ({
  canChangeSupplierType,
  isLoading,
  onChange,
  selectedSupplierAccountId,
  supplierAccounts,
}: Props) => {
  const selectedSupplierAccount = useMemo(
    () => supplierAccounts.find((account) => account.id === selectedSupplierAccountId) ?? null,
    [supplierAccounts, selectedSupplierAccountId]
  );

  const selectSupplier = useCallback(
    (closeHandler?: () => void) => (supplierAccount: SupplierAccount) => {
      if (selectedSupplierAccountId !== supplierAccount.id) {
        onChange(supplierAccount);
      }
      if (closeHandler) {
        closeHandler();
      }
    },
    [onChange, selectedSupplierAccountId]
  );

  const { open } = useCreateModal(ModalWrapper, {
    size: 'bottomSheet',
    onChange: selectSupplier(),
    value: selectedSupplierAccount,
    supplierAccounts,
    canChangeSupplierType,
  });

  const openModal = useCallback(() => {
    if (!isLoading) {
      open();
    }
  }, [isLoading, open]);

  return {
    openModal,
    selectSupplier,
    selectedSupplierAccount,
  };
};
