import { api as activeCustomJobsApi } from '../getCustomJobs/GetCustomJobs.generated';
import { api as generatedApi } from './ChangeCustomJobPriorities.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    ChangeCustomJobPriorities: {
      onQueryStarted: async ({ input }, { dispatch }) => {
        const { ids } = input;

        dispatch(
          activeCustomJobsApi.util.updateQueryData('GetCustomJobs', undefined, (draft) => {
            const shop = draft?.currentUser?.activeMember?.shop;

            if (!shop || !shop.customJobs) {
              return;
            }

            const jobsById = Object.fromEntries(shop.customJobs.map((job) => [job.id, job]));
            const sortedJobs = ids.map((id) => jobsById[id]);

            Object.assign(shop, { customJobs: sortedJobs });
          })
        );
      },
      invalidatesTags: ['CustomJob', 'VehicleById', 'VehicleByPlateVin'],
    },
  },
});

export const { useChangeCustomJobPrioritiesMutation } = api;
