import { css } from '@emotion/react';
import { Box, OldList, OldNavigationListItem } from '@partstech/ui';
import { useKeyboardInteractions } from '@partstech/ui/hooks';
import { voidFunctionStub } from '@partstech/ui/utils';
import { useCallback, useEffect, useState } from 'react';
import { Region } from 'entities/vehicle';
import { Highlight } from 'shared/ui';
import type { Vehicle, VehicleSuggestion } from 'entities/vehicle';

type Props = {
  onSelect?: (vehicle: string) => void;
  onItemClick?: (vehicle: Vehicle) => void;
  vehicles: (Vehicle | VehicleSuggestion)[];
  disabled?: boolean;
  highlightValue?: string;
};

const styles = {
  item: css`
    display: flex;
    align-items: center;
    height: auto;
  `,
};

const isVehicleSuggestion = (vehicle: Vehicle | VehicleSuggestion): vehicle is VehicleSuggestion =>
  'suggestion' in vehicle;

export const VehiclesList = ({ disabled, highlightValue, onItemClick, onSelect, vehicles }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleClick = useCallback(
    (vehicle: Vehicle | VehicleSuggestion) => () => {
      const vehicleValue = isVehicleSuggestion(vehicle) ? vehicle.toVehicle() : vehicle;

      if (!vehicleValue && isVehicleSuggestion(vehicle) && onSelect) {
        onSelect(vehicle.name);
        return;
      }

      if (onItemClick && vehicleValue) {
        onItemClick(vehicleValue);
      }
    },
    [onItemClick, onSelect]
  );

  const handleEnterPress = useCallback(() => {
    const vehicle = vehicles[selectedIndex];

    if (vehicle) {
      handleClick(vehicle)();
    }
  }, [handleClick, selectedIndex, vehicles]);

  const handleArrowDown = useCallback(() => {
    setSelectedIndex((previous) => (previous !== vehicles.length - 1 ? previous + 1 : 0));
  }, [vehicles.length]);

  useKeyboardInteractions({
    onDownArrow: disabled ? voidFunctionStub : handleArrowDown,
    onUpArrow: disabled
      ? voidFunctionStub
      : () => setSelectedIndex((previous) => (previous !== -1 ? previous - 1 : previous)),
    onEnter: handleEnterPress,
  });

  useEffect(() => {
    setSelectedIndex(-1);
  }, [vehicles, highlightValue]);

  if (vehicles.length <= 1) {
    return null;
  }

  return (
    <Box width="100%" data-testid="vehiclesList">
      <OldList size="slim" fullWidth>
        {vehicles.map((vehicle, index) => (
          <OldNavigationListItem
            key={vehicle.id ?? index}
            active={index === selectedIndex}
            onClick={handleClick(vehicle)}
            css={styles.item}
          >
            <Highlight value={highlightValue ?? ''}>{vehicle.toString()}</Highlight>

            <Region regions={vehicle.regions} />
          </OldNavigationListItem>
        ))}
      </OldList>
    </Box>
  );
};
