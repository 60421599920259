import { useMemo } from 'react';
import { createJobsFromQuery } from 'factories';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { api as generatedApi } from './GetCustomJobs.generated';
import type { GetCustomJobsQuery } from './GetCustomJobs.generated';
import type { Job } from 'shared/api';

const emptyCustomJobs: Job[] = [];

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['CustomJob'],
  endpoints: {
    GetCustomJobs: {
      providesTags: ['CustomJob'],
      transformResponse(baseQueryReturnValue: GetCustomJobsQuery) {
        if (!baseQueryReturnValue) {
          return baseQueryReturnValue;
        }

        const sortedJobs = [...(baseQueryReturnValue.currentUser?.activeMember?.shop?.customJobs ?? [])].sort(
          (a, b) => (a.priority ?? 0) - (b.priority ?? 0)
        );

        return {
          ...baseQueryReturnValue,
          currentUser: {
            ...baseQueryReturnValue.currentUser,
            activeMember: {
              ...baseQueryReturnValue.currentUser?.activeMember,
              shop: {
                ...baseQueryReturnValue.currentUser?.activeMember?.shop,
                customJobs: sortedJobs,
              },
            },
          },
        };
      },
    },
  },
});

const { useGetCustomJobsQuery } = api;

type Args = {
  skip?: boolean;
};

export const useGetCustomJobs = ({ skip = false }: Args = {}) => {
  const { hasCurrentUser } = useGetCurrentUser();

  const { data, isLoading } = useGetCustomJobsQuery(undefined, {
    skip: skip || !hasCurrentUser,
  });

  const jobs = useMemo(() => data?.currentUser?.activeMember?.shop?.customJobs ?? emptyCustomJobs, [data]);
  const customJobs = useMemo(() => createJobsFromQuery(jobs), [jobs]);

  return {
    customJobs,
    isLoading,
  };
};
