import { isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { createVehicleFromQuery, type Vehicle } from 'entities/vehicle';
import { api as generatedApi } from './GetRecentVehicles.generated';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['RecentVehicle'],
  endpoints: {
    GetRecentVehicles: {
      providesTags: ['RecentVehicle'],
    },
  },
});

const { useGetRecentVehiclesQuery } = api;

const emptyRecentVehicles: Vehicle[] = [];

export const useGetRecentVehicles = () => {
  const { data, ...props } = useGetRecentVehiclesQuery();

  const recentVehicles = useMemo(
    () =>
      data?.currentUser?.activeMember?.shop?.recentVehicles
        ?.map((recentVehicle) =>
          createVehicleFromQuery(
            recentVehicle.vehicle
              ? {
                  ...recentVehicle.vehicle,
                  vin: recentVehicle.vin,
                  plate: recentVehicle.plate ?? undefined,
                  state: recentVehicle.state ?? undefined,
                }
              : null
          )
        )
        .filter(isNotNull) ?? emptyRecentVehicles,
    [data?.currentUser?.activeMember?.shop?.recentVehicles]
  );

  return { recentVehicles, ...props };
};
