import type { Vehicle } from 'entities/vehicle';
import type { SearchQuery } from 'features/searchForm';

export interface SearchQueryConvertable {
  isVehicleRequired: () => boolean;
  toQuery(vehicle?: Vehicle | null): SearchQuery;
}

export const isSearchQueryConvertable = (model: object): model is SearchQueryConvertable =>
  'toQuery' in model && 'isVehicleRequired' in model;
