import { useCallback } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { isSearchSequenceCreator, useSearchSequence } from 'features/searchSequence';
import { Diagram } from 'models';
import { getDiagramUrl } from 'utils/url/getDiagramUrl';
import { AttributeRequiredError } from '../errors/AttributeRequiredError';
import { VehicleRequiredError } from '../errors/VehicleRequiredError';
import { isSearchQueryConvertable, SearchUrl } from '../models';
import type { Vehicle } from 'entities/vehicle';
import type { SearchEntryPointInterface, SearchSequenceQuery, SupplierQuery } from 'features/searchForm';

type Props = {
  onAttributeRequired?: () => void;
  onVehicleRequired?: () => void;
};

export const useStartSearch = ({ onAttributeRequired, onVehicleRequired }: Props = {}) => {
  const navigate = useAppNavigate();

  const { startSearchSequence, reset: resetSearchSequence } = useSearchSequence();

  const tryNavigateToSearch = useCallback(
    (searchRequest: SearchUrl) => {
      try {
        navigate(searchRequest.getUrl());
      } catch (e) {
        if (e instanceof VehicleRequiredError) {
          onVehicleRequired?.();
        }

        if (e instanceof AttributeRequiredError) {
          onAttributeRequired?.();
        }
      }
    },
    [navigate, onAttributeRequired, onVehicleRequired]
  );

  const navigateToDiagram = useCallback(
    (diagram: Diagram, vehicle: Vehicle | null) => {
      if (!diagram.category?.id || vehicle === null) {
        return;
      }

      navigate(getDiagramUrl(vehicle.id, diagram.category?.id, diagram.id));
    },
    [navigate]
  );

  return useCallback(
    (
      searchEntry: SearchEntryPointInterface | null,
      vehicle: Vehicle | null,
      preservedQuery?: Partial<SupplierQuery> & Partial<SearchSequenceQuery>
    ) => {
      if (!preservedQuery?.task_type) {
        resetSearchSequence();
      }

      if (!searchEntry) {
        return;
      }

      if (isSearchQueryConvertable(searchEntry)) {
        const searchRequest = new SearchUrl(vehicle, searchEntry);

        searchRequest
          .setIsDealerSearch(Boolean(preservedQuery?.dealers))
          .setSelectedSupplierAccountId(preservedQuery?.selected_distributor ?? null)
          .setSearchSequenceQuery({
            job_id: preservedQuery?.job_id,
            task_type: preservedQuery?.task_type,
            step: preservedQuery?.step,
          });

        tryNavigateToSearch(searchRequest);
        return;
      }

      if (isSearchSequenceCreator(searchEntry)) {
        if (searchEntry.isVehicleRequired?.() && vehicle === null) {
          onVehicleRequired && onVehicleRequired();
          return;
        }

        startSearchSequence(searchEntry, vehicle);
        return;
      }

      if (searchEntry instanceof Diagram) {
        navigateToDiagram(searchEntry, vehicle);
      }
    },
    [navigateToDiagram, onVehicleRequired, resetSearchSequence, startSearchSequence, tryNavigateToSearch]
  );
};
