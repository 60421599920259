import { Button, RoundedButton } from '@partstech/ui';
import { useCallback } from 'react';
import { useComparePlansModal } from '../../hooks/useComparePlansModal';
import { useSubscription } from '../../hooks/useSubscription';
import { ContactSmsTooltip } from '../ContactSmsTooltip';
import type { PaidFeatureAlias } from '../../constants/features';
import type { ButtonProps } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

const components = {
  button: Button,
  roundedButton: RoundedButton,
};

export type UpgradeSubscriptionButtonProps = ButtonProps &
  PropsWithChildren & {
    componentType?: keyof typeof components;
    highlightFeature?: PaidFeatureAlias;
    isNavigated?: boolean;
    onClick?: () => void;
  };

export const UpgradeSubscriptionButton = ({
  children,
  componentType = 'button',
  highlightFeature,
  isNavigated = true,
  onClick,
  ...props
}: UpgradeSubscriptionButtonProps) => {
  const { isRestrictedEX } = useSubscription();

  const { openComparePlansModal } = useComparePlansModal(highlightFeature);

  const handleClick = useCallback(() => {
    if (isNavigated) {
      openComparePlansModal();
    }

    if (onClick) {
      onClick();
    }
  }, [isNavigated, openComparePlansModal, onClick]);

  const Component = components[componentType];

  return (
    <ContactSmsTooltip isHidden={!isRestrictedEX} offsetY={-110}>
      <Component
        data-testid="upgradeSubscriptionButton"
        {...props}
        color="upgrade"
        leadingIcon="upgrade"
        iconColor="secondaryDark10"
        onClick={handleClick}
        disabled={isRestrictedEX}
      >
        {componentType === 'button' ? children || 'Upgrade subscription' : null}
      </Component>
    </ContactSmsTooltip>
  );
};
