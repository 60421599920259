import { Typography, Avatar, Box, CopyField, Link, TextCut, Loader } from '@partstech/ui';
import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { api, useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { EmailNotificationsCheckbox } from './EmailNotificationsCheckbox';

export const Info = () => {
  const dispatch = useAppDispatch();
  const { apiKey, avatar, email, fullName, initials, isPrimary, managementSystem, phone, username } =
    useGetCurrentUser();

  useEffect(() => {
    if (apiKey) {
      return;
    }
    // No way to optimistic update
    dispatch(api.util.invalidateTags([{ type: 'CurrentUser' }]));
  }, [apiKey, dispatch]);

  if (!apiKey) {
    return (
      <Loader
        size="medium"
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={5}
        data-testid="profileInfo"
      />
    );
  }

  return (
    <Box data-testid="profileInfo">
      <Box display="flex" justifyContent="center">
        <Avatar size="x-large" src={avatar} initials={initials} />
      </Box>

      <Box textAlign="center" mt={4}>
        <Typography variant="h3">
          <TextCut>{fullName}</TextCut>
        </Typography>

        <Box mt={1} mb={3}>
          <Typography variant="bodyText2" color="positive">
            {isPrimary ? 'Admin' : 'Staff'}
          </Typography>
        </Box>

        <Typography color="subtleText">
          <TextCut>{email}</TextCut>
        </Typography>

        <Box mt={1}>
          <Typography color="subtleText">{phone}</Typography>
        </Box>
      </Box>

      <Box mt={6}>
        <Typography variant="subtitle2">Notifications</Typography>

        <Box mt={2}>
          <EmailNotificationsCheckbox />
        </Box>
      </Box>

      <Box mt={6}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle2">API</Typography>

          {managementSystem?.manual && (
            <Link isExternal to={managementSystem.manual} variant="caption" color="linkText">
              View detailed setup instructions
            </Link>
          )}
        </Box>

        <Box mt={2}>
          <CopyField label="Username" defaultValue={username ?? ''} />
        </Box>

        <Box mt={3}>
          <CopyField label="API key" defaultValue={apiKey ?? ''} />
        </Box>

        <Typography variant="bodyText2" color="subtleText">
          This API key is only valid for this location.
        </Typography>
      </Box>
    </Box>
  );
};
