import { useMemo } from 'react';
import { DynamicAttributeConfigurator } from 'features/dynamicAttribute';
import { UniversalPartType } from 'models';
import { OptionListGroups } from '../../OptionListGroups';
import { SearchEntryPoints } from '../../SearchEntryPoints';
import type { SearchEntryPointInterface } from '../../../models';
import type { SearchTabs } from '../../../types';
import type { Vehicle } from 'entities/vehicle';

type Props = {
  inputValue: string;
  isVisible: boolean;
  onRecentSearchSelect: () => void;
  onSelect: (shouldShowTypeahead: boolean) => (entryPoint: SearchEntryPointInterface) => void;
  onSelectVehicleClick: () => void;
  onAdditionalButtonClick?: () => void;
  onCreateJobPageOpen?: () => void;
  onSubmit: (searchEntry: SearchEntryPointInterface) => void;
  onTabChange: (type: SearchTabs) => void;
  options: SearchEntryPointInterface[];
  tab: SearchTabs;
  value: SearchEntryPointInterface | undefined | null;
  vehicle: Vehicle | null;
  withAttributeBasedSearch: boolean;
  withJobsPriority: boolean;
  withEnhancedCategories?: boolean;
  withSearchByPartTypes?: boolean;
  withCustomJobs?: boolean;
};

export const SearchFormContent = ({
  inputValue,
  isVisible,
  onRecentSearchSelect,
  onSelect,
  onSelectVehicleClick,
  onAdditionalButtonClick,
  onCreateJobPageOpen,
  onSubmit,
  onTabChange,
  options,
  tab,
  value,
  vehicle,
  withAttributeBasedSearch,
  withJobsPriority,
  withEnhancedCategories = false,
  withCustomJobs = false,
  withSearchByPartTypes,
}: Props) => {
  const shouldShowDynamicAttributeConfigurator = useMemo(
    () => value instanceof UniversalPartType && value.shouldPickAttributes(Boolean(vehicle)),
    [value, vehicle]
  );

  if (shouldShowDynamicAttributeConfigurator && value instanceof UniversalPartType) {
    return (
      <DynamicAttributeConfigurator
        attributeBasedSearch={withAttributeBasedSearch}
        partType={value}
        onSelectVehicleClick={onSelectVehicleClick}
        onChange={onSubmit}
        vehicle={vehicle}
      />
    );
  }

  if (isVisible && inputValue.length > 0) {
    return (
      <OptionListGroups
        data-testid="typeaheadResults"
        highlightText={inputValue}
        options={options}
        onSelect={onSelect(true)}
        onChange={onSubmit}
        withJobsPriority={withJobsPriority}
        withCustomJobs={withCustomJobs}
      />
    );
  }

  return (
    <SearchEntryPoints
      attributeBasedSearch={withAttributeBasedSearch}
      onChange={onSubmit}
      onSelect={onSelect(false)}
      vehicle={vehicle}
      tab={tab}
      onRecentSearchSelect={onRecentSearchSelect}
      onSelectVehicleClick={onSelectVehicleClick}
      onAdditionalButtonClick={onAdditionalButtonClick}
      onCreateJobPageOpen={onCreateJobPageOpen}
      onTabChange={onTabChange}
      withJobsPriority={withJobsPriority}
      withEnhancedCategories={withEnhancedCategories}
      withSearchByPartTypes={withSearchByPartTypes}
    />
  );
};
