import { Box, ListItem, Typography } from '@partstech/ui';
import { FormSwitcher } from '@partstech/ui/forms';
import type { PermissionListItem } from '../../types';
import type { MemberPermissions } from 'shared/api';

type Props = {
  permission: PermissionListItem<keyof MemberPermissions>;
  onChange: () => void;
};

export const PermissionItem = ({ permission, onChange }: Props) => {
  const { label, name, description } = permission;

  return (
    <ListItem data-testid="groupItem">
      <Box pl={8}>
        <FormSwitcher name={name} onChange={onChange}>
          <Typography variant="bodyText2">{label}</Typography>
          <Typography variant="bodyText2" color="subtleText">
            {description}
          </Typography>
        </FormSwitcher>
      </Box>
    </ListItem>
  );
};
