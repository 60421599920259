import { css } from '@emotion/react';
import { Box, Icon, OldList, OldListItem, Scrollbar, TextCut, Typography, useMedia } from '@partstech/ui';
import { useCallback } from 'react';
import { Region } from 'entities/vehicle';
import { useGetRecentVehicles } from 'features/vehicleSelector/api';
import type { BoxProps, Theme } from '@partstech/ui';
import type { Vehicle } from 'entities/vehicle';

const styles = {
  scrollbar: (theme: Theme) => css`
    max-height: ${theme.sizing(120)};
    width: 100%;

    ${theme.breakpoints.down('md')} {
      max-height: 100%;
    }
  `,
};

type Props = {
  isActive?: boolean;
  onChange?: (vehicle: Vehicle | null) => void;
} & BoxProps;

export const RecentVehicles = ({ isActive, onChange, ...boxProps }: Props) => {
  const { isMobile } = useMedia();
  const { recentVehicles } = useGetRecentVehicles();

  const handleClick = useCallback(
    (vehicle: Vehicle | null) => () => {
      if (onChange && vehicle) {
        onChange(vehicle);
      }
    },
    [onChange]
  );

  return (
    <Box width="100%" {...boxProps}>
      <Scrollbar css={styles.scrollbar}>
        <OldList size="dense" fullWidth>
          {recentVehicles.map((vehicle) => (
            <OldListItem key={vehicle.id} type="menu" onClick={handleClick(vehicle)} data-testid="listItem">
              <Box display="flex">
                <Icon name="directions_car" color="subtleText" />
                <Box ml={2}>
                  <Box display="flex" maxWidth={{ sm: 67, md: '100%', lg: 125 }}>
                    <TextCut shouldShowTooltip={false}>
                      <Typography variant={isMobile ? 'bodyText2' : 'bodyText1'} component="span">
                        {vehicle.shortName}
                        {` · `}
                      </Typography>
                      <Typography variant={isMobile ? 'bodyText2' : 'bodyText1'} component="span" color="subtleText">
                        {vehicle.subModel.name} {vehicle.regions && <Region regions={vehicle.regions} />}
                        {vehicle.engine.name}
                      </Typography>
                    </TextCut>
                  </Box>

                  {vehicle.vin && !vehicle.plate && !vehicle.state && (
                    <Typography variant={isMobile ? 'bodyText2' : 'bodyText1'}>VIN: {vehicle.vin}</Typography>
                  )}
                  {vehicle.plate && vehicle.state && (
                    <Typography variant={isMobile ? 'bodyText2' : 'bodyText1'}>
                      PLATE: {vehicle.state} {vehicle.plate}
                    </Typography>
                  )}
                </Box>
              </Box>
            </OldListItem>
          ))}
        </OldList>
      </Scrollbar>
    </Box>
  );
};
