import type { AddToCartPayload } from '../types';
import type { CartLocalInventoryItemParametersInput } from 'shared/api';

export const getAddToCartLocalInventoryInput = (payload: AddToCartPayload): CartLocalInventoryItemParametersInput => ({
  inventoryItemUID: payload.localInventoryUID || '',
  partNumberId: payload.product.getPartNumberId(),
  partTypeId: payload.product.partType?.id ? String(payload.product.partType.id) : undefined,
  quantity: payload.quantity,
  searchPartNumber: payload.searchedPartNumber,
  vehicleId: payload.vehicleId,
  vin: payload.vin,
});
