import { isEmpty, keys } from '@partstech/ui/utils';
import { DEFAULT_AVAILABILITY } from 'constant';
import { filterByAvailability } from './filterByAvailability';
import { filterMatchedTires, getMatchingSetId, MATCHING_SETS_VALUE } from './getMatchingSets';
import { isDynamicAttributeFilterName } from './isDynamicAttributeFilterName';
import type { AttributeName } from 'constant';
import type { Product } from 'models';
import type { CheckedFilters, CreateFiltersConfig, MatchingSets } from 'types/search';

const filterByMatchingSet = (
  product: Product,
  checkedValues: Partial<CheckedFilters>,
  matchingSets: MatchingSets,
  products: Product[],
  supplierId?: string
) => {
  const matchingSet = matchingSets[getMatchingSetId(product)];

  if (isEmpty(matchingSet)) {
    return false;
  }

  const availabilityValue = checkedValues.availability ?? DEFAULT_AVAILABILITY;

  const availableInMatchingSet = filterMatchedTires(
    matchingSet?.filter((matchingProduct) =>
      filterByAvailability(matchingProduct, availabilityValue, products, supplierId)
    ) ?? []
  );

  return availableInMatchingSet.length > 1 && filterByAvailability(product, availabilityValue, products, supplierId);
};

export const applyFiltersToProduct =
  (
    filters: Partial<CheckedFilters>,
    { shouldUseAvailability, shouldUseMatchingSets, matchingSets, supplierId }: Partial<CreateFiltersConfig>
  ) =>
  (product: Product, products: Product[]): boolean => {
    const filterNames = keys(filters);

    return filterNames.every((filterName) => {
      const values = filters[filterName];

      if (!Array.isArray(values)) {
        return true;
      }

      if (values && values.length === 0) {
        return true;
      }

      if (filterName === 'availability') {
        return !shouldUseAvailability || filterByAvailability(product, values, products, supplierId);
      }

      if (filterName === 'parts') {
        return values?.includes(`${product.partType?.id ?? '0'}`);
      }

      if (filterName === 'manufacturers') {
        return values?.includes(product.brand?.name ?? '');
      }

      if (isDynamicAttributeFilterName(filterName)) {
        return true; // Dynamic attributes launch new search every time
      }

      if (filterName === 'HasRebate') {
        return (
          (values.includes('1') && product.rebates.length > 0) || (values.includes('0') && product.rebates.length === 0)
        );
      }

      const attributeValue = product.getAttributeValues(filterName as AttributeName);

      if (shouldUseMatchingSets && filterName === 'Position' && values.includes(MATCHING_SETS_VALUE)) {
        return (
          (matchingSets && filterByMatchingSet(product, filters, matchingSets, products, supplierId)) ||
          values?.filter((value) => value !== MATCHING_SETS_VALUE).some((value) => attributeValue.includes(value))
        );
      }

      return values?.some((value) => attributeValue.includes(value));
    });
  };
