import { Box, Skeleton, useMedia } from '@partstech/ui';

export const ProductCardSkeleton = () => {
  const { isMobile } = useMedia();

  if (isMobile) {
    return (
      <>
        <Box display="flex">
          <Box height={20} width={20} m={2}>
            <Skeleton height="100%" width="100%" />
          </Box>

          <Box height={20} m={2}>
            <Skeleton height="100%" width="100%" />
          </Box>
        </Box>

        <Box height={30} m={2}>
          <Skeleton height="100%" width="100%" />
        </Box>
      </>
    );
  }

  return (
    <Box height={50} px={6} pt={8}>
      <Box display="flex">
        <Skeleton height="100%" width="20%" />

        <Box height={30} m={4} width="100%">
          <Skeleton height="50%" width="30%" />
          <Skeleton height="50%" width="100%" />
        </Box>
      </Box>
    </Box>
  );
};
