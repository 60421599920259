import { getNDaysAgo, getToNDaysAgo } from 'shared/lib/dates';
import { api as generatedApi } from './GetReportsSummary.generated';
import type { GetReportsSummaryQuery } from './GetReportsSummary.generated';

type Reports = NonNullable<GetReportsSummaryQuery['spendReports']>;
export type ReportsSummary = NonNullable<Reports['spendSummary']>;

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetReportsSummary: {
      providesTags: () => ['Report'],
    },
  },
});

const { useGetReportsSummaryQuery } = api;

export const useGetReportsSummary = () => {
  const { data, isLoading } = useGetReportsSummaryQuery({ startDate: getNDaysAgo(30) });

  const { data: prevData, isLoading: isPreviousLoading } = useGetReportsSummaryQuery({
    startDate: getNDaysAgo(60),
    endDate: getToNDaysAgo(30),
  });

  return {
    summary: data?.spendReports?.spendSummary,
    prevSummary: prevData?.spendReports?.spendSummary,
    isLoading: isLoading || isPreviousLoading,
  };
};
