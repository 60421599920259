import { useMemo } from 'react';
import { SortOrder, SpendReportOrdersSortBy } from 'shared/api';
import { parseTimeRange } from '../../lib/parseTimeRange';
import { api as generatedApi } from './GetSpendReportOrders.generated';
import type { GetSpendReportOrdersQuery } from './GetSpendReportOrders.generated';
import type { TimeRange } from '../../lib/useReportsFilters';
import type { TableSettings } from '../../lib/useTableSettings';

type SpendReports = NonNullable<GetSpendReportOrdersQuery['spendReports']>;
export type SpendOrders = NonNullable<SpendReports['spendOrders']>;
export type SpendReportOrder = SpendOrders['items'][0];

const emptyOrders: SpendReportOrder[] = [];

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetSpendReportOrders: {
      providesTags: () => ['Report'],
    },
  },
});

const { useGetSpendReportOrdersQuery } = api;

type Props = {
  timeRange: TimeRange;
  locations: string[];
  tableSettings: TableSettings<SpendReportOrdersSortBy>;
};

export const useGetSpendReportOrders = ({ timeRange, locations, tableSettings }: Props) => {
  const { page, perPage, order, debounceSearchValue } = tableSettings;

  const { fromDate, toDate } = useMemo(() => parseTimeRange(timeRange), [timeRange]);

  const { data, isLoading } = useGetSpendReportOrdersQuery({
    startDate: fromDate,
    endDate: toDate,
    storeIds: locations,
    page,
    perPage,
    sortBy: SpendReportOrdersSortBy.DatePaid,
    sortOrder: order === 'asc' ? SortOrder.Asc : SortOrder.Desc,
    poNumber: debounceSearchValue,
  });

  const orders = useMemo(() => data?.spendReports?.spendOrders?.items || emptyOrders, [data]);
  const totalCount = useMemo(() => data?.spendReports?.spendOrders?.totalCount || 0, [data]);

  return {
    orders,
    totalCount,
    isLoading,
  };
};
