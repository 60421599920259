import { Box, Icon, TextField, useMedia } from '@partstech/ui';

type Props = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
};

export const TableSearch = ({ value, onChange, placeholder = 'Search' }: Props) => {
  const { isMobile } = useMedia();

  return (
    <Box maxWidth={isMobile ? '100%' : 86} mx={isMobile ? 4 : 0} width="100%">
      <TextField
        name="search"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        adornmentBefore={<Icon name="search" color="subtleText" />}
      />
    </Box>
  );
};
