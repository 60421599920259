import { useForm } from '@partstech/ui/forms';
import { useEffect } from 'react';
import { memberPermissionsDefaultValues } from '../../constant/memberPermissionsDefaultValues';
import type { MemberPermissions } from 'shared/api';

type FormData = MemberPermissions;

export type SetPermissionsFormProps = {
  defaultPermissions?: Partial<MemberPermissions>;
};

export const useSetPermissionsForm = ({ defaultPermissions }: SetPermissionsFormProps) => {
  const form = useForm<FormData>();
  const { reset } = form;

  useEffect(() => {
    reset(defaultPermissions ?? memberPermissionsDefaultValues, { keepDirty: true });
  }, [defaultPermissions, reset]);

  return { form };
};
