import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { isDevEnvironment } from 'app/config';
import { getFullStorySessionIdAsync } from 'integrations/fullstory';
import { GraphQLError } from './graphql/GraphQLError';
import { axiosBaseQuery } from './rest/axiosBaseQuery';
import type { ResponseError } from './graphql/GraphQLError';

export enum ApiPath {
  Graphql = '/graphql',
  Rest = '/api',
  Upload = '/g/upload',
  Export = '/g/export',
}

const tagTypes = [
  'ActiveCart',
  'AppConfig',
  'AutomaticPreferredBrand',
  'BoschAccount',
  'CurrentUser',
  'CustomPreferredBrand',
  'ExportedReport',
  'Invitation',
  'Job',
  'CustomJob',
  'LaborRate',
  'Matrix',
  'Member',
  'PartStore',
  'PaymentCard',
  'PricePackage',
  'Product',
  'RecentOrderLine',
  'RecentSearch',
  'RecentVehicle',
  'Report',
  'SavedCart',
  'Shipment',
  'ShopAddress',
  'StockOrders',
  'StockOrderTemplate',
  'Subscription',
  'SubscriptionPayment',
  'SubscriptionUpcomingUpdatePeriodInvoice',
  'SupplierAccount',
  'SupplierBySlug',
  'SystemAlert',
  'TaxRate',
  'TireStore',
  'Vehicle',
  'VehicleById',
  'VehicleByPlateVin',
] as const;

export const getApiUrl = (path: ApiPath) =>
  process.env.NODE_ENV === 'test' ? `https://app.beta.partstech.com${path}` : path;

export const api = createApi({
  baseQuery: graphqlRequestBaseQuery({
    url: getApiUrl(ApiPath.Graphql),
    customErrors: (error): ResponseError<{}> =>
      new GraphQLError({
        errors: (error.response.errors ?? []) as unknown as ResponseError<{}>['errors'],
        status: error.response.status,
      }).toSerializable(),
    prepareHeaders: async (headers) => {
      const fsid = await getFullStorySessionIdAsync(isDevEnvironment());
      headers.set('X-FSID', fsid);
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes,
  keepUnusedDataFor: 3600 * 6, // 6 hours in seconds
});

export const shopApi = createApi({
  reducerPath: 'shopApi',
  baseQuery: axiosBaseQuery({ baseUrl: getApiUrl(ApiPath.Rest) }),
  endpoints: () => ({}),
  tagTypes,
});

export const gUploadApi = createApi({
  reducerPath: 'gUploadApi',
  baseQuery: axiosBaseQuery({ baseUrl: getApiUrl(ApiPath.Upload) }),
  endpoints: () => ({}),
  tagTypes,
});

export const gExportApi = createApi({
  reducerPath: 'gExportApi',
  baseQuery: axiosBaseQuery({ baseUrl: getApiUrl(ApiPath.Export) }),
  endpoints: () => ({}),
  tagTypes,
});
