import { useCallback } from 'react';
import { useGetAddresses } from 'entities/address';
import { createStoreFromQueryData } from 'entities/store';
import { createSupplierFromQueryData } from 'entities/supplier';
import { createSupplierAccountFromData } from 'entities/supplierAccount';
import { FullStoryEventNames, useFullStory } from 'integrations/fullstory';
import { useAddSupplierAccountMutation } from 'store/mutations/currentUser/supplierAccounts/addAccount';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { convertFormFieldsToAccountField } from 'utils';

type AddShopAccountPayload = {
  credentials: Record<string, string>;
  additionalInformation?: Record<string, string>;
  supplierId: string;
  nickname?: string;
  storeId: string;
};

export const useAddSupplierAccount = () => {
  const { sendEvent } = useFullStory();

  const { defaultAddress } = useGetAddresses();
  const { shop } = useGetCurrentUser();

  const [addAccount, status] = useAddSupplierAccountMutation();

  const addSupplierAccount = useCallback(
    async (payload: AddShopAccountPayload) => {
      const response = await addAccount({
        input: {
          ...payload,
          additionalInformation: convertFormFieldsToAccountField(payload.additionalInformation),
          credentials: convertFormFieldsToAccountField(payload.credentials),
        },
      });

      if ('error' in response) {
        return response;
      }

      const account = response.data.addShopAccount?.account;

      if (!account) {
        return null;
      }

      const supplier = account?.supplier ? createSupplierFromQueryData(account.supplier) : null;

      const store = account?.store
        ? createStoreFromQueryData(account.store, supplier, defaultAddress?.coordinates ?? null, shop?.pickupRadius)
        : null;

      sendEvent(FullStoryEventNames.ADD_SUPPLIER, {
        supplier: supplier?.name,
        supplierID: supplier?.id,
        supplierType: supplier?.type,
        isServiceArea: supplier?.isServesLocation(defaultAddress),
      });

      return createSupplierAccountFromData(account, supplier, store);
    },
    [addAccount, defaultAddress, sendEvent, shop?.pickupRadius]
  );

  return [addSupplierAccount, status] as const;
};
