import { isNotNull } from '@partstech/ui/utils';
import { captureException } from 'integrations/sentry';
import { convertQueryToRecentSearch } from './convertQueryToRecentSearch';
import { api as generatedApi } from './GetRecentSearches.generated';
import type { RecentSearchesFragment } from './RecentSearchesFragment.generated';
import type { RecentSearchInterface } from '../../model';

const tryConvertQueryToRecentSearch = (queryResult: RecentSearchesFragment): RecentSearchInterface | null => {
  try {
    return convertQueryToRecentSearch(queryResult);
  } catch (e) {
    captureException(e);
    return null;
  }
};

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['RecentSearch'],
  endpoints: {
    GetRecentSearches: {
      providesTags: () => ['RecentSearch'],
    },
  },
});

const { useGetRecentSearchesQuery } = api;

const emptyRecentSearches: RecentSearchInterface[] = [];

export const useGetRecentSearches = () => {
  const { data, ...props } = useGetRecentSearchesQuery();

  const recentSearches =
    data?.currentUser?.activeMember?.shop?.recentSearches?.map(tryConvertQueryToRecentSearch).filter(isNotNull) ??
    emptyRecentSearches;

  return {
    recentSearches,
    ...props,
  };
};
