import { ModalView, Typography } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';

export const useBestPriceBundleDealModal = () => {
  const { open: openBestPriceBundleDealModal } = useCreateModal(
    ModalView,
    {
      size: 'small',
      title: 'Best Price',
      children: (
        <Typography>
          You are already receiving the best price for the bundle in your cart so you are not eligible to receive an
          additional discount.
        </Typography>
      ),
    },
    { customId: 'bestPrice' }
  );

  return { openBestPriceBundleDealModal };
};
