import { usePermissions } from 'entities/permission';
import { PreferredBrandsMode } from 'shared/api';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

export const usePreferredBrandsMode = () => {
  const { shop, isLoading } = useGetCurrentUser();

  const {
    shop: { preferredBrandsAllowed },
  } = usePermissions();

  return {
    isLoading,
    partsPreferredBrandsMode: shop?.partsPreferredBrands || PreferredBrandsMode.Disable,
    tiresPreferredBrandsMode: shop?.tiresPreferredBrands || PreferredBrandsMode.Disable,
    isFreePreferredBrands:
      shop?.partsPreferredBrands &&
      shop.partsPreferredBrands !== PreferredBrandsMode.Disable &&
      !preferredBrandsAllowed,
  };
};
