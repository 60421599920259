import { isNotNull } from '@partstech/ui/utils';
import { useCallback, useMemo, useState } from 'react';
import { usePartTypesWithSubCategory } from 'hooks/partType';
import { PartCategory, PartSubCategory, PartType } from 'models';
import { useSubCategory } from 'store/queries/categories/useSubCategory';
import { MIN_SEARCH_LENGTH } from '../constants';
import { getAllSubCategoriesFromCategories } from '../utils';
import { useSelectedPartTypes } from './useSelectedPartTypes';
import type { BreadcrumbsProps } from '@partstech/ui';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type React from 'react';

const emptySubCategories: PartSubCategory[] = [];

type UseCategoriesResult = {
  breadcrumbs: BreadcrumbsProps['breadcrumbs'];
  categories: PartCategory[];
  options: SearchEntryPointInterface[];
  partTypes: PartType[];
  selectOption: (option: SearchEntryPointInterface) => void;
  selectedCategory: PartCategory | null;
  selectedSubCategory: PartSubCategory | null;
  findCategory: (categoryId: string) => PartCategory | undefined;
  resetCategory: () => void;
  subCategories: PartSubCategory[];
  selectedPartTypes: PartType[];
  isSelectedPartType: (partType: PartType) => boolean;
  removePartType: (partType: PartType) => void;
};

type Props = {
  categories: PartCategory[];
  onChange?: (partTypes: PartType[]) => void;
  searchValue: string;
};

export const useCategorySelection = ({
  categories: rawCategories,
  onChange,
  searchValue,
}: Props): UseCategoriesResult => {
  const [selectedCategory, setSelectedCategory] = useState<PartCategory | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<PartSubCategory | null>(null);

  const {
    selectedPartTypes,
    removePartType,
    togglePartType,
    isSelected: isSelectedPartType,
  } = useSelectedPartTypes({ onChange });

  const shouldStartSearching = searchValue.length >= MIN_SEARCH_LENGTH;

  const { partTypes: partTypesByString } = usePartTypesWithSubCategory(
    { search: searchValue },
    { skip: !shouldStartSearching }
  );
  const { partTypes: partTypesByCategories } = useSubCategory({
    categoryId: selectedSubCategory?.categoryId,
    subCategoryId: selectedSubCategory?.id,
  });

  const partTypes = useMemo(() => {
    if (shouldStartSearching) {
      return partTypesByString;
    }

    if (searchValue.length > 0) {
      return [];
    }

    return partTypesByCategories;
  }, [partTypesByCategories, partTypesByString, searchValue.length, shouldStartSearching]);

  const categories = useMemo(() => {
    if (shouldStartSearching) {
      return rawCategories.filter((category) => category.isMatchedToString(searchValue));
    }

    return rawCategories;
  }, [rawCategories, searchValue, shouldStartSearching]);

  const allSubCategories = useMemo(() => getAllSubCategoriesFromCategories(rawCategories), [rawCategories]);

  const subCategories = useMemo(() => {
    if (shouldStartSearching) {
      return (
        allSubCategories?.filter((subCategory) => subCategory.isMatchedToString(searchValue)) ?? emptySubCategories
      );
    }

    return selectedCategory?.subCategories ?? emptySubCategories;
  }, [allSubCategories, searchValue, selectedCategory?.subCategories, shouldStartSearching]);

  const findCategory = useCallback(
    (categoryId: string) => rawCategories.find((category) => category.id === categoryId),
    [rawCategories]
  );

  const options = useMemo(() => {
    if (selectedSubCategory) {
      return partTypes;
    }

    if (selectedCategory) {
      return selectedCategory.subCategories;
    }

    return categories;
  }, [categories, partTypes, selectedCategory, selectedSubCategory]);

  const selectOption = useCallback(
    (option: SearchEntryPointInterface) => {
      if (option instanceof PartCategory) {
        setSelectedCategory((prev) => (prev === option ? null : option));
        setSelectedSubCategory(null);
        return;
      }

      if (option instanceof PartSubCategory) {
        setSelectedSubCategory((prev) => (prev === option ? null : option));
        setSelectedCategory(option.categoryId ? (findCategory(option.categoryId) ?? null) : null);
        return;
      }

      if (option instanceof PartType) {
        togglePartType(option);
      }
    },
    [findCategory, togglePartType]
  );

  const resetCategory = useCallback(() => {
    setSelectedCategory(null);
    setSelectedSubCategory(null);
  }, []);

  const breadcrumbs = useMemo(
    () =>
      [
        {
          title: 'All Categories',
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            resetCategory();
          },
        },
        selectedCategory && {
          title: selectedCategory.name,
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            setSelectedSubCategory(null);
          },
        },
        selectedSubCategory && {
          title: selectedSubCategory?.name,
        },
      ].filter(isNotNull),
    [resetCategory, selectedCategory, selectedSubCategory]
  );

  return {
    breadcrumbs,

    categories,
    resetCategory,
    selectedCategory,
    findCategory,

    subCategories,
    selectedSubCategory,

    options,
    selectOption,

    partTypes,
    selectedPartTypes,
    isSelectedPartType,
    removePartType,
  };
};
