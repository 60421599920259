import { useMemo } from 'react';
import { createCategoryFromQuery, createPartTypesFromQuery } from 'factories';

import { TireSize, UniversalPartType } from 'models';
import { useGetSearchEntryPointsQuery } from '../api/getSearchEntryPoints/GetSearchEntryPoints.generated';
import type { Vehicle } from 'entities/vehicle';
import type { Diagram, Job, PartCategory, TireConfiguration } from 'models';

const emptyTireSizes: TireSize[] = [];
const emptyCategories: PartCategory[] = [];
const emptyDiagrams: Diagram[] = [];
const emptyJobs: Job[] = [];
const emptyTireConfigurations: TireConfiguration[] = [];

export const useDefaultOptions = (vehicle: Vehicle | null = null) => {
  const { data, isLoading: isEntryPointsLoading } = useGetSearchEntryPointsQuery();

  const partTypes = useMemo(() => createPartTypesFromQuery(data?.popularPartTypes), [data?.popularPartTypes]);
  const categories = useMemo(
    () => data?.categories?.map(createCategoryFromQuery) ?? emptyCategories,
    [data?.categories]
  );
  const tireSizes = useMemo(
    () => data?.tireSizes?.map((size) => new TireSize(size)) ?? emptyTireSizes,
    [data?.tireSizes]
  );

  const diagrams = vehicle?.diagrams ?? emptyDiagrams;
  const jobs = vehicle?.jobs ?? emptyJobs;
  const vehicleTireSizes = vehicle?.tires ?? emptyTireConfigurations;

  const tiresOptions = useMemo(() => [...tireSizes, ...vehicleTireSizes], [tireSizes, vehicleTireSizes]);

  const fluidsAndChemicals = useMemo(
    () =>
      partTypes.filter(
        (partType): partType is UniversalPartType => !partType.isFitted && partType instanceof UniversalPartType
      ),
    [partTypes]
  );

  return {
    categories,
    diagrams,
    isLoading: isEntryPointsLoading,
    fluidsAndChemicals,
    jobs,
    partTypes,
    tireSizes,
    tiresOptions,
  };
};
