import type { AttributeInput, LocalInventorySearchInput } from 'shared/api';

export type ToLocalInventoryResourcesParams = {
  attributes: AttributeInput[] | undefined;
  vehicleId: string | undefined;
  vin: string | undefined;
};

export interface SearchLocalInventoryRequestConvertable {
  toLocalInventoryRequest(params: ToLocalInventoryResourcesParams): LocalInventorySearchInput;
}

export const isSearchLocalInventoryRequestConvertable = (
  model: object
): model is SearchLocalInventoryRequestConvertable => 'toLocalInventoryRequest' in model;
