import type { ProductQueryParams, ProductUrlParams } from 'types/product';

export const queryToUrlParams = (query: ProductQueryParams): ProductUrlParams => ({
  partNumberId: decodeURIComponent(query.partnumberid),
  partTypeId: query.part_term ?? null,
  lineCardId: query.linecard ?? null,
  supplierId: query.supplierId ?? null,
  credentialId: query.credentialId ?? null,
  vehicle: query.vehicle,
});
