import { isNotNull, uniqBy } from '@partstech/ui/utils';
import { TrackingStatus as DeliveryStatus } from 'shared/api';
import type { CartOrderItem } from './CartOrderItem';
import type { SupplierAccount } from 'entities/supplierAccount';
import type { Vehicle } from 'entities/vehicle';
import type { OrderPaymentWay, OrderStatus, TrackingStatus } from 'shared/api';

type OrderTotals = {
  discountSum: number;
  shippingCharge: number;
  taxesCharge: number;
  grandTotal?: number;
};

const deliveredStatuses: DeliveryStatus[] = [
  DeliveryStatus.Completed,
  DeliveryStatus.Cancelled,
  DeliveryStatus.Returned,
];

type AdditionalFieldOption = {
  text: string;
  value: string;
};

export type AdditionalField = {
  default?: string;
  label: string;
  name: string;
  options: AdditionalFieldOption[];
  required: boolean;
  type: string;
  value: string | null;
};

export type ShippingMethod = {
  name: string;
  charge: number;
  code: string;
};

type FieldValidation = {
  max: number | null;
  message: string | null;
  min: number | null;
  regex: string | null;
};

export type CartOrderData = {
  id: string;
  title: string;
  items: CartOrderItem[];
  totals: OrderTotals | null;
  isPaid: boolean;
  poNumber?: string | null;
  error?: string | null;
  warning?: string | null;
  supplierAccount?: SupplierAccount | null;
  rewardPoint?: number;
  additionalFields?: AdditionalField[];
  availableShippingMethods?: ShippingMethod[];
  shippingMethodCode?: string;
  preferredShippingMethodCode?: string;
  poNumberValidation?: FieldValidation | null;
  notes?: string;
  notesValidation?: FieldValidation | null;
  paymentWay?: OrderPaymentWay | null;
  status?: OrderStatus | null;
  lastTrackingDate?: string | null;
  canMarkAsDelivered?: boolean | null;
  trackingStatus?: TrackingStatus | null;
  placedDate?: string | null;
};

export class CartOrder {
  static readonly NON_VEHICLE = 'nonVehicle';

  id: string;

  title: string;

  items: CartOrderItem[];

  totals: OrderTotals | null;

  poNumber: string | null;

  poNumberValidation: FieldValidation | null;

  notes: string;

  notesValidation: FieldValidation | null;

  isPaid: boolean;

  error: string | null;

  warning: string | null;

  supplierAccount: SupplierAccount | null;

  rewardPoint: number;

  additionalFields: AdditionalField[];

  availableShippingMethods: ShippingMethod[];

  shippingMethodCode: string;

  preferredShippingMethodCode: string;

  paymentWay: OrderPaymentWay | null;

  status: OrderStatus | null;

  lastTrackingDate: string | null;

  canMarkAsDelivered: boolean | null;

  trackingStatus: TrackingStatus | null;

  placedDate: string | null;

  constructor(data: CartOrderData) {
    this.id = data.id;
    this.title = data.title;
    this.items = data.items;
    this.totals = data.totals;
    this.poNumber = data.poNumber || null;
    this.poNumberValidation = data.poNumberValidation || null;
    this.notes = data.notes || '';
    this.notesValidation = data.notesValidation || null;
    this.isPaid = data.isPaid;
    this.error = data.error || null;
    this.warning = data.warning || null;
    this.supplierAccount = data.supplierAccount || null;
    this.rewardPoint = data.rewardPoint || 0;
    this.additionalFields = data.additionalFields || [];
    this.availableShippingMethods = data.availableShippingMethods || [];
    this.shippingMethodCode = data.shippingMethodCode || '';
    this.preferredShippingMethodCode = data.preferredShippingMethodCode || '';
    this.paymentWay = data.paymentWay || null;
    this.status = data.status || null;
    this.lastTrackingDate = data.lastTrackingDate || null;
    this.canMarkAsDelivered = data.canMarkAsDelivered || null;
    this.trackingStatus = data.trackingStatus || null;
    this.placedDate = data.placedDate || null;
  }

  getPartsByVehicle(selectedVehicle: string | null, availableVehicles: Vehicle[]): CartOrderItem[] {
    if (selectedVehicle === null) {
      return this.items;
    }

    if (selectedVehicle === CartOrder.NON_VEHICLE) {
      const availableVehicleIds = availableVehicles.map((item) => item.id);

      return this.items.filter((itme) => (itme.vehicle?.id ? !availableVehicleIds.includes(itme.vehicle.id) : true));
    }

    return this.items.filter((itme) => itme.vehicle?.id === selectedVehicle);
  }

  getFilteredItems(itemIdsToFilter: string[]): CartOrderItem[] {
    return this.items.filter((item) => !itemIdsToFilter.includes(item.id));
  }

  shippingMethod(): ShippingMethod | null {
    return this.availableShippingMethods.find((method) => method.code === this.shippingMethodCode) || null;
  }

  vehicles(): Vehicle[] {
    return uniqBy(this.items.map((item) => item.vehicle).filter(isNotNull), (vehicle) => vehicle.id);
  }

  rewardPoints(): number {
    return this.items?.reduce((acc, current) => acc + (current.rewardPoint ?? 0), 0);
  }

  totalParts(): number {
    return this.items.reduce((acc, current) => acc + current.quantity, 0) || 0;
  }

  canOrderBeDelivered(): boolean {
    return Boolean(
      this.canMarkAsDelivered && this.trackingStatus !== null && !deliveredStatuses.includes(this.trackingStatus)
    );
  }

  isOrderFailed(): boolean {
    return this.status === 'FAILED';
  }

  isOrderSuccessful(): boolean {
    return this.status !== 'FAILED';
  }
}
