import { Tab, Tabs, useMedia } from '@partstech/ui';
import { TABS } from '../../constants';
import { useSupplierAccountList } from '../../hooks/useSupplierAccountList';
import { MobileWarning } from '../MobileWarning';
import { SupplierAccountItem } from '../SupplierAccountItem';
import { SupplierInstructions } from '../SupplierInstructions';
import type { SupplierAccount } from 'entities/supplierAccount';

export type SupplierSelectorListProps = {
  supplierAccounts: SupplierAccount[];
  onChange: (supplierAccount: SupplierAccount) => void;
  value: SupplierAccount | null;
  canChangeSupplierType: boolean;
};

export const SupplierAccountList = ({
  supplierAccounts,
  value,
  canChangeSupplierType,
  onChange,
}: SupplierSelectorListProps) => {
  const { isMobile } = useMedia();

  const { renderTooltip, changeTab, supplierAccountsByType, activeTab, isDisabled, hasWarning } =
    useSupplierAccountList({
      supplierAccounts,
      value,
      canChangeSupplierType,
    });

  return (
    <>
      <Tabs activeTab={activeTab} onChange={changeTab} tabWidth={1 / 2}>
        {TABS.map((tab) => (
          <Tab id={tab} key={tab}>
            {tab.toUpperCase()}
          </Tab>
        ))}
      </Tabs>

      {supplierAccountsByType.length === 0 && (
        <SupplierInstructions supplierType={activeTab === 'parts' ? 'part' : 'tire'} />
      )}

      {hasWarning && <MobileWarning isPartStockOrder={activeTab === 'tires'} />}

      {(!hasWarning || !isMobile) &&
        supplierAccountsByType.map((supplierAccount) => (
          <SupplierAccountItem
            key={supplierAccount.id}
            onClick={onChange}
            supplierAccount={supplierAccount}
            value={value?.id}
            disabled={isDisabled(supplierAccount)}
            tooltipMessage={renderTooltip(supplierAccount)}
          />
        ))}
    </>
  );
};
