import { css } from '@emotion/react';
import { Box, Breadcrumbs, Card, Icon, Menu, MenuItem, Scrollbar, TextField, Typography } from '@partstech/ui';
import { useCallback } from 'react';
import { useInputValue } from 'shared/lib/form';
import { useCategorySelection } from '../../hooks/useCategorySelection';
import { EmptyState } from './EmptyState';
import { SelectedPartTypes } from './SelectedPartTypes';
import type { PartTypeList, PartCategory, PartSubCategory, PartType } from 'models';

type Props = {
  categories: PartCategory[];
  onChange: (partTypes: PartTypeList) => void;
  variant: 'compact' | 'extended';
  withSearchByPartTypes?: boolean;
};

const styles = {
  menu: (variant: Props['variant']) => () => css`
    overflow: auto;
    flex: 1;

    > .ui-card {
      ${variant === 'extended' &&
      `
      width: 100%;
      max-width: 33%;
      `}

      &:not(&:last-of-type) {
        ${variant === 'extended' && `margin-right: 16px`}
      }

      flex: 1;
      max-height: 100%;
    }
  `,
};

export const CategoryNavigator = ({
  categories: defaultCategories,
  onChange,
  variant = 'extended',
  withSearchByPartTypes = false,
}: Props) => {
  const { changeValue, debouncedValue, clearValue } = useInputValue('');

  const {
    breadcrumbs,
    categories,
    partTypes,
    selectOption,
    selectedCategory,
    selectedSubCategory,
    selectedPartTypes,
    subCategories,
    isSelectedPartType,
    removePartType,
  } = useCategorySelection({ categories: defaultCategories, searchValue: debouncedValue });

  const handleMenuItemClick = useCallback(
    (option: PartCategory | PartSubCategory | PartType) => () => {
      selectOption(option);
    },
    [selectOption]
  );

  const isSearchMode = debouncedValue.length > 0;

  const hasSubCategories = subCategories.length > 0;
  const hasPartTypes = partTypes.length > 0;

  const notFound = isSearchMode && !hasPartTypes;

  const shouldShowCategories = (variant === 'extended' || !selectedCategory) && !isSearchMode;

  const shouldShowSubCategories =
    hasSubCategories &&
    !isSearchMode &&
    (variant === 'extended' || (variant === 'compact' && selectedCategory && !selectedSubCategory));

  const shouldShowPartTypes =
    hasPartTypes && (variant === 'extended' || (variant === 'compact' && (selectedSubCategory || isSearchMode)));

  return (
    <Box display="flex" flexDirection="column" gap={4} width="100%" height={125}>
      {withSearchByPartTypes && (
        <TextField placeholder="Search by part type" onChange={changeValue} isClearable onClear={clearValue} />
      )}

      {variant === 'compact' && selectedCategory && !isSearchMode && (
        <Breadcrumbs breadcrumbs={breadcrumbs} background="mono2" display="flex" px={4} py={2} />
      )}

      {notFound ? (
        <EmptyState />
      ) : (
        <Box display="flex" flex="1" css={styles.menu(variant)}>
          {shouldShowCategories && (
            <Card elevation={0} width="100%" borderColor="mono6">
              <Scrollbar>
                {variant === 'extended' && (
                  <Box mx={4} pt={4}>
                    <Typography variant="overline" textTransform="uppercase">
                      Select category
                    </Typography>
                  </Box>
                )}

                <Menu fullWidth builtIn>
                  {categories.map((category) => (
                    <MenuItem
                      selected={selectedCategory?.id === category.id}
                      suffix={selectedCategory?.id === category.id ? <Icon name="remove" /> : <Icon name="add" />}
                      key={category.id}
                      onClick={handleMenuItemClick(category)}
                    >
                      {category.toString()}
                    </MenuItem>
                  ))}
                </Menu>
              </Scrollbar>
            </Card>
          )}

          {shouldShowSubCategories && (
            <Card elevation={0} width="100%" borderColor="mono6">
              <Scrollbar>
                {variant === 'extended' && (
                  <Box mx={4} pt={4}>
                    <Typography variant="overline" textTransform="uppercase">
                      Select subcategory
                    </Typography>
                  </Box>
                )}

                <Menu builtIn>
                  {subCategories.map((subCategory) => {
                    const isSelected =
                      selectedSubCategory?.id === subCategory.id && selectedCategory?.id === subCategory.categoryId;

                    return (
                      <MenuItem
                        selected={isSelected}
                        suffix={isSelected ? <Icon name="remove" /> : <Icon name="add" />}
                        key={`${subCategory.id}-${subCategory.categoryId}`}
                        onClick={handleMenuItemClick(subCategory)}
                      >
                        {subCategory.toString()}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Scrollbar>
            </Card>
          )}

          {shouldShowPartTypes && (
            <Card elevation={0} width="100%" borderColor="mono6">
              <Scrollbar>
                {variant === 'extended' && (
                  <Box mx={4} pt={4}>
                    <Typography variant="overline" textTransform="uppercase">
                      Select part type
                    </Typography>
                  </Box>
                )}

                <Menu builtIn>
                  {partTypes.map((partType) => {
                    const isSelected = isSelectedPartType(partType);

                    return (
                      <MenuItem
                        selected={isSelected}
                        prefix={isSelected ? <Icon name="check_box" /> : <Icon name="check_box_blank" />}
                        key={partType.toString()}
                        onClick={handleMenuItemClick(partType)}
                      >
                        {partType.toString()}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Scrollbar>
            </Card>
          )}
        </Box>
      )}

      <SelectedPartTypes partTypes={selectedPartTypes} onRemove={removePartType} onChange={onChange} />
    </Box>
  );
};
