import { Box, Typography } from '@partstech/ui';
import categoriesImg from 'images/icons/search-tabs/categories.svg';

export const EmptyState = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height={150}
    width="100%"
    flexDirection="column"
    gap={4}
  >
    <img src={categoriesImg} alt="" />
    <Typography>No results found for your search</Typography>
  </Box>
);
