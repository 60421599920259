import { Box, Icon, Typography } from '@partstech/ui';
import { ConnectionTime } from 'shared/api';
import type { BoxProps, ThemeColorName } from '@partstech/ui';

const iconColors: Record<Exclude<ConnectionTime, ConnectionTime.Na>, ThemeColorName> = {
  [ConnectionTime.LessOneMin]: 'positive',
  [ConnectionTime.Today]: 'primaryLight',
  [ConnectionTime.NextDay]: 'neutral',
  [ConnectionTime.OnePlusDays]: 'secondaryDark10',
};

const values: Record<Exclude<ConnectionTime, ConnectionTime.Na>, string> = {
  [ConnectionTime.LessOneMin]: '< 1 MIN',
  [ConnectionTime.Today]: 'TODAY',
  [ConnectionTime.NextDay]: 'TOMORROW',
  [ConnectionTime.OnePlusDays]: '1+ DAYS',
};

type Props = BoxProps & {
  connectionTime: ConnectionTime;
};

export const SupplierConnectionTime = ({ connectionTime, ...props }: Props) => {
  if (connectionTime === 'NA') {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" gap={1} {...props}>
      <Icon name="dot" color={iconColors[connectionTime]} size="medium" />

      <Typography variant="overline">CONNECTION TIME: {values[connectionTime]}</Typography>
    </Box>
  );
};
