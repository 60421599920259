import { createSelector, createSlice } from '@reduxjs/toolkit';
import { createStoreFromData } from 'entities/store';
import { getAllSuppliersSearch } from 'store/entities/store';
import { selectSupplierById } from 'store/entities/supplier';
import { selectRootState } from 'store/utils';
import type { Store } from 'shared/api/rest/gen/shop';
import type { RootState } from 'store';

type State = {
  partStores: Store[];
  tireStores: Store[];
  isLoadingPartStores: boolean;
  isLoadingTireStores: boolean;
  isUninitializedPartStores: boolean;
  isUninitializedTireStores: boolean;
};

const initialState: State = {
  partStores: [],
  tireStores: [],
  isLoadingPartStores: false,
  isLoadingTireStores: false,
  isUninitializedPartStores: true,
  isUninitializedTireStores: true,
};

const suppplierSearchSlice = createSlice({
  name: 'supplierSearch',
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSuppliersSearch.pending, (state, action) => {
        if (action.meta.arg.searchType === 'parts') {
          state.isLoadingPartStores = true;
        }
        if (action.meta.arg.searchType === 'tires') {
          state.isLoadingTireStores = true;
        }
      })
      .addCase(getAllSuppliersSearch.rejected, (state, action) => {
        if (action.meta.arg.searchType === 'parts') {
          state.isLoadingPartStores = false;
          state.isUninitializedPartStores = false;
        }
        if (action.meta.arg.searchType === 'tires') {
          state.isLoadingTireStores = false;
          state.isUninitializedTireStores = false;
        }
      })
      .addCase(getAllSuppliersSearch.fulfilled, (state, action) => {
        const { partStores, tireStores } = action.payload;

        if (action.meta.arg.searchType === 'parts') {
          state.partStores = partStores;
          state.isLoadingPartStores = false;
          state.isUninitializedPartStores = false;
        }
        if (action.meta.arg.searchType === 'tires') {
          state.tireStores = tireStores;
          state.isLoadingTireStores = false;
          state.isUninitializedTireStores = false;
        }
      });
  },
});

export const supplierSearch = suppplierSearchSlice.reducer;

export const { resetState } = suppplierSearchSlice.actions;

const selectState = (state: RootState) => state.features.supplierSearch;

const denormalizeStores = (rootState: RootState, stores: Store[]) =>
  stores.map((store) => {
    const supplier = selectSupplierById(rootState, String(store.supplierId));

    return createStoreFromData(store, supplier);
  });

export const selectSearchedStoresState = createSelector(
  [selectRootState, selectState, (_, supplierType: 'parts' | 'tires' | 'dealer') => supplierType],
  (rootState, state, supplierType) => {
    if (supplierType === 'tires') {
      return {
        stores: denormalizeStores(rootState, state.tireStores),
        isLoading: state.isLoadingTireStores,
        isUninitialized: state.isUninitializedTireStores,
      };
    }

    if (supplierType === 'dealer') {
      const stores = denormalizeStores(rootState, state.partStores).filter((store) => store.supplier?.isDealer);

      return {
        stores,
        isLoading: state.isLoadingPartStores,
        isUninitialized: state.isUninitializedPartStores,
      };
    }

    return {
      stores: denormalizeStores(rootState, state.partStores),
      isLoading: state.isLoadingPartStores,
      isUninitialized: state.isUninitializedPartStores,
    };
  }
);
