import { gExportApi } from 'shared/api';

type Params = {
  from: string;
  to?: string | null;
  storeIds?: string | null;
  partTypeId: number;
  sortBy?: 'totalPrice' | 'totalQuantity' | null;
  order?: 'asc' | 'desc' | null;
};

const api = gExportApi.injectEndpoints({
  endpoints: (build) => ({
    GetPartTypeReportExport: build.query<string, Params>({
      query: (params) => ({ method: 'GET', url: '/parts-report/products', params }),
      serializeQueryArgs: (params) => params,
      providesTags: () => ['ExportedReport'],
    }),
  }),
});

export const { useLazyGetPartTypeReportExportQuery } = api;
