/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { StoreFragmentDoc } from '../../../../shared/api/graphql/fragment/Store.generated';
import { SupplierFragmentDoc } from '../../../../shared/api/graphql/fragment/Supplier.generated';
import { SupplierAccountFragmentDoc } from './SupplierAccount.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetSupplierAccountsQueryVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;

export type GetSupplierAccountsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        accounts?: Array<{
          __typename?: 'Account';
          customerDescription?: Types.Scalars['String']['input'] | null;
          id: string;
          nickname?: Types.Scalars['String']['input'] | null;
          priority: number;
          status: Types.AccountStatus;
          store?: {
            __typename?: 'Store';
            name: Types.Scalars['String']['input'];
            id: string;
            logo?: Types.Scalars['String']['input'] | null;
            dropship: Types.Scalars['Boolean']['input'];
            hotshot: number;
            phone?: Types.Scalars['String']['input'] | null;
            pickUp: Types.Scalars['Boolean']['input'];
            warehouse: Types.Scalars['Boolean']['input'];
            address: {
              __typename?: 'Address';
              address1: Types.Scalars['String']['input'];
              address2?: Types.Scalars['String']['input'] | null;
              city: Types.Scalars['String']['input'];
              country: Types.Country;
              state: Types.Scalars['String']['input'];
              zipCode: Types.Scalars['String']['input'];
            };
            coordinates?: {
              __typename?: 'Coordinates';
              longitude: Types.Scalars['Float']['input'];
              latitude: Types.Scalars['Float']['input'];
            } | null;
          } | null;
          supplier?: {
            __typename?: 'Supplier';
            allowCustomNotes: Types.Scalars['Boolean']['input'];
            allowCustomPurchaseOrderNumbers: Types.Scalars['Boolean']['input'];
            allowStockOrders: Types.Scalars['Boolean']['input'];
            allowStoreSelect: Types.Scalars['Boolean']['input'];
            autoValidation: Types.Scalars['Boolean']['input'];
            checkoutMessage?: Types.Scalars['String']['input'] | null;
            connectionTime: Types.ConnectionTime;
            comingSoon: Types.Scalars['Boolean']['input'];
            coreChargePolicy?: Types.Scalars['String']['input'] | null;
            coreReturns: Types.Scalars['Boolean']['input'];
            displayAction: Types.DisplayAction;
            hideLocation: Types.Scalars['Boolean']['input'];
            id: string;
            isTire: Types.Scalars['Boolean']['input'];
            isTireConnect: Types.Scalars['Boolean']['input'];
            lockedStore: Types.Scalars['Boolean']['input'];
            logo: Types.Scalars['String']['input'];
            marketingDescription?: Types.Scalars['String']['input'] | null;
            name: Types.Scalars['String']['input'];
            orderingSupported: Types.Scalars['Boolean']['input'];
            paid: Types.Scalars['Boolean']['input'];
            registrationInstruction?: Types.Scalars['String']['input'] | null;
            returnPolicy?: Types.Scalars['String']['input'] | null;
            type: Types.SupplierType;
            tutorialUrl?: Types.Scalars['String']['input'] | null;
            slug: Types.Scalars['String']['input'];
            additionalInformationConfiguration?: {
              __typename?: 'AdditionalInformation';
              title: Types.Scalars['String']['input'];
              description: Types.Scalars['String']['input'];
              fields: Array<{
                __typename?: 'ConfigurationField';
                helper?: Types.Scalars['String']['input'] | null;
                internalName: Types.Scalars['String']['input'];
                label: Types.Scalars['String']['input'];
                link?: Types.Scalars['String']['input'] | null;
                options: Array<Types.Scalars['String']['input']>;
                required: Types.Scalars['Boolean']['input'];
                type: Types.ConfigurationFieldType;
                validationRules?: {
                  __typename?: 'ValidationRule';
                  inputType: Types.ValidationInputType;
                  length?: Types.Scalars['String']['input'] | null;
                } | null;
              }>;
            } | null;
            credentialsConfiguration: Array<{
              __typename?: 'ConfigurationField';
              helper?: Types.Scalars['String']['input'] | null;
              internalName: Types.Scalars['String']['input'];
              label: Types.Scalars['String']['input'];
              link?: Types.Scalars['String']['input'] | null;
              options: Array<Types.Scalars['String']['input']>;
              required: Types.Scalars['Boolean']['input'];
              type: Types.ConfigurationFieldType;
              validationRules?: {
                __typename?: 'ValidationRule';
                inputType: Types.ValidationInputType;
                length?: Types.Scalars['String']['input'] | null;
              } | null;
            }>;
            outageType?: {
              __typename?: 'Outage';
              type: Types.OutageType;
              message?: Types.Scalars['String']['input'] | null;
            } | null;
            serveLocations: Array<{
              __typename?: 'ServeLocation';
              country: Types.Country;
              regions: Array<Types.Scalars['String']['input']>;
            }>;
          } | null;
          additionalInformation: Array<{
            __typename?: 'AccountField';
            name: Types.Scalars['String']['input'];
            value?: Types.Scalars['String']['input'] | null;
          }>;
          credentials: Array<{
            __typename?: 'AccountField';
            name: Types.Scalars['String']['input'];
            value?: Types.Scalars['String']['input'] | null;
          }>;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export const GetSupplierAccountsDocument = `
    query GetSupplierAccounts($ids: [ID!]) {
  currentUser {
    activeMember {
      shop {
        accounts(ids: $ids) {
          ...SupplierAccount
          store {
            ...Store
          }
          supplier {
            ...Supplier
          }
        }
      }
    }
  }
}
    ${SupplierAccountFragmentDoc}
${StoreFragmentDoc}
${SupplierFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSupplierAccounts: build.query<GetSupplierAccountsQuery, GetSupplierAccountsQueryVariables | void>({
      query: (variables) => ({ document: GetSupplierAccountsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSupplierAccountsQuery, useLazyGetSupplierAccountsQuery } = injectedRtkApi;
