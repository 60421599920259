/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdateOrderNotesMutationVariables = Types.Exact<{
  customNotes: Types.Scalars['String']['input'];
  orderId: Types.Scalars['ID']['input'];
}>;

export type UpdateOrderNotesMutation = {
  __typename?: 'Mutation';
  updateActiveCartOrderCustomNotes?:
    | { __typename?: 'UpdateOrderCustomNotesErrorPayload'; errorMessage: Types.Scalars['String']['input'] }
    | {
        __typename?: 'UpdateOrderCustomNotesSuccessPayload';
        updatedOrderId: string;
        order?: { __typename?: 'CartOrder'; customNotes?: Types.Scalars['String']['input'] | null } | null;
      }
    | null;
};

export const UpdateOrderNotesDocument = `
    mutation UpdateOrderNotes($customNotes: String!, $orderId: ID!) {
  updateActiveCartOrderCustomNotes(
    input: {customNotes: $customNotes, orderId: $orderId}
  ) {
    ... on UpdateOrderCustomNotesErrorPayload {
      errorMessage
    }
    ... on UpdateOrderCustomNotesSuccessPayload {
      updatedOrderId
      order {
        customNotes
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateOrderNotes: build.mutation<UpdateOrderNotesMutation, UpdateOrderNotesMutationVariables>({
      query: (variables) => ({ document: UpdateOrderNotesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateOrderNotesMutation } = injectedRtkApi;
