import { Box, Icon, Tooltip, Typography } from '@partstech/ui';
import { AccountStatus as Statuses } from 'shared/api';
import { StatusTooltip } from './StatusTooltip';
import type { IconProps, TooltipProps } from '@partstech/ui';
import type { SupplierAccount } from 'entities/supplierAccount';
import type { PropsWithChildren } from 'react';

type Label = {
  name: IconProps['name'];
  color: IconProps['color'];
};

type StatusInfoType = Record<Statuses, Label | null>;

const iconVariant: StatusInfoType = {
  [Statuses.Processing]: { name: 'pending', color: 'neutral' },
  [Statuses.Approved]: { name: 'check_circle', color: 'positive' },
  [Statuses.NotApproved]: { name: 'warning', color: 'negative' },
  [Statuses.HelpRequested]: { name: 'help', color: 'neutral' },
  [Statuses.PaymentPending]: { name: 'pending', color: 'neutral' },
  [Statuses.Pending]: { name: 'pending', color: 'neutral' },
  [Statuses.PartstechHelpRequested]: { name: 'pending', color: 'neutral' },
  [Statuses.ReadyForReview]: { name: 'pending', color: 'neutral' },
  [Statuses.SupplierApprovalNeeded]: { name: 'pending', color: 'neutral' },
};

type Props = PropsWithChildren<
  {
    account: SupplierAccount;
    showMessage?: boolean;
  } & Partial<TooltipProps>
>;

export const AccountStatus = ({ account, showMessage, children, ...props }: Props) => {
  const { status, statusMessage, customerDescription, supplier } = account;

  const iconProps: Label | null = supplier?.comingSoon ? { name: 'timer', color: 'negative' } : iconVariant[status];

  const comingSoonDescription = `You can pre-register for ${supplier?.name} and we will notify you when they become available`;

  if (!iconProps) {
    return null;
  }

  return (
    <Tooltip
      {...props}
      tooltip={
        <StatusTooltip
          label={statusMessage}
          description={supplier?.comingSoon ? comingSoonDescription : (customerDescription ?? '')}
        />
      }
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Icon {...iconProps} size="large" />

        {showMessage && (
          <Typography component="div" variant="bodyText2" color={iconProps.color}>
            {statusMessage}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};
