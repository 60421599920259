import { useCallback } from 'react';
import { useMatch } from 'react-router-dom';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { useHashNavigate } from 'app/AppRouter/useHashNavigate';
import { HashRoutes, Routes } from 'constant';
import { usePermissions } from 'entities/permission';
import { useLogOut } from 'features/logOut';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { usePunchoutInfo } from 'store/queries/punchoutInfo/usePunchoutInfo';
import { getMyShopUrl } from 'utils/url';

export enum NavigationItemKey {
  Profile = 'profile',
  Memberships = 'memberships',
  Suppliers = 'suppliers',
  SavedQuotes = 'saved-quotes',
  StockOrders = 'stock-orders',
  Orders = 'orders',
  Analytics = 'analytics',
  Settings = 'settings',
  Rewards = 'rewards',
  Help = 'help',
  LogOut = 'logout',
}

export type UseAppNavigationListArgs = {
  onItemClick?: (key: NavigationItemKey) => void;
};

export const useAppNavigationList = ({ onItemClick }: UseAppNavigationListArgs) => {
  const { pathname, hash } = useAppLocation();

  const { generateHashUrl } = useHashNavigate();

  const { avatar, fullName, initials, isAllowedToModifySuppliers } = useGetCurrentUser();
  const { punchoutEnabled } = usePunchoutInfo();

  const {
    member: { reportsDashboardAccessAllowed },
  } = usePermissions();

  const [logOut] = useLogOut();

  const isMyShopPage = Boolean(useMatch(Routes.MY_SHOP));
  const isSubscriptionPage = Boolean(useMatch(Routes.SUBSCRIPTION));
  const isOpenRecentOrdersPage = Boolean(useMatch(Routes.RECENT_ORDERS));
  const isOpenSavedQuotesPage = Boolean(useMatch(Routes.SAVED_QUOTES));
  const isOpenAddSupplierPage = Boolean(useMatch(Routes.ADD_SUPPLIER));
  const isOpenRewardsPage = Boolean(useMatch(getMyShopUrl('rewards')));

  const isOpenMyShop = (isMyShopPage || isSubscriptionPage) && !isOpenRewardsPage;
  const isOpenProfile = hash === HashRoutes.PROFILE;
  const isOpenReportsPage = pathname.includes(Routes.REPORTS);
  const isOpenStockOrderPage = pathname.includes(Routes.STOCK_ORDER);

  const selectItem = useCallback(
    (key: NavigationItemKey) => () => {
      onItemClick?.(key);
    },
    [onItemClick]
  );

  const handleLogOut = useCallback(() => {
    onItemClick?.(NavigationItemKey.LogOut);

    logOut();
  }, [logOut, onItemClick]);

  return {
    avatar,
    initials,
    profileLink: generateHashUrl({ hash: HashRoutes.PROFILE }),
    fullName,
    showReportsButton: reportsDashboardAccessAllowed,
    showSavedQuotesButton: !punchoutEnabled,
    showAddSupplierButton: isAllowedToModifySuppliers,
    isOpenMyShop,
    isOpenProfile,
    isOpenRecentOrdersPage,
    isOpenSavedQuotesPage,
    isOpenReportsPage,
    isOpenStockOrderPage,
    isOpenAddSupplierPage,
    isOpenRewardsPage,
    selectItem,
    logOut: handleLogOut,
  };
};
