import { Box, Link, Typography } from '@partstech/ui';
import { URL } from 'constant';
import { Copyright } from 'shared/ui';
import type { BoxProps } from '@partstech/ui';

export const SignUpFooter = (props: BoxProps) => (
  <Box textAlign="center" {...props}>
    <Link to={URL.HOME} isExternal variant="caption" underline="hover" color="subtleText">
      PartsTech Marketing site
    </Link>

    <Typography variant="caption" color="subtleText" component="p">
      By clicking “Sign up” or “Sign in with Google” you agree to our
    </Typography>

    <div>
      <Link to={URL.TERMS_OF_SERVICE} isExternal color="linkText" underline="hover" variant="caption">
        Terms of service
      </Link>{' '}
      <Typography variant="caption" color="subtleText">
        and
      </Typography>{' '}
      <Link to={URL.PRIVACY_POLICY} isExternal color="linkText" underline="hover" variant="caption">
        Privacy Policy
      </Link>
    </div>

    <Copyright />
  </Box>
);
