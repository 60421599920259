import { PaidFeatureAlias } from '../../../constants/features';
import { CustomJobs } from './CustomJobs';
import { Jobs } from './Jobs';
import { Labor } from './Labor';
import { Permissions } from './Permissions';
import { PreferredBrands } from './PreferredBrands';
import { RetailPricing } from './RetailPricing';
import { Tires } from './Tires';
import type { ReactNode } from 'react';

type Props = { feature: PaidFeatureAlias };

const Descriptions: Record<PaidFeatureAlias, (() => ReactNode) | null> = {
  [PaidFeatureAlias.PreferredBrands]: PreferredBrands,
  [PaidFeatureAlias.RetailPricing]: RetailPricing,
  [PaidFeatureAlias.TireComparison]: null,
  [PaidFeatureAlias.UserPermissions]: Permissions,
  [PaidFeatureAlias.Analytics]: null,
  [PaidFeatureAlias.Jobs]: Jobs,
  [PaidFeatureAlias.CustomJobs]: CustomJobs,
  [PaidFeatureAlias.Labor]: Labor,
  [PaidFeatureAlias.Support]: null,
  [PaidFeatureAlias.Tires]: Tires,
};

export const FeatureDescription = ({ feature }: Props) => {
  const Description = Descriptions[feature];

  if (!Description) {
    return null;
  }

  return <Description />;
};
