import { useMemo } from 'react';
import { SortOrder } from 'shared/api';
import { parseTimeRange } from '../../lib/parseTimeRange';
import { api as generatedApi } from './GetTireReportPurchases.generated';
import type { GetTireReportPurchasesQuery } from './GetTireReportPurchases.generated';
import type { TimeRange } from '../../lib/useReportsFilters';
import type { TableSettings } from '../../lib/useTableSettings';
import type { TireReportsSizesSortBy } from 'shared/api';

type TireReport = NonNullable<GetTireReportPurchasesQuery['tireReports']>;
export type TireReportPurchases = NonNullable<TireReport['tireSizes']>;
export type TireReportPurchase = NonNullable<TireReportPurchases['statistic']>[0];

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetTireReportPurchases: {
      providesTags: () => ['Report'],
    },
  },
});

const { useGetTireReportPurchasesQuery } = api;

type Props = {
  timeRange: TimeRange;
  locations: string[];
  tableSettings: TableSettings<TireReportsSizesSortBy>;
};

const emptyPurchases: TireReportPurchase[] = [];

export const useGetTireReportPurchases = ({ timeRange, locations, tableSettings }: Props) => {
  const { page, perPage, order, debounceSearchValue, sortBy } = tableSettings;

  const { fromDate, toDate } = useMemo(() => parseTimeRange(timeRange), [timeRange]);

  const { data, isLoading } = useGetTireReportPurchasesQuery({
    startDate: fromDate,
    endDate: toDate,
    supplierIds: locations,
    page,
    perPage,
    sortBy,
    sortOrder: order === 'asc' ? SortOrder.Asc : SortOrder.Desc,
    search: debounceSearchValue,
  });

  const tirePurchases = useMemo(() => data?.tireReports?.tireSizes?.statistic || emptyPurchases, [data]);
  const totalCount = useMemo(() => data?.tireReports?.tireSizes?.totalCount || 0, [data]);

  return {
    tirePurchases,
    totalCount,
    isLoading,
  };
};
