import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

type DatadogUserData = {
  email: string;
  id: string;
  isDemo: boolean;
  isSupplier: boolean;
  name: string;
  shopManagementSystemName: string;
  username: string;
  shop: {
    id: string | null;
    name: string | null;
  };
};

export const useIdentifyDatadog = (user: DatadogUserData | null) => {
  useEffect(() => {
    if (!user) {
      return;
    }

    datadogRum.setUser(user);
  }, [user]);
};
