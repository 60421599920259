import { LocalInventoryAvailabilityLine, Quote } from 'models';

export const createLocalInventoryQuoteFromQuery = (quantity: number) =>
  new Quote({
    action: 'ADD_TO_CART',
    status: 'LOCAL_INVENTORY',
    lines: [
      new LocalInventoryAvailabilityLine({
        address: null,
        group: null,
        id: null,
        quantity,
        name: '',
        type: 'MAIN',
      }),
    ],
    variant: 'default',
  });
