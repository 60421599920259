import { useMemo } from 'react';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

export const useGetDatadogContext = () => {
  const { fullName, email, username, accountId, isSupplier, managementSystem, isDemo, shop, isSuccess } =
    useGetCurrentUser();

  return useMemo(() => {
    if (!isSuccess) {
      return null;
    }

    return {
      email: email ?? '',
      id: accountId ?? '',
      isDemo: isDemo ?? false,
      isSupplier,
      name: fullName,
      shopManagementSystemName: managementSystem?.name ?? '',
      username: username ?? '',
      shop: {
        id: shop?.id ?? null,
        name: shop?.name ?? null,
      },
    };
  }, [
    accountId,
    email,
    fullName,
    isDemo,
    isSuccess,
    isSupplier,
    managementSystem?.name,
    shop?.id,
    shop?.name,
    username,
  ]);
};
