import { AvailabilityTooltip } from 'constant';
import { Availability } from '../Availability';
import { AvailableOrderStatus } from '../Statuses/AvailableOrderStatus';
import { InStockStatus } from '../Statuses/InStockStatus';
import type { AvailabilityLine, Quote } from 'models';

type Props = {
  allowSelect: boolean;
  onStoreChange?: (store: AvailabilityLine) => void;
  quote: Quote;
  store?: AvailabilityLine;
  usePortal?: boolean;
  disableAutoPlacement?: boolean;
};

export const InStockAvailability = ({
  allowSelect,
  quote,
  store,
  onStoreChange,
  usePortal,
  disableAutoPlacement,
}: Props) => {
  if (!store) {
    return null;
  }

  return (
    <Availability
      mt={1}
      allowSelect={allowSelect}
      store={store}
      stores={quote.lines}
      message={store.isMainInStock() ? '' : AvailabilityTooltip.IN_NETWORK}
      status={store.isNetwork() ? AvailableOrderStatus : InStockStatus}
      onStoreChange={onStoreChange}
      usePortal={usePortal}
      disableAutoPlacement={disableAutoPlacement}
    />
  );
};
