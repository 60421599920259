/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type DeleteCustomJobMutationVariables = Types.Exact<{
  input: Types.DeleteJobInput;
}>;

export type DeleteCustomJobMutation = {
  __typename?: 'Mutation';
  deleteJob?: { __typename?: 'DeleteJobPayload'; id: string } | null;
};

export const DeleteCustomJobDocument = `
    mutation DeleteCustomJob($input: DeleteJobInput!) {
  deleteJob(input: $input) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DeleteCustomJob: build.mutation<DeleteCustomJobMutation, DeleteCustomJobMutationVariables>({
      query: (variables) => ({ document: DeleteCustomJobDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteCustomJobMutation } = injectedRtkApi;
