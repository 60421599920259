import { Typography, Box, TextCut } from '@partstech/ui';
import { StoreName } from '../StoreName';
import type { Store } from '../../model/Store';
import type { BoxProps } from '@partstech/ui';
import type { SupplierAccount } from 'entities/supplierAccount/@x/store';

const typographySizes = {
  medium: { name: 'subtitle2', address: 'bodyText2' },
  default: { name: 'subtitle2', address: 'caption' },
  large: { name: 'subtitle2', address: 'bodyText1' },
} as const;

type Props = {
  store: Store;
  account?: SupplierAccount | null;
  linesCount?: {
    name?: number;
    address?: number;
  };
  size?: keyof typeof typographySizes;
} & BoxProps;

export const StoreAddress = ({ store, account, linesCount, size = 'default', ...props }: Props) => {
  const typographyVariant = typographySizes[size];

  return (
    <Box {...props}>
      <StoreName
        name={account?.name || store.name}
        icon={store.warehouse ? 'apartment' : undefined}
        variant={typographyVariant.name}
        linesCount={linesCount?.name}
      />

      {!store.warehouse && (
        <Typography component="span" variant={typographyVariant.address}>
          <TextCut linesCount={linesCount?.address}>{store.formattedAddress ?? ''}</TextCut>
        </Typography>
      )}
    </Box>
  );
};
