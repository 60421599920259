import { Routes } from 'constant';
import { generateUrl } from './generateUrl';
import type { SupplierAccountType } from 'features/supplierAccount';

export type SuppliersSearchQuery = Partial<{
  tab: 'tires' | 'parts';
}>;

type Args = {
  supplierType?: SupplierAccountType | null;
  search?: SuppliersSearchQuery;
};

export const getSuppliersUrl = ({ supplierType = null, search }: Args = {}) =>
  generateUrl(Routes.SUPPLIERS, { supplierType }, search);
