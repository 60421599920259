import { client } from 'shared/api/rest/client';
import type { Config } from 'shared/api/rest/client';

export function login(body: LoginRequest, config?: Config): Promise<LoginResponse> {
  return client.post(`/api/login`, body, config);
}

export function googleAuthUri(config?: Config): Promise<string> {
  return client.get(`/api/google/auth`, config);
}

export function googleLogin(query: GoogleLoginQuery, config?: Config): Promise<void> {
  return client.get(`/api/google/login`, { params: query, ...config });
}

export function getFsStats(config?: Config): Promise<GetFsStatsResponse> {
  return client.get(`/api/fs-stats`, config);
}

export function getStore(id: number, config?: Config): Promise<Store> {
  return client.get(`/api/stores/${id}`, config);
}

export function getSuppliers(query: GetSuppliersQuery, config?: Config): Promise<Supplier[]> {
  return client.get(`/api/suppliers`, { params: query, ...config });
}

export function getSupplierProfile(slug: string, config?: Config): Promise<Supplier> {
  return client.get(`/api/suppliers/slug/${slug}`, config);
}

export function getStores(query: GetStoresQuery, config?: Config): Promise<Store[]> {
  return client.get(`/api/stores`, { params: query, ...config });
}

export function searchStores(query: SearchStoresQuery, config?: Config): Promise<SearchStoresResponse> {
  return client.get(`/api/suppliers/stores`, { params: query, ...config });
}

export function searchTireStores(query: SearchTireStoresQuery, config?: Config): Promise<Store[]> {
  return client.get(`/api/suppliers/tire-stores`, { params: query, ...config });
}

export function getSupplierStores(
  supplierId: number,
  query: GetSupplierStoresQuery,
  config?: Config
): Promise<Store[]> {
  return client.get(`/api/suppliers/${supplierId}/stores`, { params: query, ...config });
}

export function credentialsHelp(config?: Config): Promise<void> {
  return client.post(`/api/profile/credentials/help-request`, null, config);
}

export function logout(config?: Config): Promise<void> {
  return client.post(`/api/logout`, null, config);
}

export function checkMitchell1Token(
  body: CheckMitchell1TokenRequest,
  config?: Config
): Promise<CheckMitchell1TokenResponse> {
  return client.post(`/api/mitchell1/setup/check`, body, config);
}

export function refreshMitchell1Token(
  body: RefreshMitchell1TokenRequest,
  config?: Config
): Promise<RefreshMitchell1TokenResponse> {
  return client.post(`/api/mitchell1/setup/refresh`, body, config);
}

export function generateMitchell1Token(
  body: GenerateMitchell1TokenRequest,
  config?: Config
): Promise<GenerateMitchell1TokenResponse> {
  return client.post(`/api/mitchell1/setup/auth`, body, config);
}

export function batchGetLabor(body: BatchGetLaborRequest, config?: Config): Promise<BatchGetLaborResponse> {
  return client.post(`/api/labor/batch`, body, config);
}

export type LoginRequest = {
  password: string;
  rememberMe: boolean;
  username: string;
};

export type LoginResponse = {
  success: boolean;
  url: string;
};

export type GoogleLoginQuery = {
  code: string;
};

export type GetFsStatsResponse = {
  smsValidatedDate: string | null;
};

export type GetSuppliersQuery = {
  ids: string | number[];
};

export type GetStoresQuery = {
  ids: string | number[];
};

export type SearchStoresResponse = {
  center: Coordinates;
  stores: Store[];
};

export type SearchStoresQuery = {
  q?: string;
  lat?: number;
  lng?: number;
  box?: {
    bottom: number;
    left: number;
    right: number;
    top: number;
  };
};

export type SearchTireStoresQuery = {
  q?: string;
  country?: 'US' | 'CA' | 'MX';
  state?: string;
};

export type GetSupplierStoresQuery = {
  keyword?: string;
  from?: number;
  storeId?: number;
};

export type CheckMitchell1TokenRequest = {
  token: string;
};

export type CheckMitchell1TokenResponse = {
  username: string;
};

export type RefreshMitchell1TokenRequest = {
  token: string;
};

export type RefreshMitchell1TokenResponse = {
  token: string;
};

export type GenerateMitchell1TokenRequest = {
  apiKey: string;
  login: string;
};

export type GenerateMitchell1TokenResponse = {
  token: string;
};

export type BatchGetLaborRequest = {
  procedureDetails: {
    mitchell1VehicleId?: string | null;
    partTypeId: number;
    vehicleId: number;
  }[];
};

export type BatchGetLaborResponse = {
  procedureDetails: LaborProcedureDetail[];
};

export type AdditionalInformationField = {
  internalName: string;
  label: string;
  required: boolean;
  type: 'text' | 'phone' | 'email';
  value: string | null;
};

export type Address = {
  address1: string;
  address2: string | null;
  city: string;
  country?: 'US' | 'CA' | 'MX';
  state: string;
  zipCode: string;
};

export type Coordinates = {
  latitude: number;
  longitude: number;
};

export type CredentialField = {
  helper: string | null;
  internalName: string;
  label: string;
  link: string | null;
  options: string[];
  required: boolean;
  type: 'text' | 'password' | 'select' | 'checkbox';
  validationRules: {
    inputType: 'any' | 'numeric' | 'alphabetic' | 'email';
    length: string | null;
  } | null;
  value: string | null;
};

export type Credentials = {
  additionalInformation?: CredentialsAdditionalInformation;
  credentials: CredentialField[];
  customerDescription: string;
  id: number;
  nickname?: string | null;
  priority: number;
  status: 0 | 1 | 2 | 20 | 24 | 25 | 30 | 40 | 45;
  storeId: number | null;
  supplierId: number;
};

export type CredentialsAdditionalInformation = {
  description: string | null;
  fields: AdditionalInformationField[];
  title: string;
};

export type Error = {
  errors: {
    code: number;
    field: string | null;
    message: string;
  }[];
};

export type ErrorLine = {
  code: number;
  field: string | null;
  message: string;
};

export type Labor = Record<string, LaborLine>;

export type LaborLine = {
  duration: number;
  id: string;
  name: string;
  notes: string;
  parent_id: string | null;
  skill: string;
};

export type LaborProcedureDetail = {
  availableMitchell1Vehicles: {
    bodyStyle: string;
    country: string;
    driveType: string;
    engine: string;
    engineCode: string;
    fuelType: string;
    id: string;
    make: string;
    mitchell1VehicleId: string;
    model: string;
    submodel: string;
    transferCaseType: string;
    transmissionCode: string;
    transmissionControlType: string;
  }[];
  error: {
    code: number;
    message: string;
  } | null;
  mitchell1VehicleId: string | null;
  operationProcedures: {
    error: {
      code: number;
      message: string;
    } | null;
    operation: {
      component: string;
      group: string;
      id: number;
      operation: string;
      subgroup?: string;
    };
    procedure: {
      applicationGroups: {
        additionalProcedures: LaborProcedureInformation[];
        applicationGroupName: string;
        combinationProcedures: LaborProcedureInformation[];
        items: LaborProcedureItem[];
        procedureNote: string | null;
      }[];
      component: string;
      group: string;
      operation: string;
      skillLevel: string | null;
      subgroup: string;
    } | null;
  }[];
  partTypeId: number;
  vehicleId: number | null;
};

export type LaborProcedureInformation = {
  component: string;
  items: LaborProcedureItem[];
  operation: string;
  procedureNote: string | null;
  skillLevel: string | null;
};

export type LaborProcedureItem = {
  additionalApplication: string | null;
  applicationNote: string | null;
  key: string;
  standardTime: number;
  vehicleApplication: string | null;
  warrantyTime: number;
};

export type Mitchell1Order = {
  ConfirmationNumber?: string;
  DeliveryOptions?: string;
  OrderMessage?: string;
  Parts?: Mitchell1OrderPart2[];
  ReservedPONumber?: string | null;
  TrackingNumber?: string | null;
  type?: string;
};

export type Mitchell1OrderPart2 = {
  Description: string;
  LocationId: string;
  LocationName: string;
  ManufacturerLineCode: string;
  ManufacturerName: string;
  Metadata?: string | null;
  PartCategory?: string | null;
  PartNumber: string;
  QuantityAvailable: number;
  QuantityOrdered: number;
  QuantityRequested: number;
  ShippingCost?: number | null;
  ShippingDescription?: string | null;
  Size?: string | null;
  Status: string;
  SupplierName?: string | null;
  UnitCore: number;
  UnitCost: number;
  UnitList: number;
  type: string;
};

export type Mitchell1PartItem2 = {
  Description: string;
  IsTire: boolean;
  ManufacturerLineCode: string;
  ManufacturerName: string;
  Metadata?: string | null;
  PartCategory?: string | null;
  PartNumber: string;
  Quantity: number;
  ShippingCost?: number | null;
  ShippingDescription?: string | null;
  Size?: string | null;
  SupplierName?: string | null;
  UnitCore: number;
  UnitCost: number;
  UnitList: number;
  UpcCode?: string | null;
  type: string;
};

export type Mitchell1ShoppingCart = {
  labors: {
    Description: string;
    Hours: number;
    Price: number | null;
    type: string;
  }[];
  notes: {
    Note: string;
    type: string;
  }[];
  orders: Mitchell1Order[];
  partItems: Mitchell1PartItem2[];
};

export type Order = {
  billingAddress: Address;
  canMarkAsDelivered: boolean;
  card: {
    brand: 'Visa' | 'MasterCard' | 'Discover' | 'AmericanExpress' | 'JCB' | 'Diners Club' | 'UnionPay';
    last4: number;
  } | null;
  confirmationNumbers: string;
  credentialId: number | null;
  customNotes: string | null;
  customerNumber: string | null;
  datePaid: string | null;
  discountTotal: number;
  fetChargeTotal: number;
  grandTotal: number;
  hash: string;
  id: number;
  lastTrackingDate: string | null;
  lines: OrderItem[];
  paymentWay: 'WHOLESALE' | 'CREDIT_CARD';
  purchaseOrderNumber: string;
  shippingAddress: Address;
  shippingMethod: string;
  shippingTotal: number;
  shippings: {
    trackingNumber: string;
    trackingUrl: string;
  }[];
  shopId: number;
  storeId: number;
  supplierId?: number;
  taxTotal: number;
  trackingStatus:
    | 'ORDER_PLACED'
    | 'ACKNOWLEDGED'
    | 'CONFIRMED'
    | 'SHIPPING'
    | 'SHIPPED'
    | 'DELIVERED'
    | 'CANCELLED'
    | 'RETURNED';
  user: {
    firstName: string;
    lastName: string;
  };
  userId: number;
};

export type OrderItem = {
  bundled: boolean;
  coreCharge: number | null;
  id: number;
  image: string | null;
  partName: string | null;
  partNumber: string;
  price: number;
  quantity: number;
  returnAction: 'CANCEL' | 'RETURN' | null;
  returnId: number | null;
  rewardPoints: number | null;
  status:
    | 'NEW'
    | 'NOT_SHIPPED'
    | 'SHIPPED'
    | 'DELIVERED'
    | 'CANCEL_INITIATED'
    | 'CANCEL_APPROVED'
    | 'CANCELLED'
    | 'RETURN_INITIATED'
    | 'RETURN_APPROVED'
    | 'RETURNED';
  subtotal: number;
  supplierNotes: string | null;
  totalDiscount: number;
  trackingNumbers: string[];
  trackingUrl: string | null;
  urlParams: ProductUrlParams;
  vehicle: Vehicle | null;
};

export type PaymentMethod = 'WHOLESALE' | 'CREDIT_CARD';

export type ProductUrlParams = {
  credentialId: number | null;
  linecard: number;
  part_term: number | null;
  partnumberid: string;
  supplierId: number | null;
  vehicle?: number;
  vin?: string | null;
};

export type Store = {
  additionalInformation: CredentialsAdditionalInformation | null;
  address: Address;
  autoValidateCredentials: boolean;
  code: string | null;
  coordinates: Coordinates;
  credentials: CredentialField[];
  distance: number | null;
  dropship: boolean;
  email: string | null;
  hotshot: boolean;
  hotshotOutOfRange: boolean;
  id: number;
  logo: string | null;
  name: string | null;
  national: boolean;
  orderingSupported: boolean;
  phone: string | null;
  pickUp: boolean;
  servesLocation: boolean;
  supplierId: number;
  userNote: string | null;
  warehouse: boolean;
};

export type Supplier = {
  allowCustomNotes: boolean;
  allowCustomPurchaseNumber: boolean;
  allowOrder: boolean;
  allowPartLevelNotes: boolean;
  allowStockOrder: boolean;
  allowStoreSelect: boolean;
  autoValidateCredentials: boolean;
  checkoutMessage: string | null;
  comingSoon: boolean;
  connectionTime: 'NA' | 'LESS_ONE_MIN' | 'TODAY' | 'NEXT_DAY' | '1_PLUS_DAYS';
  coreChargePolicy: string | null;
  coreReturns: boolean;
  credentials: CredentialField[] | null;
  id: number;
  instructionUrl: string | null;
  locationHidden: boolean;
  logo: string | null;
  marketingDescription: string | null;
  name: string;
  newSupplierBadge: boolean;
  outageMessage?: string | null;
  outageType: 'NO_OUTAGE' | 'PARTIAL_OUTAGE' | 'FULL_OUTAGE';
  paid: boolean;
  registrationInstruction: string | null;
  returnPeriod: number | null;
  returnPolicy: string | null;
  showStatusLink: boolean;
  slug: string;
  storeLocked: boolean;
  supportsApprove: boolean;
  tireConnect: boolean;
  tires: boolean;
  tutorialUrl: string | null;
  type: 'TIRES' | 'DEALER' | 'AFTERMARKET';
};

export type Vehicle = {
  engineId: string;
  engineName: string;
  id: number;
  makeId: number;
  makeName: string;
  makePriority: number;
  modelId: number;
  modelName: string;
  regionIds: number[];
  submodelId: number;
  submodelName: string;
  vehicleTypeGroup: number;
  year: number;
};
