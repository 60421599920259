import { css } from '@emotion/react';
import { Box, Divider, ImageFallback, ModalView, Typography, useMedia } from '@partstech/ui';
import { PaidFeatureAlias, UpgradeSubscriptionButton } from 'features/subscription';
import partsReportImage from './parts.png';
import spendReportImage from './spend.png';
import tiresReportImage from './tires.png';

const imageUrls = {
  spend: spendReportImage,
  tires: tiresReportImage,
  parts: partsReportImage,
};

const styles = {
  image: css`
    display: block;
  `,
};

export type ReportType = keyof typeof imageUrls | null;

type Props = {
  reportType: ReportType;
  onClose: () => void;
};

export const MarketingModal = ({ reportType, onClose }: Props) => {
  const { isMobile } = useMedia();

  const isSpendReporting = reportType === 'spend';

  if (!reportType) {
    return null;
  }

  return (
    <ModalView title="Upgrade subscription" disableGutters onClose={onClose}>
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
        <Box p={6} width={isMobile ? '100%' : '50%'}>
          <Box mb={2}>
            <Typography>
              {isSpendReporting
                ? 'Basic Analytics is only available with a PartsTech Plus subscription.'
                : 'Advanced Analytics is only available with a PartsTech Complete subscription.'}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography>
              {isSpendReporting
                ? 'Basic Analytics includes valuable insights into what you spend on parts ordering.'
                : 'Advanced Analytics includes valuable insights into how you buy Parts and Tires and how they impact your business.'}
            </Typography>
          </Box>
          <Box mb={6}>
            <Typography>
              {isSpendReporting
                ? 'A PartsTech Plus subscription also includes other features that save you time.'
                : 'A PartsTech Complete subscription also includes other features that save you time and super-charge your shop.'}
            </Typography>
          </Box>

          <Box mb={4}>
            <UpgradeSubscriptionButton
              className={`fs-subscription-upgrade-reporting-${reportType}`}
              fullWidth
              highlightFeature={PaidFeatureAlias.Analytics}
              onClick={onClose}
              data-testid="learnMore"
            >
              Learn more
            </UpgradeSubscriptionButton>
          </Box>
        </Box>

        <Divider direction={isMobile ? 'horizontal' : 'vertical'} />

        <Box width={isMobile ? '100%' : '50%'}>
          <ImageFallback width="100%" src={imageUrls[reportType]} css={styles.image} />
        </Box>

        {isMobile && <Divider direction="horizontal" />}
      </Box>
    </ModalView>
  );
};
