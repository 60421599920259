import { SearchTypes } from 'types/search';
import { RecentSearchAbstract } from './RecentSearchAbstract';
import type { AbstractParams } from './RecentSearchAbstract';
import type { Vehicle } from 'entities/vehicle';
import type { RecentSearchInterface } from 'features/recentSearches';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { KeywordSearchFormValues } from 'types/search';

export class Keyword extends RecentSearchAbstract implements RecentSearchInterface, SearchEntryPointInterface {
  type: string = 'Keyword';

  partText: string;

  partTextId: string;

  vehicle: Vehicle;

  constructor(
    id: string,
    vehicle: Vehicle,
    params: AbstractParams & { partText?: { id?: string | null; text?: string | null } | null }
  ) {
    super(id, vehicle, params);

    this.vehicle = vehicle;
    this.partTextId = params.partText?.id ?? '';
    this.partText = params?.partText?.text ?? '';
  }

  populateInput(): string {
    return this.partText;
  }

  toString(): string {
    return this.partText;
  }

  toSearchValues(): KeywordSearchFormValues {
    return {
      type: SearchTypes.PARTS,
      partText: this.partText,
      partTextId: this.partTextId,
      plate: this.plate,
      state: this.state,
      vin: this.vin,
      vehicleId: this.vehicle.id,
    };
  }
}
