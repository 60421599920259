import { PartCategory } from '../models/PartCategory';
import { createSubCategoryFromQuery } from './createSubCategoryFromQuery';
import type { GetCategoryQuery } from 'store/queries/categories/GetCategory.generated';
import type { ArrayItemType } from 'types/general';

export const createCategoryFromQuery = (category: ArrayItemType<NonNullable<GetCategoryQuery['category']>>) =>
  new PartCategory(
    category.id,
    category.name,
    category.subCategories?.map((subCategory) => createSubCategoryFromQuery(subCategory, category.id, [])) ?? []
  );
