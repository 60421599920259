import { useCallback } from 'react';
import { useMutationCallback } from 'shared/api';
import { api as generatedApi } from './CreateCustomJob.generated';
import type { PartType } from 'models';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreateCustomJob: {
      invalidatesTags: ['CustomJob', 'VehicleById', 'VehicleByPlateVin'],
    },
  },
});

export const { useCreateCustomJobMutation } = api;

type Props = {
  onSuccess?: () => Promise<void> | void;
};

export const useCreateCustomJob = ({ onSuccess }: Props = {}) => {
  const [mutation, status] = useMutationCallback(useCreateCustomJobMutation, {
    onSuccess,
  });

  const createCustomJob = useCallback(
    ({ name, partTypes }: { name: string; partTypes: PartType[] }) => {
      const partTypeIds = partTypes.map((part) => part.id);

      return mutation({ input: { name, partTypeIds } });
    },
    [mutation]
  );

  return [createCustomJob, status] as const;
};
