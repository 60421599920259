import { DateTime } from 'luxon';

/** * @public */
export const getNDaysAgo = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() - days);
  date.setHours(0, 0, 0, 0);

  return DateTime.fromJSDate(date).toISO() ?? '';
};
