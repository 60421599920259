import { useCallback, useEffect, useMemo } from 'react';
import { Diagram, UniversalPartType } from 'models';
import { useSelectedSearchEntry } from './useSelectedSearchEntry';
import { useSelectedVehicle } from './useSelectedVehicle';
import type { Vehicle } from 'entities/vehicle';
import type { SearchEntryPointInterface } from 'features/searchForm';

type Props = {
  searchEntry: SearchEntryPointInterface | null;
  vehicle: Vehicle | null;
  onChange: (entry: SearchEntryPointInterface | null, vehicle: Vehicle | null) => void;
};

export const useSearchBarState = ({ searchEntry: defaultSearchEntry, vehicle: defaultVehicle, onChange }: Props) => {
  const [searchEntry, setSearchEntry] = useSelectedSearchEntry(defaultSearchEntry);
  const [vehicle, setVehicle] = useSelectedVehicle(defaultVehicle);

  const changeSearchEntry = useCallback(
    (entry: SearchEntryPointInterface | null) => {
      setSearchEntry(entry);

      if (entry !== null) {
        onChange(entry, vehicle);
      }
    },
    [onChange, setSearchEntry, vehicle]
  );

  const changeVehicle = useCallback(
    (selectedVehicle: Vehicle | null, silent: boolean = false) => {
      setVehicle(selectedVehicle);

      if (searchEntry instanceof Diagram) {
        setSearchEntry(null);
      }

      if (!silent) {
        onChange(
          searchEntry instanceof UniversalPartType ? searchEntry.resetSelectedAttributes() : searchEntry,
          selectedVehicle
        );
      }
    },
    [setVehicle, searchEntry, setSearchEntry, onChange]
  );

  const resetSearch = useCallback(() => {
    changeVehicle(null);
    changeSearchEntry(null);
  }, [changeSearchEntry, changeVehicle]);

  useEffect(() => {
    if (defaultSearchEntry && !defaultVehicle) {
      setVehicle(null);
    }
  }, [defaultSearchEntry, defaultVehicle, setVehicle]);

  return useMemo(
    () => ({
      changeSearchEntry,
      changeVehicle,
      resetSearch,

      searchEntry,
      vehicle,
    }),
    [changeSearchEntry, changeVehicle, resetSearch, searchEntry, vehicle]
  );
};
