import { useMemo } from 'react';
import { parseTimeRange } from '../../lib/parseTimeRange';
import { api as generatedApi } from './GetPartReports.generated';
import type { GetPartReportsQuery } from './GetPartReports.generated';
import type { TimeRange } from '../../lib/useReportsFilters';

type PartReports = NonNullable<GetPartReportsQuery['partReports']>;
export type PartReport = PartReports['partChart'];

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetPartReports: {
      providesTags: () => ['Report'],
    },
  },
});

const { useGetPartReportsQuery } = api;

type Props = {
  timeRange: TimeRange;
  locations: string[];
};

export const useGetPartReports = ({ timeRange, locations }: Props) => {
  const { fromDate, toDate, fromPreviousDate, toPreviousDate } = useMemo(() => parseTimeRange(timeRange), [timeRange]);

  const { data, isLoading } = useGetPartReportsQuery({
    startDate: fromDate,
    endDate: toDate,
    storeIds: locations,
  });

  const { data: prevData, isLoading: isPrevLoading } = useGetPartReportsQuery(
    {
      startDate: fromPreviousDate || '',
      endDate: toPreviousDate,
      storeIds: locations,
    },
    {
      skip: !fromPreviousDate || !toPreviousDate,
    }
  );

  return {
    reports: data?.partReports?.partChart,
    prevReports: fromPreviousDate && toPreviousDate ? prevData?.partReports?.partChart : undefined,
    isLoading: isLoading || isPrevLoading,
  };
};
