import { HistoryModalProvider, createHistoryModalRouter } from '@partstech/ui';
import { useMemo } from 'react';
import { ContactSupport } from './ContactSupport';
import { Success } from './Success';
import type { ContactSupportPaths, SupportMode, SupportFormData } from '../../../types';
import type { ModalComponentProps } from '@partstech/ui';

const createRouter = <TPaths extends SupportMode>() =>
  createHistoryModalRouter<ContactSupportPaths<TPaths>, undefined>([
    {
      path: 'contactSupport',
      title: 'Contact our Support team',
      element: <ContactSupport />,
    },
    {
      path: 'success',
      title: 'Message sent',
      element: <Success />,
    },
  ]);

export type SupportModalProps<TSupportMode extends SupportMode> = {
  mode: SupportMode;
  defaultValues?: Omit<SupportFormData<TSupportMode>, 'notes'>;
};

export const SupportModalComponent = <TSupportMode extends SupportMode>({
  mode,
  defaultValues,
}: ModalComponentProps<SupportModalProps<TSupportMode>>) => {
  const router = useMemo(() => createRouter<TSupportMode>(), []);

  return (
    <HistoryModalProvider
      router={router}
      initialRoute={{ path: 'contactSupport', state: { mode: mode as TSupportMode, defaultValues } }}
      initialData={undefined}
    />
  );
};
