/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { VehicleFragmentDoc } from '../../../../shared/api/graphql/fragment/VehicleFragment.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type RecentSearchesFragment = {
  __typename?: 'RecentSearch';
  id: string;
  type: Types.RecentSearchType;
  params:
    | {
        __typename?: 'RecentSearchCategories';
        plate?: Types.Scalars['String']['input'] | null;
        state?: Types.Scalars['String']['input'] | null;
        vin?: Types.Scalars['String']['input'] | null;
        vehicle?: {
          __typename?: 'Vehicle';
          id: string;
          type: Types.VehicleType;
          regions?: Array<Types.VehicleRegion> | null;
          year: number;
          make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
          model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
          subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
          engine: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] };
        } | null;
      }
    | {
        __typename?: 'RecentSearchKeyword';
        plate?: Types.Scalars['String']['input'] | null;
        state?: Types.Scalars['String']['input'] | null;
        vin?: Types.Scalars['String']['input'] | null;
        partText?: {
          __typename?: 'RecentSearchPartText';
          id?: string | null;
          text: Types.Scalars['String']['input'];
        } | null;
        vehicle?: {
          __typename?: 'Vehicle';
          id: string;
          type: Types.VehicleType;
          regions?: Array<Types.VehicleRegion> | null;
          year: number;
          make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
          model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
          subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
          engine: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] };
        } | null;
      }
    | {
        __typename?: 'RecentSearchNonApp';
        plate?: Types.Scalars['String']['input'] | null;
        state?: Types.Scalars['String']['input'] | null;
        vin?: Types.Scalars['String']['input'] | null;
        partTypes?: Array<{
          __typename?: 'PartType';
          id: string;
          application: Types.PartTypeApplication;
          name: Types.Scalars['String']['input'];
        }> | null;
        attributes?: Array<{
          __typename?: 'RecentSearchAttribute';
          name: Types.Scalars['String']['input'];
          values: Array<Types.Scalars['String']['input']>;
        }> | null;
        partText?: {
          __typename?: 'RecentSearchPartText';
          id?: string | null;
          text: Types.Scalars['String']['input'];
        } | null;
        vehicle?: {
          __typename?: 'Vehicle';
          id: string;
          type: Types.VehicleType;
          regions?: Array<Types.VehicleRegion> | null;
          year: number;
          make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
          model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
          subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
          engine: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] };
        } | null;
      }
    | {
        __typename?: 'RecentSearchPartNumber';
        partNumber: Types.Scalars['String']['input'];
        manufacturers?: Array<Types.Scalars['String']['input']> | null;
        plate?: Types.Scalars['String']['input'] | null;
        state?: Types.Scalars['String']['input'] | null;
        vin?: Types.Scalars['String']['input'] | null;
        vehicle?: {
          __typename?: 'Vehicle';
          id: string;
          type: Types.VehicleType;
          regions?: Array<Types.VehicleRegion> | null;
          year: number;
          make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
          model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
          subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
          engine: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] };
        } | null;
      }
    | {
        __typename?: 'RecentSearchTires';
        plate?: Types.Scalars['String']['input'] | null;
        state?: Types.Scalars['String']['input'] | null;
        vin?: Types.Scalars['String']['input'] | null;
        tiresPartNumber?: Types.Scalars['String']['input'] | null;
        tire?: {
          __typename?: 'RecentSearchTire';
          id?: string | null;
          name?: Types.Scalars['String']['input'] | null;
          size: {
            __typename?: 'TireSize';
            width: Types.Scalars['Float']['input'];
            height: Types.Scalars['Float']['input'];
            rim: Types.Scalars['Float']['input'];
          };
          rearSize?: {
            __typename?: 'TireSize';
            width: Types.Scalars['Float']['input'];
            height: Types.Scalars['Float']['input'];
            rim: Types.Scalars['Float']['input'];
          } | null;
        } | null;
        vehicle?: {
          __typename?: 'Vehicle';
          id: string;
          type: Types.VehicleType;
          regions?: Array<Types.VehicleRegion> | null;
          year: number;
          make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
          model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
          subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
          engine: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] };
        } | null;
      };
};

export const RecentSearchesFragmentDoc = `
    fragment RecentSearches on RecentSearch {
  id
  type
  params {
    vehicle {
      ...Vehicle
    }
    plate
    state
    vin
    ... on RecentSearchKeyword {
      partText {
        id
        text
      }
    }
    ... on RecentSearchPartNumber {
      partNumber
      manufacturers
    }
    ... on RecentSearchNonApp {
      partTypes {
        id
        application
        name
      }
      attributes {
        name
        values
      }
      partText {
        id
        text
      }
    }
    ... on RecentSearchTires {
      tiresPartNumber: partNumber
      tire {
        id
        name
        size {
          width
          height
          rim
        }
        rearSize {
          width
          height
          rim
        }
      }
    }
  }
}
    ${VehicleFragmentDoc}`;
