import { LaborApplication } from 'models';
import type { CartLaborItemFragment } from 'shared/api';

export const createLaborApplicationFromCartLaborItem = (laborItem: CartLaborItemFragment) =>
  new LaborApplication({
    applicationId: laborItem.identifiers && 'id' in laborItem.identifiers ? laborItem.identifiers.id : laborItem.id,
    applicationSource:
      laborItem.identifiers && 'motorLaborSource' in laborItem.identifiers
        ? laborItem.identifiers.motorLaborSource
        : null,
    duration: laborItem.hours,
    defaultDuration: laborItem.defaultHours,
    name: laborItem.name,
    notes: laborItem.notes ? [laborItem.notes] : [],
    skill: laborItem.skill,
    customId: laborItem.id,
    orderItemId: laborItem.orderItemId,
    vehicleId: laborItem.vehicle?.id,
    vin: laborItem.vin,
    taxonomy: laborItem.taxonomy,
    cartLocalInventoryItemId: laborItem.cartLocalInventoryItemId,
  });
