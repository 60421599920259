import { css } from '@emotion/react';
import type { ProductProps } from './types';
import type { Theme } from '@partstech/ui';

export type LayoutProps = {
  hasError: boolean;
  hasNotes: boolean;
  hasPrimaryAction: boolean;
  hasVariations: boolean;
  hasQuote: boolean;
  hasSponsored: boolean;
};

const layoutStyles = (size: ProductProps['layout'], hidden: boolean, layoutProps: LayoutProps) => {
  const { hasSponsored, hasError, hasNotes, hasPrimaryAction, hasVariations, hasQuote } = layoutProps;

  const errorArea = hasError ? 'Error' : 'Attributes';
  const additionalInfoArea = hasNotes || hasVariations ? 'AdditionalInfo' : 'Attributes';
  const statusMessageArea = hasQuote ? 'StatusMessage' : 'Pricing';
  const sponsoredArea = hasSponsored ? 'Sponsored' : 'Images';
  const primaryAction = hasPrimaryAction ? 'Footer' : 'StatusMessage';

  switch (true) {
    case size === 'collapsed':
      return css`
        grid-template-columns: 135px 2fr 1fr;
        grid-template-rows: 0.1fr auto auto 0.1fr auto;
        grid-template-areas:
          'Images Title Pricing'
          'Images Availability Pricing'
          'Images Error Pricing'
          'Images Attributes Footer'
          'Images AdditionalInfo StatusMessage';
      `;
    case size === 'small' && hidden:
      return css`
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 0.1fr auto auto auto auto auto;
        grid-template-areas:
          'Title Title'
          'Images Pricing'
          'Images Availability'
          'Images Error'
          'Images Attributes'
          'Images AdditionalInfo'
          'Images Footer';
      `;
    case size === 'medium':
    case size === 'large':
      return css`
        grid-template-columns: 164px minmax(200px, 2fr) minmax(150px, 1fr);
        grid-template-areas:
          'Images Title Pricing'
          'Images Availability Pricing'
          'Images ${errorArea} Pricing'
          'Images Attributes ${statusMessageArea}'
          'Images ${additionalInfoArea} ${statusMessageArea}'
          '${sponsoredArea} Footer ${primaryAction}';
      `;
    default:
      return css`
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(2, auto) 10fr repeat(5, auto);
        grid-template-areas:
          'Title Title'
          'Images Pricing'
          'Sponsored Pricing'
          'Availability Availability'
          'Error Error'
          'Attributes Attributes'
          'AdditionalInfo AdditionalInfo'
          'StatusMessage StatusMessage'
          'Footer Footer';
      `;
  }
};

const layoutFooterStyles = (size: ProductProps['layout'], hidden: boolean) => {
  switch (true) {
    case size === 'collapsed':
      return css`
        grid-template-areas:
          'Details'
          'Actions'
          'PrimaryAction';
      `;
    case size === 'small' && hidden:
      return css`
        grid-template-areas:
          'Details'
          'Actions'
          'PrimaryAction';
      `;
    case size === 'medium':
    case size === 'large':
      return css`
        grid-template-columns: 10fr auto 1fr;
        grid-template-areas: 'Details Actions PrimaryAction';
      `;

    default:
      return css`
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'Actions Actions'
          'Details PrimaryAction';
      `;
  }
};

export const styles = {
  container: css`
    container-type: inline-size;

    &:has([class*='dropdown-content']) {
      z-index: 1;
    }
  `,
  resultsGrid: (layout: ProductProps['layout'], hidden: boolean, layoutProps: LayoutProps) => css`
    display: grid;
    row-gap: 0; //Do not change the gap to prevent unnecessary margins

    ${layoutStyles(layout, hidden, layoutProps)}

    @container (max-width: 600px) {
      ${layoutStyles('small', hidden, layoutProps)}
    }

    .collapsed {
      ${hidden &&
      css`
        margin: 0;
        height: 0;
        overflow: hidden;
        transform: scale(1, 0);
        opacity: 0;
      `}

      transform: scale(1, 1);
      transform-origin: top;
      transition:
        transform,
        height 0.4s ease-in-out;
    }

    > * {
      :empty {
        display: none;
      }
    }
  `,
  images: (hidden: boolean) => (theme: Theme) => css`
    grid-area: Images;

    > *:empty {
      display: none;
    }

    ${theme.breakpoints.down('md')} {
      margin-bottom: ${theme.sizing(2)};

      ${hidden &&
      css`
        margin-bottom: 0;
      `}
    }
  `,
  sponsored: (theme: Theme) => css`
    grid-area: Sponsored;
    align-self: flex-end;
    justify-self: flex-start;
    margin: 0;
    min-height: ${theme.sizing(6)};
    display: flex;
    align-items: center;
  `,
  brand: (hidden: boolean) => (theme: Theme) => css`
    height: ${hidden ? theme.sizing(10) : theme.sizing(12)};
    transition: height 0.4s ease-in-out;
  `,
  title: css`
    grid-area: Title;
  `,
  pricing: css`
    grid-area: Pricing;
  `,
  certification: css`
    grid-area: Certification;
  `,
  error: css`
    grid-area: Error;
  `,
  availability: (theme: Theme) => css`
    grid-area: Availability;
    margin-bottom: ${theme.sizing(2)};
  `,
  attributes: (smallLayout: boolean, hidden: boolean) => css`
    grid-area: Attributes;
    transition: all 0.4s ease-in-out;

    ${hidden &&
    css`
      margin-bottom: 0;
    `}

    ${smallLayout && `columns: 2;`}

    @container (max-width: 600px) {
      columns: 2;
    }
  `,
  additionalInfo: (smallLayout: boolean, hasPrimaryAction: boolean) => (theme: Theme) => css`
    display: grid;
    grid-template-columns: subgrid;
    grid-area: AdditionalInfo;
    margin-bottom: ${theme.sizing(hasPrimaryAction ? 2 : 4)};
    row-gap: ${theme.sizing(4)};

    > div {
      ${smallLayout && `grid-column: 1/-1;`}

      @container (max-width: 600px) {
        grid-column: 1/-1;
      }
    }

    ${theme.breakpoints.down('md')} {
      row-gap: ${theme.sizing(2)};
    }
  `,
  notes: css`
    justify-self: flex-start;
    align-self: flex-start;
    grid-column: 1/2;
  `,
  variations: css`
    grid-column: 1/-1;
  `,
  ETAMessage: (hidden: boolean) => css`
    ${hidden &&
    css`
      display: none;
    `}
  `,
  statusMessage: (hidden: boolean) => css`
    grid-area: StatusMessage;
    align-self: flex-end;
    justify-self: flex-end;

    ${hidden &&
    css`
      display: none;
    `}
  `,
  details: css`
    grid-area: Details;
  `,
  actions: css`
    grid-area: Actions;

    @container (max-width: 600px) {
      flex-direction: column;
    }
  `,
  primaryAction: css`
    grid-area: PrimaryAction;
  `,

  footer: (layout: ProductProps['layout'], hidden: boolean) => css`
    grid-area: Footer;
    display: grid;
    row-gap: 0; //Do not change the gap to prevent unnecessary margins

    ${layoutFooterStyles(layout, hidden)}

    @container (max-width: 600px) {
      ${layoutFooterStyles('small', hidden)}
    }
    > *:empty {
      display: none;
    }
  `,
};
