import { Box, Button, Typography } from '@partstech/ui';
import { LaborRateDropdown } from 'entities/laborRate';
import { currency } from 'shared/lib/string';
import type { LaborRate } from 'shared/api';

type Props = {
  onAddToCart: () => void;
  totalHours: number;
  laborRates: LaborRate[];
  selectedLaborRate?: LaborRate;
  onSelectLaborRate: (laborRateId: string) => void;
  isAdding?: boolean;
};

export const LaborReviewSummaryInfo = ({
  onAddToCart,
  totalHours,
  laborRates,
  selectedLaborRate,
  onSelectLaborRate,
  isAdding = true,
}: Props) => (
  <Box pt={4}>
    {totalHours > 0 && (
      <>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="subtitle1" color="subtleText">
            Labor total:
          </Typography>
          <Typography>{totalHours} hrs</Typography>
        </Box>

        <LaborRateDropdown
          laborRates={laborRates}
          selectedLaborRate={selectedLaborRate}
          onSelectLaborRate={onSelectLaborRate}
        />
      </>
    )}

    <Box display="flex" justifyContent="space-between" my={4}>
      <Typography variant="subtitle1" color="subtleText">
        Total:
      </Typography>
      <Typography variant="h3">{currency(totalHours * (selectedLaborRate?.rate || 0))}</Typography>
    </Box>

    <Button fullWidth onClick={onAddToCart} disabled={isAdding && totalHours === 0} data-testid="submitButton">
      {isAdding ? 'Add to cart' : 'Update labor'}
    </Button>
  </Box>
);
