/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type CreateSupportCaseMutationVariables = Types.Exact<{
  input: Types.CreateSupportCaseInput;
}>;

export type CreateSupportCaseMutation = {
  __typename?: 'Mutation';
  createSupportCase?: { __typename?: 'CreateSupportCasePayload'; success: Types.Scalars['Boolean']['input'] } | null;
};

export const CreateSupportCaseDocument = `
    mutation CreateSupportCase($input: CreateSupportCaseInput!) {
  createSupportCase(input: $input) {
    success
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateSupportCase: build.mutation<CreateSupportCaseMutation, CreateSupportCaseMutationVariables>({
      query: (variables) => ({ document: CreateSupportCaseDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateSupportCaseMutation } = injectedRtkApi;
