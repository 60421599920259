import { Menu } from '@partstech/ui';
import { useCallback } from 'react';
import { Option } from './Option';
import type { PartType } from 'models';

type Props = {
  highlightedText?: string;
  onSelect?: (partType: PartType) => void;
  options: PartType[];
  value: PartType[];
  disabledText?: string;
};

export const PartTypes = ({ highlightedText, onSelect, options, value, disabledText }: Props) => {
  const isSelected = useCallback(
    (option: PartType) => value.some((selectedOption) => selectedOption.id === option.id),
    [value]
  );

  return (
    <Menu builtIn>
      {options.map((option, index) => (
        <Option<PartType>
          highlightedText={highlightedText}
          key={`${option.id}-${index}`}
          isSelected={isSelected(option)}
          onSelect={onSelect}
          option={option}
          disabledText={disabledText}
          hasPrefix
        />
      ))}
    </Menu>
  );
};
