import { useEffect } from 'react';
import { configRoutingHosts } from 'app/config';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

export const useRedirectToSupplierPortal = () => {
  const { isSupplier } = useGetCurrentUser();

  useEffect(() => {
    if (!isSupplier) {
      return;
    }

    window.location.replace(`//${configRoutingHosts.supplier}${'/'}`);
  }, [isSupplier]);
};
