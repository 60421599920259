import { Box, Link, Typography } from '@partstech/ui';
import { URL } from 'constant';
import { Copyright } from '../Copyright';
import type { BoxProps } from '@partstech/ui';

export const AuthMarketingLinks = (props: BoxProps) => (
  <Box textAlign="center" {...props}>
    <Link to={URL.HOME} isExternal underline="hover" color="defaultText" variant="caption">
      PartsTech Marketing site
    </Link>

    <Typography variant="caption" color="defaultText" component="span">
      {' | '}

      <Link to={URL.TERMS_OF_SERVICE} isExternal underline="hover" variant="caption">
        Terms of service
      </Link>

      {' | '}

      <Link to={URL.PRIVACY_POLICY} isExternal underline="hover" variant="caption">
        Privacy Policy
      </Link>
    </Typography>

    <Copyright />
  </Box>
);
