import { useMedia } from '@partstech/ui';
import { Region } from 'entities/vehicle';
import { OptionsList } from '../../OptionsList';
import { useParametersList } from '../useParametersList';
import { useGetEngines } from './useGetEngines';
import type { CommonParametersListProps } from '../types';
import type { Vehicle } from 'entities/vehicle';
import type { VehicleRegion } from 'shared/api';

type Option = Omit<Vehicle['engine'], 'regions'> & {
  regions?: VehicleRegion[];
};

export const EnginesList = ({
  searchValue,
  onChange,
  isUserFocused,
  engineId: selectedValue,
  ...props
}: CommonParametersListProps) => {
  const { isMobile } = useMedia();

  const { engines } = useGetEngines({ searchValue, ...props });
  const { updateValue } = useParametersList({
    fieldName: 'engineId',
    options: engines,
    onChange,
    isUserFocused,
    searchValue,
    selectedValue,
  });

  return (
    <OptionsList
      options={engines}
      onClick={updateValue}
      columnCount={isMobile ? 1 : undefined}
      adornmentAfter={(option: Option) => (option.regions ? <Region regions={option.regions} /> : null)}
    />
  );
};
