import { Box, Card, Typography } from '@partstech/ui';
import { useCallback, useMemo } from 'react';
import { isOtherReason } from '../../../constants/isOtherReason';
import { getShortPlanName } from '../../../utils/getShortPlanName';
import { ReasonButton } from './ReasonButton';
import type { CardProps } from '@partstech/ui';
import type { Dispatch, SetStateAction } from 'react';

type Props = CardProps & {
  isPlan: boolean;
  isOtherReasonSelected: boolean;
  productName: string;
  reasons: string[];
  selectedReasons: string[];
  setSelectedReasons: Dispatch<SetStateAction<string[]>>;
};

export const ReasonsCard = ({
  isOtherReasonSelected,
  isPlan,
  productName,
  reasons,
  selectedReasons,
  setSelectedReasons,
  ...props
}: Props) => {
  const isActiveReason = useCallback((reason: string) => selectedReasons.includes(reason), [selectedReasons]);

  const actionName = useMemo(() => {
    if (isPlan) {
      return `downgrading to ${getShortPlanName(productName)}`;
    }

    return `removing ${productName}`;
  }, [isPlan, productName]);

  const isReasonDisabled = useMemo(
    () => isOtherReasonSelected || selectedReasons.length === 3,
    [isOtherReasonSelected, selectedReasons]
  );

  const handleSelectReason = useCallback(
    (reason: string) => () => {
      setSelectedReasons((prev) => {
        if (prev.includes(reason)) {
          return prev.filter((item) => item !== reason);
        }

        if (isOtherReason(reason)) {
          return [reason];
        }

        return [...prev, reason];
      });
    },
    [setSelectedReasons]
  );

  return (
    <Card textAlign="center" {...props}>
      <Typography component="span" variant="bodyText2">
        We value your opinion! Please help us to improve our product by selecting the reason for {actionName}. (Select
        up to 3 reasons)
      </Typography>

      <Typography component="span" color="negative">
        *
      </Typography>

      <Box mt={5} display="flex" flexWrap="wrap" gap={4} justifyContent="center">
        {reasons.map((reason, index) => (
          <ReasonButton
            key={index}
            isActive={isActiveReason(reason)}
            isDisabled={isReasonDisabled}
            reason={reason}
            onClick={handleSelectReason(reason)}
          />
        ))}
      </Box>
    </Card>
  );
};
