/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetReportsSummaryQueryVariables = Types.Exact<{
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  startDate: Types.Scalars['DateTime']['input'];
}>;

export type GetReportsSummaryQuery = {
  __typename?: 'Query';
  spendReports?: {
    __typename?: 'SpendReports';
    spendSummary?: {
      __typename?: 'SpendSummary';
      totalPrice: Types.Scalars['BigDecimal']['input'];
      totalParts: number;
      totalTires: number;
    } | null;
  } | null;
};

export const GetReportsSummaryDocument = `
    query GetReportsSummary($endDate: DateTime, $startDate: DateTime!) {
  spendReports {
    spendSummary(endDate: $endDate, startDate: $startDate) {
      totalPrice
      totalParts
      totalTires
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetReportsSummary: build.query<GetReportsSummaryQuery, GetReportsSummaryQueryVariables>({
      query: (variables) => ({ document: GetReportsSummaryDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetReportsSummaryQuery, useLazyGetReportsSummaryQuery } = injectedRtkApi;
