import { useFormContext } from '@partstech/ui/forms';
import { useCallback, useMemo } from 'react';
import { usePermissions } from 'entities/permission';
import { defaultPermissionGroups } from './defaultPermissionGroups';
import type { ChangeEvent } from 'react';
import type { MemberPermissions } from 'shared/api';

export type PermissionListArgs = {
  previewMode?: boolean;
  onChange?: (permissions: MemberPermissions) => Promise<void>;
};

export const usePermissionList = ({ previewMode = false, onChange }: PermissionListArgs) => {
  const {
    shop: { advancedReportsAllowed, spendReportAllowed, retailPricingAllowed },
  } = usePermissions();

  const { watch, getValues, reset } = useFormContext<MemberPermissions>();

  const reportsAccessAllowed = watch('reportsDashboardAccess');

  const groups = useMemo(
    () =>
      defaultPermissionGroups.map((group) => ({
        ...group,
        permissions: group.permissions.filter((permission) =>
          permission.isAvailable({
            reportsAccessAllowed,
            retailPricingAllowed,
            previewMode,
            spendReportAllowed,
            advancedReportsAllowed,
          })
        ),
      })),
    [advancedReportsAllowed, spendReportAllowed, reportsAccessAllowed, retailPricingAllowed, previewMode]
  );

  const changePermission = useCallback(async () => {
    if (!onChange) {
      return;
    }

    const permissions = getValues();

    await onChange(permissions);
  }, [onChange, getValues]);

  const changeGroupPermission = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const selectedGroup = defaultPermissionGroups.find((group) => group.name === e.target.name);
      if (!selectedGroup) {
        return;
      }

      const values = selectedGroup.permissions.reduce(
        (memo, permission) => ({ ...memo, [permission.name]: e.target.checked }),
        getValues()
      );

      reset(values, { keepDirty: true });

      await changePermission();
    },
    [getValues, reset, changePermission]
  );

  return { groups, changePermission, changeGroupPermission };
};
