/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetPartReportPurchasesQueryVariables = Types.Exact<{
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  perPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.PartReportSortBy>;
  sortOrder?: Types.InputMaybe<Types.SortOrder>;
  startDate: Types.Scalars['DateTime']['input'];
  storeIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;

export type GetPartReportPurchasesQuery = {
  __typename?: 'Query';
  partReports?: {
    __typename?: 'PartReports';
    partTypes?: {
      __typename?: 'ReportPartTypes';
      totalCount: number;
      partTypesStatistics: Array<{
        __typename?: 'PartTypesStatistic';
        totalOrders: number;
        totalPrice: Types.Scalars['BigDecimal']['input'];
        totalQuantity: number;
        partType?: {
          __typename?: 'PartType';
          id: string;
          name: Types.Scalars['String']['input'];
          category?: { __typename?: 'PartCategory'; name: Types.Scalars['String']['input'] } | null;
        } | null;
      }>;
    } | null;
  } | null;
};

export const GetPartReportPurchasesDocument = `
    query GetPartReportPurchases($endDate: DateTime, $page: Int, $perPage: Int, $search: String, $sortBy: PartReportSortBy, $sortOrder: SortOrder, $startDate: DateTime!, $storeIds: [ID!]) {
  partReports {
    partTypes(
      endDate: $endDate
      page: $page
      perPage: $perPage
      search: $search
      sortBy: $sortBy
      sortOrder: $sortOrder
      startDate: $startDate
      storeIds: $storeIds
    ) {
      totalCount
      partTypesStatistics {
        totalOrders
        totalPrice
        totalQuantity
        partType {
          id
          name
          category {
            name
          }
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetPartReportPurchases: build.query<GetPartReportPurchasesQuery, GetPartReportPurchasesQueryVariables>({
      query: (variables) => ({ document: GetPartReportPurchasesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPartReportPurchasesQuery, useLazyGetPartReportPurchasesQuery } = injectedRtkApi;
