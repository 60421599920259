import { isNotNull, uniq } from '@partstech/ui/utils';
import { CartImprovedStatus } from 'shared/api';
import { SearchSequenceType, SearchTypes } from 'types/search';
import type { CartOrder } from './CartOrder';
import type { Vehicle } from 'entities/vehicle';
import type { SearchSequenceCreatorInterface } from 'features/searchSequence';
import type { CartOrderItem, CartTotals, LaborApplication } from 'models';
import type { SearchSequence, SearchTask } from 'types/search';
import type { UserName } from 'types/userName';

type CartUser = UserName & { id?: string };

export type CartData = {
  id: string;
  orders: CartOrder[];
  vehicles: Vehicle[];
  updatedAt?: string;
  status: CartImprovedStatus;
  isActive: boolean;
  showOnlyRetailPrice?: boolean;
  customerEmail?: string | null;
  notes?: string | null;
  repairOrderNumber?: string | null;
  totals?: CartTotals;
  user?: CartUser | null;
  laborRate?: number | null;
  laborApplication?: LaborApplication[];
  localInventory?: CartOrder | null;
};

export type CartError = {
  id: string;
  message: string;
};

export class Cart implements SearchSequenceCreatorInterface {
  id: string;

  orders: CartOrder[];

  vehicles: Vehicle[];

  updatedAt: string;

  status: CartImprovedStatus;

  isActive: boolean;

  showOnlyRetailPrice: boolean;

  info: {
    customerEmail: string;
    notes: string;
    repairOrderNumber: string;
  };

  totals: CartTotals | null;

  user: CartUser | null;

  laborRate: number | null;

  laborApplications: LaborApplication[];

  localInventory: CartOrder | null;

  constructor(data: CartData) {
    this.id = data.id;
    this.orders = data.orders;
    this.vehicles = data.vehicles;
    this.updatedAt = data.updatedAt || '';
    this.status = data.status;
    this.isActive = data.isActive;
    this.showOnlyRetailPrice = data.showOnlyRetailPrice || false;
    this.info = {
      customerEmail: data.customerEmail || '',
      notes: data.notes || '',
      repairOrderNumber: data.repairOrderNumber || '',
    };
    this.totals = data.totals || null;
    this.user = data.user || null;
    this.laborRate = data.laborRate || null;
    this.laborApplications = data.laborApplication || [];
    this.localInventory = data.localInventory || null;
  }

  get poNumbers(): string[] {
    return uniq(this.orders.map((order) => order.poNumber || ''));
  }

  get globalPoNumber(): string {
    if (this.poNumbers.length === 0) {
      return '';
    }
    if (this.poNumbers.length === 1) {
      return `${this.poNumbers[0]}`;
    }
    return 'Multiple POs';
  }

  get lastVehicle(): Vehicle | null {
    return this.vehicles[this.vehicles.length - 1] || null;
  }

  get isArchived(): boolean {
    return this.status === CartImprovedStatus.Archived;
  }

  get isEmpty(): boolean {
    return this.orders.length === 0 && this.laborApplications.length === 0 && this.localInventory?.items.length === 0;
  }

  partsCount(): number {
    const orderPartsCount = this.orders.reduce((acc, order) => {
      const itemsCount = order.items?.reduce((count, item) => count + item.quantity, 0) || 0;
      return acc + itemsCount;
    }, 0);

    const localInventoryPartsCount = this.localInventory?.items?.reduce((count, item) => count + item.quantity, 0) || 0;

    return orderPartsCount + localInventoryPartsCount;
  }

  unavailableParts(): CartOrderItem[] {
    const parts = this.orders.reduce<CartOrderItem[]>((acc, order) => {
      if (order.error) {
        return [...acc, ...order.items];
      }

      const itemsWithError = order.items.filter((item) => Boolean(item.error));

      return [...acc, ...itemsWithError];
    }, []);

    return parts.sort((a, b) => (Number(a.vehicle?.id) || 0) - (Number(b.vehicle?.id) || 0));
  }

  unavailablePartsCount(): number {
    return this.unavailableParts().reduce((acc, part) => acc + part.quantity, 0);
  }

  hasAvailableParts(): boolean {
    const availableParts = this.orders
      .filter((order) => !order.error)
      .flatMap((order) => order.items || [])
      .filter((part) => !part.error);

    return availableParts.length > 0;
  }

  hasUnavailableParts(): boolean {
    return this.unavailableParts().length > 0;
  }

  getOrderErrors(): CartError[] {
    return this.orders.map((order) => (order.error ? { id: order.id, message: order.error } : null)).filter(isNotNull);
  }

  getPartErrors(): CartError[] {
    const orderErrors = this.orders
      .flatMap((order) =>
        order.items.map((item) => (item.error ? { id: item.id, message: `${item.title}: ${item.error}` } : null))
      )
      .filter(isNotNull);

    const localInventoryErrors =
      this.localInventory?.items
        .map((item) => (item.error ? { id: item.id, message: `${item.title}: ${item.error}` } : null))
        .filter(isNotNull) || [];

    return [...orderErrors, ...localInventoryErrors];
  }

  hasErrors(): boolean {
    return this.getOrderErrors().length > 0 || this.getPartErrors().length > 0;
  }

  getLinkedLaborApplications() {
    return this.laborApplications.filter((application) => application.relatedItemId);
  }

  getUnlinkedLaborApplications() {
    return this.laborApplications.filter((application) => !application.relatedItemId);
  }

  getTotalLaborHours() {
    const totalLaborHours = this.laborApplications.reduce((acc, application) => acc + application.duration, 0);
    return Number(totalLaborHours.toFixed(3));
  }

  getPoNumbers() {
    return this.orders
      .filter((order) => order.supplierAccount?.supplier?.allowCustomPurchaseOrderNumbers)
      .map((order) => order.poNumber);
  }

  public createSearchSequence(): SearchSequence {
    const tasks: SearchTask[] = this.unavailableParts().map((part) => ({
      searchParams: {
        vehicle: part.vehicle?.id || undefined,
        partnumber: part.partNumber,
        type: part.isTire ? SearchTypes.TIRES : SearchTypes.PARTS,
      },
      isCompleted: false,
      title: part.partNumber,
    }));

    return {
      title: 'Out of stock alternates',
      id: 'alternateParts',
      type: SearchSequenceType.AlternateParts,
      tasks,
    };
  }
}
