import { css } from '@emotion/react';
import { Typography, Link, Button, Scrollbar, ModalView } from '@partstech/ui';
import { useCreateModal, useOpen } from '@partstech/ui/hooks';
import { useCallback, useMemo } from 'react';

type Props = {
  dense?: boolean;
  title: string;
  policy: string;
  type?: 'modal' | 'inline';
  variant?: 'bordered' | 'text' | 'link';
};

const styles = {
  text: css`
    max-height: 300px;
    text-align: left;
  `,
};

export const PolicyButton = ({ dense = false, title, policy, variant = 'bordered', type = 'modal' }: Props) => {
  const { isOpen, open: handleOpenModal, close: handleCloseModal } = useOpen();

  const policyBlock = (
    <Typography component="div">
      <div
        dangerouslySetInnerHTML={{
          __html: policy,
        }}
      />
    </Typography>
  );

  const { open } = useCreateModal(ModalView, { title, children: policyBlock, size: 'large' }, { customId: 'policy' });

  const openModal = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      handleOpenModal();

      if (type === 'modal') {
        open({ onClose: handleCloseModal });
      }
    },
    [handleCloseModal, handleOpenModal, open, type]
  );

  const button = useMemo(() => {
    if (variant === 'text' || variant === 'bordered') {
      return (
        <Button
          variant={variant === 'text' ? 'text' : 'secondary'}
          onClick={openModal}
          size={dense ? 'dense' : 'regular'}
        >
          {title}
        </Button>
      );
    }

    return (
      <Link to="#" onClick={openModal}>
        {title}
      </Link>
    );
  }, [title, dense, openModal, variant]);

  return (
    <>
      {button}

      {isOpen && type === 'inline' && <Scrollbar css={styles.text}>{policyBlock}</Scrollbar>}
    </>
  );
};
