import { css } from '@emotion/react';
import { Box, ButtonGroup, ModalButtons, useMedia } from '@partstech/ui';
import { StoreInfo } from '../StoreInfo';
import type { BoxProps } from '@partstech/ui';
import type { Store } from 'entities/store';
import type { SupplierAccount } from 'entities/supplierAccount';
import type { PropsWithChildren, ReactNode } from 'react';

const styles = {
  modalButton: css`
    button {
      flex: 1;
    }
  `,
};

type Props = PropsWithChildren<
  {
    store: Store;
    account?: SupplierAccount | null;
    actions?: ReactNode;
    footerElement?: ReactNode;
  } & BoxProps
>;

export const CredentialsWrapper = ({ store, account, children, actions, footerElement, ...props }: Props) => {
  const { isMobile } = useMedia();

  return (
    <>
      <Box
        display="flex"
        flexDirection={{ sm: 'column', md: 'row' }}
        minHeight={{ sm: 'calc(100 * var(--vh) - 142px)', md: 110 }}
        flexWrap="nowrap"
      >
        <StoreInfo store={store} account={account} />

        <Box
          width={{ sm: '100%', md: 97 }}
          height="100%"
          flex={{ sm: '1', md: 'initial' }}
          ml={{ sm: 0, md: 6 }}
          data-testid="content"
          {...props}
        >
          {children}

          {!isMobile && (
            <ButtonGroup alignItems="center" mt={6} flexDirection={{ sm: 'column', md: 'row' }}>
              {actions}
            </ButtonGroup>
          )}

          {footerElement}
        </Box>
      </Box>

      {isMobile && (
        <ModalButtons direction="row-reverse" separated css={styles.modalButton}>
          {actions}
        </ModalButtons>
      )}
    </>
  );
};
