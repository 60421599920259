import { Box, Typography } from '@partstech/ui';
import { CredentialsWrapper } from '../../CredentialsWrapper';
import { FormActions } from './FormActions';
import type { SupplierAccount } from 'entities/supplierAccount';

type Props = {
  account: SupplierAccount;
  onCancel: () => void;
  onSubmit: () => void;
};

export const DeleteCredentials = ({ account, onSubmit, onCancel }: Props) => {
  const { store, supplier } = account;

  if (!supplier || !store) {
    return null;
  }

  return (
    <CredentialsWrapper
      store={store}
      account={account}
      actions={<FormActions onSubmit={onSubmit} onCancel={onCancel} />}
    >
      <Box mt={{ sm: 6, md: 0 }} mb={5}>
        <Box mb={4}>
          <Typography>
            Are you sure you want to delete{' '}
            <Typography variant="subtitle1" component="span">
              {supplier.name} {account.name}
            </Typography>{' '}
            from your supplier list?
          </Typography>
        </Box>

        {!account?.isPaymentPending && <Typography>This cannot be undone.</Typography>}
      </Box>
    </CredentialsWrapper>
  );
};
