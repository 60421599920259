import { css } from '@emotion/react';
import { Box, Link, Tooltip, Typography, typographyStyles } from '@partstech/ui';
import { memo } from 'react';
import warningImage from 'images/icons/warning.svg';
import type { Theme, LinkProps } from '@partstech/ui';
import type { Product } from 'models';

const styles = {
  textNew: (theme: Theme) => css`
    ${typographyStyles.caption('inherit')(theme)};
  `,

  image: (theme: Theme) => css`
    max-width: 100%;
    width: 100%;

    ${theme.breakpoints.down('md')} {
      max-width: ${theme.sizing(80)};
    }
  `,
};

type Props = {
  warning: Product['warnings'][0];
  color: LinkProps['color'];
  variant: LinkProps['variant'];
};

export const Warning = memo(({ warning, color, variant }: Props) => {
  const fileIsImage = 'url' in warning;
  const file = 'url' in warning ? warning.url : null;
  const value = 'text' in warning ? warning.text : '';
  const attachment = 'attachment' in warning ? warning.attachment : '';

  const isDocument = Boolean(attachment && !file && !value);

  return (
    <Tooltip
      tooltip={
        <Box
          width="100%"
          maxWidth={155}
          height="100%"
          display="flex"
          alignItems="center"
          alignContent="center"
          py={2}
          data-testid="warningTooltip"
        >
          {!fileIsImage && value && (
            <>
              <Box mr={5} ml={2}>
                <img css={styles.image} src={warningImage} alt="" data-testid="warningImage" />
              </Box>

              <div>
                <Typography color="white" variant="caption" bold>
                  WARNING: Proposition 65 Information:{' '}
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: value }} css={styles.textNew} />
              </div>
            </>
          )}

          {fileIsImage && !value && <img css={styles.image} src={file || ''} alt="" data-testid="warningImage" />}

          {fileIsImage && value && (
            <div>
              <div>
                <Typography color="white" variant="caption" bold>
                  WARNING: Proposition 65 Information{' '}
                </Typography>

                <Box mb={2}>
                  <Typography variant="caption">With text and image.</Typography>
                </Box>
              </div>

              <img css={styles.image} src={file || ''} alt="" data-testid="warningImage" />
            </div>
          )}

          {isDocument && 'Click to see document'}
        </Box>
      }
      autoWidth
    >
      <Link
        to={(isDocument && attachment) || '#'}
        target={(isDocument && '_blank') || '_self'}
        variant={variant}
        color={color}
        isExternal
        data-testid="warningDocumentLink"
      >
        WARNING: Proposition 65 Information
      </Link>
    </Tooltip>
  );
});
