import { useCreateModal } from '@partstech/ui/hooks';
import { SupportModalComponent } from './SupportModalComponent';
import type { SupportModalProps } from './SupportModalComponent';
import type { SupportMode } from '../../types';

export const useSupportModal = <TSupportMode extends SupportMode>({
  defaultValues,
  mode,
}: SupportModalProps<TSupportMode>) =>
  useCreateModal(SupportModalComponent, { defaultValues, mode, size: 'medium' }, { customId: 'contact' });
