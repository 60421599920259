/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { ReportProductFragmentDoc } from '../ReportProductFragment.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetTireProductsQueryVariables = Types.Exact<{
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  startDate: Types.Scalars['DateTime']['input'];
  supplierIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  tireSize: Types.Scalars['String']['input'];
}>;

export type GetTireProductsQuery = {
  __typename?: 'Query';
  tireReports?: {
    __typename?: 'TireReports';
    tireProducts?: Array<{
      __typename?: 'ReportProduct';
      dateOfFirstPurchase: Types.Scalars['DateTime']['input'];
      dateOfLastPurchase: Types.Scalars['DateTime']['input'];
      image: Types.Scalars['String']['input'];
      lineCardId?: number | null;
      displayPartNumber?: Types.Scalars['String']['input'] | null;
      partNumber: Types.Scalars['String']['input'];
      partNumberId: Types.Scalars['String']['input'];
      title: Types.Scalars['String']['input'];
      totalOrders: number;
      totalPrice: Types.Scalars['BigDecimal']['input'];
      totalQuantity: number;
      account?: { __typename?: 'Account'; id: string } | null;
      brand?: { __typename?: 'Brand'; name: Types.Scalars['String']['input'] } | null;
      mostCommonSupplier?: { __typename?: 'Supplier'; logo: Types.Scalars['String']['input'] } | null;
      supplier?: { __typename?: 'Supplier'; id: string } | null;
      vehicle?: { __typename?: 'Vehicle'; id: string } | null;
    }> | null;
  } | null;
};

export const GetTireProductsDocument = `
    query GetTireProducts($endDate: DateTime, $startDate: DateTime!, $supplierIds: [ID!], $tireSize: String!) {
  tireReports {
    tireProducts(
      endDate: $endDate
      startDate: $startDate
      supplierIds: $supplierIds
      tireSize: $tireSize
    ) {
      ...ReportProduct
    }
  }
}
    ${ReportProductFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetTireProducts: build.query<GetTireProductsQuery, GetTireProductsQueryVariables>({
      query: (variables) => ({ document: GetTireProductsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetTireProductsQuery, useLazyGetTireProductsQuery } = injectedRtkApi;
