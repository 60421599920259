import { useCallback } from 'react';
import { api as activeCartApi, type GetActiveCartQuery } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { useAppDispatch } from 'store';
import { createLocalInventoryItemData, getAddToCartLocalInventoryInput } from '../../../utils';
import { api } from './AddLocalInventoryItemToCart.generated';
import type { AddToCartPayload } from '../../../types';

const { useAddLocalInventoryItemToActiveCartMutation } = api;

export const useAddLocalInventoryItemToCart = () => {
  const dispatch = useAppDispatch();

  const [mutate, status] = useAddLocalInventoryItemToActiveCartMutation();

  useMutationStatus({ status });

  const addLocalInventoryInventoryToCart = useCallback(
    async (payload: AddToCartPayload) => {
      const res = await mutate({ item: getAddToCartLocalInventoryInput(payload) });
      const { data } = res;

      if (data?.addLocalInventoryItemToActiveCart && 'localInventoryItem' in data.addLocalInventoryItemToActiveCart) {
        const { localInventoryItem } = data.addLocalInventoryItemToActiveCart;
        const localInventoryItemData = createLocalInventoryItemData(
          localInventoryItem.id,
          payload.quantity,
          payload.product
        );

        dispatch(
          activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
            if (!draft?.activeCart) {
              Object.assign(draft, {
                activeCart: {
                  id: 'activeCart',
                  orders: [],
                  localInventoryItems: [localInventoryItemData],
                },
              } satisfies GetActiveCartQuery);

              return;
            }

            const inventoryItem = draft.activeCart?.localInventoryItems?.find(
              (item) => item.id === localInventoryItem.id
            );

            if (inventoryItem) {
              Object.assign(inventoryItem, { quantity: inventoryItem.quantity + payload.quantity });
            } else if (!draft.activeCart.localInventoryItems) {
              Object.assign(draft.activeCart, {
                localInventoryItems: [localInventoryItemData],
              });
            } else {
              draft.activeCart.localInventoryItems.push(localInventoryItemData);
            }
          })
        );
      }

      return res;
    },
    [mutate, dispatch]
  );

  return [addLocalInventoryInventoryToCart] as const;
};
