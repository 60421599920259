import { init as initSentry } from '@sentry/react';
import { isDevEnvironment, environment, release } from 'app/config';
import { checkModuleError } from 'shared/lib/bundling';
import type { BrowserOptions } from '@sentry/react';

export const init = (browserOptions: BrowserOptions) =>
  initSentry({
    allowUrls: [/.*partstech\.com/],
    dsn: 'https://fae64f2aab434d769809c82bdd5380dd@o442774.ingest.sentry.io/5416820',
    debug: false,
    enabled: !isDevEnvironment(),
    release,
    environment,
    tracesSampleRate: 0,
    normalizeDepth: 10,
    beforeSend: (event, hint) => {
      const isModuleError = checkModuleError(hint.originalException as Error);
      if (isModuleError) {
        return null;
      }

      return event;
    },
    ignoreErrors: [
      'ChunkLoadError',
      'Failed to fetch dynamically imported module:',
      "Can't execute code from a freed script",
      's.defineSlot is not a function',
      'ResizeObserver loop limit exceeded',
      /^calljs\d+ is not defined$/i,
      'UET is not defined',
      /iframeConnectionError/,
    ],
    ...browserOptions,
  });
