import { Box } from '@partstech/ui';
import { AvailabilityDropdown, type AvailabilityStatusProps } from '../AvailabilityDropdown';
import type { BoxProps } from '@partstech/ui';
import type { AvailabilityLine } from 'models';

type Props = {
  allowSelect: boolean;
  onStoreChange?: (store: AvailabilityLine) => void;
  status: React.JSXElementConstructor<AvailabilityStatusProps>;
  store?: AvailabilityLine;
  stores?: AvailabilityLine[];
  message?: string;
  usePortal?: boolean;
  disableAutoPlacement?: boolean;
} & BoxProps;

export const Availability = ({
  allowSelect,
  onStoreChange,
  status,
  store,
  stores = [],
  message,
  usePortal,
  disableAutoPlacement,
  ...boxProps
}: Props) => {
  const Status = status;

  if (stores.length > 0 || Boolean(message)) {
    return (
      <Box {...boxProps}>
        <AvailabilityDropdown
          allowSelect={allowSelect}
          onStoreChange={onStoreChange}
          status={status}
          store={store}
          stores={stores}
          message={message}
          usePortal={usePortal}
          disableAutoPlacement={disableAutoPlacement}
        />
      </Box>
    );
  }

  return (
    <Box {...boxProps}>
      <Status store={store} quantity={store?.formattedMaxQuantity} />
    </Box>
  );
};
