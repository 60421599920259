import { useMemo } from 'react';
import { usePermissions } from 'entities/permission';
import type { Job } from 'models';

type Props = {
  jobs: Job[];
  search?: string;
  skip?: boolean;
};

const emptyJobs: Job[] = [];

const findJobsByString = (jobs: Job[], search: string) =>
  search ? jobs.filter((job) => job.isMatchedToString(search)) : jobs;

const searchJobs = (allJobs: Job[], search: string, jobsAllowed: boolean) => {
  const jobs = jobsAllowed ? allJobs : allJobs.filter((job) => job.isFree);

  return findJobsByString(jobs, search);
};

export const useJobSearch = ({ jobs, search = '', skip = false }: Props) => {
  const {
    shop: { jobsAllowed },
  } = usePermissions();

  const filteredJobs = useMemo(() => {
    if (skip) {
      return emptyJobs;
    }

    return searchJobs(jobs, search, jobsAllowed);
  }, [jobs, jobsAllowed, search, skip]);

  return { jobs: filteredJobs };
};
