import { useMutationCallback } from 'shared/api';
import { api as generatedApi } from './UpdateTaxRate.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateTaxRate: {
      invalidatesTags: ['TaxRate', 'ActiveCart'],
    },
  },
});

const { useUpdateTaxRateMutation } = api;

export const useUpdateTaxRate = () => useMutationCallback(useUpdateTaxRateMutation);
