/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { StoreFragmentDoc } from '../../../../shared/api/graphql/fragment/Store.generated';
import { SupplierFragmentDoc } from '../../../../shared/api/graphql/fragment/Supplier.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetPartStoresQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.PartStoreInput>;
}>;

export type GetPartStoresQuery = {
  __typename?: 'Query';
  stores?: Array<{
    __typename?: 'Store';
    name: Types.Scalars['String']['input'];
    id: string;
    logo?: Types.Scalars['String']['input'] | null;
    dropship: Types.Scalars['Boolean']['input'];
    hotshot: number;
    phone?: Types.Scalars['String']['input'] | null;
    pickUp: Types.Scalars['Boolean']['input'];
    warehouse: Types.Scalars['Boolean']['input'];
    supplier?: {
      __typename?: 'Supplier';
      allowCustomNotes: Types.Scalars['Boolean']['input'];
      allowCustomPurchaseOrderNumbers: Types.Scalars['Boolean']['input'];
      allowStockOrders: Types.Scalars['Boolean']['input'];
      allowStoreSelect: Types.Scalars['Boolean']['input'];
      autoValidation: Types.Scalars['Boolean']['input'];
      checkoutMessage?: Types.Scalars['String']['input'] | null;
      connectionTime: Types.ConnectionTime;
      comingSoon: Types.Scalars['Boolean']['input'];
      coreChargePolicy?: Types.Scalars['String']['input'] | null;
      coreReturns: Types.Scalars['Boolean']['input'];
      displayAction: Types.DisplayAction;
      hideLocation: Types.Scalars['Boolean']['input'];
      id: string;
      isTire: Types.Scalars['Boolean']['input'];
      isTireConnect: Types.Scalars['Boolean']['input'];
      lockedStore: Types.Scalars['Boolean']['input'];
      logo: Types.Scalars['String']['input'];
      marketingDescription?: Types.Scalars['String']['input'] | null;
      name: Types.Scalars['String']['input'];
      orderingSupported: Types.Scalars['Boolean']['input'];
      paid: Types.Scalars['Boolean']['input'];
      registrationInstruction?: Types.Scalars['String']['input'] | null;
      returnPolicy?: Types.Scalars['String']['input'] | null;
      type: Types.SupplierType;
      tutorialUrl?: Types.Scalars['String']['input'] | null;
      slug: Types.Scalars['String']['input'];
      additionalInformationConfiguration?: {
        __typename?: 'AdditionalInformation';
        title: Types.Scalars['String']['input'];
        description: Types.Scalars['String']['input'];
        fields: Array<{
          __typename?: 'ConfigurationField';
          helper?: Types.Scalars['String']['input'] | null;
          internalName: Types.Scalars['String']['input'];
          label: Types.Scalars['String']['input'];
          link?: Types.Scalars['String']['input'] | null;
          options: Array<Types.Scalars['String']['input']>;
          required: Types.Scalars['Boolean']['input'];
          type: Types.ConfigurationFieldType;
          validationRules?: {
            __typename?: 'ValidationRule';
            inputType: Types.ValidationInputType;
            length?: Types.Scalars['String']['input'] | null;
          } | null;
        }>;
      } | null;
      credentialsConfiguration: Array<{
        __typename?: 'ConfigurationField';
        helper?: Types.Scalars['String']['input'] | null;
        internalName: Types.Scalars['String']['input'];
        label: Types.Scalars['String']['input'];
        link?: Types.Scalars['String']['input'] | null;
        options: Array<Types.Scalars['String']['input']>;
        required: Types.Scalars['Boolean']['input'];
        type: Types.ConfigurationFieldType;
        validationRules?: {
          __typename?: 'ValidationRule';
          inputType: Types.ValidationInputType;
          length?: Types.Scalars['String']['input'] | null;
        } | null;
      }>;
      outageType?: {
        __typename?: 'Outage';
        type: Types.OutageType;
        message?: Types.Scalars['String']['input'] | null;
      } | null;
      serveLocations: Array<{
        __typename?: 'ServeLocation';
        country: Types.Country;
        regions: Array<Types.Scalars['String']['input']>;
      }>;
    } | null;
    address: {
      __typename?: 'Address';
      address1: Types.Scalars['String']['input'];
      address2?: Types.Scalars['String']['input'] | null;
      city: Types.Scalars['String']['input'];
      country: Types.Country;
      state: Types.Scalars['String']['input'];
      zipCode: Types.Scalars['String']['input'];
    };
    coordinates?: {
      __typename?: 'Coordinates';
      longitude: Types.Scalars['Float']['input'];
      latitude: Types.Scalars['Float']['input'];
    } | null;
  }> | null;
};

export const GetPartStoresDocument = `
    query GetPartStores($input: PartStoreInput) {
  stores(storeInput: {parts: $input}) {
    ...Store
    supplier {
      ...Supplier
    }
  }
}
    ${StoreFragmentDoc}
${SupplierFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetPartStores: build.query<GetPartStoresQuery, GetPartStoresQueryVariables | void>({
      query: (variables) => ({ document: GetPartStoresDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPartStoresQuery, useLazyGetPartStoresQuery } = injectedRtkApi;
