import { Box, Typography } from '@partstech/ui';
import { Outlet } from 'app/AppRouter/Outlet';
import { TransitionLogo } from 'features/transitionLogo';
import { AuthMarketingLinks } from 'shared/ui';
import type { PropsWithChildren } from 'react';

export const InvitationLayout = ({ children }: PropsWithChildren) => (
  <Box width={{ sm: '100%', md: 87 }} height={{ sm: 'auto', md: '100%' }} mx="auto" pt={10} background="white">
    <Outlet>{children}</Outlet>

    <Box mt={10} display="flex" flexDirection="column" alignItems="center" gap={1}>
      <Typography variant="caption">Powered by</Typography>

      <TransitionLogo />
    </Box>

    <AuthMarketingLinks mt={4} />
  </Box>
);
