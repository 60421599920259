import { useCallback } from 'react';
import { useMutationCallback } from 'shared/api';
import { api as generatedApi } from './UpdateCustomJob.generated';
import type { PartType } from 'models';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateCustomJob: {
      invalidatesTags: ['CustomJob', 'VehicleById', 'VehicleByPlateVin'],
    },
  },
});

export const { useUpdateCustomJobMutation } = api;

type Props = {
  onSuccess?: () => Promise<void> | void;
};

export const useUpdateCustomJob = ({ onSuccess }: Props = {}) => {
  const [mutation, status] = useMutationCallback(useUpdateCustomJobMutation, {
    onSuccess,
  });

  const updateCustomJob = useCallback(
    ({ id, name, partTypes }: { id: string; name: string; partTypes: PartType[] }) => {
      const partTypeIds = partTypes.map((part) => part.id);

      return mutation({ input: { id, name, partTypeIds } });
    },
    [mutation]
  );

  return [updateCustomJob, status] as const;
};
