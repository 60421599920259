import { createAddressFromData } from 'entities/address/@x/store';
import { Store } from '../model/Store';
import type { Supplier } from 'entities/supplier/@x/store';
import type { AdditionalInformation, Address, ConfigurationFieldType } from 'shared/api';
import type { CredentialsAdditionalInformation, Store as StoreType } from 'shared/api/rest/gen/shop';

const denormalizeAdditionalInformation = (information: CredentialsAdditionalInformation): AdditionalInformation => ({
  title: information.title,
  description: information.description ?? '',
  fields: information.fields.map((field) => ({
    ...field,
    options: [],
    type: field.type.toUpperCase() as ConfigurationFieldType,
  })),
});

export const createStoreFromData = (payloadStore: StoreType, supplier: Supplier | null) => {
  const address = payloadStore.address ? createAddressFromData(payloadStore.address as Address) : null;

  const store = new Store({
    ...payloadStore,
    id: String(payloadStore.id),
    name: payloadStore.name ?? '',
    address,
    additionalInformation: payloadStore.additionalInformation
      ? denormalizeAdditionalInformation(payloadStore.additionalInformation)
      : null,
  });

  if (supplier) {
    store.setSupplier(supplier);
  }

  return store;
};
