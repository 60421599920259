import { api as generatedApi } from './GetPartStores.generated';
import type { GetPartStoresQuery } from './GetPartStores.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetPartStores: {
      providesTags: (response: GetPartStoresQuery | undefined) => {
        const storeTags = response?.stores?.map((store) => ({ type: 'PartStore' as const, id: store.id })) ?? [];

        return ['PartStore', ...storeTags];
      },
    },
  },
});

export const { useLazyGetPartStoresQuery, useGetPartStoresQuery } = api;
