import { Box, Banner } from '@partstech/ui';
import type { BoxProps } from '@partstech/ui';
import type { Supplier } from 'entities/supplier';

type Props = {
  supplier: Supplier;
} & BoxProps;

export const TireConnectBanner = ({ supplier, ...props }: Props) => {
  if (supplier.isTireDisconnected) {
    return null;
  }

  return (
    <Box mb={{ sm: 4, md: 6 }} {...props}>
      <Banner variant="warning" icon="warning" fullWidth>
        Connecting suppliers may take up to two weeks.
      </Banner>
    </Box>
  );
};
