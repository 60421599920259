/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetTireReportPurchasesQueryVariables = Types.Exact<{
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  perPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.TireReportsSizesSortBy>;
  sortOrder?: Types.InputMaybe<Types.SortOrder>;
  startDate: Types.Scalars['DateTime']['input'];
  supplierIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;

export type GetTireReportPurchasesQuery = {
  __typename?: 'Query';
  tireReports?: {
    __typename?: 'TireReports';
    tireSizes?: {
      __typename?: 'ReportTireSizes';
      totalCount: number;
      statistic: Array<{
        __typename?: 'TireSizesStatistic';
        totalOrders: number;
        totalPrice: Types.Scalars['BigDecimal']['input'];
        totalQuantity: number;
        tireSize?: Types.Scalars['String']['input'] | null;
        mostPopularBrand?: { __typename?: 'Brand'; name: Types.Scalars['String']['input'] } | null;
      }>;
    } | null;
  } | null;
};

export const GetTireReportPurchasesDocument = `
    query GetTireReportPurchases($endDate: DateTime, $page: Int, $perPage: Int, $search: String, $sortBy: TireReportsSizesSortBy, $sortOrder: SortOrder, $startDate: DateTime!, $supplierIds: [ID!]) {
  tireReports {
    tireSizes(
      endDate: $endDate
      page: $page
      perPage: $perPage
      search: $search
      sortBy: $sortBy
      sortOrder: $sortOrder
      startDate: $startDate
      supplierIds: $supplierIds
    ) {
      totalCount
      statistic {
        totalOrders
        totalPrice
        totalQuantity
        mostPopularBrand {
          name
        }
        tireSize
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetTireReportPurchases: build.query<GetTireReportPurchasesQuery, GetTireReportPurchasesQueryVariables>({
      query: (variables) => ({ document: GetTireReportPurchasesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetTireReportPurchasesQuery, useLazyGetTireReportPurchasesQuery } = injectedRtkApi;
