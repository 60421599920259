/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetTaxRateQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetTaxRateQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        retailPricing?: { __typename?: 'RetailPricing'; taxRate: Types.Scalars['BigDecimal']['input'] } | null;
      } | null;
    } | null;
  } | null;
};

export const GetTaxRateDocument = `
    query GetTaxRate {
  currentUser {
    activeMember {
      shop {
        retailPricing {
          taxRate
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetTaxRate: build.query<GetTaxRateQuery, GetTaxRateQueryVariables | void>({
      query: (variables) => ({ document: GetTaxRateDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetTaxRateQuery, useLazyGetTaxRateQuery } = injectedRtkApi;
