import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { App } from 'app/App';
import { environment, isDevEnvironment, release } from 'app/config';
import { init as initDatadog } from 'integrations/datadog';
import { FullStory, init as initFullstory, getFullStorySessionId } from 'integrations/fullstory';
import { sentryFullstory } from 'integrations/fullstory/sentry';
import { init as initSentry } from 'integrations/sentry';
import { init as initUserpilot } from 'integrations/userpilot';
import { client } from 'shared/api';

initFullstory();

client.interceptors.request.use((request) => {
  // eslint-disable-next-line no-param-reassign
  request.headers['X-FSID'] = getFullStorySessionId(isDevEnvironment());

  return request;
});

initSentry({
  integrations: (defaultIntegrations) => {
    const integrations = defaultIntegrations;

    integrations.push(sentryFullstory('partstech', { client: FullStory }));

    return defaultIntegrations.filter((integration) => integration.name !== 'BrowserSession');
  },
});

initUserpilot();

initDatadog({ env: environment, version: release });

const root = createRoot(document.getElementById('app')!);
root.render(<App />);
