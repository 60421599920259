import { DateTime } from 'luxon';

/** * @public */
export const getToNDaysAgo = (days: number) => {
  const date = new Date();
  date.setDate(date.getDate() - days - 1);
  date.setHours(23, 59, 59, 999);

  return DateTime.fromJSDate(date).toISO() ?? '';
};
