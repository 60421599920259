/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { VehicleFragmentDoc } from '../../../../shared/api/graphql/fragment/VehicleFragment.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type RecentVehicleFragment = {
  __typename?: 'RecentVehicle';
  id: string;
  vin?: Types.Scalars['String']['input'] | null;
  state?: Types.Scalars['String']['input'] | null;
  plate?: Types.Scalars['String']['input'] | null;
  vehicle?: {
    __typename?: 'Vehicle';
    id: string;
    type: Types.VehicleType;
    regions?: Array<Types.VehicleRegion> | null;
    year: number;
    make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
    model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
    subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
    engine: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] };
  } | null;
};

export const RecentVehicleFragmentDoc = `
    fragment RecentVehicle on RecentVehicle {
  id
  vin
  state
  plate
  vehicle {
    ...Vehicle
  }
}
    ${VehicleFragmentDoc}`;
