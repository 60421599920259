import { css } from '@emotion/react';
import { Box, Button, Chip, Scrollbar } from '@partstech/ui';
import { useCallback } from 'react';
import { PartTypeList } from 'models';
import type { BoxProps, Theme } from '@partstech/ui';
import type { PartType } from 'models';

type Props = BoxProps & {
  partTypes: PartType[];
  onRemove: (partType: PartType) => void;
  onChange?: (partTypes: PartTypeList) => void;
};

const styles = {
  tags: (theme: Theme) => css`
    max-height: ${theme.sizing(27)};
  `,
};

export const SelectedPartTypes = ({ partTypes, onChange, onRemove, ...props }: Props) => {
  const handleRemoveClick = useCallback(
    (partType: PartType) => () => {
      onRemove(partType);
    },
    [onRemove]
  );

  const handleSearchClick = useCallback(() => {
    onChange?.(new PartTypeList(partTypes));
  }, [onChange, partTypes]);

  if (partTypes.length === 0) {
    return null;
  }

  return (
    <Box {...props}>
      <Scrollbar css={styles.tags}>
        <Box data-testid="selectedPartTypes" gap={2} display="flex" flexWrap="wrap">
          {partTypes.map((partType) => (
            <Chip key={partType.id} data-testid="tag" onClose={handleRemoveClick(partType)}>
              {partType.name}
            </Chip>
          ))}
        </Box>
      </Scrollbar>

      <Box mt={4}>
        <Button fullWidth onClick={handleSearchClick}>
          Search
        </Button>
      </Box>
    </Box>
  );
};
