import { sortBy } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { api as generatedApi } from './GetPartReportLocations.generated';
import type { GetPartReportLocationsQuery } from './GetPartReportLocations.generated';

type Reports = NonNullable<GetPartReportLocationsQuery['partReports']>;
export type PartReportLocation = NonNullable<Reports['partStores']>[0];
type Location = { id: string; name: string };

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetPartReportLocations: {
      providesTags: () => ['Report'],
    },
  },
});

const { useGetPartReportLocationsQuery } = api;

const emptyLocations: Location[] = [];

export const useGetPartReportLocations = () => {
  const { data, ...rest } = useGetPartReportLocationsQuery();

  const locations = useMemo<Location[]>(() => {
    const entities = data?.partReports?.partStores?.map((store) => ({
      id: store.id,
      name: `${store.supplier?.name} - ${store.name}`,
    }));

    return entities ? sortBy(entities, ({ name }) => `${name}`.toLocaleLowerCase()) : emptyLocations;
  }, [data]);

  return {
    ...rest,
    locations,
  };
};
