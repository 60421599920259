import { css } from '@emotion/react';
import { Box, Card, Divider, ImageFallback, Typography, useMedia } from '@partstech/ui';
import { useScrollListener } from '@partstech/ui/hooks';
import { useCallback, useMemo } from 'react';
import { LaborReviewLine } from '../LaborReviewLine';
import { LaborReviewModalLayout } from '../LaborReviewModalLayout';
import { LaborReviewSummaryBottomSheet } from './LaborReviewSummaryBottomSheet';
import { LaborReviewSummaryInfo } from './LaborReviewSummaryInfo';
import type { LaborPart } from '../../../utils/getLaborPartsFromCartOrders';
import type { LaborApplication } from 'models';
import type { PropsWithChildren } from 'react';
import type { LaborRate } from 'shared/api';

const styles = {
  image: css`
    object-fit: contain;
  `,
};

type Props = {
  onAddToCart: () => void;
  totalHours: number;
  selectedLaborApplications: LaborApplication[];
  customDurations: Record<string, number>;
  changeCustomDuration: (laborApplicationId: string, customDuration: number) => void;
  removeLaborApplication: (application: LaborApplication) => void;
  onClose: () => void;
  laborRates: LaborRate[];
  selectedLaborRate?: LaborRate;
  onSelectLaborRate: (laborRateId: string) => void;
  laborParts: LaborPart[];
  isAdding: boolean;
};

export const LinkedLaborReviewModal = ({
  onClose,
  onAddToCart,
  totalHours,
  customDurations,
  changeCustomDuration,
  removeLaborApplication,
  selectedLaborApplications,
  laborRates,
  selectedLaborRate,
  onSelectLaborRate,
  laborParts,
  isAdding,
}: PropsWithChildren<Props>) => {
  const { isMobile } = useMedia();
  const { isScrolling, onScroll } = useScrollListener();

  const hasLabor = totalHours > 0;

  const laborApplicationsByParts = useMemo(
    () =>
      laborParts.reduce(
        (acc, part) => {
          const applications = selectedLaborApplications.filter((application) => application.relatedItemId === part.id);

          return {
            ...acc,
            [part.id]: applications,
          };
        },
        {} as Record<string, LaborApplication[]>
      ),
    [selectedLaborApplications, laborParts]
  );

  const handleAddToCart = useCallback(() => {
    onAddToCart();
    onClose?.();
  }, [onAddToCart, onClose]);

  return (
    <LaborReviewModalLayout
      onClose={onClose}
      onScroll={onScroll}
      vehicle={null}
      summary={
        isMobile ? (
          <LaborReviewSummaryBottomSheet
            onAddToCart={handleAddToCart}
            totalHours={totalHours}
            laborRates={laborRates}
            selectedLaborRate={selectedLaborRate}
            onSelectLaborRate={onSelectLaborRate}
            isScrolling={isScrolling}
            isAdding={isAdding}
          />
        ) : (
          <Card width={80} px={4} py={6} position="sticky" top={6} data-testid="reviewSummary">
            <Typography variant="subtitle1">Summary</Typography>

            <LaborReviewSummaryInfo
              onAddToCart={handleAddToCart}
              totalHours={totalHours}
              laborRates={laborRates}
              selectedLaborRate={selectedLaborRate}
              onSelectLaborRate={onSelectLaborRate}
              isAdding={isAdding}
            />
          </Card>
        )
      }
    >
      {hasLabor &&
        laborParts.map((part) => {
          const laborApplications = laborApplicationsByParts[part.id];

          if (!laborApplications || laborApplications.length === 0) {
            return null;
          }

          return (
            <Card key={part.id} mb={4}>
              <Box px={4} py={2} display="flex" alignItems="center" gap={2}>
                <ImageFallback src={part.image || undefined} width={40} height={40} css={styles.image} />

                <Typography variant="subtitle1">{part.title}</Typography>
              </Box>

              <Divider />

              <Box p={4}>
                {laborApplications.map((application) => (
                  <LaborReviewLine
                    key={application.id}
                    laborApplication={application}
                    customDuration={customDurations[application.id]}
                    onRemove={removeLaborApplication}
                    onChangeCustomDuration={changeCustomDuration}
                  />
                ))}
              </Box>
            </Card>
          );
        })}
    </LaborReviewModalLayout>
  );
};
