import { useForm } from '@partstech/ui/forms';
import { useToggle } from '@partstech/ui/hooks';
import { isEqual } from '@partstech/ui/utils';
import { useCallback, useEffect } from 'react';
import { useValidateAddresses } from 'entities/address';
import { FullStoryEventNames, useFullStory } from 'integrations/fullstory';
import { Country } from 'shared/api';
import { useFormMutationCallback, useFormStatus } from 'shared/lib/form';
import { useCheckDuplicates } from '../../api/checkDuplicates';
import { useCreateShopMutation } from '../../api/createShop';
import { useUpdateShopMutation } from '../../api/updateShop/UpdateShop.generated';
import type { ShopFormData } from '../../types';
import type { ChangeEvent } from 'react';

const initialValues: ShopFormData = {
  billingAddress: {
    address1: '',
    city: '',
    country: Country.Us,
    state: '',
    zipCode: '',
  },
  name: '',
  phone: '',
  website: '',
};

type Args = {
  defaultValues?: ShopFormData;
  onSubmit: (
    shop: ShopFormData,
    params: Partial<{ hasDuplicates: boolean; hasChangedShopInfo: boolean }>
  ) => Promise<void>;
  hasShop?: boolean;
};

export const useCreateShopForm = ({ defaultValues, onSubmit, hasShop }: Args) => {
  const { sendEvent } = useFullStory();

  const { isToggle: isShowShippingForm, setToggle: setIsShowShippingForm } = useToggle(true);

  const form = useForm<ShopFormData>({ defaultValues: initialValues });
  const { reset, watch, setValue, setError } = form;

  const formValues = watch();

  const [checkShopDuplicates, checkShopDuplicatesStatus] = useCheckDuplicates();
  const [validateAddresses, statusValidateAddresses] = useValidateAddresses();
  useFormStatus({ form, status: statusValidateAddresses });

  const handleCreateShopSuccess = useCallback(async () => {
    sendEvent(FullStoryEventNames.CREATE_SHOP);

    await onSubmit(formValues, { hasDuplicates: checkShopDuplicatesStatus.data?.checkDuplicates?.hasDuplicates });
  }, [checkShopDuplicatesStatus.data?.checkDuplicates?.hasDuplicates, formValues, onSubmit, sendEvent]);

  const [createShop] = useFormMutationCallback(useCreateShopMutation, {
    onSuccess: handleCreateShopSuccess,
    onValidationError: setError,
  });
  const [updateShop] = useFormMutationCallback(useUpdateShopMutation, {
    onSuccess: handleCreateShopSuccess,
    onValidationError: setError,
  });

  const changeShippingAddressCheckbox = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(
        'shippingAddress',
        e.target.checked
          ? undefined
          : {
              address1: '',
              city: '',
              country: Country.Us,
              state: '',
              zipCode: '',
            }
      );

      setIsShowShippingForm(e.target.checked);
    },
    [setIsShowShippingForm, setValue]
  );

  const submitForm = useCallback(
    async (values: ShopFormData) => {
      if (hasShop && isEqual(defaultValues, values)) {
        await onSubmit(values, { hasChangedShopInfo: false });
        return;
      }

      const hasValidAddresses = await validateAddresses({
        billingAddress: values.billingAddress,
        shippingAddress: values.shippingAddress,
      });

      if (!hasValidAddresses) {
        return;
      }

      if (!hasShop) {
        const hasDuplicates = await checkShopDuplicates({
          billingAddress: values.billingAddress,
          phone: values.phone,
        });

        if (hasDuplicates === null) {
          return;
        }

        if (hasDuplicates === true) {
          await onSubmit(values, { hasDuplicates: true });
          return;
        }

        await createShop({ input: values });

        return;
      }

      await updateShop({ input: values });
    },
    [checkShopDuplicates, createShop, defaultValues, hasShop, onSubmit, updateShop, validateAddresses]
  );

  useEffect(() => {
    if (defaultValues) {
      const { shippingAddress } = defaultValues;

      setIsShowShippingForm(!shippingAddress);

      reset(defaultValues);
    }
  }, [defaultValues, reset, setIsShowShippingForm]);

  return { form, submitForm, isShowShippingForm, changeShippingAddressCheckbox };
};
