/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetPartTypeReportsQueryVariables = Types.Exact<{
  partTypeId: Types.Scalars['ID']['input'];
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  startDate: Types.Scalars['DateTime']['input'];
  storeIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;

export type GetPartTypeReportsQuery = {
  __typename?: 'Query';
  partReports?: {
    __typename?: 'PartReports';
    partType?: {
      __typename?: 'PartTypesStatistic';
      totalOrders: number;
      totalPrice: Types.Scalars['BigDecimal']['input'];
      totalQuantity: number;
      partType?: { __typename?: 'PartType'; name: Types.Scalars['String']['input'] } | null;
    } | null;
  } | null;
};

export const GetPartTypeReportsDocument = `
    query GetPartTypeReports($partTypeId: ID!, $endDate: DateTime, $startDate: DateTime!, $storeIds: [ID!]) {
  partReports {
    partType(
      partTypeId: $partTypeId
      endDate: $endDate
      startDate: $startDate
      storeIds: $storeIds
    ) {
      totalOrders
      totalPrice
      totalQuantity
      partType {
        name
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetPartTypeReports: build.query<GetPartTypeReportsQuery, GetPartTypeReportsQueryVariables>({
      query: (variables) => ({ document: GetPartTypeReportsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPartTypeReportsQuery, useLazyGetPartTypeReportsQuery } = injectedRtkApi;
