import { gExportApi } from 'shared/api';

type Params = {
  from: string;
  to?: string | null;
  supplierIds?: string | null;
  tireSize: string;
};

const api = gExportApi.injectEndpoints({
  endpoints: (build) => ({
    GetTireSizeReportExport: build.query<string, Params>({
      query: (params) => ({ method: 'GET', url: '/tire-report/products', params }),
      serializeQueryArgs: (params) => params,
      providesTags: () => ['ExportedReport'],
    }),
  }),
});

export const { useLazyGetTireSizeReportExportQuery } = api;
