import { useBrowserTabActivity, useLocalStorage } from '@partstech/ui/hooks';
import { useCallback, useEffect } from 'react';
import { LocalStorageKeys } from 'constant';

export const useAutoReloadOnStorageChange = () => {
  const [_, setValue] = useLocalStorage<boolean>(LocalStorageKeys.NEED_TO_REFRESH);

  const isActive = useBrowserTabActivity({ refreshTime: 500 });

  useEffect(() => {
    if (isActive) {
      return () => {};
    }

    const handleStorageEvent = (event: StorageEvent) => {
      if (event.key !== LocalStorageKeys.NEED_TO_REFRESH) {
        return;
      }

      setValue();
      window.location.reload();
    };

    window.addEventListener('storage', handleStorageEvent);

    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, [isActive, setValue]);

  return useCallback(() => setValue(true), [setValue]);
};
