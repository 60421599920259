import { isNotNull } from '@partstech/ui/utils';
import { Vehicle } from 'entities/vehicle';
import { createLaborApplicationFromCartLaborItem } from 'factories/labor';
import { Cart } from 'models';
import { CartImprovedStatus } from 'shared/api';
import {
  createCartOrderFromActiveCart,
  createCartOrderFromActiveOrder,
  createCartOrderFromLocalInventory,
  createCartOrderFromPaidOrder,
} from './createCartOrder';
import { createCartTotalsFromCartOrders } from './createCartTotals';
import type { SupplierAccount } from 'entities/supplierAccount';
import type { ActiveCartRawData } from 'features/cart/api/queries';
import type { SavedCartRawData } from 'features/savedQuotes';

const createLaborApplications = (cart: ActiveCartRawData | SavedCartRawData) => {
  const unlinkedLaborApplications = cart.laborItems?.map(createLaborApplicationFromCartLaborItem) || [];

  const paidUnlinkedLaborApplications =
    'orderedLaborItems' in cart ? cart.orderedLaborItems?.map(createLaborApplicationFromCartLaborItem) || [] : [];

  const linkedOrdersLaborApplications =
    cart.orders
      ?.flatMap((order) => order.items?.flatMap((orderItem) => orderItem.laborItems))
      .filter(isNotNull)
      .map(createLaborApplicationFromCartLaborItem) || [];

  const linkedLocalInventoryLaborApplications =
    'localInventoryItems' in cart && cart.localInventoryItems
      ? cart.localInventoryItems
          .flatMap((item) => item.laborItems || [])
          .filter(isNotNull)
          .map(createLaborApplicationFromCartLaborItem)
      : [];

  const paidLinkedLaborApplications =
    'paidOrders' in cart
      ? cart.paidOrders
          ?.flatMap((order) => order.lines?.flatMap((orderItem) => orderItem.orderedLaborItems))
          .filter(isNotNull)
          .map(createLaborApplicationFromCartLaborItem) || []
      : [];

  return [
    ...unlinkedLaborApplications,
    ...paidUnlinkedLaborApplications,
    ...linkedOrdersLaborApplications,
    ...linkedLocalInventoryLaborApplications,
    ...paidLinkedLaborApplications,
  ];
};

const createVehicles = (vehiclesRaw?: ActiveCartRawData['vehicles'] | null) =>
  vehiclesRaw
    ?.map((item) => (item.vehicle ? new Vehicle({ ...item.vehicle, vin: item.vin }) : null))
    .filter(isNotNull) || [];

export const createCartFromActiveCartData = ({
  cart,
  accounts,
  orderItemIdsToRemove = [],
}: {
  cart: ActiveCartRawData;
  accounts: SupplierAccount[];
  orderItemIdsToRemove?: string[];
}) => {
  const vehicles = createVehicles(cart?.vehicles);

  const orders =
    cart.orders
      ?.map((order) => createCartOrderFromActiveCart({ order, vehicles, accounts, orderItemIdsToRemove }))
      .filter((order) => order.items.length > 0) || [];

  const laborApplication = createLaborApplications(cart);
  const laborHours = laborApplication.reduce((acc, application) => acc + application.duration, 0);
  const localInventory = createCartOrderFromLocalInventory({ cart, vehicles, orderItemIdsToRemove });

  return new Cart({
    id: cart.id,
    orders,
    vehicles,
    status: CartImprovedStatus.Created,
    isActive: true,
    totals: createCartTotalsFromCartOrders(orders, cart.laborRate || 0, laborHours, localInventory),
    laborRate: cart.laborRate,
    laborApplication: createLaborApplications(cart),
    localInventory,
  });
};

export const createCartFromSavedCartData = (cart: SavedCartRawData) => {
  const vehicles = createVehicles(cart?.vehicles);

  const activeOrders = cart.orders?.map((order) => createCartOrderFromActiveOrder(order, vehicles));

  const paidOrders = cart.paidOrders?.map((order) => createCartOrderFromPaidOrder(order, vehicles)) || [];

  const laborApplication = createLaborApplications(cart);
  const laborHours = laborApplication.reduce((acc, application) => acc + application.duration, 0);

  return new Cart({
    id: cart.id,
    orders: activeOrders?.length ? activeOrders : paidOrders,
    vehicles,
    updatedAt: cart.updatedAt,
    status: cart.improvedStatus,
    isActive: cart.active,
    customerEmail: cart.customerEmail,
    notes: cart.notes,
    repairOrderNumber: cart.repairOrderNumber,
    showOnlyRetailPrice: cart.showRetailPricing,
    totals: createCartTotalsFromCartOrders(activeOrders || [], cart.laborRate || 0, laborHours),
    laborApplication,
    laborRate: cart.laborRate || 0,
    user: cart.user,
  });
};
