import { useMemo } from 'react';
import useCookie from 'react-use-cookie';

export const useCookieUTMParams = () => {
  const [msclkid] = useCookie('msclkid', undefined);
  const [fbclid] = useCookie('fbclid', undefined);
  const [gclid] = useCookie('gclid', undefined);
  const [utm_campaign] = useCookie('utm_campaign', undefined);
  const [utm_source] = useCookie('utm_source', undefined);
  const [utm_term] = useCookie('utm_term', undefined);
  const [utm_medium] = useCookie('utm_medium', undefined);
  const [utm_content] = useCookie('utm_content', undefined);

  return useMemo(
    () => ({ msclkid, fbclid, gclid, utm_campaign, utm_source, utm_term, utm_medium, utm_content }) as const,
    [fbclid, gclid, msclkid, utm_campaign, utm_content, utm_medium, utm_source, utm_term]
  );
};
