import { Box, Typography } from '@partstech/ui';

export const GoogleInvalidToken = () => (
  <Box display="flex" flexDirection="column" gap={2}>
    <Box textAlign="center">
      <Typography variant="subtitle1" color="negative">
        Google sign in failed
      </Typography>
    </Box>

    <Typography variant="bodyText2" color="negative">
      The Google token is invalid. Please try again.
    </Typography>
  </Box>
);
