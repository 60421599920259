import { css } from '@emotion/react';
import { Box, ComboBox, Icon, Scrollbar, TextField, Typography, WithClickAway } from '@partstech/ui';
import { useOpen } from '@partstech/ui/hooks';
import { useCallback, useMemo, useRef } from 'react';
import { useInputValue } from 'shared/lib/form';
import { MIN_SEARCH_LENGTH } from '../../constants';
import { useCategoryOptions } from '../../hooks';
import { getSubCategoriesByPartTypes } from '../../utils/getSubCategoriesByPartTypes';
import { PartTaxonomySelector } from '../PartTaxonomySelector';
import { ValuePlaceholder } from '../ValuePlaceholder';
import type { ComboBoxProps, Theme } from '@partstech/ui';

import type { PartSubCategory, PartType } from 'models';
import type { ChangeEvent } from 'react';

type TaxonomySelectBoxProps = {
  defaultValue: PartType[];
  onChange: (newValue: PartType[]) => void;
  disabledPartTypeIds?: string[];
  hasTags?: boolean;
  selectableSubCategories?: boolean;
} & ComboBoxProps;

const styles = {
  field: css`
    .text-field-container,
    .text-field-input-container {
      padding-right: 0;
    }
  `,

  contentScroll: css`
    position: relative;
  `,
  dropdownContainer: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  `,
  searchInput: (theme: Theme) => css`
    margin-top: 0;
    border-color: transparent;
    border-radius: 0;
    border-bottom-color: ${theme.color.mono17};
  `,
};

export const TaxonomySelectBox = ({
  defaultValue,
  disabledPartTypeIds,
  onChange,
  hasTags,
  selectableSubCategories,
  ...comboBoxProps
}: TaxonomySelectBoxProps) => {
  const { value: searchValue, debouncedValue, clearValue, changeValue } = useInputValue('');

  const {
    categories,
    selectedPartTypes,
    togglePartType,
    toggleSubCategory,
    removeSubCategory,
    subCategories,
    partTypes,
    openOption,
    openedSubCategory,
    openedCategory,
    goBack,
    reset,
    isLoaded,
    isFetching,
    findCategoryNameBySubCategory,
    findSubCategory,
  } = useCategoryOptions({
    searchValue: searchValue === '' ? '' : debouncedValue,
    defaultValue,
    disabledPartTypeIds,
    onChange,
  });

  const { open, close, isOpen } = useOpen();

  const scrollbar = useRef<HTMLDivElement>(null);

  const handleSearchInputChange = useCallback(
    (input: ChangeEvent<HTMLInputElement>) => {
      changeValue(input);

      if (openedSubCategory || openedCategory) {
        reset();
      }
    },
    [changeValue, reset, openedCategory, openedSubCategory]
  );

  const handleClearClick = useCallback(() => {
    if (!searchValue) {
      close();
    }

    scrollbar.current?.scroll({ top: 0 });
    reset();
  }, [close, reset, searchValue]);

  const handleClose = useCallback(() => {
    clearValue();

    close();
    reset();
  }, [clearValue, reset, close]);

  const isAllOptionsEmpty = isLoaded && categories.length === 0 && subCategories.length === 0 && partTypes.length === 0;

  const selectorTitle = openedSubCategory?.name ?? openedCategory?.name;

  const selectedSubCategories = useMemo(
    () => getSubCategoriesByPartTypes(selectedPartTypes, findSubCategory),
    [findSubCategory, selectedPartTypes]
  );

  return (
    <ComboBox
      label="Select part types"
      labelAlwaysUp={isOpen || selectedPartTypes.length > 0}
      open={isOpen}
      onOpen={open}
      onClose={handleClose}
      css={styles.field}
      fieldContent={
        hasTags && <ValuePlaceholder<PartSubCategory> value={selectedSubCategories} onRemove={removeSubCategory} />
      }
      expandedContent={
        <WithClickAway onClickAway={handleClose} css={styles.dropdownContainer}>
          <TextField
            adornmentBefore={<Icon name="search" size="medium" />}
            value={searchValue}
            onChange={handleSearchInputChange}
            name="taxonomyFilter"
            onClear={handleClearClick}
            css={styles.searchInput}
            isClearable
          />
          <Scrollbar ref={scrollbar} data-testid="partTaxonomySelector" css={styles.contentScroll}>
            {isAllOptionsEmpty && (
              <Box position="relative" width="100%" textAlign="center" p={2}>
                <Typography color="subtleText">Your search yielded no results</Typography>
              </Box>
            )}

            {isFetching && (
              <Box position="relative" width="100%" textAlign="center" p={2}>
                <Typography color="subtleText">Fetching results...</Typography>
              </Box>
            )}

            {!isAllOptionsEmpty && !isFetching && (
              <PartTaxonomySelector
                disabledPartTypeIds={disabledPartTypeIds}
                title={selectorTitle}
                categories={categories}
                subCategories={subCategories}
                showSectionTitles={Boolean(searchValue) && searchValue.length >= MIN_SEARCH_LENGTH}
                partTypes={partTypes}
                onOpen={openOption}
                onGoBack={goBack}
                value={selectedPartTypes}
                onTogglePartType={togglePartType}
                onToggleSubCategory={toggleSubCategory}
                highlightedText={searchValue.length >= MIN_SEARCH_LENGTH ? searchValue : ''}
                renderTag={findCategoryNameBySubCategory}
                selectableSubCategories={selectableSubCategories}
              />
            )}
          </Scrollbar>
        </WithClickAway>
      }
      {...comboBoxProps}
    />
  );
};
