import { Link, TextCut, Typography } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { VehicleDetailsModal } from '../VehicleDetailsModal';
import type { Vehicle } from '../../model/Vehicle';
import type { TypographyProps } from '@partstech/ui';
import type { SyntheticEvent } from 'react';

type Props = {
  vehicle: Vehicle;
  disabled: boolean;
  variant?: TypographyProps['variant'];
};

export const Vin = ({ vehicle, disabled, variant = 'caption' }: Props) => {
  const { open } = useCreateModal(
    VehicleDetailsModal,
    { id: vehicle.id, vin: vehicle.vin, size: 'medium' },
    { customId: 'vinDetails' }
  );

  const handleOpen = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      open();
    },
    [open]
  );

  if (disabled) {
    return (
      <Typography variant={variant} color="subtleText">
        VIN: {vehicle.vin}
      </Typography>
    );
  }

  return (
    <Link to="#" onClick={handleOpen} data-testid="vinButton" variant={variant} color="linkText" underline="hover">
      <TextCut shouldShowTooltip={false}>VIN: {vehicle.vin}</TextCut>
    </Link>
  );
};
