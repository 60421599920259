import { Job } from '../../models/Job';
import { createPartTypeFromQuery } from '../partTypes/createPartTypeFromQuery';
import type { JobFragment } from 'shared/api';

export const createJobFromQuery = (job: JobFragment) =>
  new Job({
    ...job,
    partTypes: job.partTypes.map((jobPartType) => ({
      ...jobPartType,
      partType: createPartTypeFromQuery(jobPartType.partType),
      optional: !jobPartType.required,
    })),
    type: job.type,
    priority: job.priority,
  });
