import { api as rootSupplierAccountsApi } from 'store/queries/currentUser/supplierAccounts/GetSupplierAccounts.generated';
import type { SupplierFragment, StoreFragment } from 'shared/api';
import type { SupplierAccountFragment } from 'store/queries/currentUser/supplierAccounts/SupplierAccount.generated';

type Account = Partial<
  SupplierAccountFragment & {
    store: StoreFragment | null;
    supplier: SupplierFragment | null;
  }
>;

export const updateSupplierAccountAction = (accountId: string, account: Partial<Account>) =>
  rootSupplierAccountsApi.util.updateQueryData('GetSupplierAccounts', {}, (draft) => {
    const entity = draft.currentUser?.activeMember?.shop?.accounts?.find(
      (supplierAccount) => supplierAccount.id === accountId
    );

    if (entity) {
      Object.assign(entity, account);
    }
  });
