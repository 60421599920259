import { Brand, Media, PartNumber, PartType, Product } from 'models';
import { createAttributesFromQuery } from './createAttributesFromQuery';
import { createInterchangePart } from './createInterchangePart';
import { createQuoteFromQuery } from './quote';
import type { SupplierAccount } from 'entities/supplierAccount';
import type { BaseProductFragment } from 'store/queries/searchResults/BaseProductFragment.generated';

type Params = {
  account?: SupplierAccount | null;
  product: BaseProductFragment;
  showRetailPrice?: boolean;
  vehicleId?: string | null;
  isPreferred?: boolean;
};

export const createBaseProductFromQuery = ({
  account,
  product,
  showRetailPrice = false,
  vehicleId,
  isPreferred = false,
}: Params) => {
  const model = new Product({
    ...product,
    attributes: createAttributesFromQuery(product.attributes),
    bundles: product.bundles,
    coreCharge: product.coreCharge ?? null,
    credentialId: account?.id ?? null,
    descriptions: product.descriptions,
    fetCharge: product.fetCharge ?? null,
    hasVehicleFitment: product.hasFitment,
    id: product.id,
    interchangePart: createInterchangePart(product.interchangeFor),
    lineCardId: product.lineCard?.id ? Number(product.lineCard?.id) : null,
    listPrice: product.listPrice ?? null,
    mapPrice: product.mapPrice ?? null,
    notes: product.notes.map((note) => note.group),
    notesOem: product.notesOem,
    price: product.price ?? null,
    quantityStep: product.quantityStep ?? null,
    rebates: product.rebates,
    retailPrice: product.customerPrice ?? null,
    rewardPoints: product.rewardPoints ?? null,
    shippingCharge: product.shippingCharge ?? false,
    sortHash: product.sortHash ?? '',
    sponsoredPurchasesCount: product.sponsoredPurchasesCount ?? null,
    sponsorType: product.sponsorType ?? null,
    stocked: product.stocked,
    stockMessage: product.deliveryNotes,
    title: product.title,
    variations: product.variations.map((variation) => ({
      ...variation,
      attributes: createAttributesFromQuery(variation.attributes),
    })),
    vehicleId: vehicleId ? Number(vehicleId) : null,
  });

  if (account?.supplier) {
    model.setSupplier(account.supplier);
  }

  if (account?.store) {
    model.setStore(account.store);
  }

  model.setMedia(
    new Media({
      images: product.images,
      image360Groups: product.image360Groups.map((group) => group.images),
      videos: product.videos,
    })
  );

  model.setShowRetailPrice(showRetailPrice);

  model.setPartNumber(new PartNumber({ partNumber: product.partNumber, id: product.partNumberId }));

  if (product.partType) {
    model.setPartType(new PartType(product.partType));
  }

  if (product.brand) {
    model.setBrand(
      new Brand({
        id: product.brand.id,
        isPreferred,
        lineCode: product.brand.lineCode ?? null,
        logo: product.brand.logo ?? null,
        name: product.brand.name ?? null,
      })
    );
  }

  const quote = createQuoteFromQuery(product, model.stockMessage, account);

  if (quote) {
    model.setQuote(quote);
  }

  return model;
};
