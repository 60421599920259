import { css } from '@emotion/react';
import { Badge, Box, Link, Status } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';
import icon from 'images/content/jobs/vehicle-specific-icon.svg';
import { IntroducingModal } from './IntroducingModal';
import type { Theme } from '@partstech/ui';

const styles = {
  label: (theme: Theme) => css`
    > div {
      background: linear-gradient(90deg, ${theme.color.primary}, ${theme.color.primaryLight10});
      border: none;
    }
  `,
};

export const VehicleSpecificJobsLabel = () => {
  const { open } = useCreateModal(IntroducingModal, { size: 'large' }, { customId: 'vehicleSpecificJobs' });

  return (
    <Box
      pl={{ sm: 1, md: 4 }}
      display="flex"
      alignItems={{ sm: 'flex-start', md: 'center' }}
      justifyContent="flex-start"
      flexDirection={{ sm: 'column', md: 'row' }}
    >
      <Box
        position="relative"
        height={8}
        width={37}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        css={styles.label}
      >
        <Badge content="NEW" backgroundColor="positive" variant="round" offsetX={4} offsetY={-5} />
        <Status variant="filled" builtIn="top-left" color="primary">
          <Box display="flex" gap={1} py={0.5} mr={4}>
            <img src={icon} alt="Vehicle specific job icon" />
            Vehicle specific
          </Box>
        </Status>
      </Box>

      <Box ml={{ sm: 0, md: 5 }} pt={{ sm: 2, md: 1 }} pb={2}>
        <Link to="" variant="bodyText2" onClick={open}>
          Learn more
        </Link>
      </Box>
    </Box>
  );
};
