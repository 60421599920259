import type { AvailabilityLine } from 'models';
import type { ProductDeliveryNote } from 'shared/api';

type AvailabilityLineVariant = 'default' | 'featured' | 'partialMain';
type Status = 'IN_STOCK' | 'SPECIAL_ORDER' | 'BACK_ORDER' | 'OUT_OF_STOCK' | 'LOCAL_INVENTORY';

export class Quote {
  lines: AvailabilityLine[];

  status: Status;

  action: 'ADD_TO_CART' | 'CHECK_AVAILABILITY' | 'CALL_AVAILABILITY' | 'CALL_SUPPLIER' | 'HIDDEN';

  stockMessage?: ProductDeliveryNote[] | null = null;

  variant?: AvailabilityLineVariant = 'default';

  constructor({
    action,
    status,
    lines,
    stockMessage,
    variant,
  }: Pick<Quote, 'action' | 'status' | 'lines' | 'stockMessage' | 'variant'>) {
    this.action = action;
    this.status = status;
    this.lines = lines;
    this.stockMessage = stockMessage;
    this.variant = variant;
  }

  getAvailableQuantity() {
    return this.lines.reduce((sum, line) => sum + line.quantity, 0);
  }

  getMainLine() {
    return this.lines.find((line) => line.isMain());
  }

  getNetworkLine() {
    return this.lines.find((line) => line.isNetwork());
  }

  getClosestLine() {
    return this.lines.find((line) => line.isClosest());
  }

  getStockMessage() {
    if (!this.stockMessage) {
      return null;
    }

    return this.stockMessage;
  }

  static getVariant(
    storesGroups: (string | null)[],
    status: Status,
    hasTargetSupplier: boolean
  ): AvailabilityLineVariant {
    const firstStoreGroup = storesGroups[0];

    if (!firstStoreGroup && status !== 'IN_STOCK') {
      return hasTargetSupplier ? 'featured' : 'default';
    }

    if (hasTargetSupplier && firstStoreGroup === 'Within 2 hours') {
      return 'partialMain';
    }

    if (hasTargetSupplier) {
      return 'featured';
    }

    return 'default';
  }

  hasOneLine() {
    return this.lines.length === 1;
  }

  private isMainEmptyAndNetworkInStock() {
    return (
      (this.isInStock() &&
        !this.isClosest() &&
        !this.getMainLine()?.isMainInStock() &&
        this.getNetworkLine()?.isNetworkInStock()) ||
      false
    );
  }

  private isMainEmptyAndClosestInStock() {
    return (
      (this.isInStock() && !this.getMainLine()?.isMainInStock() && this.getClosestLine()?.isClosestInStock()) || false
    );
  }

  isAvailableOrder() {
    return false;
  }

  isBackOrder() {
    return this.status === 'BACK_ORDER';
  }

  isInStock() {
    return this.status === 'IN_STOCK';
  }

  isInNetwork() {
    return this.isMainEmptyAndNetworkInStock();
  }

  isClosest() {
    return this.isMainEmptyAndClosestInStock();
  }

  isOutOfStock() {
    return this.status === 'OUT_OF_STOCK';
  }

  isSpecialOrder() {
    return this.status === 'SPECIAL_ORDER';
  }

  isLocalInventory() {
    return this.status === 'LOCAL_INVENTORY';
  }

  isAddToCart() {
    return this.action === 'ADD_TO_CART';
  }

  isCallAvailability() {
    return this.action === 'CALL_AVAILABILITY';
  }

  isCallSupplier() {
    return this.action === 'CALL_SUPPLIER';
  }

  isFastestDelivery() {
    return this.isInStock() && !this.isClosest() && !this.isInNetwork();
  }

  isDirectDelivery() {
    return this.isInStock() && !this.isInNetwork();
  }

  isNetworkDelivery() {
    return this.isInNetwork() || this.isInStock();
  }
}
