/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdateOrderPoNumberMutationVariables = Types.Exact<{
  purchaseOrderNumber: Types.Scalars['String']['input'];
  orderId: Types.Scalars['ID']['input'];
}>;

export type UpdateOrderPoNumberMutation = {
  __typename?: 'Mutation';
  updateActiveCartOrderPurchaseOrderNumber?:
    | { __typename?: 'UpdateOrderPurchaseOrderNumberErrorPayload'; errorMessage: Types.Scalars['String']['input'] }
    | {
        __typename?: 'UpdateOrderPurchaseOrderNumberSuccessPayload';
        updatedOrderId: string;
        order?: { __typename?: 'CartOrder'; purchaseOrderNumber?: Types.Scalars['String']['input'] | null } | null;
      }
    | null;
};

export const UpdateOrderPoNumberDocument = `
    mutation UpdateOrderPoNumber($purchaseOrderNumber: String!, $orderId: ID!) {
  updateActiveCartOrderPurchaseOrderNumber(
    input: {purchaseOrderNumber: $purchaseOrderNumber, orderId: $orderId}
  ) {
    ... on UpdateOrderPurchaseOrderNumberErrorPayload {
      errorMessage
    }
    ... on UpdateOrderPurchaseOrderNumberSuccessPayload {
      updatedOrderId
      order {
        purchaseOrderNumber
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateOrderPoNumber: build.mutation<UpdateOrderPoNumberMutation, UpdateOrderPoNumberMutationVariables>({
      query: (variables) => ({ document: UpdateOrderPoNumberDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateOrderPoNumberMutation } = injectedRtkApi;
