/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { ReportLocationFragmentDoc } from '../ReportLocationFragment.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetSpendReportLocationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetSpendReportLocationsQuery = {
  __typename?: 'Query';
  spendReports?: {
    __typename?: 'SpendReports';
    spendStores?: Array<{
      __typename?: 'Store';
      id: string;
      name: Types.Scalars['String']['input'];
      supplier?: { __typename?: 'Supplier'; id: string; name: Types.Scalars['String']['input'] } | null;
    }> | null;
  } | null;
};

export const GetSpendReportLocationsDocument = `
    query GetSpendReportLocations {
  spendReports {
    spendStores {
      ...ReportLocation
    }
  }
}
    ${ReportLocationFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSpendReportLocations: build.query<GetSpendReportLocationsQuery, GetSpendReportLocationsQueryVariables | void>({
      query: (variables) => ({ document: GetSpendReportLocationsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSpendReportLocationsQuery, useLazyGetSpendReportLocationsQuery } = injectedRtkApi;
