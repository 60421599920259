import { api as currentUserApi } from 'store/queries/currentUser/useGetCurrentUser';
import { api as generatedApi } from './SetDiyUser.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    SetDiyUser: {
      onQueryStarted: async (_input, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;

          await dispatch(
            currentUserApi.util.updateQueryData('GetCurrentUser', undefined, (draft) => {
              if (!draft.currentUser) {
                return;
              }

              Object.assign(draft.currentUser, { isDiy: true });
            })
          );
        } catch {
          /* empty */
        }
      },
    },
  },
});

export const { useSetDiyUserMutation } = api;
