/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';

import { CartLocalInventoryItemFragmentDoc } from '../../queries/CartLocalInventoryItemFragment.generated';
import { CartOrderFragmentDoc } from '../../queries/CartOrderFragment.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type SubmitCartMutationVariables = Types.Exact<{
  submitOrders: Array<Types.SubmitOrderInput> | Types.SubmitOrderInput;
  laborItemIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  localInventoryItemIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  submittedLabor?: Types.InputMaybe<Array<Types.SubmittedLabor> | Types.SubmittedLabor>;
  withLabor?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type SubmitCartMutation = {
  __typename?: 'Mutation';
  submitActiveCart?:
    | { __typename?: 'FailedSubmitActiveCartPayload' }
    | {
        __typename?: 'SubmitActiveCartErrorPayload';
        errorMessage: Types.Scalars['String']['input'];
        submitOrdersErrors?: Array<{
          __typename?: 'SubmitOrderErrorPayload';
          errorMessage: Types.Scalars['String']['input'];
        }> | null;
      }
    | {
        __typename?: 'SubmitActiveCartOrderErrorsPayload';
        failedOrders: Array<{ __typename?: 'CartOrder'; id: string }>;
        failedLocalInventoryItems: Array<{ __typename?: 'CartLocalInventoryItem'; id: string }>;
      }
    | {
        __typename?: 'SubmitActiveCartSuccessPayload';
        redirectUrl?: Types.Scalars['String']['input'] | null;
        orders: Array<{
          __typename?: 'CartOrder';
          id: string;
          purchaseOrderNumber?: Types.Scalars['String']['input'] | null;
          customNotes?: Types.Scalars['String']['input'] | null;
          builtOrder?: {
            __typename?: 'BuiltOrder';
            coreChargeTotal: Types.Scalars['Float']['input'];
            discountTotal: Types.Scalars['Float']['input'];
            fetChargeTotal: Types.Scalars['Float']['input'];
            priceTotal: Types.Scalars['Float']['input'];
            shippingTotal: Types.Scalars['Float']['input'];
            paymentWay: Types.OrderPaymentWay;
            tax: Types.Scalars['Float']['input'];
            boschReward?: { __typename?: 'OrderBoschReward'; points: number } | null;
            bundles: Array<{
              __typename?: 'CartBundle';
              description?: Types.Scalars['String']['input'] | null;
              discount?: Types.Scalars['Float']['input'] | null;
              name: Types.Scalars['String']['input'];
              quantity: number;
              items: Array<{
                __typename?: 'CartBundleItem';
                discount: Types.Scalars['Float']['input'];
                orderItemId: string;
                requiredQuantity: number;
              }>;
            }>;
            errors: Array<{
              __typename?: 'OrderError';
              errorType: Types.OrderErrorType;
              message: Types.Scalars['String']['input'];
            }>;
            availableFreightTerms: Array<{
              __typename?: 'FreightTerm';
              name: Types.Scalars['String']['input'];
              frightTermService?: {
                __typename?: 'FreightTermService';
                charge: Types.Scalars['Float']['input'];
                methodCode?: Types.Scalars['String']['input'] | null;
              } | null;
            }>;
            selectedFrightTermService?: {
              __typename?: 'FreightTermService';
              methodCode?: Types.Scalars['String']['input'] | null;
            } | null;
            availableAdditionalFields?: Array<{
              __typename?: 'AvailableAdditionalFields';
              default?: Types.Scalars['String']['input'] | null;
              label: Types.Scalars['String']['input'];
              name: Types.Scalars['String']['input'];
              required: Types.Scalars['Boolean']['input'];
              type: Types.Scalars['String']['input'];
              options?: Array<{
                __typename?: 'AvailableAdditionalFieldOption';
                text: Types.Scalars['String']['input'];
                value: Types.Scalars['String']['input'];
              }> | null;
            }> | null;
            customNotesValidation?: {
              __typename?: 'OrderFieldValidation';
              maxLength?: number | null;
              minLength?: number | null;
              regex?: Types.Scalars['String']['input'] | null;
              message?: Types.Scalars['String']['input'] | null;
            } | null;
            purchaseOrderNumberValidation?: {
              __typename?: 'OrderFieldValidation';
              maxLength?: number | null;
              minLength?: number | null;
              regex?: Types.Scalars['String']['input'] | null;
              message?: Types.Scalars['String']['input'] | null;
            } | null;
          } | null;
          additionalFields?: Array<{
            __typename?: 'OrderAdditionalField';
            name: Types.Scalars['String']['input'];
            value: Types.Scalars['String']['input'];
          }> | null;
          optionsPreferences?: {
            __typename?: 'SupplierOptionsPreferences';
            shippingMethodCode?: Types.Scalars['String']['input'] | null;
          } | null;
          account?: {
            __typename?: 'Account';
            id: string;
            supplier?: { __typename?: 'Supplier'; id: string; name: Types.Scalars['String']['input'] } | null;
          } | null;
          items?: Array<{
            __typename?: 'CartOrderItem';
            id: string;
            image?: Types.Scalars['String']['input'] | null;
            partName: Types.Scalars['String']['input'];
            partNumber: Types.Scalars['String']['input'];
            partNumberId: Types.Scalars['String']['input'];
            lineCardId?: number | null;
            origin: Types.CartOrderItemOrigin;
            quantity: number;
            tireSize?: Types.Scalars['String']['input'] | null;
            vin?: Types.Scalars['String']['input'] | null;
            builtItem?: {
              __typename?: 'BuiltItem';
              sponsorType: Types.OrderItemSponsorType;
              bundled: Types.Scalars['Boolean']['input'];
              discount: Types.Scalars['Float']['input'];
              errors: Array<{ __typename?: 'OrderItemError'; message: Types.Scalars['String']['input'] }>;
              availableMotorLaborApplications?: Array<{
                __typename?: 'CartLabor';
                duration: Types.Scalars['BigDecimal']['input'];
                id: string;
                name?: Types.Scalars['String']['input'] | null;
                notes?: Array<Types.Scalars['String']['input']> | null;
                parentId?: string | null;
                skillCode: Types.Scalars['String']['input'];
                taxonomyName: Types.Scalars['String']['input'];
                taxonomyAction?: Types.Scalars['String']['input'] | null;
                taxonomyId: string;
                baseLaborTimeDescription?: Types.Scalars['String']['input'] | null;
                descriptions?: Array<Types.Scalars['String']['input']> | null;
                position?: { __typename?: 'Position'; name: Types.Scalars['String']['input'] } | null;
                attributes?: Array<{
                  __typename?: 'Attribute';
                  name?: Types.Scalars['String']['input'] | null;
                  text?: Types.Scalars['String']['input'] | null;
                }> | null;
              }> | null;
              availableBundles: Array<{
                __typename?: 'AvailableBundle';
                description?: Types.Scalars['String']['input'] | null;
                expirationDate?: Types.Scalars['String']['input'] | null;
                id: Types.Scalars['String']['input'];
                name: Types.Scalars['String']['input'];
              }>;
              product?: {
                __typename?: 'OrderItemProduct';
                price: Types.Scalars['Float']['input'];
                customerPrice?: Types.Scalars['Float']['input'] | null;
                coreCharge?: Types.Scalars['Float']['input'] | null;
                fetCharge?: Types.Scalars['Float']['input'] | null;
                mapPrice?: Types.Scalars['Float']['input'] | null;
                listPrice?: Types.Scalars['Float']['input'] | null;
                backOrderable?: Types.Scalars['Boolean']['input'] | null;
                specialOrder?: Types.Scalars['Boolean']['input'] | null;
                partNumberDisplay: Types.Scalars['String']['input'];
                availabilityLines: Array<{
                  __typename?: 'OrderItemProductAvailabilityLine';
                  address?: Types.Scalars['String']['input'] | null;
                  group?: Types.Scalars['String']['input'] | null;
                  id?: number | null;
                  name: Types.Scalars['String']['input'];
                  quantity: number;
                  type: Types.OrderItemProductAvailabilityLineType;
                }>;
                attributes: Array<{
                  __typename?: 'OrderItemProductAttribute';
                  name?: Types.Scalars['String']['input'] | null;
                  value: Array<Types.Scalars['String']['input']>;
                }>;
                notes: Array<{ __typename?: 'OrderItemProductNotes'; notes: Array<Types.Scalars['String']['input']> }>;
                deliveryNotes: Array<{
                  __typename?: 'OrderItemProductDeliveryNotes';
                  label?: Types.Scalars['String']['input'] | null;
                  text?: Types.Scalars['String']['input'] | null;
                }>;
                warnings: Array<
                  | { __typename?: 'OrderItemProductAttachmentWarning'; attachment: Types.Scalars['String']['input'] }
                  | { __typename?: 'OrderItemProductImageWarning'; path: Types.Scalars['String']['input'] }
                  | { __typename?: 'OrderItemProductTextWarning'; text: Types.Scalars['String']['input'] }
                >;
              } | null;
              boschReward?: { __typename?: 'OrderBoschReward'; points: number } | null;
            } | null;
            brand?: {
              __typename?: 'Brand';
              logo?: Types.Scalars['String']['input'] | null;
              name: Types.Scalars['String']['input'];
            } | null;
            partType?: {
              __typename?: 'PartType';
              id: string;
              name: Types.Scalars['String']['input'];
              category?: { __typename?: 'PartCategory'; name: Types.Scalars['String']['input'] } | null;
            } | null;
            vehicle?: { __typename?: 'Vehicle'; id: string } | null;
            laborItems?: Array<{
              __typename?: 'CartLaborItem';
              cartLocalInventoryItemId?: string | null;
              id: string;
              hours: Types.Scalars['Float']['input'];
              defaultHours: Types.Scalars['Float']['input'];
              vin?: Types.Scalars['String']['input'] | null;
              orderItemId?: string | null;
              name: Types.Scalars['String']['input'];
              notes?: Types.Scalars['String']['input'] | null;
              skill: Types.Scalars['String']['input'];
              identifiers?:
                | { __typename?: 'Mitchell1Identifiers' }
                | { __typename?: 'MotorIdentifiers'; id: string; motorLaborSource?: Types.MotorLaborSource | null }
                | null;
              vehicle?: { __typename?: 'Vehicle'; id: string } | null;
              taxonomy?: {
                __typename?: 'LaborTaxonomy';
                action?: Types.Scalars['String']['input'] | null;
                id: string;
                name: Types.Scalars['String']['input'];
              } | null;
            }> | null;
          }> | null;
        }>;
        mitchell1Payload?: {
          __typename?: 'SubmitActiveCartMitchell1Payload';
          labors?: Array<{
            __typename?: 'Mitchell1SubmittedLabor';
            description: Types.Scalars['String']['input'];
            hours: Types.Scalars['Float']['input'];
            price: Types.Scalars['Float']['input'];
            type: Types.Scalars['String']['input'];
          }> | null;
          notes?: Array<{
            __typename?: 'Mitchell1SubmittedNote';
            note: Types.Scalars['String']['input'];
            type: Types.Scalars['String']['input'];
          }> | null;
          parts: Array<{
            __typename?: 'Mitchell1SubmittedPart';
            quantity: number;
            description: Types.Scalars['String']['input'];
            isTire: Types.Scalars['Boolean']['input'];
            shippingCost?: Types.Scalars['Float']['input'] | null;
            shippingDescription?: Types.Scalars['String']['input'] | null;
            size?: Types.Scalars['String']['input'] | null;
            supplierName: Types.Scalars['String']['input'];
            type: Types.Scalars['String']['input'];
            unitCore: Types.Scalars['Float']['input'];
            unitCost: Types.Scalars['Float']['input'];
            unitList: Types.Scalars['Float']['input'];
            manufacturerLineCode: Types.Scalars['String']['input'];
            manufacturerName: Types.Scalars['String']['input'];
            metadata: Types.Scalars['String']['input'];
            partCategory: Types.Scalars['String']['input'];
            partNumber: Types.Scalars['String']['input'];
            upcCode?: Types.Scalars['String']['input'] | null;
          }>;
        } | null;
        localInventoryItems: Array<{
          __typename?: 'CartLocalInventoryItem';
          customerPrice?: Types.Scalars['BigDecimal']['input'] | null;
          id: string;
          image?: Types.Scalars['String']['input'] | null;
          listPrice?: Types.Scalars['BigDecimal']['input'] | null;
          localInventoryUID: Types.Scalars['String']['input'];
          orderGroupId: string;
          partName: Types.Scalars['String']['input'];
          partNumber: Types.Scalars['String']['input'];
          partNumberId: Types.Scalars['String']['input'];
          price: Types.Scalars['BigDecimal']['input'];
          quantity: number;
          availableMotorLaborApplications?: Array<{
            __typename?: 'CartLabor';
            duration: Types.Scalars['BigDecimal']['input'];
            id: string;
            name?: Types.Scalars['String']['input'] | null;
            notes?: Array<Types.Scalars['String']['input']> | null;
            parentId?: string | null;
            skillCode: Types.Scalars['String']['input'];
            taxonomyName: Types.Scalars['String']['input'];
            taxonomyAction?: Types.Scalars['String']['input'] | null;
            taxonomyId: string;
            baseLaborTimeDescription?: Types.Scalars['String']['input'] | null;
            descriptions?: Array<Types.Scalars['String']['input']> | null;
            position?: { __typename?: 'Position'; name: Types.Scalars['String']['input'] } | null;
            attributes?: Array<{
              __typename?: 'Attribute';
              name?: Types.Scalars['String']['input'] | null;
              text?: Types.Scalars['String']['input'] | null;
            }> | null;
          }> | null;
          laborItems?: Array<{
            __typename?: 'CartLaborItem';
            cartLocalInventoryItemId?: string | null;
            id: string;
            hours: Types.Scalars['Float']['input'];
            defaultHours: Types.Scalars['Float']['input'];
            vin?: Types.Scalars['String']['input'] | null;
            orderItemId?: string | null;
            name: Types.Scalars['String']['input'];
            notes?: Types.Scalars['String']['input'] | null;
            skill: Types.Scalars['String']['input'];
            identifiers?:
              | { __typename?: 'Mitchell1Identifiers' }
              | { __typename?: 'MotorIdentifiers'; id: string }
              | null;
            vehicle?: { __typename?: 'Vehicle'; id: string } | null;
            taxonomy?: {
              __typename?: 'LaborTaxonomy';
              action?: Types.Scalars['String']['input'] | null;
              id: string;
              name: Types.Scalars['String']['input'];
            } | null;
          }> | null;
          partType?: { __typename?: 'PartType'; id: string } | null;
          product?: {
            __typename?: 'CartLocalInventoryProduct';
            hasFitment: Types.Scalars['Boolean']['input'];
            localInventoryUID: string;
            partNumber: Types.Scalars['String']['input'];
            partNumberId: Types.Scalars['String']['input'];
            price?: Types.Scalars['BigDecimal']['input'] | null;
            quantity: number;
            title: Types.Scalars['String']['input'];
            attributes: Array<{
              __typename?: 'CartLocalInventoryProductAttribute';
              name: Types.Scalars['String']['input'];
              values: Array<Types.Scalars['String']['input']>;
            }>;
            descriptions: Array<{
              __typename?: 'CartLocalInventoryProductDescription';
              code: Types.Scalars['String']['input'];
              label: Types.Scalars['String']['input'];
              values: Array<Types.Scalars['String']['input']>;
            }>;
            notes: Array<{
              __typename?: 'CartLocalInventoryProductNotes';
              group: Array<Types.Scalars['String']['input']>;
            }>;
            variations: Array<{
              __typename?: 'CartLocalInventoryProductVariation';
              variationID: Types.Scalars['String']['input'];
              attributes: Array<{
                __typename?: 'CartLocalInventoryProductAttribute';
                name: Types.Scalars['String']['input'];
                values: Array<Types.Scalars['String']['input']>;
              }>;
              notes: Array<{
                __typename?: 'CartLocalInventoryProductNotes';
                group: Array<Types.Scalars['String']['input']>;
              }>;
            }>;
            warnings: Array<
              | {
                  __typename?: 'CartLocalInventoryProductWarningAttachment';
                  attachment: Types.Scalars['String']['input'];
                }
              | { __typename?: 'CartLocalInventoryProductWarningImage'; path: Types.Scalars['String']['input'] }
              | { __typename?: 'CartLocalInventoryProductWarningText'; text: Types.Scalars['String']['input'] }
            >;
          } | null;
          validationError?: {
            __typename?: 'CartLocalInventoryItemValidationError';
            errorType: Types.CartLocalInventoryItemValidationErrorType;
            message?: Types.Scalars['String']['input'] | null;
          } | null;
          vehicle?: { __typename?: 'Vehicle'; id: string } | null;
        }>;
      }
    | null;
};

export const SubmitCartDocument = `
    mutation SubmitCart($submitOrders: [SubmitOrderInput!]!, $laborItemIds: [ID!], $localInventoryItemIds: [ID!], $submittedLabor: [SubmittedLabor!], $withLabor: Boolean = false) {
  submitActiveCart(
    input: {submitOrders: $submitOrders, laborItemIds: $laborItemIds, localInventoryItemIds: $localInventoryItemIds, submittedLabor: $submittedLabor}
  ) {
    ... on SubmitActiveCartErrorPayload {
      errorMessage
      submitOrdersErrors {
        errorMessage
      }
    }
    ... on SubmitActiveCartOrderErrorsPayload {
      failedOrders {
        id
      }
      failedLocalInventoryItems {
        id
      }
    }
    ... on SubmitActiveCartSuccessPayload {
      redirectUrl
      orders {
        ...CartOrder
      }
      mitchell1Payload {
        labors {
          description
          hours
          price
          type
        }
        notes {
          note
          type
        }
        parts {
          quantity
          description
          isTire
          shippingCost
          shippingDescription
          size
          supplierName
          type
          unitCore
          unitCost
          unitList
          manufacturerLineCode
          manufacturerName
          metadata
          partCategory
          partNumber
          upcCode
        }
      }
      localInventoryItems {
        ...CartLocalInventoryItem
      }
    }
  }
}
    ${CartOrderFragmentDoc}
${CartLocalInventoryItemFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    SubmitCart: build.mutation<SubmitCartMutation, SubmitCartMutationVariables>({
      query: (variables) => ({ document: SubmitCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSubmitCartMutation } = injectedRtkApi;
