import { Specification } from '../../../Specification';
import { AttributeTooltip } from '../AttributeTooltip';
import { TireQualityTranslation } from '../TireQualityTranslation';
import type { Attribute } from 'models';

type Props = {
  attribute: Attribute;
};

export const TireQualityAttribute = ({ attribute }: Props) => (
  <Specification
    label={attribute.label}
    value={attribute.getValue()}
    adornmentAfter={<AttributeTooltip tooltip={<TireQualityTranslation attribute={attribute} />} />}
  />
);
