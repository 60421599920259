import { Layout } from 'components/Layout';
import { AuthLayout } from 'components/Layout/AuthLayout';
import { ProfiledMainLayout } from 'components/Layout/MainLayout';
import { PagePermission, Routes } from 'constant';
import { myShopPages } from 'pages/MyShop';
import { QuoteLayout } from 'pages/Quote/QuoteLayout';
import { savedQuotesPage } from 'pages/SavedQuotes';
import { signUpPages } from 'pages/SignUp';
import { smsConnectionsPages } from 'pages/SmsConnections';
import { stockOrdersPages } from 'pages/StockOrders';
import { InvitationLayout } from 'widgets/authentication';
import { getApplicationRedirects } from './applicationRedirects';
import { ErrorElement } from './ErrorElement';
import { getModalPages } from './getModalPages';
import { ProtectedRoute } from './ProtectedRoute';
import { reportsPages } from './reportsPages';
import type { RouteObject } from 'react-router-dom';

const createQuotePage: RouteObject = {
  path: Routes.CREATE_QUOTE,
  lazy: () => import('pages/Quote/CreateQuotePage').then((module) => ({ Component: module.CreateQuotePage })),
};

const dashboardPage: RouteObject = {
  index: true,
  path: Routes.DASHBOARD,
  lazy: () => import('pages/Dashboard').then((module) => ({ Component: module.Dashboard })),
};

const googleLoginPage: RouteObject = {
  path: Routes.LOGIN_GOOGLE,
  lazy: () => import('pages/Login').then((module) => ({ Component: module.Login })),
};

const googleSignUpPage: RouteObject = {
  path: Routes.SIGN_UP_GOOGLE,
  lazy: () => import('pages/SignUp/GoogleSignUp').then((module) => ({ Component: module.GoogleSignUp })),
};

const loginPage: RouteObject = {
  path: Routes.LOGIN,
  lazy: () => import('pages/Login').then((module) => ({ Component: module.Login })),
};

const loginByInvitationPage: RouteObject = {
  path: Routes.LOGIN_BY_INVITATION,
  lazy: () => import('pages/LoginByInvitation').then((module) => ({ Component: module.LoginByInvitation })),
};

const mitchell1Page: RouteObject = {
  path: Routes.MITCHELL1_SETUP,
  lazy: () => import('pages/Mitchell1').then((module) => ({ Component: module.Mitchell1 })),
};

const orderPage: RouteObject = {
  path: Routes.ORDER,
  lazy: () => import('pages/OrderPage').then((module) => ({ Component: module.OrderPage })),
};

const productPage: RouteObject = {
  path: Routes.PRODUCT_PAGE,
  lazy: () => import('pages/ProductPage').then((module) => ({ Component: module.ProductPage })),
};

const cartPage: RouteObject = {
  path: Routes.CART,
  lazy: () => import('pages/Cart').then((module) => ({ Component: module.Cart })),
};

const cartTransferPage: RouteObject = {
  path: Routes.CART_TRANSFER,
  lazy: () => import('pages/Cart').then((module) => ({ Component: module.Cart })),
};

const recentOrdersPage: RouteObject = {
  path: Routes.RECENT_ORDERS,
  lazy: () => import('pages/RecentOrders').then((module) => ({ Component: module.RecentOrders })),
};

const resetPasswordFormPage: RouteObject = {
  path: Routes.RESET_PASSWORD_FORM,
  lazy: () => import('pages/ResetPassword').then((module) => ({ Component: module.ResetPassword })),
};

const resetPasswordRequestFormPage: RouteObject = {
  path: Routes.RESET_PASSWORD_REQUEST,
  lazy: () =>
    import('pages/ResetPasswordRequestForm').then((module) => ({ Component: module.ResetPasswordRequestForm })),
};

const searchResultsPage: RouteObject = {
  path: Routes.SEARCH_RESULTS,
  lazy: () => import('pages/SearchResults').then((module) => ({ Component: module.SearchResults })),
};

const signUpByInvitationPage: RouteObject = {
  path: Routes.SIGN_UP_INVITATION,
  lazy: () => import('pages/SignUpByInvitation').then((module) => ({ Component: module.SignUpByInvitation })),
};

const selectShop: RouteObject = {
  path: Routes.SHOP,
  lazy: () => import('pages/SelectShop').then((module) => ({ Component: module.SelectShop })),
};

const viewQuotePage: RouteObject = {
  path: Routes.QUOTE,
  lazy: () => import('pages/Quote/ViewQuotePage').then((module) => ({ Component: module.ViewQuotePage })),
};

const suppliersFramePage: RouteObject = {
  path: Routes.SUPPLIERS_FRAME,
  lazy: () => import('pages/SuppliersFrame').then((module) => ({ Component: module.SuppliersFrame })),
};

const retailPricingCalculatorFramePage: RouteObject = {
  path: Routes.RETAIL_PRICING_CALCULATOR_FRAME,
  lazy: () => import('pages/RetailPricingCalculator').then((module) => ({ Component: module.RetailPricingCalculator })),
};

export const routes: RouteObject[] = [
  {
    element: <ProfiledMainLayout />,
    errorElement: <ErrorElement />,
    children: [
      ...getApplicationRedirects(),

      suppliersFramePage,
      retailPricingCalculatorFramePage,

      smsConnectionsPages,
      {
        element: <AuthLayout />,
        children: [signUpPages, mitchell1Page],
      },
      {
        element: <QuoteLayout />,
        children: [viewQuotePage],
      },
      {
        element: <Layout />,
        children: [orderPage],
      },

      {
        element: <ProtectedRoute permission={PagePermission.UNAUTHORIZED} />,
        children: [
          {
            element: <AuthLayout />,
            children: [
              loginPage,
              {
                element: <InvitationLayout />,
                children: [loginByInvitationPage, signUpByInvitationPage],
              },
              googleLoginPage,
              resetPasswordRequestFormPage,
              resetPasswordFormPage,
              googleSignUpPage,
            ],
          },
        ],
      },

      {
        element: <ProtectedRoute permission={PagePermission.AUTHORIZED} />,
        children: [
          {
            element: <Layout />,
            children: [
              dashboardPage,
              reportsPages,
              myShopPages,
              cartPage,
              cartTransferPage,
              searchResultsPage,
              productPage,
              recentOrdersPage,
              stockOrdersPages,
              savedQuotesPage,
            ],
          },
          selectShop,
          {
            element: <QuoteLayout />,
            children: [createQuotePage],
          },
        ],
      },

      ...getModalPages(),
    ],
  },
];
