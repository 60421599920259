import { useCallback, useMemo } from 'react';
import { api as generatedApi } from './GetLaborRates.generated';
import type { GetLaborRatesQuery } from './GetLaborRates.generated';
import type { LaborRate } from '../../model/laborRate';

const emptyLaborRates: LaborRate[] = [];

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['LaborRate'],
  endpoints: {
    GetLaborRates: {
      providesTags: (response: GetLaborRatesQuery | undefined) => {
        const shop = response?.currentUser?.activeMember?.shop;
        if (!shop) {
          return [];
        }

        const laborRateTags =
          shop?.laborRates?.map((laborRate) => ({ type: 'LaborRate' as const, id: laborRate.id })) ?? [];

        return ['LaborRate', ...laborRateTags];
      },
    },
  },
});

const { useGetLaborRatesQuery } = api;

type Props = {
  skip?: boolean;
};

export const useGetLaborRates = ({ skip }: Props = {}) => {
  const { data, ...props } = useGetLaborRatesQuery(undefined, {
    skip,
  });

  const laborRates = useMemo(
    () => data?.currentUser?.activeMember?.shop?.laborRates ?? emptyLaborRates,
    [data?.currentUser?.activeMember?.shop?.laborRates]
  );

  const defaultLaborRate = useMemo(() => laborRates.find((laborRate) => laborRate.isDefault), [laborRates]);

  const getLaborRateById = useCallback(
    (id: string) => laborRates.find((laborRate) => laborRate.id === id),
    [laborRates]
  );

  return {
    laborRates,
    defaultLaborRate,
    getLaborRateById,
    ...props,
  };
};
