import { useCallback } from 'react';
import { api as generatedApi } from './CreateRecentSearch.generated';
import type { CreateRecentSearchMutation } from './CreateRecentSearch.generated';
import type { Vehicle } from 'entities/vehicle';
import type { SearchEntryPointInterface } from 'features/searchForm';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreateRecentSearch: {
      invalidatesTags: (response: CreateRecentSearchMutation | undefined) => {
        const hasVehicleId = Boolean(response?.createRecentSearch?.recentSearch?.params.vehicle?.id);

        if (hasVehicleId) {
          return ['RecentSearch', 'RecentVehicle'];
        }

        return ['RecentSearch'];
      },
    },
  },
});

const { useCreateRecentSearchMutation } = api;

export const useCreateRecentSearch = (searchEntry: SearchEntryPointInterface | null, vehicle: Vehicle | null) => {
  const [createRecentSearchMutation, status] = useCreateRecentSearchMutation();

  const createRecentSearch = useCallback(() => {
    if (!searchEntry?.toRecentSearch) {
      return;
    }

    createRecentSearchMutation({ input: searchEntry.toRecentSearch(vehicle) });
  }, [searchEntry, createRecentSearchMutation, vehicle]);

  return [createRecentSearch, status] as const;
};
