/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../shared/api/graphql/graphql';

export type GetPunchoutInfoQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetPunchoutInfoQuery = {
  __typename?: 'Query';
  punchout?: {
    __typename?: 'Punchout';
    punchoutEnabled: Types.Scalars['Boolean']['input'];
    session?: {
      __typename?: 'PunchoutSession';
      callbackOrderUrl?: Types.Scalars['String']['input'] | null;
      callbackUrl?: Types.Scalars['String']['input'] | null;
      purchaseOrderNumber?: Types.Scalars['String']['input'] | null;
      sessionHash: Types.Scalars['String']['input'];
      hasReservedPoNumbers: Types.Scalars['Boolean']['input'];
      partner?: {
        __typename?: 'Partner';
        catalogSdk: Types.Scalars['Boolean']['input'];
        generalButtons: Types.Scalars['Boolean']['input'];
        shutOffPoNumbers: Types.Scalars['Boolean']['input'];
        localInventory: Types.Scalars['Boolean']['input'];
        managementSystem?: { __typename?: 'ManagementSystem'; name: Types.Scalars['String']['input'] } | null;
      } | null;
    } | null;
  } | null;
};

export const GetPunchoutInfoDocument = `
    query GetPunchoutInfo {
  punchout {
    punchoutEnabled
    session {
      callbackOrderUrl
      callbackUrl
      purchaseOrderNumber
      sessionHash
      hasReservedPoNumbers
      partner {
        catalogSdk
        generalButtons
        shutOffPoNumbers
        managementSystem {
          name
        }
        localInventory
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetPunchoutInfo: build.query<GetPunchoutInfoQuery, GetPunchoutInfoQueryVariables | void>({
      query: (variables) => ({ document: GetPunchoutInfoDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPunchoutInfoQuery, useLazyGetPunchoutInfoQuery } = injectedRtkApi;
