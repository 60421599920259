import { shopApi } from 'shared/api';

const api = shopApi.injectEndpoints({
  endpoints: (build) => ({
    LogOut: build.mutation<void, void>({
      query: (body) => ({ url: '/logout', method: 'POST', body }),
    }),
  }),
});

export const logOutMutation = api.endpoints.LogOut;

export const { useLogOutMutation } = api;
