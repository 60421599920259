import { useLaunchDarkly } from 'integrations/launchDarkly';
import { usePunchoutInfo } from 'store/queries/punchoutInfo/usePunchoutInfo';
import { useSearchParams } from './useSearchParams';

export const useHasLocalInventory = () => {
  const { isTiresSearch } = useSearchParams();
  const { localInventory } = usePunchoutInfo();
  const {
    flags: { localInventory: localInventoryFeatureFlag },
  } = useLaunchDarkly();

  return { hasLocalInventory: Boolean(!isTiresSearch && localInventory && localInventoryFeatureFlag) };
};
