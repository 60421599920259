import { css } from '@emotion/react';
import { Box, Icon, Typography } from '@partstech/ui';
import { Region } from '../Region';
import { Vin } from '../Vin';
import type { Vehicle } from '../../model/Vehicle';
import type { BoxProps, IconProps } from '@partstech/ui';

const styles = {
  details: css`
    span {
      position: relative;
      top: 1px;
    }
  `,
};

type Props = BoxProps & {
  vehicle: Vehicle | null;
  size?: 'dense' | 'default';
  iconSize?: IconProps['size'];
  multiline?: boolean;
  hasVin?: boolean;
  hasVinDetails?: boolean;
};

export const VehicleLabel = ({
  vehicle,
  size = 'default',
  hasVin = true,
  hasVinDetails,
  multiline,
  iconSize,
  ...boxProps
}: Props) => (
  <Box display="flex" gap={2} alignItems="center" data-testid="vehicleLabel" {...boxProps}>
    <Icon size={iconSize} name="directions_car" color="subtleText" />

    {vehicle === null && (
      <Typography textTransform="uppercase" variant="overline" color="subtleText" component="p">
        No vehicle
      </Typography>
    )}

    {vehicle && (
      <Box
        position="relative"
        display="flex"
        alignItems={multiline ? 'flex-start' : 'center'}
        flexDirection={multiline ? 'column' : 'row'}
        flexWrap="wrap"
        gap={multiline ? 0 : 1}
        width="100%"
      >
        <Typography textTransform="uppercase" variant={size === 'dense' ? 'overline' : 'subtitle2'}>
          {vehicle.shortName}
        </Typography>

        <Typography variant={size === 'dense' ? 'caption' : 'bodyText2'} textTransform="uppercase" css={styles.details}>
          {vehicle.subModel.name}
          <Region regions={vehicle.regions} /> {vehicle.engine.name}
        </Typography>

        {vehicle.vin && hasVin && (
          <Box textAlign={multiline ? 'left' : 'right'} flexGrow={1}>
            <Vin vehicle={vehicle} disabled={!hasVinDetails} variant={size === 'dense' ? 'caption' : 'bodyText2'} />
          </Box>
        )}
      </Box>
    )}
  </Box>
);
