import { isEmpty, isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { createVehicleFromQuery } from '../../lib/createVehicleFromQuery';
import { api as generatedApi } from './GetVehiclesByPlateVin.generated';
import type { Vehicle } from '../../model/Vehicle';
import type { LicensePlate } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetVehiclesByPlateVin: {
      providesTags: ['VehicleByPlateVin'],
    },
  },
});

const { useGetVehiclesByPlateVinQuery } = api;

const emptyVehicles: Vehicle[] = [];

type Args = Partial<{ vin: string; plate: LicensePlate }>;

export const useGetVehiclesByPlateVin = ({ vin, plate }: Args, skip: boolean = false) => {
  const isEmptyArgs = isEmpty(plate) && vin?.length === 0;

  const { currentData, ...props } = useGetVehiclesByPlateVinQuery({ vin, plate }, { skip: skip || isEmptyArgs });

  const vehicles = useMemo(
    () =>
      currentData?.vehicles
        ? currentData?.vehicles.map((vehicle) => createVehicleFromQuery({ ...vehicle, ...plate })).filter(isNotNull)
        : emptyVehicles,
    [currentData?.vehicles, plate]
  );

  return {
    vehicles,
    ...props,
  };
};
