import { Box, Button, ModalButtons, ModalView, Typography } from '@partstech/ui';
import { FormTextAreaField, HookForm } from '@partstech/ui/forms';
import { useMemo, useState } from 'react';
import { required } from 'shared/lib/form';
import { capitalize } from 'shared/lib/string';
import { isOtherReason } from '../../constants/isOtherReason';
import { useDowngradeForm } from '../../hooks/useDowngradeForm';
import { useSubscription } from '../../hooks/useSubscription';
import { getShortPlanName } from '../../utils/getShortPlanName';
import { LoseFeaturesCard } from '../LoseFeaturesCard';
import { ReasonsCard } from './ReasonsCard';
import { SupportCard } from './SupportCard';
import { TermsCheckBoxField } from './TermsCheckBoxField';
import type { ProductAlias } from '../../constants/products';
import type { ModalProps } from '@partstech/ui';

export type DowngradeModalProps = ModalProps & {
  isPlan?: boolean;
  product: {
    alias: ProductAlias;
    id: string | null;
    name: string;
  };
};

export const DowngradeModal = ({ isPlan = false, product, onClose }: DowngradeModalProps) => {
  const { alias, id, name } = product;

  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);

  const { subscription } = useSubscription();
  const subscriptionPlan = subscription?.plan;

  const { form, isSubmitting, isSubmitDisabled, submitForm } = useDowngradeForm({
    currentPlanId: subscriptionPlan?.id,
    id,
    isPlan,
    selectedReasons,
    onSuccess: onClose,
  });

  const actionName = useMemo(() => {
    if (isPlan) {
      return `downgrade to ${getShortPlanName(name)}`;
    }

    return `remove ${name}`;
  }, [isPlan, name]);

  const reasons = useMemo(() => {
    if (isPlan) {
      return subscriptionPlan?.reasons;
    }

    return subscription?.addOns?.find((addOn) => addOn.name === name)?.reasons;
  }, [subscriptionPlan, isPlan, name, subscription?.addOns]);

  const isOtherReasonSelected = useMemo(() => selectedReasons.some(isOtherReason), [selectedReasons]);

  if (!subscription || !reasons) {
    return null;
  }

  return (
    <ModalView title={capitalize(actionName)} onClose={onClose}>
      <Box textAlign="center">
        <Typography variant="h3">Are you sure you want to {actionName}?</Typography>
      </Box>

      <Box mt={5} display="flex" flexDirection={{ sm: 'column', md: 'row' }} justifyContent="space-between" gap={5}>
        <LoseFeaturesCard planAliasAfterDowngrading={alias} title="Here’s what you will lose" width="100%" />

        <SupportCard display={isPlan ? undefined : 'flex'} alignItems="center" />
      </Box>

      <ReasonsCard
        my={5}
        p={5}
        isOtherReasonSelected={isOtherReasonSelected}
        isPlan={isPlan}
        productName={name}
        reasons={reasons}
        selectedReasons={selectedReasons}
        setSelectedReasons={setSelectedReasons}
      />

      <HookForm form={form} onSubmit={submitForm}>
        <FormTextAreaField
          height={16}
          name="feedback"
          label="Additional feedback"
          placeholder="We appreciate if you could share some additional feedback with us."
          rules={{ required: isOtherReasonSelected ? required : undefined }}
        />

        <Box mt={5}>
          <TermsCheckBoxField expiresDate={subscription?.currentPeriodEnd} isPlan={isPlan} productName={name} />
        </Box>

        <ModalButtons>
          <Button
            type="submit"
            color="negative"
            disabled={isSubmitDisabled}
            isLoading={isSubmitting}
            data-testid="submitButton"
          >
            {capitalize(actionName)}
          </Button>

          <Button variant="text" onClick={onClose}>
            Keep me {isPlan ? `on ${getShortPlanName(subscriptionPlan?.name ?? '')}` : 'subscribed'}
          </Button>
        </ModalButtons>
      </HookForm>
    </ModalView>
  );
};
