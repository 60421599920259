import { Routes } from 'constant';
import { ReportsRouteLayout } from 'pages/Reports';
import type { RouteObject } from 'react-router-dom';

const dashboardPage = {
  index: true,
  lazy: () => import('pages/Reports').then((module) => ({ Component: module.ReportsDashboard })),
};

const spendReportsPage = {
  path: 'spending',
  lazy: () => import('pages/Reports').then((module) => ({ Component: module.SpendReportsPage })),
};

const tireReportsPage = {
  path: 'tires',
  lazy: () => import('pages/Reports').then((module) => ({ Component: module.TireReportsPage })),
};

const tireSizePage = {
  path: 'tires/tire-size-page/:tireSize',
  lazy: () => import('pages/Reports').then((module) => ({ Component: module.TireSizePage })),
};

const partReportsPage = {
  path: 'parts',
  lazy: () => import('pages/Reports').then((module) => ({ Component: module.PartReportsPage })),
};

const partTypePage = {
  path: 'parts/part-type-page/:partType',
  lazy: () => import('pages/Reports').then((module) => ({ Component: module.PartTypePage })),
};

export const reportsPages: RouteObject = {
  path: Routes.REPORTS,
  element: <ReportsRouteLayout />,
  children: [dashboardPage, spendReportsPage, tireReportsPage, tireSizePage, partReportsPage, partTypePage],
};
