import { gExportApi } from 'shared/api';

type Params = {
  from: string;
  to?: string | null;
  timeZone?: string | null;
  storeIds?: string | null;
  poNumber?: string | null;
  sortBy?: 'datePaid' | 'grandTotal' | null;
  order?: 'asc' | 'desc' | null;
};

const api = gExportApi.injectEndpoints({
  endpoints: (build) => ({
    GetSpendReportExport: build.query<string, Params>({
      query: (params) => ({ method: 'GET', url: '/spend-report/orders', params }),
      serializeQueryArgs: (params) => params,
      providesTags: () => ['ExportedReport'],
    }),
  }),
});

export const { useLazyGetSpendReportExportQuery } = api;
