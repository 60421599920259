import { datadogRum } from '@datadog/browser-rum';

type Props = {
  env: 'production' | 'beta' | 'development';
  version: string;
};

export const init = ({ env, version }: Props) => {
  if (env === 'production') {
    return;
  }

  datadogRum.init({
    applicationId: '812e7d4e-705b-4fe4-a374-26475241fbf1',
    clientToken: 'pubc690cf1d0d29722039e0acb78bbdfcca',
    site: 'us5.datadoghq.com',
    service: 'shop-web',
    env,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    defaultPrivacyLevel: 'mask-user-input',
  });
};
