import { Box, useMedia } from '@partstech/ui';
import { useCallback } from 'react';
import { SupplierAccountsRequiredView } from 'components/SupplierAccountsRequiredView';
import { useGetCustomJobs } from 'entities/customJob';
import { usePermissions } from 'entities/permission';
import { type Vehicle } from 'entities/vehicle';
import { RecentSearchesList } from 'features/recentSearches';
import { CategoryNavigator } from 'features/taxonomy';
import { useSupplierAccounts } from 'hooks/supplierAccounts';
import { UniversalPartType } from 'models';
import { SearchTypes } from 'types/search';
import { useDefaultOptions } from '../../hooks/useDefaultOptions';
import { SearchTabs } from '../../types';
import { OptionListGroups } from '../OptionListGroups';
import { Categories } from './Categories';
import { Diagrams } from './Diagrams';
import { FluidsAndChemicals } from './FluidsAndChemicals';
import { Jobs } from './Jobs';
import { PartsSearchTabs } from './PartsSearchTabs';
import { ServiceGuides } from './ServiceGuides';
import { Tires } from './Tires';
import type { RecentSearchInterface } from 'features/recentSearches';
import type { SearchEntryPointInterface } from 'features/searchForm';

export type SearchEntryPointsProps = {
  attributeBasedSearch?: boolean;
  isKeyboardDisabled?: boolean;
  onChange?: (value: SearchEntryPointInterface) => void;
  onRecentSearchSelect?: (value: RecentSearchInterface) => void;
  onSelect?: (value: SearchEntryPointInterface) => void;
  onSelectVehicleClick?: () => void;
  onAdditionalButtonClick?: () => void;
  onCreateJobPageOpen?: () => void;
  onTabChange: (tab: SearchTabs) => void;
  tab: SearchTabs;
  vehicle?: Vehicle | null;
  searchEntry?: SearchEntryPointInterface | null;
  withJobsPriority: boolean;
  withEnhancedCategories?: boolean;
  withSearchByPartTypes?: boolean;
};

export const SearchEntryPoints = ({
  attributeBasedSearch = false,
  onChange,
  onRecentSearchSelect,
  onSelect,
  onSelectVehicleClick,
  onAdditionalButtonClick,
  onCreateJobPageOpen,
  onTabChange,
  isKeyboardDisabled = false,
  tab,
  vehicle,
  searchEntry,
  withJobsPriority,
  withEnhancedCategories = false,
  withSearchByPartTypes = false,
}: SearchEntryPointsProps) => {
  const { isMobile } = useMedia();

  const {
    shop: { jobsAllowed },
  } = usePermissions();

  const { categories, diagrams, jobs, partTypes, tiresOptions, fluidsAndChemicals } = useDefaultOptions(vehicle);
  const { hasAccounts: hasPartsAccounts } = useSupplierAccounts({ type: 'parts' });
  const { hasAccounts: hasTiresAccounts } = useSupplierAccounts({ type: 'tires' });
  const { customJobs } = useGetCustomJobs({ skip: !jobsAllowed });

  const hasVehicle = Boolean(vehicle?.id);
  const totalCustomJobs = customJobs.length;

  const handleChange = useCallback(
    (option: SearchEntryPointInterface) => {
      onChange && onChange(option);
    },
    [onChange]
  );

  const handleSelect = useCallback(
    (option: SearchEntryPointInterface) => {
      onSelect && onSelect(option);
    },
    [onSelect]
  );

  const popularOptions = withJobsPriority ? [...jobs, ...partTypes] : partTypes;

  return (
    <Box data-testid="searchEntryPoints" display="flex" flexDirection="column" height="100%">
      <Box mt={{ sm: -1, md: -4 }} mx={0}>
        <PartsSearchTabs tab={tab} onChange={onTabChange} withJobsPriority={withJobsPriority} />
      </Box>

      {tab === SearchTabs.POPULAR && (
        <SupplierAccountsRequiredView isAllowed={hasPartsAccounts} type={SearchTypes.PARTS}>
          <Box width="100%" height="100%">
            <OptionListGroups
              options={popularOptions}
              onChange={handleChange}
              onSelect={handleSelect}
              className="ps-popular"
              shouldSplitPartTypes
              isKeyboardDisabled={isKeyboardDisabled}
              withJobsPriority={withJobsPriority}
            />
          </Box>
        </SupplierAccountsRequiredView>
      )}

      {tab === SearchTabs.TIRES && (
        <SupplierAccountsRequiredView isAllowed={hasTiresAccounts} type={SearchTypes.TIRES}>
          <Tires
            hasVehicle={hasVehicle}
            onSelectVehicleClick={onSelectVehicleClick}
            options={tiresOptions}
            onChange={handleChange}
          />
        </SupplierAccountsRequiredView>
      )}

      {tab === SearchTabs.FLUIDS_CHEMICALS && (
        <SupplierAccountsRequiredView isAllowed={hasPartsAccounts} type={SearchTypes.PARTS}>
          <FluidsAndChemicals
            attributeBasedSearch={attributeBasedSearch}
            onSelectVehicleClick={onSelectVehicleClick}
            options={fluidsAndChemicals}
            defaultPartType={searchEntry instanceof UniversalPartType ? searchEntry : undefined}
            onChange={handleChange}
            vehicle={vehicle ?? null}
          />
        </SupplierAccountsRequiredView>
      )}

      {tab === SearchTabs.DIAGRAMS && (
        <SupplierAccountsRequiredView isAllowed={hasPartsAccounts} type={SearchTypes.PARTS}>
          <Diagrams
            hasVehicle={hasVehicle}
            options={diagrams}
            onChange={handleChange}
            onSelect={handleSelect}
            onSelectVehicleClick={onSelectVehicleClick}
            isKeyboardDisabled={isKeyboardDisabled}
          />
        </SupplierAccountsRequiredView>
      )}

      {tab === SearchTabs.JOBS && (
        <SupplierAccountsRequiredView isAllowed={hasPartsAccounts} type={SearchTypes.PARTS}>
          <Jobs
            hasVehicle={hasVehicle}
            options={jobs}
            onChange={handleChange}
            onSelect={handleSelect}
            onSelectVehicleClick={onSelectVehicleClick}
            onAdditionalButtonClick={onAdditionalButtonClick}
            onCreateJobPageOpen={onCreateJobPageOpen}
            isKeyboardDisabled={isKeyboardDisabled}
            totalCustomJobs={totalCustomJobs}
          />
        </SupplierAccountsRequiredView>
      )}

      {tab === SearchTabs.CATEGORIES && (
        <SupplierAccountsRequiredView isAllowed={hasPartsAccounts} type={SearchTypes.PARTS}>
          {withEnhancedCategories ? (
            <CategoryNavigator
              categories={categories}
              onChange={handleChange}
              variant={isMobile ? 'compact' : 'extended'}
              withSearchByPartTypes={withSearchByPartTypes}
            />
          ) : (
            <Categories
              categories={categories}
              onChange={handleChange}
              onSelect={handleSelect}
              isKeyboardDisabled={isKeyboardDisabled}
            />
          )}
        </SupplierAccountsRequiredView>
      )}

      {tab === SearchTabs.SERVICE_GUIDES && (
        <SupplierAccountsRequiredView isAllowed={hasPartsAccounts} type={SearchTypes.PARTS}>
          <ServiceGuides
            vehicleId={vehicle?.id}
            vehicleYear={vehicle?.year}
            vin={vehicle?.vin}
            onSelectVehicleClick={onSelectVehicleClick}
          />
        </SupplierAccountsRequiredView>
      )}

      {tab === SearchTabs.RECENT_SEARCHES && (
        <SupplierAccountsRequiredView isAllowed={hasPartsAccounts} type={SearchTypes.PARTS}>
          <RecentSearchesList onSelect={onRecentSearchSelect} />
        </SupplierAccountsRequiredView>
      )}
    </Box>
  );
};
