import { css } from '@emotion/react';
import { Box, Button, Divider, Popover, Scrollbar } from '@partstech/ui';
import { useCallback } from 'react';
import { ErrorStatus } from '../../../ErrorStatus';
import { PartInfo } from '../PartInfo';
import { QuantityInput } from '../QuantityInput';
import type { Theme } from '@partstech/ui';
import type { CartOrderItem as CartOrderItemModel } from 'models';
import type { MouseEvent } from 'react';

const styles = {
  partInfo: (theme: Theme) => css`
    max-height: ${theme.sizing(70)};
    width: ${theme.sizing(80)};
    padding: ${theme.sizing(4)};
  `,
  targetElement: css`
    pointer-events: none;
  `,
};

type Props = {
  orderItem: CartOrderItemModel;
  showRetailPrice: boolean;
  disabled: boolean;
  onChangeQuantity: (quantity: number) => void;
  onRemove: () => void;
};

export const PartPopover = ({ orderItem, showRetailPrice, disabled, onChangeQuantity, onRemove }: Props) => {
  const handleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  return (
    <Popover
      initiallyOpened
      targetElement={<Box width={24} height={22} position="absolute" css={styles.targetElement} />}
      preferPlacement="bottom-start"
      padding={0}
    >
      <Box onClick={handleClick}>
        <Box p={4} display="flex" justifyContent="space-between" alignItems="center" maxWidth={80} gap={2}>
          <div>
            <QuantityInput quantity={orderItem.quantity} onChangeQuantity={onChangeQuantity} disabled={disabled} />

            {orderItem.error && <ErrorStatus message={orderItem.error} />}
          </div>

          <Button variant="secondary" onClick={onRemove} disabled={disabled} data-testid="delete">
            Delete
          </Button>
        </Box>

        <Divider />

        <Scrollbar css={styles.partInfo}>
          <PartInfo orderItem={orderItem} showRetailPrice={showRetailPrice} />
        </Scrollbar>
      </Box>
    </Popover>
  );
};
