import { css } from '@emotion/react';
import { TextCut, Typography } from '@partstech/ui';
import { useRef } from 'react';
import { useScrollIntoView } from 'shared/lib/react-dom';
import { Highlight } from 'shared/ui';
import type { Theme } from '@partstech/ui';
import type { FunctionComponent, ReactNode } from 'react';

const styles = {
  partsListItem: (isSelected: boolean) => (theme: Theme) => css`
    width: 100%;
    min-height: ${theme.sizing(8)};
    padding: ${theme.sizing(1, 2)};
    background: ${isSelected ? theme.color.mono2 : 'transparent'};
    border-radius: ${theme.sizing(1)};
    cursor: pointer;
    text-align: left;

    &:hover {
      background: ${theme.color.mono2};
    }
  `,
  partsListItemSuggestion: (theme: Theme) => css`
    margin-right: ${theme.sizing(1)};
  `,
};

type Props = {
  children?: ReactNode;
  className?: string;
  highlightValue?: string;
  isSelected: boolean;
  linesCount?: number;
  onSelect: () => void;
  text: string;
};

export const DiagramListItem: FunctionComponent<Props> = ({
  children,
  className,
  highlightValue,
  isSelected,
  linesCount = 1,
  onSelect,
  text,
  ...props
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useScrollIntoView(buttonRef, { block: 'center' }, { skip: !isSelected });

  return (
    <button css={styles.partsListItem(isSelected)} onClick={onSelect} ref={buttonRef} className={className} {...props}>
      <TextCut shouldShowTooltip={false} linesCount={linesCount}>
        <>
          <Typography variant="bodyText2" css={styles.partsListItemSuggestion} component="span">
            <Highlight value={highlightValue ?? ''}>{text}</Highlight>
          </Typography>
          {children}
        </>
      </TextCut>
    </button>
  );
};
