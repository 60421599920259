import { Box, Typography } from '@partstech/ui';

export const AccountNotFound = () => (
  <Box display="flex" flexDirection="column" gap={2} textAlign="left">
    <Box textAlign="center">
      <Typography variant="subtitle1" color="negative">
        No PartsTech account found
      </Typography>
    </Box>

    <Typography variant="bodyText2" color="negative">
      The email address you entered is not associated with an exiting PartsTech account.
    </Typography>

    <Typography variant="bodyText2" color="negative">
      If you are new for PartsTech, create an account to get started or try logging in again with a different account.
    </Typography>
  </Box>
);
