import { css } from '@emotion/react';
import {
  BottomSheet,
  Box,
  Button,
  Dropdown,
  Icon,
  Menu,
  MenuAction,
  MenuItem,
  TextField,
  Typography,
  useMedia,
} from '@partstech/ui';
import { useCreateModal, useOpen } from '@partstech/ui/hooks';
import { useCallback, useEffect } from 'react';
import { currency } from 'shared/lib/string';
import { EditLaborRateModal } from '../EditLaborRateModal';
import type { LaborRate } from '../../model/laborRate';
import type { TouchEvent } from 'react';

const styles = {
  dropdown: css`
    cursor: pointer;
    input {
      cursor: pointer;
    }
  `,
};

type Props = {
  laborRates: LaborRate[];
  selectedLaborRate?: LaborRate;
  onSelectLaborRate: (laborRateId: string) => void;
};

export const LaborRateDropdown = ({ laborRates, selectedLaborRate, onSelectLaborRate }: Props) => {
  const { isMobile } = useMedia();
  const { open: openRateSelect, close: closeRateSelect, isOpen: isRateSelectOpen } = useOpen(false);

  const handleTouchStart = useCallback((e: TouchEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  const handleSelect = useCallback(
    (newLaborRateId: string) => () => {
      onSelectLaborRate(newLaborRateId);
      closeRateSelect();
    },
    [onSelectLaborRate, closeRateSelect]
  );

  const { open } = useCreateModal(
    EditLaborRateModal,
    { showHelperText: true, onAdd: onSelectLaborRate, canBeBottomSheet: true },
    { customId: 'editLaborRate' }
  );

  const handleClick = useCallback(() => open(), [open]);

  useEffect(() => {
    if (!selectedLaborRate && laborRates[0]) {
      const defaultLaborRate = laborRates.find((laborRate) => laborRate.isDefault) || laborRates[0];
      onSelectLaborRate(defaultLaborRate.id);
    }
  }, [laborRates, onSelectLaborRate, selectedLaborRate]);

  if (laborRates.length === 0) {
    return (
      <Button data-testid="addLaborRateButton" leadingIcon="add" variant="secondary" onClick={handleClick} fullWidth>
        Add labor rate
      </Button>
    );
  }

  if (isMobile) {
    return (
      <>
        <TextField
          onClick={openRateSelect}
          suffix={<Icon name="expand_more" onClick={openRateSelect} />}
          label="Labor rate"
          readOnly
          value={currency(selectedLaborRate?.rate || 0)}
          data-testid="laborRateDropdown"
        />

        {isRateSelectOpen && (
          <BottomSheet title="Labor rate" onClose={closeRateSelect} isClosable hasOverlay px={0}>
            <Box maxHeight={`${window.innerHeight - 114}px`} onTouchStart={handleTouchStart}>
              {laborRates.map((laborRate) => (
                <MenuItem
                  key={laborRate.id}
                  onClick={handleSelect(laborRate.id)}
                  prefix={
                    <Box width={6} alignItems="center">
                      {selectedLaborRate?.id === laborRate.id ? <Icon name="check" color="subtleText" /> : null}
                    </Box>
                  }
                >
                  <Typography>{currency(laborRate.rate)}</Typography>
                  <Typography variant="bodyText2" color="subtleText">{`${laborRate.name}${
                    laborRate.isDefault ? ' (Default)' : ''
                  }`}</Typography>
                </MenuItem>
              ))}

              <Button onClick={handleClick} leadingIcon="add" variant="text">
                Add labor rate
              </Button>
            </Box>
          </BottomSheet>
        )}
      </>
    );
  }

  return (
    <Dropdown
      renderButton={({ isOpened, toggleHandler }) => (
        <div css={styles.dropdown} onClick={toggleHandler} role="presentation">
          <TextField
            suffix={<Icon name={isOpened ? 'expand_less' : 'expand_more'} />}
            label="Labor rate"
            readOnly
            value={currency(selectedLaborRate?.rate || 0)}
            data-testid="laborRateDropdown"
          />
        </div>
      )}
      preferPlacement="bottom-end"
    >
      {({ closeHandler }) => (
        <Box width={72}>
          <Menu
            onSelect={closeHandler}
            footer={
              <MenuAction trailingIcon="add" onClick={handleClick}>
                Add labor rate
              </MenuAction>
            }
            fullWidth
          >
            {laborRates.map((laborRate) => (
              <MenuItem key={laborRate.id} onClick={handleSelect(laborRate.id)}>
                <Typography>{currency(laborRate.rate)}</Typography>
                <Typography variant="bodyText2" color="subtleText">{`${laborRate.name}${
                  laborRate.isDefault ? ' (Default)' : ''
                }`}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </Dropdown>
  );
};
