import { BottomSheet } from '@partstech/ui';
import { SupplierInformationContent } from 'components/SupplierSelector/SupplierTabs/SupplierInformationContent';
import type { SupplierAccount } from 'entities/supplierAccount';

type Props = {
  onClose: () => void;
  supplierAccount: SupplierAccount;
};

export const SupplierInformationFlyoutMobile = ({ onClose, supplierAccount }: Props) => (
  <BottomSheet onClose={onClose} isClosable>
    <SupplierInformationContent supplierAccount={supplierAccount} onClose={onClose} />
  </BottomSheet>
);
