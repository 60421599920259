export type IdentifyTraits = Required<Pick<Traits, 'email' | 'firstName' | 'lastName' | 'company'>> & {
  userId: string;
};

export const identify = ({ userId, ...traits }: IdentifyTraits): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    mbsy.on('mbsyIdentifySuccess', () => resolve());

    mbsy.on('mbsyIdentifyError', () => reject(new Error('Failed to identify user')));

    mbsy.identify(userId, traits);
  });
