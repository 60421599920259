import { AvailabilityLine, FeaturedAvailabilityLine } from 'models';
import type { AvailabilityLineData, Quote } from 'models';

export const createAvailability = (line: AvailabilityLineData, variant?: Quote['variant']) => {
  switch (variant) {
    case 'featured':
    case 'partialMain':
      return new FeaturedAvailabilityLine(line);

    default:
      return new AvailabilityLine(line);
  }
};
