import { entries } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { getUTMQueryParams } from './getUTMQueryParams';
import { useCookieUTMParams } from './useCookieUTMParams';
import type { UtmParams } from 'shared/types';

export const useUTMParams = (defaultUtmParams?: UtmParams) => {
  const utmQueryParams = useMemo(() => getUTMQueryParams(), []);

  const cookieUTMParams = useCookieUTMParams();

  const utmParams = useMemo(
    () => ({
      MSCLKID: cookieUTMParams.msclkid || utmQueryParams.msclkid || defaultUtmParams?.msclkid || '',
      FBCLID: cookieUTMParams.fbclid || utmQueryParams.fbclid || defaultUtmParams?.fbclid || '',
      GCLID: cookieUTMParams.gclid || utmQueryParams.gclid || defaultUtmParams?.gclid || '',
      utm_campaign: cookieUTMParams.utm_campaign || utmQueryParams.utm_campaign || defaultUtmParams?.utm_campaign || '',
      utm_source: cookieUTMParams.utm_source || utmQueryParams.utm_source || defaultUtmParams?.utm_source || '',
      utm_term: cookieUTMParams.utm_term || utmQueryParams.utm_term || defaultUtmParams?.utm_term || '',
      utm_medium: cookieUTMParams.utm_medium || utmQueryParams.utm_medium || defaultUtmParams?.utm_medium || '',
      utm_content: cookieUTMParams.utm_content || utmQueryParams.utm_content || defaultUtmParams?.utm_content || '',
    }),
    [cookieUTMParams, defaultUtmParams, utmQueryParams]
  );

  const utmEntries = useMemo(() => entries(utmParams).map(([key, value]) => ({ key, value })), [utmParams]);

  return useMemo(() => ({ utmParams, utmEntries }), [utmEntries, utmParams]);
};
