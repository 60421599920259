import { useMemo } from 'react';
import { CURRENT_TIMEZONE } from 'shared/lib/dates';
import { parseTimeRange } from '../../lib/parseTimeRange';
import { api as generatedApi } from './GetTireReports.generated';
import type { GetTireReportsQuery } from './GetTireReports.generated';
import type { TimeRange } from '../../lib/useReportsFilters';
import type { ReportGrouping } from 'shared/api';

type TireReports = NonNullable<GetTireReportsQuery['tireReports']>;
export type TireReport = NonNullable<TireReports['tireChart']>[0];

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetTireReports: {
      providesTags: () => ['Report'],
    },
  },
});

const { useGetTireReportsQuery } = api;

const empryReports: TireReport[] = [];

type Props = {
  timeRange: TimeRange;
  locations: string[];
  grouping: ReportGrouping;
  tireSize?: string;
};

export const useGetTireReports = ({ timeRange, locations, grouping, tireSize }: Props) => {
  const { fromDate, toDate, fromPreviousDate, toPreviousDate } = useMemo(() => parseTimeRange(timeRange), [timeRange]);

  const { data, isLoading } = useGetTireReportsQuery({
    startDate: fromDate,
    endDate: toDate,
    supplierIds: locations,
    grouping,
    timeZone: CURRENT_TIMEZONE,
  });

  const { data: prevData, isLoading: isPrevLoading } = useGetTireReportsQuery(
    {
      startDate: fromPreviousDate || '',
      endDate: toPreviousDate,
      supplierIds: locations,
      grouping,
      timeZone: CURRENT_TIMEZONE,
    },
    {
      skip: !fromPreviousDate || !toPreviousDate,
    }
  );

  const reports = useMemo(() => data?.tireReports?.tireChart || empryReports, [data?.tireReports?.tireChart]);

  const prevReports = useMemo(
    () => prevData?.tireReports?.tireChart || empryReports,
    [prevData?.tireReports?.tireChart]
  );

  const filteredReports = useMemo(() => {
    if (!tireSize) {
      return reports;
    }
    return reports.filter((report) => report.tireSize === tireSize);
  }, [reports, tireSize]);

  const filteredPrevReports = useMemo(() => {
    if (!fromPreviousDate || !toPreviousDate || !prevReports) {
      return undefined;
    }
    if (!tireSize) {
      return prevReports;
    }
    return prevReports.filter((report) => report.tireSize === tireSize);
  }, [fromPreviousDate, prevReports, tireSize, toPreviousDate]);

  return {
    reports: filteredReports,
    prevReports: filteredPrevReports,
    isLoading: isLoading || isPrevLoading,
  };
};
