import { Tooltip } from '@partstech/ui';
import { BaseStatus } from '../BaseStatus';
import type { AvailabilityStatusProps } from '../../AvailabilityDropdown';

type Props = Pick<AvailabilityStatusProps, 'onClick' | 'store' | 'trailingIcon'>;

export const LocalInventoryStatus = ({ onClick, store, trailingIcon }: Props) => (
  <Tooltip tooltip="This part is in your shop’s inventory. Check before ordering more.">
    <BaseStatus
      color="primaryLight10"
      leadingIcon={store?.getIcon()}
      onClick={onClick}
      text={store?.getTitle() ?? ''}
      trailingIcon={trailingIcon}
    />
  </Tooltip>
);
