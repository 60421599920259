import { useMemo } from 'react';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useGetPunchoutInfoQuery, type GetPunchoutInfoQuery } from './GetPunchoutInfo.generated';

export type PunchoutInfo = NonNullable<GetPunchoutInfoQuery['punchout']>;

export const usePunchoutInfo = () => {
  const { shop, hasCurrentUser } = useGetCurrentUser();

  const { data, ...props } = useGetPunchoutInfoQuery(undefined, { skip: !hasCurrentUser || !shop?.id });

  const punchoutEnabled = data?.punchout?.punchoutEnabled ?? false;
  const poNumbersEnabled = data ? !data.punchout?.session?.partner?.shutOffPoNumbers : true;
  const isCatalogSdk = data?.punchout?.session?.partner?.catalogSdk ?? false;
  const poNumber = data?.punchout?.session?.purchaseOrderNumber ?? null;
  const punchoutSessionHash = data?.punchout?.session?.sessionHash ?? null;
  const smsName = data?.punchout?.session?.partner?.managementSystem?.name ?? null;
  const generalButtons = data?.punchout?.session?.partner?.generalButtons ?? true;
  const callbackOrderUrl = data?.punchout?.session?.callbackOrderUrl ?? null;
  const callbackUrl = data?.punchout?.session?.callbackUrl ?? null;
  const hasReservedPoNumbers = data?.punchout?.session?.hasReservedPoNumbers ?? false;
  const localInventory = data?.punchout?.session?.partner?.localInventory ?? false;

  const canSubmitQuote = useMemo(() => {
    if (isCatalogSdk) {
      return true;
    }

    if (!punchoutEnabled) {
      return false;
    }

    if (!generalButtons) {
      return false;
    }

    return Boolean(callbackUrl);
  }, [callbackUrl, generalButtons, isCatalogSdk, punchoutEnabled]);

  const canProceedCheckout = useMemo(() => {
    if (isCatalogSdk) {
      return true;
    }

    if (!punchoutEnabled) {
      return true;
    }

    if (!generalButtons) {
      return false;
    }

    if (callbackOrderUrl) {
      return true;
    }

    return !callbackUrl;
  }, [callbackOrderUrl, callbackUrl, generalButtons, isCatalogSdk, punchoutEnabled]);

  return {
    ...props,
    poNumbersEnabled,
    isCatalogSdk,
    poNumber,
    punchoutEnabled,
    punchoutSessionHash,
    smsName,
    canSubmitQuote,
    canProceedCheckout,
    hasReservedPoNumbers,
    localInventory,
    isLoaded: Boolean(data),
  };
};
