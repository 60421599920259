import { DateTime } from 'luxon';

/**
 * @deprecated Use `DateFormatPreset` from `@partstech/ui` instead
 */
export enum DateFormat {
  chart = 'yyyy-MM-dd', // 2019-09-20
  time = 'hh:mm a', // 02:22 AM
  type1 = 'M/d/yyyy', // 9/20/2019
  type2 = 'LLLL d, yyyy', // September 20, 2019
  type3 = 'MMM d, y, hh:mm a', // Sep 20, 2019, 02:22 AM
}

export const CURRENT_TIMEZONE = DateTime.now().toFormat('z'); // Europe/Kiev
export const CURRENT_YEAR = new Date().getFullYear(); // 2023
