import { omit } from '@partstech/ui/utils';
import { Routes } from 'constant';
import { pickDefinedValues } from 'shared/lib/object';
import { urlParamsToQuery } from '../product/urlParamsToQuery';
import { generateUrl } from './generateUrl';
import type { ProductUrlParams, ProductUrlQueryParams } from 'types/product';

export const getProductUrl = (urlParams: ProductUrlParams, query?: Partial<ProductUrlQueryParams>) => {
  const partNumberId = urlParams.partNumberId ?? '';
  const searchParams = urlParamsToQuery(urlParams);

  return generateUrl(
    Routes.PRODUCT_PAGE,
    { partNumberId: encodeURIComponent(partNumberId) },
    {
      ...omit(pickDefinedValues({ ...searchParams, ...query }), 'partnumberid'),
    }
  );
};
