import { Button, ModalButtons, ModalView, Typography, useMedia } from '@partstech/ui';
import { FormTextField, HookForm, useForm } from '@partstech/ui/forms';
import { useCallback } from 'react';
import { useUpdateSupplierAccount } from 'hooks/supplierAccounts';
import { useFormStatus } from 'shared/lib/form';
import type { SupplierAccount } from 'entities/supplierAccount';

type Props = {
  onClose: () => void;
  supplierAccount: SupplierAccount;
};

type FormData = {
  nickname: string;
};

export const NicknameModal = ({ supplierAccount, onClose }: Props) => {
  const { isMobile } = useMedia();

  const { id, nickname } = supplierAccount;

  const [updateAccount, status] = useUpdateSupplierAccount();

  const form = useForm<FormData>({
    defaultValues: {
      nickname: supplierAccount.name,
    },
  });

  useFormStatus({ form, status, onSuccess: onClose });

  const {
    formState: { isSubmitting },
  } = form;

  const handleSubmit = useCallback(
    (values: FormData) => updateAccount({ ...values, accountId: id }, supplierAccount),
    [id, supplierAccount, updateAccount]
  );

  return (
    <ModalView title={`${nickname ? 'Edit' : 'Add'} Nickname`} onClose={onClose}>
      <HookForm form={form} onSubmit={handleSubmit}>
        <Typography>The nickname will replace the location name on invoices, emails, etc.</Typography>

        <FormTextField name="nickname" label="Nickname" maxLength={40} showMaxLength hideRequiredMark />

        <ModalButtons>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
          {!isMobile && (
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
          )}
        </ModalButtons>
      </HookForm>
    </ModalView>
  );
};
