import { Box, Typography } from '@partstech/ui';
import { useMemo } from 'react';
import { getTireQualityTemperature, getTireQualityTraction, getTireQualityTreadwear } from 'utils';
import type { Attribute } from 'models';

type Props = {
  attribute: Attribute;
};

export const TireQualityTranslation = ({ attribute }: Props) => {
  const tireQuality = useMemo(() => attribute.getValue(), [attribute]);

  const [tireQualityTreadwear, tireQualityTraction, tireQualityTemperature] = tireQuality ? tireQuality.split(' ') : [];

  return (
    <>
      {tireQualityTreadwear && (
        <>
          <Typography variant="overline" color="white">
            TREADWEAR{' '}
          </Typography>
          <Typography variant="overline" color="white" component="div">
            {tireQualityTreadwear}:{' '}
            <Typography variant="caption" color="white">
              {getTireQualityTreadwear(tireQualityTreadwear)}{' '}
            </Typography>
          </Typography>
          <Box mb={2} />
        </>
      )}
      {tireQualityTraction && (
        <>
          <Typography variant="overline" color="white">
            TRACTION{' '}
          </Typography>
          <Typography variant="overline" color="white" component="div">
            {tireQualityTraction}:{' '}
            <Typography variant="caption" color="white">
              {getTireQualityTraction(tireQualityTraction)}{' '}
            </Typography>
          </Typography>
          <Box mb={2} />
        </>
      )}
      {tireQualityTemperature && (
        <>
          <Typography variant="overline" color="white">
            TEMPERATURE{' '}
          </Typography>
          <Typography variant="overline" color="white" component="div">
            {tireQualityTemperature}:{' '}
            <Typography variant="caption" color="white">
              {getTireQualityTemperature(tireQualityTemperature)}
            </Typography>
          </Typography>
        </>
      )}
    </>
  );
};
