import { useMemo } from 'react';
import { useSearchResults } from 'pages/SearchResults/SearchResultsContext';
import { useAfterMarketSupplierAccounts } from './useAfterMarketSupplierAccounts';
import { useDealerSupplierAccounts } from './useDealerSupplierAccounts';
import type { SupplierAccount } from 'entities/supplierAccount';

const getTotalProducts = (totals: Record<number, number>, supplierAccounts: SupplierAccount[]): string => {
  const totalProducts = supplierAccounts.reduce((acc, account) => {
    const currentTotal = totals[Number(account.id)];

    if (currentTotal) {
      return acc + currentTotal;
    }

    return acc;
  }, 0);

  if (totalProducts === 0) {
    return '';
  }

  if (totalProducts >= 99) {
    return '(99+)';
  }

  return `(${totalProducts})`;
};

export const useSupplierAccountsProductsCount = () => {
  const { totals } = useSearchResults();

  const { dealerAccounts } = useDealerSupplierAccounts();
  const { afterMarketAccounts } = useAfterMarketSupplierAccounts();

  const dealersTotal = useMemo(() => getTotalProducts(totals, dealerAccounts), [dealerAccounts, totals]);

  const afterMarketTotal = useMemo(() => getTotalProducts(totals, afterMarketAccounts), [afterMarketAccounts, totals]);

  return { dealersTotal, afterMarketTotal };
};
