import { useCallback, useState } from 'react';

export const useToggleCategory = <T>() => {
  const [openedCategory, setOpenedCategory] = useState<T | null>(null);

  const resetCategory = useCallback(() => {
    setOpenedCategory(null);
  }, []);

  const toggleCategory = useCallback((category: T) => {
    setOpenedCategory((prevCategory) => (prevCategory === category ? null : category));
  }, []);

  return { openedCategory, resetCategory, setOpenedCategory: toggleCategory };
};
