import { Card, Tooltip, Typography } from '@partstech/ui';

type Props = {
  message: string;
  showTooltip?: boolean;
};

export const ErrorStatus = ({ message, showTooltip }: Props) => (
  <Card color="negative" borderWidth={1} borderColor="mono6" px={2} radius={4} maxWidth="100%">
    {showTooltip ? (
      <Tooltip tooltip={message} hasEllipsis display="flex">
        <Typography variant="overline" textTransform="uppercase" color="negative">
          {message}
        </Typography>
      </Tooltip>
    ) : (
      <Typography variant="overline" textTransform="uppercase" color="negative">
        {message}
      </Typography>
    )}
  </Card>
);
