/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { VehicleFragmentDoc } from '../../../../shared/api/graphql/fragment/VehicleFragment.generated';
import { CartLocalInventoryItemFragmentDoc } from './CartLocalInventoryItemFragment.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetActiveLocalInventoryItemsQueryVariables = Types.Exact<{
  withLabor?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type GetActiveLocalInventoryItemsQuery = {
  __typename?: 'Query';
  activeCart?: {
    __typename?: 'Cart';
    localInventoryItems?: Array<{
      __typename?: 'CartLocalInventoryItem';
      customerPrice?: Types.Scalars['BigDecimal']['input'] | null;
      id: string;
      image?: Types.Scalars['String']['input'] | null;
      listPrice?: Types.Scalars['BigDecimal']['input'] | null;
      localInventoryUID: Types.Scalars['String']['input'];
      orderGroupId: string;
      partName: Types.Scalars['String']['input'];
      partNumber: Types.Scalars['String']['input'];
      partNumberId: Types.Scalars['String']['input'];
      price: Types.Scalars['BigDecimal']['input'];
      quantity: number;
      availableMotorLaborApplications?: Array<{
        __typename?: 'CartLabor';
        duration: Types.Scalars['BigDecimal']['input'];
        id: string;
        name?: Types.Scalars['String']['input'] | null;
        notes?: Array<Types.Scalars['String']['input']> | null;
        parentId?: string | null;
        skillCode: Types.Scalars['String']['input'];
        taxonomyName: Types.Scalars['String']['input'];
        taxonomyAction?: Types.Scalars['String']['input'] | null;
        taxonomyId: string;
        baseLaborTimeDescription?: Types.Scalars['String']['input'] | null;
        descriptions?: Array<Types.Scalars['String']['input']> | null;
        position?: { __typename?: 'Position'; name: Types.Scalars['String']['input'] } | null;
        attributes?: Array<{
          __typename?: 'Attribute';
          name?: Types.Scalars['String']['input'] | null;
          text?: Types.Scalars['String']['input'] | null;
        }> | null;
      }> | null;
      laborItems?: Array<{
        __typename?: 'CartLaborItem';
        cartLocalInventoryItemId?: string | null;
        id: string;
        hours: Types.Scalars['Float']['input'];
        defaultHours: Types.Scalars['Float']['input'];
        vin?: Types.Scalars['String']['input'] | null;
        orderItemId?: string | null;
        name: Types.Scalars['String']['input'];
        notes?: Types.Scalars['String']['input'] | null;
        skill: Types.Scalars['String']['input'];
        identifiers?: { __typename?: 'Mitchell1Identifiers' } | { __typename?: 'MotorIdentifiers'; id: string } | null;
        vehicle?: { __typename?: 'Vehicle'; id: string } | null;
        taxonomy?: {
          __typename?: 'LaborTaxonomy';
          action?: Types.Scalars['String']['input'] | null;
          id: string;
          name: Types.Scalars['String']['input'];
        } | null;
      }> | null;
      partType?: { __typename?: 'PartType'; id: string } | null;
      product?: {
        __typename?: 'CartLocalInventoryProduct';
        hasFitment: Types.Scalars['Boolean']['input'];
        localInventoryUID: string;
        partNumber: Types.Scalars['String']['input'];
        partNumberId: Types.Scalars['String']['input'];
        price?: Types.Scalars['BigDecimal']['input'] | null;
        quantity: number;
        title: Types.Scalars['String']['input'];
        attributes: Array<{
          __typename?: 'CartLocalInventoryProductAttribute';
          name: Types.Scalars['String']['input'];
          values: Array<Types.Scalars['String']['input']>;
        }>;
        descriptions: Array<{
          __typename?: 'CartLocalInventoryProductDescription';
          code: Types.Scalars['String']['input'];
          label: Types.Scalars['String']['input'];
          values: Array<Types.Scalars['String']['input']>;
        }>;
        notes: Array<{ __typename?: 'CartLocalInventoryProductNotes'; group: Array<Types.Scalars['String']['input']> }>;
        variations: Array<{
          __typename?: 'CartLocalInventoryProductVariation';
          variationID: Types.Scalars['String']['input'];
          attributes: Array<{
            __typename?: 'CartLocalInventoryProductAttribute';
            name: Types.Scalars['String']['input'];
            values: Array<Types.Scalars['String']['input']>;
          }>;
          notes: Array<{
            __typename?: 'CartLocalInventoryProductNotes';
            group: Array<Types.Scalars['String']['input']>;
          }>;
        }>;
        warnings: Array<
          | { __typename?: 'CartLocalInventoryProductWarningAttachment'; attachment: Types.Scalars['String']['input'] }
          | { __typename?: 'CartLocalInventoryProductWarningImage'; path: Types.Scalars['String']['input'] }
          | { __typename?: 'CartLocalInventoryProductWarningText'; text: Types.Scalars['String']['input'] }
        >;
      } | null;
      validationError?: {
        __typename?: 'CartLocalInventoryItemValidationError';
        errorType: Types.CartLocalInventoryItemValidationErrorType;
        message?: Types.Scalars['String']['input'] | null;
      } | null;
      vehicle?: { __typename?: 'Vehicle'; id: string } | null;
    }> | null;
    vehicles?: Array<{
      __typename?: 'CartLinkedVehicle';
      id: string;
      vin?: Types.Scalars['String']['input'] | null;
      vehicle?: {
        __typename?: 'Vehicle';
        id: string;
        type: Types.VehicleType;
        regions?: Array<Types.VehicleRegion> | null;
        year: number;
        make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
        model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
        subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
        engine: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] };
      } | null;
    }> | null;
  } | null;
};

export const GetActiveLocalInventoryItemsDocument = `
    query GetActiveLocalInventoryItems($withLabor: Boolean = false) {
  activeCart {
    localInventoryItems {
      ...CartLocalInventoryItem
    }
    vehicles {
      id
      vin
      vehicle {
        ...Vehicle
      }
    }
  }
}
    ${CartLocalInventoryItemFragmentDoc}
${VehicleFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetActiveLocalInventoryItems: build.query<
      GetActiveLocalInventoryItemsQuery,
      GetActiveLocalInventoryItemsQueryVariables | void
    >({
      query: (variables) => ({ document: GetActiveLocalInventoryItemsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetActiveLocalInventoryItemsQuery, useLazyGetActiveLocalInventoryItemsQuery } = injectedRtkApi;
