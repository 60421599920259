import { SupplierAccount } from '../model/SupplierAccount';
import type { Store } from 'entities/store/@x/supplierAccount';
import type { Supplier } from 'entities/supplier/@x/supplierAccount';
import type { Account, AccountField } from 'shared/api';

const denormalizeFields = (fields: AccountField[]) =>
  fields.reduce<Record<string, string>>((acc, field) => ({ ...acc, [field.name]: field.value ?? '' }), {});

export const createSupplierAccountFromData = (
  supplierAccount: Omit<Account, 'store' | 'supplier' | 'active'>,
  supplier: Supplier | null,
  store: Store | null
) => {
  const credentials = denormalizeFields(supplierAccount?.credentials ?? []);
  const additionalInformation = denormalizeFields(supplierAccount?.additionalInformation ?? []);

  const account = new SupplierAccount({ ...supplierAccount, credentials, additionalInformation });

  if (supplier) {
    account.setSupplier(supplier);
  }

  if (store) {
    account.setStore(store);
  }

  return account;
};
