import { Typography, Card, Icon, Box } from '@partstech/ui';

type Props = {
  title: string;
  onClick: () => void;
};

export const MobileTableButton = ({ title, onClick }: Props) => (
  <Card onClick={onClick}>
    <Box m={4} maxWidth={86} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="subtitle1">{title}</Typography>

      <Box display="flex" alignItems="center">
        <Box pr={4}>
          <Typography variant="bodyText2" color="subtleText" component="span">
            Data table
          </Typography>
        </Box>

        <Icon name="chevron_right" color="subtleText" />
      </Box>
    </Box>
  </Card>
);
