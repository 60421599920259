/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { ReportProductFragmentDoc } from '../ReportProductFragment.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetPartTypeProductsQueryVariables = Types.Exact<{
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  perPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  partTypeId: Types.Scalars['ID']['input'];
  sortBy?: Types.InputMaybe<Types.PartReportSortBy>;
  sortOrder?: Types.InputMaybe<Types.SortOrder>;
  startDate: Types.Scalars['DateTime']['input'];
  storeIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;

export type GetPartTypeProductsQuery = {
  __typename?: 'Query';
  partReports?: {
    __typename?: 'PartReports';
    partProducts?: Array<{
      __typename?: 'ReportProduct';
      dateOfFirstPurchase: Types.Scalars['DateTime']['input'];
      dateOfLastPurchase: Types.Scalars['DateTime']['input'];
      image: Types.Scalars['String']['input'];
      lineCardId?: number | null;
      displayPartNumber?: Types.Scalars['String']['input'] | null;
      partNumber: Types.Scalars['String']['input'];
      partNumberId: Types.Scalars['String']['input'];
      title: Types.Scalars['String']['input'];
      totalOrders: number;
      totalPrice: Types.Scalars['BigDecimal']['input'];
      totalQuantity: number;
      account?: { __typename?: 'Account'; id: string } | null;
      brand?: { __typename?: 'Brand'; name: Types.Scalars['String']['input'] } | null;
      mostCommonSupplier?: { __typename?: 'Supplier'; logo: Types.Scalars['String']['input'] } | null;
      supplier?: { __typename?: 'Supplier'; id: string } | null;
      vehicle?: { __typename?: 'Vehicle'; id: string } | null;
    }> | null;
  } | null;
};

export const GetPartTypeProductsDocument = `
    query GetPartTypeProducts($endDate: DateTime, $page: Int, $perPage: Int, $partTypeId: ID!, $sortBy: PartReportSortBy, $sortOrder: SortOrder, $startDate: DateTime!, $storeIds: [ID!]) {
  partReports {
    partProducts(
      endDate: $endDate
      page: $page
      perPage: $perPage
      partTypeId: $partTypeId
      sortBy: $sortBy
      sortOrder: $sortOrder
      startDate: $startDate
      storeIds: $storeIds
    ) {
      ...ReportProduct
    }
  }
}
    ${ReportProductFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetPartTypeProducts: build.query<GetPartTypeProductsQuery, GetPartTypeProductsQueryVariables>({
      query: (variables) => ({ document: GetPartTypeProductsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPartTypeProductsQuery, useLazyGetPartTypeProductsQuery } = injectedRtkApi;
