import type { CartLocalInventoryItemFragment } from '../api/queries';
import type { Product } from 'models';

export const createLocalInventoryItemData = (
  id: string,
  quantity: number,
  product: Product
): CartLocalInventoryItemFragment => ({
  id,
  localInventoryUID: product.localInventoryUID || '',
  orderGroupId: '1',
  partName: product.title,
  partNumber: product.partNumber?.partNumber || '',
  partNumberId: product.getPartNumberId(),
  price: product.price || 0,
  product: {
    attributes: [],
    descriptions: [],
    hasFitment: true,
    localInventoryUID: product.localInventoryUID || '',
    notes: [],
    partNumber: product.partNumber?.partNumber || '',
    partNumberId: product.getPartNumberId(),
    price: product.price || 0,
    quantity,
    title: product.partType?.name || '',
    variations: [],
    warnings: [],
  },
  quantity,
  vehicle: null,
});
