import { Attribute, AttributeCollection } from 'models';
import type { AttributeName, AttributeOption } from 'constant';
import type { LocalInventoryProductAttribute, ProductAttribute } from 'shared/api';

type TechnicalAttributes = Array<LocalInventoryProductAttribute | ProductAttribute>;

const createAttribute = (
  name: AttributeName,
  children: Attribute[],
  productOptions?: string[],
  attributeOptions?: AttributeOption,
  isTechnical?: boolean
) =>
  new Attribute({
    name,
    values: productOptions ?? [],
    emptyValue: attributeOptions?.emptyValue ?? 'Not Specified',
    label: attributeOptions?.label ?? Attribute.formatName(name),
    isCollapsed: attributeOptions?.isCollapsed ?? false,
    isHidden: attributeOptions?.isHidden ?? false,
    isTechnical,
    rangeStep: attributeOptions?.rangeStep ?? null,
    usage: attributeOptions?.usage ?? {},
    children,
  });

export const createAttributesFromQuery = (
  productAttributes: TechnicalAttributes,
  isTechnical: boolean = false
): AttributeCollection => {
  const getChildren = (parentName: AttributeName): Attribute[] => {
    const parentOptions = Attribute.getOptionsByName(parentName);

    if (!parentOptions?.childrenNames) {
      return [];
    }

    return parentOptions.childrenNames.map((childName) => {
      const childOptions = Attribute.getOptionsByName(childName);
      const childProductAttribute = productAttributes.find((attr) => attr.name === childName);

      return createAttribute(
        childName,
        getChildren(childName),
        childProductAttribute?.values,
        childOptions,
        isTechnical
      );
    });
  };

  const attributes = productAttributes.map((productAttribute) => {
    const { name, values } = productAttribute;
    const attributeOptions = Attribute.getOptionsByName(name);

    return createAttribute(
      name as AttributeName,
      getChildren(name as AttributeName),
      values,
      attributeOptions,
      isTechnical
    );
  });

  return new AttributeCollection(attributes);
};
