const { config } = window;

export const environment = config?.environment ?? 'development';

export const release = config?.release ?? 'development';

export const configRoutingHosts = {
  /* eslint-disable no-underscore-dangle */
  shop: config?.routing_params?._shop_host,
  supplier: config?.routing_params?._supplier_host,
};

export const launchDarklyKey = config?.launch_darkly;
