import { Box, ModalView } from '@partstech/ui';
import { VehicleDiagrams } from 'features/diagramsList';
import type { Diagram } from 'shared/api';

type Props = {
  defaultCategoryId: string | null;
  onClose: () => void;
  onDiagramSelect: (diagram: Diagram, vehicleId: string) => void;
  onSelectVehicleClick?: () => void;
  vehicleId: string | null;
};

export const DiagramsListModal = ({
  defaultCategoryId,
  onClose,
  onDiagramSelect,
  onSelectVehicleClick,
  vehicleId,
}: Props) => (
  <ModalView title="Vehicle diagrams" onClose={onClose} data-testid="diagramsListModal">
    <Box px={4}>
      <VehicleDiagrams
        defaultCategoryId={defaultCategoryId}
        vehicleId={vehicleId}
        onClose={onClose}
        onDiagramSelect={onDiagramSelect}
        onSelectVehicleClick={onSelectVehicleClick}
      />
    </Box>
  </ModalView>
);
