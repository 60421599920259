import { Box, Card, Typography } from '@partstech/ui';
import { TransitionLogo } from 'features/transitionLogo';
import type { CardProps } from '@partstech/ui';
import type { ReactNode } from 'react';

type Props = {
  isFullVariant: boolean;
  adjustableNoMediaSize?: boolean;
  adornmentBefore?: ReactNode;
} & CardProps;

export const MediaPlaceholder = ({ isFullVariant, adornmentBefore, adjustableNoMediaSize, ...cardProps }: Props) => (
  <Card
    display="flex"
    flexDirection={isFullVariant ? 'column' : 'row'}
    background={isFullVariant ? 'white' : 'mono2'}
    height={{ sm: isFullVariant ? 70 : 31, md: isFullVariant ? 100 : 31 }}
    {...cardProps}
  >
    {adornmentBefore && adornmentBefore}

    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent={adornmentBefore ? 'flex-start' : 'center'}
      flexGrow={2}
    >
      <TransitionLogo
        variant="emblem"
        color="mono17"
        size={adjustableNoMediaSize ? 'small' : 'regular'}
        disableShrinkOnMobile
      />
      <Box mt={adjustableNoMediaSize ? 0 : 2}>
        <Typography variant="caption" color="mono40">
          Image coming soon
        </Typography>
      </Box>
    </Box>
  </Card>
);
