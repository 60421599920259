import { uniq } from '@partstech/ui/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePermissions } from 'entities/permission';
import { useAddItemsToCart } from 'features/cart/api/mutations/addItemsToCart';
import { useAddItemToCart } from 'features/cart/api/mutations/addItemToCart';
import { useAddLocalInventoryItemToCart } from 'features/cart/api/mutations/addLocalInventoryItemToCart';
import { useChangeCartLocalInventoryItemQuantity } from 'features/cart/api/mutations/changeCartLocalInventoryItemQuantity';
import { useDeleteCartLocalInventoryItem } from 'features/cart/api/mutations/deleteCartLocalInventoryItem';
import { useDeleteOrderItems } from 'features/cart/api/mutations/deleteCartOrderItems';
import { useLazyGetActiveLocalInventoryItemsQuery } from 'features/cart/api/queries/useGetActiveLocalInventoryItems';
import { useChangeCartItemQuantity } from '../api/mutations/changeCartItemQuantity';
import { useLazyGetActiveCartQuery } from '../api/queries/GetActiveCart.generated';
import { useLazyGetActiveCartOrdersQuery } from '../api/queries/useGetActiveCartOrders';
import { useRemoveFromCartTracking } from './useRemoveFromCartTracking';
import type { AddToActiveCardPayload, AddToCartPayload, QueueItem } from '../types';
import type { Highlights } from './useCartHighlights';
import type { CartOrderItem } from 'models';
import type { AddItemToActiveCartResult } from 'shared/api';

type Props = {
  startRemoving: (orderItem: CartOrderItem, onTimeout: () => void) => void;
  onRemoved: (orderItemId: string) => void;
  addHighlights: (value: Highlights) => void;
  onUpdateCart: () => void;
  isCartLoaded: boolean;
  hasCart: boolean;
};

export const useCartQueue = ({
  startRemoving,
  onRemoved,
  addHighlights,
  onUpdateCart,
  isCartLoaded,
  hasCart,
}: Props) => {
  const {
    shop: { laborAllowed },
  } = usePermissions();

  const { trackRemoveFromCart } = useRemoveFromCartTracking();

  const [queue, setQueue] = useState<QueueItem[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [orderIdsToUpdate, setOrderIdsToUpdate] = useState<string[]>([]);
  const [shouldUpdateLocalInventoryItems, setShouldUpdateLocalInventoryItems] = useState(false);

  const isRemovingBlocked = useMemo(() => queue.some((item) => item.type === 'add'), [queue]);

  const [fetchCart] = useLazyGetActiveCartQuery();
  const [fetchOrders] = useLazyGetActiveCartOrdersQuery();
  const [fetchLocalInventoryItems] = useLazyGetActiveLocalInventoryItemsQuery();
  const [addItemToCart] = useAddItemToCart();
  const [addLocalInventoryItemToCart] = useAddLocalInventoryItemToCart();
  const [addItemsToCart] = useAddItemsToCart();
  const [removeOrderItems] = useDeleteOrderItems();
  const [removeLocalInventory] = useDeleteCartLocalInventoryItem();
  const [changeCartItemQuantity] = useChangeCartItemQuantity();
  const [changeCartLocalInventoryItemQuantity] = useChangeCartLocalInventoryItemQuantity();

  const updateOrders = useCallback(async () => {
    hasCart
      ? await fetchOrders({ ids: orderIdsToUpdate, withLabor: laborAllowed })
      : await fetchCart({ withLabor: laborAllowed });

    setOrderIdsToUpdate([]);
  }, [fetchCart, fetchOrders, hasCart, laborAllowed, orderIdsToUpdate]);

  const updateLocalInventoryItems = useCallback(async () => {
    await fetchLocalInventoryItems({ withLabor: laborAllowed });
    setShouldUpdateLocalInventoryItems(false);
  }, [fetchLocalInventoryItems, laborAllowed]);

  const processCartUpdates = useCallback(async () => {
    if (!isCartLoaded) {
      return;
    }

    setIsProcessing(true);

    if (orderIdsToUpdate.length > 0) {
      await updateOrders();
    }

    if (shouldUpdateLocalInventoryItems) {
      await updateLocalInventoryItems();
    }

    onUpdateCart();
    setIsProcessing(false);
  }, [
    isCartLoaded,
    onUpdateCart,
    orderIdsToUpdate,
    shouldUpdateLocalInventoryItems,
    updateLocalInventoryItems,
    updateOrders,
  ]);

  const proceedQueue = useCallback(
    async (item: QueueItem) => {
      setIsProcessing(true);

      if (item.type === 'add') {
        if (item.payload.localInventoryUID) {
          const { data } = await addLocalInventoryItemToCart(item.payload);

          if (item.onFinally && data?.addLocalInventoryItemToActiveCart) {
            item.onFinally(data.addLocalInventoryItemToActiveCart);
          }

          if (
            data?.addLocalInventoryItemToActiveCart &&
            'localInventoryItem' in data.addLocalInventoryItemToActiveCart
          ) {
            const { localInventoryItem } = data.addLocalInventoryItemToActiveCart;
            setShouldUpdateLocalInventoryItems(true);
            addHighlights({ [localInventoryItem.id]: 'info' });
          }
        } else {
          const { data } = await addItemToCart(item.payload);

          if (item.onFinally && data?.addItemToActiveCart) {
            item.onFinally(data.addItemToActiveCart);
          }

          if (data?.addItemToActiveCart && 'orderId' in data.addItemToActiveCart) {
            const { orderId, orderItemId } = data.addItemToActiveCart;
            setOrderIdsToUpdate((prev) => uniq([...prev, orderId]));
            addHighlights({ [orderItemId]: 'info' });
          }
        }
      }

      if (item.type === 'addMultiple') {
        const { data } = await addItemsToCart(item.payload);

        if (item.onFinally && data?.batchAddItemsToActiveCart?.results) {
          item.onFinally(data.batchAddItemsToActiveCart?.results);
        }

        data?.batchAddItemsToActiveCart?.results.forEach((resultData) => {
          if ('orderId' in resultData.result) {
            const { orderId, orderItemId } = resultData.result;
            setOrderIdsToUpdate((prev) => uniq([...prev, orderId]));
            addHighlights({ [orderItemId]: 'info' });
          }
        });
      }

      if (item.type === 'changeQuantity') {
        const { orderItem, quantity } = item.payload;

        if (orderItem.localInventoryUID) {
          const res = await changeCartLocalInventoryItemQuantity({ itemId: orderItem.id, quantity });

          if (
            res.data?.updateActiveCartLocalInventoryItemQuantity &&
            'item' in res.data.updateActiveCartLocalInventoryItemQuantity
          ) {
            setShouldUpdateLocalInventoryItems(true);
          }
        } else {
          const res = await changeCartItemQuantity({ itemId: orderItem.id, quantity });

          if (res.data?.updateActiveCartItemQuantity && 'orderId' in res.data.updateActiveCartItemQuantity) {
            const { orderId } = res.data.updateActiveCartItemQuantity;
            setOrderIdsToUpdate((prev) => uniq([...prev, orderId]));
          }
        }
      }

      if (item.type === 'remove') {
        if (item.payload.localInventoryUID) {
          await removeLocalInventory({ ids: [item.payload.id] });
        } else {
          await removeOrderItems({ itemIds: [item.payload.id] });
        }

        onRemoved(item.payload.id);
        trackRemoveFromCart(item.payload);
      }

      setQueue((prev) => prev.slice(1));
      setIsProcessing(false);
    },
    [
      addHighlights,
      addItemToCart,
      addItemsToCart,
      addLocalInventoryItemToCart,
      changeCartItemQuantity,
      changeCartLocalInventoryItemQuantity,
      onRemoved,
      removeLocalInventory,
      removeOrderItems,
      trackRemoveFromCart,
    ]
  );

  const addToCart = useCallback((item: AddToCartPayload, onFinally?: (response: AddToActiveCardPayload) => void) => {
    setQueue((prev) => [...prev, { type: 'add', payload: item, onFinally }]);
  }, []);

  const addToCartMultiple = useCallback(
    (items: AddToCartPayload[], onFinally?: (response: AddItemToActiveCartResult[]) => void) => {
      setQueue((prev) => [...prev, { type: 'addMultiple', payload: items, onFinally }]);
    },
    []
  );

  const changeQuantity = useCallback((orderItem: CartOrderItem, quantity: number) => {
    setQueue((prev) => [...prev, { type: 'changeQuantity', payload: { orderItem, quantity } }]);
  }, []);

  const removeOrderItem = useCallback(
    (orderItem: CartOrderItem, orderId: string) => {
      if (!isRemovingBlocked) {
        startRemoving(orderItem, () => {
          setQueue((prev) => [...prev, { type: 'remove', payload: orderItem }]);
          if (orderItem.localInventoryUID) {
            setShouldUpdateLocalInventoryItems(true);
          } else {
            setOrderIdsToUpdate((prev) => uniq([...prev, orderId]));
          }
        });
      }
    },
    [isRemovingBlocked, startRemoving]
  );

  useEffect(() => {
    if (!isProcessing && queue[0] && isCartLoaded) {
      proceedQueue(queue[0]);
    }
  }, [isCartLoaded, isProcessing, proceedQueue, queue]);

  useEffect(() => {
    if (!isProcessing && !queue[0]) {
      processCartUpdates();
    }
  }, [queue, processCartUpdates, isProcessing]);

  return {
    addToCart,
    addToCartMultiple,
    removeOrderItem,
    changeQuantity,
    isLoading: queue.length > 0 || orderIdsToUpdate.length > 0 || shouldUpdateLocalInventoryItems,
  };
};
