import { SearchTypes } from 'types/search';
import { RecentSearchAbstract } from './RecentSearchAbstract';
import type { AbstractParams } from './RecentSearchAbstract';
import type { RecentSearchInterface } from './RecentSearchInterface';
import type { Vehicle } from 'entities/vehicle';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { PartNumberFormValues } from 'types/search';

export class PartNumber extends RecentSearchAbstract implements RecentSearchInterface, SearchEntryPointInterface {
  type: string = 'Part Number';

  partNumber: string;

  manufacturers: string[] | null | undefined;

  constructor(
    id: string,
    vehicle: Vehicle | null,
    params: AbstractParams & { partNumber: string; manufacturers?: string[] | null }
  ) {
    super(id, vehicle, params);

    this.partNumber = params.partNumber;
    this.manufacturers = params.manufacturers;
  }

  populateInput(): string {
    return this.partNumber;
  }

  toString(): string {
    return this.manufacturers ? `${this.partNumber} (${this.manufacturers.join(', ')})` : this.partNumber;
  }

  toSearchValues(): PartNumberFormValues {
    return {
      type: SearchTypes.PARTS,
      vehicleId: this.vehicle?.id ?? null,
      vin: this.vin,
      plate: this.plate,
      state: this.state,
      partNumber: this.partNumber ?? '',
      manufacturers: this.manufacturers ?? [],
    };
  }
}
