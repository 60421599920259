import { sortBy } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { parseTimeRange } from '../../lib/parseTimeRange';
import { api as generatedApi } from './GetTireProducts.generated';
import type { GetTireProductsQuery } from './GetTireProducts.generated';
import type { TimeRange } from '../../lib/useReportsFilters';

type TireReport = NonNullable<GetTireProductsQuery['tireReports']>;
export type TireProduct = NonNullable<TireReport['tireProducts']>[0];

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetTireProducts: {
      providesTags: () => ['Report'],
    },
  },
});

const { useGetTireProductsQuery } = api;

type Props = {
  timeRange: TimeRange;
  locations: string[];
  tireSize: string;
};

const emptyProducts: TireProduct[] = [];

export const useGetTireProducts = ({ timeRange, locations, tireSize }: Props) => {
  const { fromDate, toDate } = useMemo(() => parseTimeRange(timeRange), [timeRange]);

  const { data, isLoading } = useGetTireProductsQuery({
    startDate: fromDate,
    endDate: toDate,
    supplierIds: locations,
    tireSize,
  });

  const products = useMemo(() => data?.tireReports?.tireProducts || emptyProducts, [data]);
  const mostPopularProduct = useMemo(() => sortBy(products, (product) => -product.totalQuantity)[0], [products]);

  return {
    products,
    mostPopularProduct,
    isLoading,
  };
};
