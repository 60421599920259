import { ConnectionTime, type ConfigurationFieldType, type ValidationInputType } from 'shared/api';
import { Supplier } from '../model/Supplier';
import type { Supplier as SupplierEntity } from 'shared/api/rest/gen/shop';

const connectionTimeMap: Record<SupplierEntity['connectionTime'], ConnectionTime> = {
  '1_PLUS_DAYS': ConnectionTime.OnePlusDays,
  LESS_ONE_MIN: ConnectionTime.LessOneMin,
  NA: ConnectionTime.Na,
  NEXT_DAY: ConnectionTime.NextDay,
  TODAY: ConnectionTime.Today,
};

export const createSupplierFromData = (supplierData: SupplierEntity) => {
  const credentialsConfiguration =
    supplierData.credentials?.map((credential) => {
      const inputType = credential.validationRules?.inputType.toUpperCase() as ValidationInputType;

      return {
        ...credential,
        type: credential.type.toUpperCase() as ConfigurationFieldType,
        validationRules: credential.validationRules ? { ...credential.validationRules, inputType } : undefined,
      };
    }) ?? [];

  const supplier = new Supplier({
    ...supplierData,
    id: String(supplierData.id),
    logo: supplierData.logo ?? '',
    type: supplierData.type,
    allowCustomPurchaseOrderNumbers: supplierData.allowCustomPurchaseNumber,
    allowStockOrders: supplierData.allowStockOrder,
    hideLocation: supplierData.locationHidden,
    isTire: supplierData.tires,
    isTireConnect: supplierData.tireConnect,
    lockedStore: supplierData.storeLocked,
    credentialsConfiguration,
    connectionTime: connectionTimeMap[supplierData.connectionTime],
  });

  supplier.setOutage({ type: supplierData.outageType, message: supplierData.outageMessage });

  return supplier;
};
