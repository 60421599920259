import { Box, Button, ButtonGroup, Typography, useMedia } from '@partstech/ui';
import { HookForm, FormTextAreaField, FormTextField } from '@partstech/ui/forms';
import { email, maxLength, required } from 'shared/lib/form';
import { useContactForm } from './useContactForm';
import type { SupportMode, SupportFormData } from '../../../../../types';

const salesForce = {
  text: maxLength(32000),
  fieldShort: maxLength(127),
  field: maxLength(255),
};

type Props<TSupportMode extends SupportMode> = {
  mode: TSupportMode;
  defaultValues?: Partial<Omit<SupportFormData<TSupportMode>, 'notes'>>;
  onCancel?: () => void;
  onSubmit?: () => void;
};

export const ContactForm = <TSupportMode extends SupportMode>({
  defaultValues,
  mode,
  onCancel,
  onSubmit,
}: Props<TSupportMode>) => {
  const { isMobile } = useMedia();

  const { form, formSubmit, isSubmitting, isValid } = useContactForm<TSupportMode>({
    defaultValues,
    mode,
    onSubmit,
    onCancel,
  });

  return (
    <Box display="flex" flexDirection="column" flexWrap="wrap">
      <Box mb={6}>
        <Typography>
          The PartsTech Support team is always here to help! Just let us know what you need and we’ll get back to you as
          soon as we can.
        </Typography>

        <Box mt={2}>
          <Typography>All fields are required.</Typography>
        </Box>
      </Box>

      <HookForm form={form} onSubmit={formSubmit}>
        {mode === 'globalSupport' ? (
          <Box mb={{ sm: 5, md: 6 }}>
            <FormTextField name="username" id="username" label="Username" rules={{ required }} />
          </Box>
        ) : (
          <Box display="flex" justifyContent="space-between" flexDirection={{ sm: 'column', md: 'row' }}>
            <Box mb={{ sm: 5, md: 6 }} flex="1" mr={{ md: 2 }}>
              <FormTextField
                name="firstName"
                id="firstName"
                label="First name"
                rules={{ maxLength: salesForce.fieldShort, required }}
              />
            </Box>

            <Box mb={{ sm: 5, md: 6 }} flex="1">
              <FormTextField
                name="lastName"
                id="lastName"
                label="Last name"
                rules={{ maxLength: salesForce.fieldShort, required }}
              />
            </Box>
          </Box>
        )}

        <Box mb={{ sm: 5, md: 6 }}>
          <FormTextField
            name="email"
            id="email"
            label="Email address"
            rules={{ pattern: email, maxLength: salesForce.field, required }}
            normalize={(value) => value.trim()}
          />
        </Box>

        <Box mb={{ sm: 5, md: 6 }}>
          <FormTextAreaField
            name="notes"
            id="notes"
            label="Message the team"
            rules={{ maxLength: salesForce.text, required }}
            height={19}
          />
        </Box>

        <ButtonGroup flexDirection={{ sm: 'column', md: 'row' }}>
          <Button type="submit" disabled={!isValid || isSubmitting} isLoading={isSubmitting} fullWidth={isMobile}>
            Submit message
          </Button>

          <Button onClick={onCancel} variant="text" fullWidth={isMobile}>
            Cancel
          </Button>
        </ButtonGroup>
      </HookForm>
    </Box>
  );
};
