import { css } from '@emotion/react';
import { Banner, Box, Breadcrumbs, Card, Link, Typography, useMedia } from '@partstech/ui';
import React, { useCallback, useEffect, useMemo } from 'react';
import { OptionsByColumns } from 'components/OptionsByColumns';
import conventional from 'images/icons/attributes/conventional.svg';
import fullSynthetic from 'images/icons/attributes/full-synthetic.svg';
import syntheticBlend from 'images/icons/attributes/synthetic-blend.svg';
import { UniversalPartType } from 'models';
import { DependentAttribute } from './DependentAttribute';
import { GraphicalList } from './GraphicalList';
import { useDynamicAttributeConfigurator } from './useDynamicAttributeConfigurator';
import type { CardProps, Theme } from '@partstech/ui';
import type { Vehicle } from 'entities/vehicle';
import type { ReactNode } from 'react';

const icons: Record<string, string> = {
  Conventional: conventional,
  Synthetic: fullSynthetic,
  'Synthetic Blend': syntheticBlend,
};

const styles = {
  options: (isAdornmentAfter: boolean) => (theme: Theme) => css`
    height: ${isAdornmentAfter ? theme.sizing(128) : '100%'};
  `,
};

export type DynamicAttributeConfiguratorProps = {
  attributeBasedSearch?: boolean;
  onChange: (partType: UniversalPartType) => void;
  /**
   * If provided, will render breadcrumbs root with a part name and called when it's clicked
   */
  onReset?: () => void;
  onSelectVehicleClick?: () => void;
  partType: UniversalPartType;
  adornmentAfter?: ReactNode;
  vehicle: Vehicle | null;
} & Omit<CardProps, 'onChange' | 'onReset'>;

export const DynamicAttributeConfigurator = ({
  attributeBasedSearch = false,
  onChange,
  onReset,
  onSelectVehicleClick,
  partType,
  adornmentAfter,
  vehicle,
  ...cardProps
}: DynamicAttributeConfiguratorProps) => {
  const { isMobile } = useMedia();

  const {
    attribute,
    breadcrumbs,
    dependentAttribute,
    isFetching,
    isFirstAttribute,
    isLastAttribute,
    selectedAttributes,
    selectValue,
  } = useDynamicAttributeConfigurator({
    attributeBasedSearch,
    partType,
    onReset,
    vehicle,
  });

  const dependentAttributeValues = useMemo(() => dependentAttribute?.values ?? [], [dependentAttribute]);

  const attributeValues = useMemo(() => attribute?.values ?? [], [attribute?.values]);

  const handleChangeAttribute = useCallback(
    (name: string) => (value: string) => {
      selectValue(name, value);

      const newPartType = new UniversalPartType(partType);

      if (isLastAttribute) {
        onChange(newPartType.setSelectedAttributes({ ...selectedAttributes, [name]: [value] }));
      }
    },
    [isLastAttribute, onChange, partType, selectValue, selectedAttributes]
  );

  const handleSelectVehicleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      onSelectVehicleClick?.();
    },
    [onSelectVehicleClick]
  );

  const graphicalOptions = useMemo(
    () =>
      attribute?.values?.map((value) => ({
        value,
        icon: icons[value],
      })) ?? [],
    [attribute?.values]
  );

  useEffect(() => {
    if (isFetching || !dependentAttribute?.values) {
      return;
    }

    const newPartType = new UniversalPartType(partType);

    const { name, values } = dependentAttribute;

    if (values.length === 1) {
      const value = values[0];
      if (value) {
        onChange(
          newPartType.setSelectedAttributes({
            ...selectedAttributes,
            [name]: [value],
          })
        );
      }
    }

    if (values.length === 0) {
      onChange(newPartType.setSelectedAttributes({ ...selectedAttributes, [name]: [] }));
    }
  }, [dependentAttribute, isFetching, onChange, partType, selectedAttributes]);

  return (
    <Card
      display="flex"
      flexDirection="column"
      elevation={null}
      height="100%"
      {...cardProps}
      data-testid="dynamicAttributesConfigurator"
    >
      <Box display="flex">
        <Breadcrumbs background="mono2" px={4} py={2} breadcrumbs={breadcrumbs} mb={4} />
      </Box>

      {isFirstAttribute && (
        <>
          {dependentAttribute?.values && dependentAttribute.values.length > 0 && (
            <DependentAttribute
              title={dependentAttribute.name}
              values={dependentAttributeValues}
              isFetching={isFetching}
              mb={4}
              onChange={handleChangeAttribute(attribute?.name ?? '')}
            />
          )}

          {!vehicle && (
            <Banner icon="info" variant="info" radius={6} borderWidth={0} dense mb={4} display="inline-flex">
              Want to see vehicle specific options?{' '}
              <Link variant="caption" color="linkText" underline="hover" to="#" onClick={handleSelectVehicleClick}>
                Click here to enter a vehicle
              </Link>
            </Banner>
          )}

          <OptionsByColumns
            maxColumns={2}
            options={attributeValues}
            onChange={handleChangeAttribute(attribute?.name ?? '')}
            css={styles.options(Boolean(adornmentAfter))}
          />
        </>
      )}

      {!isFirstAttribute && (
        <GraphicalList
          isFetching={isFetching}
          onChange={handleChangeAttribute(attribute?.name ?? '')}
          options={graphicalOptions}
          title={
            dependentAttribute &&
            !isMobile && (
              <Typography variant="subtitle1">
                Please select a {String(attribute?.name).toLowerCase()} to see results
              </Typography>
            )
          }
          css={styles.options(Boolean(adornmentAfter))}
        />
      )}

      {adornmentAfter}
    </Card>
  );
};
