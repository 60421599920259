/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type ChangeCustomJobPrioritiesMutationVariables = Types.Exact<{
  input: Types.ChangeJobPrioritiesInput;
}>;

export type ChangeCustomJobPrioritiesMutation = {
  __typename?: 'Mutation';
  changeJobPriorities?: { __typename?: 'ChangeJobPrioritiesPayload'; ids: Array<string> } | null;
};

export const ChangeCustomJobPrioritiesDocument = `
    mutation ChangeCustomJobPriorities($input: ChangeJobPrioritiesInput!) {
  changeJobPriorities(input: $input) {
    ids
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ChangeCustomJobPriorities: build.mutation<
      ChangeCustomJobPrioritiesMutation,
      ChangeCustomJobPrioritiesMutationVariables
    >({
      query: (variables) => ({ document: ChangeCustomJobPrioritiesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useChangeCustomJobPrioritiesMutation } = injectedRtkApi;
