import { isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { PartType } from 'models';
import { api as generatedApi } from 'store/queries/currentUser/GetAutomaticPreferredBrands.generated';
import { convertAutomaticPreferredBrands } from 'utils';
import type { PreferredBrandsEdge } from 'shared/api';
import type { GetAutomaticPreferredBrandsQuery } from 'store/queries/currentUser/GetAutomaticPreferredBrands.generated';
import type { BrandHookType } from 'types/preferredBrand';

const emptyValue: PreferredBrandsEdge[] | null = [];

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['AutomaticPreferredBrand'],
  endpoints: {
    GetAutomaticPreferredBrands: {
      providesTags: (response: GetAutomaticPreferredBrandsQuery | undefined) => {
        const shop = response?.currentUser?.activeMember?.shop;

        if (!shop || !shop.preferredBrands?.edges) {
          return [];
        }

        const preferredBrands =
          shop.preferredBrands?.edges?.map((edge) => ({
            type: 'AutomaticPreferredBrand' as const,
            id: edge?.node?.brand?.id,
          })) ?? [];

        return ['AutomaticPreferredBrand', ...preferredBrands];
      },
    },
  },
});

const { useGetAutomaticPreferredBrandsQuery } = api;

export const useGetAutomaticPreferredBrands = (type: BrandHookType, skip: boolean) => {
  const { data, ...props } = useGetAutomaticPreferredBrandsQuery({}, { skip });

  const preferredBrands = data?.currentUser?.activeMember?.shop?.preferredBrands?.edges ?? emptyValue;

  const filteredBrands = useMemo(
    () =>
      (type === 'Tire'
        ? preferredBrands.filter((brand) => brand?.node?.partType?.id === PartType.TIRE_ID)
        : preferredBrands.filter((brand) => brand?.node?.partType?.id !== PartType.TIRE_ID)
      ).filter(isNotNull),
    [preferredBrands, type]
  );

  const convertedBrands = useMemo(() => convertAutomaticPreferredBrands(filteredBrands), [filteredBrands]);

  return { brands: convertedBrands, ...props };
};
