import { useModalHistory } from '@partstech/ui';
import { useCallback } from 'react';
import { ContactForm } from './ContactForm';
import type { ContactSupportPaths, SupportMode } from '../../../../types';

export const ContactSupport = <TSupportMode extends SupportMode>() => {
  const { state, reset, onClose } = useModalHistory<ContactSupportPaths<TSupportMode>, 'contactSupport'>();

  const { mode = 'globalSupport', defaultValues } = state ?? {};

  const handleSubmit = useCallback(() => reset('success'), [reset]);

  return (
    <ContactForm mode={mode as TSupportMode} defaultValues={defaultValues} onSubmit={handleSubmit} onCancel={onClose} />
  );
};
