import { Box, Icon, Menu, MenuItem, Typography } from '@partstech/ui';
import { useCallback } from 'react';
import { Highlight } from 'shared/ui';
import type { PartCategory } from 'models';

type Props = {
  categories: Array<PartCategory>;
  onClick: (category: PartCategory) => void;
  overlineText?: string;
  highlightedText?: string;
};

export const CategoriesList = ({ categories, onClick, overlineText, highlightedText = '' }: Props) => {
  const handleClick = useCallback(
    (category: PartCategory) => () => {
      onClick(category);
    },
    [onClick]
  );

  return (
    <Box>
      {Boolean(overlineText) && (
        <Box pt={3} pr={3} pb={0} pl={4}>
          <Typography variant="overline" color="subtleText">
            {overlineText}
          </Typography>
        </Box>
      )}
      <Box mb={3}>
        <Menu builtIn size="regular">
          {categories.map((category) => (
            <MenuItem
              data-testid="listItem"
              key={category.id}
              suffix={<Icon name="chevron_right" />}
              onClick={handleClick(category)}
            >
              <Highlight value={highlightedText}>{category.name}</Highlight>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </Box>
  );
};
