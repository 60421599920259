import type { SearchEntryPointInterface } from 'features/searchForm';

type DiagramData = {
  id: string;
  name: string;
  category?: {
    id: string;
    name: string;
  } | null;
  brand?: {
    id: string;
    name: string;
  } | null;
  url?: string | null;
};

/**
 * TODO: Compose with entities/Diagram, rename to DiagramEntryPoint, move to features/searchForm
 */
export class Diagram implements SearchEntryPointInterface {
  id: string;

  name: string;

  brand: { id: string; name: string } | null;

  category: { id: string; name: string } | null;

  url: string | null;

  constructor(diagramData: DiagramData) {
    this.brand = diagramData.brand ?? null;
    this.category = diagramData.category ?? null;
    this.id = diagramData.id;
    this.name = diagramData.name;
    this.url = diagramData.url ?? null;
  }

  toString(): string {
    return this.name ?? this.brand?.name ?? '';
  }

  isVehicleRequired(): boolean {
    return true;
  }

  getClassName(): string {
    return 'fs-diagram';
  }

  getColumnTitle(): string {
    return 'diagrams';
  }

  populateInput(): string {
    return this.toString();
  }

  isMatchedToString(search: string): boolean {
    return (
      this.name.toLowerCase().includes(search.toLowerCase()) ||
      Boolean(this.category?.name.toLowerCase().includes(search.toLowerCase()))
    );
  }
}
