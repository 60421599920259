import { css } from '@emotion/react';
import { Typography, Card, Box, Skeleton } from '@partstech/ui';
import googleIcon from 'images/icons/google-icon.svg';
import type { Theme } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

const buttonHeight = '40px';

const styles = {
  icon: css`
    background-image: url(${googleIcon});
    background-repeat: no-repeat;
  `,

  button: (theme: Theme) => css`
    height: ${buttonHeight};
    padding: ${theme.sizing(0, 7)};
    width: 100%;
    cursor: pointer;
  `,
};

type Props = PropsWithChildren<{
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}>;

export const GoogleButton = ({ children, isDisabled, isLoading, onClick }: Props) => {
  if (isLoading) {
    return <Skeleton width="100%" height={buttonHeight} />;
  }

  return (
    <Card elevation={1} radius={0} position="relative" data-testid="googleButton">
      <Box m="auto" width={6} height={6} position="absolute" left={3} top={0} bottom={0} css={styles.icon} />

      <button css={styles.button} onClick={onClick} type="button" disabled={isDisabled}>
        <Typography color="subtleText" component="span" variant="subtitle2">
          {children}
        </Typography>
      </button>
    </Card>
  );
};
