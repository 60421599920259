import { css } from '@emotion/react';
import { Box, Typography } from '@partstech/ui';
import { memo, useCallback, useMemo } from 'react';
import { useBundlesModal } from 'components/Product/Bundle/useBundlesModal';
import { useBestPriceBundleDealModal } from '../useBestPriceBundleDealModal';
import { useBundleDealModal } from '../useBundleDealModals';
import type { BoxProps, Theme } from '@partstech/ui';
import type { BundleDeal } from 'models';
import type { MouseEvent } from 'react';
import type { AvailableBundle, Bundle } from 'shared/api';

const styles = {
  bundle: (theme: Theme) => css`
    padding: 0;
    cursor: pointer;
    text-decoration: underline;
    color: ${theme.color.positive};
  `,
};

type Props = {
  isBundled: boolean;
  bundlesAvailable: Array<AvailableBundle | Bundle>;
  bundleDeal: BundleDeal | null;
} & BoxProps;

export const Bundles = memo(({ isBundled, bundlesAvailable, bundleDeal, ...props }: Props) => {
  const { openBundlesModal } = useBundlesModal(bundlesAvailable);
  const { openBundleDealModal } = useBundleDealModal(bundleDeal);
  const { openBestPriceBundleDealModal } = useBestPriceBundleDealModal();

  const isBestPriceBundle = bundleDeal?.savingsAmount === 0;

  const handleBundleModalClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (isBestPriceBundle) {
        openBestPriceBundleDealModal();
      } else if (isBundled) {
        openBundleDealModal();
      } else {
        openBundlesModal();
      }
    },
    [isBestPriceBundle, isBundled, openBestPriceBundleDealModal, openBundleDealModal, openBundlesModal]
  );

  const buttonLabel = useMemo(() => {
    if (isBestPriceBundle) {
      return 'Best price';
    }
    if (isBundled) {
      return 'Bundle deal';
    }
    return 'Bundle eligible';
  }, [isBestPriceBundle, isBundled]);

  return (
    <Box {...props}>
      {(isBundled || bundlesAvailable.length > 0) && (
        <button type="button" css={styles.bundle} onClick={handleBundleModalClick}>
          <Typography variant="overline" color="positive" textTransform="uppercase">
            {buttonLabel}
          </Typography>
        </button>
      )}
    </Box>
  );
});
