import { isNotNull } from '@partstech/ui/utils';
import { AvailabilityType } from 'types/search';
import { createOption } from './createOption';
import { groupFilterOptions } from './groupFilterOptions';
import { sortAvailabilityOptions } from './sortAvailabilityOptions';
import type { AvailabilityLine, Product, Quote } from 'models';
import type { CreateFiltersConfig, FilterOption } from 'types/search';

const createGroupAvailabilityOptions = (line: AvailabilityLine): FilterOption => {
  if (line.group !== null) {
    if (line.group === 'Home Store' || line.group === 'Today') {
      return createOption(AvailabilityType.FastestDelivery, AvailabilityType.FastestDelivery, 1, 'radio', line.group);
    }

    if (line.group === 'Next Day' || line.group === 'Tomorrow') {
      return createOption(line.group, 'By Tomorrow', 1, 'radio', line.group);
    }

    if (line.group === '2+ Days') {
      return createOption(line.group, 'More than 2 days', 1, 'radio', line.group);
    }

    return createOption(line.group, line.group, 1, 'radio', line.group);
  }

  if (line.name === 'Local') {
    return createOption(
      AvailabilityType.FastestDelivery,
      AvailabilityType.FastestDelivery,
      1,
      'radio',
      line.name,
      true
    );
  }

  return createOption(line.id ? `${line.id}` : line.name, line.name, 1, 'radio', line.id ? `${line.id}` : line.name);
};

const createAvailabilityByTypeOptions = (quote: Quote | null): FilterOption | null => {
  if (quote?.isInNetwork()) {
    return createOption(AvailabilityType.InNetwork, AvailabilityType.InNetwork, 1, 'radio', AvailabilityType.InNetwork);
  }

  if (quote?.isClosest()) {
    return createOption(
      AvailabilityType.DirectDelivery,
      AvailabilityType.DirectDelivery,
      1,
      'radio',
      AvailabilityType.DirectDelivery
    );
  }

  if (quote?.isInStock()) {
    return createOption(
      AvailabilityType.FastestDelivery,
      AvailabilityType.FastestDelivery,
      1,
      'radio',
      AvailabilityType.FastestDelivery
    );
  }

  return null;
};

const countGroupAvailabilityOptions = (products: Product[], sortedOptions: FilterOption[], optionValues: string[]) =>
  sortedOptions.map((option, index) => ({
    ...option,
    count: products.reduce((count, product) => {
      const previousValues = optionValues.slice(0, index + 1);

      const lines = product.quote?.lines ?? [];
      const lineIds = lines.map((line) => `${line.group ?? line.id ?? line.name}`);

      const shouldCount = lineIds.some((lineId) => previousValues.some((value) => value === lineId));

      return shouldCount ? count + 1 : count;
    }, 0),
  }));

const countAvailabilityByTypeOptions = (products: Product[], sortedOptions: FilterOption[]) =>
  sortedOptions.map((option) => ({
    ...option,
    count: products.reduce((count, { quote }) => {
      const shouldCount =
        (option.value === AvailabilityType.FastestDelivery && quote?.isFastestDelivery()) ||
        (option.value === AvailabilityType.DirectDelivery && quote?.isDirectDelivery()) ||
        (option.value === AvailabilityType.InNetwork && quote?.isNetworkDelivery());

      return shouldCount ? count + 1 : count;
    }, 0),
  }));

const getOptionsWithCount = (
  products: Product[],
  sortedOptions: FilterOption[],
  optionValues: string[],
  config: CreateFiltersConfig
) =>
  config.shouldUseGroupAvailability
    ? countGroupAvailabilityOptions(products, sortedOptions, optionValues)
    : countAvailabilityByTypeOptions(products, sortedOptions);

export const createAvailabilityOptions = (products: Product[], config: CreateFiltersConfig) => {
  const getAvailabilityOptions = config.shouldUseGroupAvailability
    ? (product: Product) => product.quote?.lines?.map(createGroupAvailabilityOptions)
    : (product: Product) => createAvailabilityByTypeOptions(product.quote);

  const options = groupFilterOptions(products.flatMap(getAvailabilityOptions).filter(isNotNull));
  const sortedOptions = sortAvailabilityOptions(options, config.supplierId);

  const optionValues = sortedOptions.map((option) => option.label || '');

  const optionsWithCount = getOptionsWithCount(products, sortedOptions, optionValues, config);

  return groupFilterOptions(optionsWithCount);
};
