import { useMutationCallback } from 'shared/api';
import { api as generatedApi } from './UpdateLaborRate.generated';
import type { UpdateLaborRateMutation } from './UpdateLaborRate.generated';
import type { UseMutationCallbackArgs } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateLaborRate: {
      invalidatesTags: ['LaborRate'],
    },
  },
});

const { useUpdateLaborRateMutation } = api;

export const useUpdateLaborRate = (args?: UseMutationCallbackArgs<UpdateLaborRateMutation, {}>) =>
  useMutationCallback(useUpdateLaborRateMutation, args);
