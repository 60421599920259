import { useEffect } from 'react';
import { logOutMutation, logOutListener } from 'features/logOut';
import { resetStore } from 'store/resetStore';
import { useAutoReloadOnStorageChange } from './useAutoReloadOnStorageChange';

export const useLogOutListener = () => {
  const reloadTrigger = useAutoReloadOnStorageChange();

  useEffect(() => {
    const unsubscribe = logOutListener.startListening({
      predicate: (action) => logOutMutation.matchFulfilled(action),

      effect: (_action, { dispatch }) => {
        resetStore(dispatch);

        reloadTrigger();
      },
    });

    return () => {
      unsubscribe();
    };
  }, [reloadTrigger]);
};
