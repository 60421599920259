import { isEmpty } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

export const useGetLaunchDarklyContext = () => {
  const { username, shop, isSuccess } = useGetCurrentUser();

  return useMemo(() => {
    if (!isSuccess) {
      return undefined;
    }

    return isEmpty(shop?.id)
      ? {
          kind: 'user',
          anonymous: true,
          key: 'anon',
        }
      : {
          kind: 'multi',
          user: {
            kind: 'user',
            key: username ?? '',
          },
          shop: {
            kind: 'shop',
            key: shop?.id ?? '',
            demo: shop?.attributes?.demo ?? false,
            name: shop?.name ?? '',
            registrationDate: shop?.attributes?.registrationDate,
            shippingZipCode: shop?.attributes?.defaultShippingZipCode ?? null,
            suppliers: shop?.attributes?.suppliers ?? [],
            totalPaidOrders: shop?.attributes?.totalPaidOrders ?? 0,
            usersCount: shop?.attributes?.usersCount ?? 0,
          },
        };
  }, [
    isSuccess,
    shop?.attributes?.defaultShippingZipCode,
    shop?.attributes?.demo,
    shop?.attributes?.registrationDate,
    shop?.attributes?.suppliers,
    shop?.attributes?.totalPaidOrders,
    shop?.attributes?.usersCount,
    shop?.id,
    shop?.name,
    username,
  ]);
};
