import { useMemo } from 'react';
import { PreferredBrandsMode } from 'shared/api';
import { usePreferredBrandsMode } from 'store/queries/currentUser/usePreferredBrandsMode';
import { useBrandOptions } from '../preferredBrands/useBrandOptions';
import { usePreferredBrands } from '../preferredBrands/usePreferredBrands';
import { useSearchParams } from './useSearchParams';
import type { Product } from 'models';

export const useSearchPreferredBrands = (products: Product[] = []) => {
  const { isTiresSearch, isPartsSearch, isPartNumberSearch } = useSearchParams();
  const { tiresPreferredBrandsMode, partsPreferredBrandsMode } = usePreferredBrandsMode();

  const type = isTiresSearch ? 'Tire' : 'Part';
  const mode = type === 'Part' ? partsPreferredBrandsMode : tiresPreferredBrandsMode;

  const { preferredBrands } = usePreferredBrands({ mode, type });
  const { preferredBrandOptions, regularBrandOptions, maxPreferredBrandsCount } = useBrandOptions({
    products,
    type: isTiresSearch ? 'Tire' : 'Part',
    isPartNumberSearch,
  });

  const restrictPreferredBrands = useMemo(
    () =>
      (isPartsSearch && partsPreferredBrandsMode === PreferredBrandsMode.Disable) ||
      (isTiresSearch && tiresPreferredBrandsMode === PreferredBrandsMode.Disable),
    [isPartsSearch, isTiresSearch, partsPreferredBrandsMode, tiresPreferredBrandsMode]
  );

  const preferredBrandsList = useMemo(() => {
    if (isPartNumberSearch || restrictPreferredBrands) {
      return [];
    }

    return preferredBrands;
  }, [isPartNumberSearch, preferredBrands, restrictPreferredBrands]);

  return { preferredBrandOptions, regularBrandOptions, preferredBrands: preferredBrandsList, maxPreferredBrandsCount };
};
