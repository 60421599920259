import { Tab, Tabs } from '@partstech/ui';
import { useCallback, useMemo } from 'react';
import { usePermissions } from 'entities/permission';
import { ProductAlias, useSubscription } from 'features/subscription';
import { useMyShopTabs } from './useMyShopTabs';
import type { TabProps } from '@partstech/ui';
import type { MyShopPageAlias } from 'utils/url';

export const MyShopTabs = () => {
  const { isRestrictedEX, subscription } = useSubscription();

  const { activeTab, tabs, changeTab } = useMyShopTabs();

  const {
    member: { retailPricingAllowed },
  } = usePermissions();

  const showRetailPricingUpgradeIcon = useMemo(
    () => (isRestrictedEX ? subscription.plan.alias !== ProductAlias.Complete : !retailPricingAllowed),
    [isRestrictedEX, retailPricingAllowed, subscription.plan.alias]
  );

  const getIconStyles = useCallback(
    (alias: string): Partial<TabProps<MyShopPageAlias>> => {
      if (alias === 'retail-pricing' && showRetailPricingUpgradeIcon) {
        return { icon: 'upgrade', iconColor: 'secondary' };
      }

      return {};
    },
    [showRetailPricingUpgradeIcon]
  );

  return (
    <Tabs activeTab={activeTab} onChange={changeTab} data-testid="myShopTabsContainer">
      {tabs.map((tab) => (
        <Tab id={tab.alias} key={tab.alias} data-testid={`${tab.alias}Tab`} {...getIconStyles(tab.alias)}>
          {tab.title}
        </Tab>
      ))}
    </Tabs>
  );
};
