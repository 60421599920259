import { css } from '@emotion/react';
import { Button, Icon } from '@partstech/ui';
import type { ButtonProps } from '@partstech/ui';

const styles = {
  dragButton: css`
    padding: 0;
    height: auto;
    cursor: inherit;

    span {
      display: flex;
      min-height: auto;
      min-width: auto;
    }
  `,
};

export const DragButton = (props: ButtonProps) => (
  <Button title="Drag to change supplier's priority" variant="text" noHover {...props} css={styles.dragButton}>
    <Icon name="drag" size="medium" />
  </Button>
);
