import { useCallback, useMemo, useState } from 'react';
import { identify as mbsyIdentify } from './identify';
import type { IdentifyTraits } from './identify';

type IdentifyStatus = {
  isLoading: boolean;
  isSuccess: boolean;
  error: string | null;
};

export const useIdentify = () => {
  const [status, setStatus] = useState<IdentifyStatus>({
    isLoading: false,
    error: null,
    isSuccess: false,
  });

  const identify = useCallback(async (traits: IdentifyTraits) => {
    setStatus({ isLoading: true, error: null, isSuccess: false });

    try {
      await mbsyIdentify(traits);

      setStatus({ isLoading: false, error: null, isSuccess: true });

      return true;
    } catch (error) {
      setStatus({ isLoading: false, error: (error as Error).message, isSuccess: false });

      throw error;
    }
  }, []);

  return useMemo(() => [identify, status] as const, [status, identify]);
};
