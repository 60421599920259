import { Loader } from '@partstech/ui';
import React from 'react';
import { useGetVehicle } from '../../api/useGetVehicle';
import { VehicleDetailsModalView } from '../VehicleDetailsModalView';

type Props = {
  id: string;
  vin?: string;
  onClose: () => void;
};

export const VehicleDetailsModal: React.FunctionComponent<Props> = ({ id, vin, onClose }) => {
  const { vehicle, isFetching } = useGetVehicle({ id, vin });

  if (!vehicle || isFetching) {
    return <Loader size="large" />;
  }

  return <VehicleDetailsModalView vehicle={vehicle} onClose={onClose} />;
};
