import { css } from '@emotion/react';
import { Banner, Box, Button, Icon, IconButton, ImageFallback, Scrollbar, useMedia } from '@partstech/ui';
import { sortBy } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { Title } from 'components/OptionList/Title';
import { OptionsByColumns } from 'components/OptionsByColumns';
import { VehicleRequiredView } from 'components/VehicleRequiredView';
import { usePermissions } from 'entities/permission';
import { PaidFeatureAlias, useLearnAboutFeatureModal } from 'features/subscription';
import jobsImg from 'images/icons/search-tabs/jobs.svg';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { JobType } from 'models';
import { FreeJobs } from './FreeJobs';
import { VehicleSpecificJobsLabel } from './VehicleSpecificJobsLabel';
import type { Theme } from '@partstech/ui';
import type { Job } from 'models';

const styles = {
  iconButton: (theme: Theme) => css`
    min-width: ${theme.sizing(6)};
    min-height: ${theme.sizing(6)};
    padding: ${theme.sizing(1)};
  `,
};

type Props = {
  hasVehicle?: boolean;
  options: Job[];
  onChange: (option: Job) => void;
  onSelect?: (option: Job) => void;
  onSelectVehicleClick?: () => void;
  onAdditionalButtonClick?: () => void;
  onCreateJobPageOpen?: () => void;
  isKeyboardDisabled?: boolean;
  totalCustomJobs?: number;
};

export const Jobs = ({
  hasVehicle = false,
  isKeyboardDisabled,
  onChange,
  onSelect,
  onSelectVehicleClick,
  onAdditionalButtonClick,
  onCreateJobPageOpen,
  options,
  totalCustomJobs,
}: Props) => {
  const { isMobile } = useMedia();

  const {
    shop: { jobsAllowed },
  } = usePermissions();

  const {
    flags: { vehicleSpecificJobs, customJobs: customJobsEnabled },
  } = useLaunchDarkly();

  const { open: openLearnAboutJobsModal } = useLearnAboutFeatureModal({ feature: PaidFeatureAlias.Jobs });
  const { open: openLearnAboutCustomJobsModal } = useLearnAboutFeatureModal({ feature: PaidFeatureAlias.CustomJobs });

  const selectJob = useCallback(
    (job: Job) => {
      if (job.isPaid && !jobsAllowed) {
        openLearnAboutJobsModal();
        return;
      }

      onChange(job);
    },
    [jobsAllowed, onChange, openLearnAboutJobsModal]
  );

  const handleOpen = useCallback(() => {
    openLearnAboutCustomJobsModal();
  }, [openLearnAboutCustomJobsModal]);

  const fittedCustomJobs = useMemo(
    () =>
      sortBy(
        options.filter((job) => job.type === JobType.Custom),
        'priority'
      ),
    [options]
  );

  const freeJobs = useMemo(
    () => (jobsAllowed ? [] : options.filter((job) => job.isFree && job.type !== JobType.Custom)),
    [jobsAllowed, options]
  );

  const paidJobs = useMemo(
    () =>
      jobsAllowed
        ? options.filter((job) => job.type !== JobType.Custom)
        : options.filter((job) => job.isPaid && job.type !== JobType.Custom),
    [jobsAllowed, options]
  );

  const hasCustomJobs = totalCustomJobs && totalCustomJobs > 0;
  const showCustomJobsBanner = totalCustomJobs === fittedCustomJobs.length;

  return (
    <VehicleRequiredView
      data-testid="jobs"
      width="100%"
      hasOptions={options.length > 0}
      hasVehicle={hasVehicle}
      onSelectVehicleClick={onSelectVehicleClick}
      noOptionsIcon={<ImageFallback src={jobsImg} height="40px" width="40px" />}
      noVehicleText={
        customJobsEnabled && jobsAllowed
          ? 'A vehicle must be selected in order to use the jobs functionality. To add or view your created jobs, go to manage jobs.'
          : 'A vehicle must be selected in order to view jobs.'
      }
      additionalButtonText={customJobsEnabled && jobsAllowed ? 'Manage jobs' : undefined}
      onAdditionalButtonClick={onAdditionalButtonClick}
      noOptionsText="There are currently no jobs available for the vehicle you selected."
      maxHeight={104}
      height={104}
    >
      <Scrollbar>
        {customJobsEnabled ? (
          <Box display="flex" justifyContent="space-between" alignItems="flex-start" mt={vehicleSpecificJobs ? 2 : 0}>
            <Box width="50%">
              {jobsAllowed && vehicleSpecificJobs && <VehicleSpecificJobsLabel />}
              {freeJobs.length > 0 && <FreeJobs jobs={freeJobs} onSelect={selectJob} />}
              <OptionsByColumns
                onChange={selectJob}
                onSelect={onSelect}
                options={paidJobs}
                maxColumns={1}
                isKeyboardDisabled={isKeyboardDisabled}
                title={!vehicleSpecificJobs ? 'popular' : undefined}
              />
            </Box>
            <Box width="50%">
              <Box display="flex" justifyContent="space-between" pl={{ sm: 1, md: 4 }}>
                <Title title="Custom" />
                {Boolean(hasCustomJobs) &&
                  (isMobile ? (
                    <IconButton css={styles.iconButton} onClick={onAdditionalButtonClick} noHover>
                      <Icon name="settings" size="default" />
                    </IconButton>
                  ) : (
                    <Button variant="text" size="dense" leadingIcon="settings" onClick={onAdditionalButtonClick}>
                      Manage
                    </Button>
                  ))}
              </Box>

              <OptionsByColumns
                onChange={selectJob}
                onSelect={onSelect}
                options={fittedCustomJobs}
                maxColumns={1}
                isKeyboardDisabled={isKeyboardDisabled}
              />
              <Box maxWidth={87} mt={2} pl={{ sm: 1, md: 4 }}>
                {!showCustomJobsBanner && (
                  <Banner key="banner" variant="info" icon="info" mb={2} dense>
                    Jobs may not appear if the parts don't match the vehicle.
                  </Banner>
                )}

                {!jobsAllowed ? (
                  <Button
                    key="upgrade-button"
                    color="upgrade"
                    leadingIcon="upgrade"
                    size="dense"
                    iconColor="secondaryDark10"
                    onClick={handleOpen}
                  >
                    Create job
                  </Button>
                ) : (
                  <Button
                    key="create-job-button"
                    variant={hasCustomJobs ? 'secondary' : 'primary'}
                    size="dense"
                    leadingIcon="add"
                    onClick={onCreateJobPageOpen}
                  >
                    Create job
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box mt={vehicleSpecificJobs ? 2 : 0}>
            {jobsAllowed && vehicleSpecificJobs && <VehicleSpecificJobsLabel />}
            {freeJobs.length > 0 && <FreeJobs jobs={freeJobs} onSelect={selectJob} />}
            <OptionsByColumns
              onChange={selectJob}
              onSelect={onSelect}
              options={paidJobs}
              maxColumns={2}
              isKeyboardDisabled={isKeyboardDisabled}
            />
          </Box>
        )}
      </Scrollbar>
    </VehicleRequiredView>
  );
};
