import { css } from '@emotion/react';
import { Box, Typography } from '@partstech/ui';
import type { TypographyProps } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

const styles = {
  textWrapper: css`
    overflow: hidden;
    overflow-wrap: break-word;
  `,
  title: (uppercase: boolean) => css`
    text-transform: ${uppercase ? 'uppercase' : 'none'};
  `,
};

type Props = PropsWithChildren<{
  title: string;
  titleVariant?: TypographyProps['variant'];
  uppercaseTitle?: boolean;
  gap?: number;
  align?: 'left' | 'right';
  leftAdornment?: JSX.Element;
  rightAdornment?: JSX.Element;
}>;

export const InfoBandItem = ({
  title,
  titleVariant = 'overline',
  uppercaseTitle = true,
  gap = 2,
  align = 'left',
  leftAdornment,
  rightAdornment,
  children,
}: Props) => (
  <div data-testid="infoBandItem">
    <Box textAlign={align} mb={gap} css={styles.title(uppercaseTitle)}>
      <Typography variant={titleVariant} color="subtleText">
        {title}
      </Typography>
    </Box>

    <Box display="flex" alignItems="center" justifyContent={align === 'left' ? 'flex-start' : 'flex-end'}>
      {leftAdornment && (
        <Box display="flex" mr={2}>
          {leftAdornment}
        </Box>
      )}

      <div css={styles.textWrapper}>{children}</div>

      {rightAdornment && (
        <Box display="flex" ml={2}>
          {rightAdornment}
        </Box>
      )}
    </Box>
  </div>
);
