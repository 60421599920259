export const TABLE_LIMIT_OPTIONS = [
  {
    value: 10,
    text: '10/page',
  },
  {
    value: 25,
    text: '25/page',
  },
  {
    value: 50,
    text: '50/page',
  },
  {
    value: 100,
    text: '100/page',
  },
];
