import { useDeepCompareEffect } from '@partstech/ui/hooks';
import { entries, isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useFullStory } from 'integrations/fullstory';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { usePunchoutInfo } from 'store/queries/punchoutInfo/usePunchoutInfo';

type Props = {
  skip: boolean;
};

export const useSendShopData = ({ skip = false }: Props) => {
  const { setUserVars } = useFullStory();

  const { shopMemberships, shop } = useGetCurrentUser();
  const { smsName, punchoutSessionHash: punchoutSessionId, isLoaded: isPunchoutSettingsLoaded } = usePunchoutInfo();

  const { flags } = useLaunchDarkly();
  const arrayOfFlags = useMemo(() => entries(flags).map(([key, value]) => `${key}=${JSON.stringify(value)}`), [flags]);

  useDeepCompareEffect(() => {
    if (!skip && shopMemberships.length > 0 && isPunchoutSettingsLoaded) {
      setUserVars({
        shopName: shopMemberships?.map((shopMembership) => shopMembership.shop?.name).filter(isNotNull),
        usersCount: shop?.attributes?.usersCount ?? 0,
        numberOrders: shop?.orderingStatistic?.totalPaidOrders ?? 0,
        shopManagementSystemName: smsName ?? '',
        punchoutSessionId,
        smsValidatedDate: shop?.smsValidatedDate ?? '',
        ldflags: arrayOfFlags,
      });
    }
  }, [
    shopMemberships,
    isPunchoutSettingsLoaded,
    smsName,
    punchoutSessionId,
    arrayOfFlags,
    shop?.orderingStatistic?.totalPaidOrders,
    shop?.attributes?.usersCount,
    skip,
    setUserVars,
  ]);
};
