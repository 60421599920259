import { Routes } from 'constant';
import { generateUrl } from './generateUrl';
import type { SupplierAccountType } from 'features/supplierAccount';

export type AddSuppliersTabs = 'all' | 'tires' | 'parts' | 'dealer';

export type AddSuppliersSearchParams = {
  activeScreen: 'all-suppliers' | 'my-suppliers';
  supplierAccountFilter?: SupplierAccountType;
};

export type PathParams = { tab: AddSuppliersTabs };

export const getAddSupplierUrl = (tab: AddSuppliersTabs, search?: AddSuppliersSearchParams) =>
  generateUrl(Routes.ADD_SUPPLIER, { tab }, search);
