import type { MemberPermissions } from 'shared/api';

export const memberPermissionsDefaultValues: MemberPermissions = {
  allOrders: true,
  boschRewards: false,
  brandPreferences: false,
  partsReport: false,
  payments: false,
  reportsDashboardAccess: true,
  retailPricing: false,
  shipping: false,
  shopProfile: false,
  spendReport: false,
  subscription: false,
  suppliers: false,
  tireReport: false,
  viewWholesaleCosts: false,
};
