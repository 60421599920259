import { MaxPreferredBrandsCount } from 'constant';
import { PreferredBrandsMode } from 'shared/api';
import type { BrandHookType } from 'types/preferredBrand';

export const getMaxSearchPreferredBrandsCount = (
  mode: PreferredBrandsMode,
  type: BrandHookType,
  brandsCount: number
) => {
  if (mode === PreferredBrandsMode.Custom) {
    return brandsCount;
  }

  return type === 'Part' ? MaxPreferredBrandsCount.Parts : MaxPreferredBrandsCount.Tires;
};
