import { isEmpty } from '@partstech/ui/utils';
import { createOption } from './createOption';
import { groupFilterOptions } from './groupFilterOptions';
import type { AttributeName } from 'constant';
import type { Product } from 'models';
import type { FilterOption } from 'types/search';

export const makeAttributeFilterOptions = (
  name: AttributeName,
  products: Product[],
  checkedOptions: FilterOption[]
) => {
  const options = products.flatMap((product) =>
    product
      .getAttributeValues(name)
      .filter((value) => !isEmpty(value))
      .map((value: string) => createOption(value, value, 1))
  );

  return groupFilterOptions([...checkedOptions, ...options]);
};
