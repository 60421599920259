/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetSpendReportOrdersQueryVariables = Types.Exact<{
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  perPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  poNumber?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortBy?: Types.InputMaybe<Types.SpendReportOrdersSortBy>;
  sortOrder?: Types.InputMaybe<Types.SortOrder>;
  startDate: Types.Scalars['DateTime']['input'];
  storeIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  supplierIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;

export type GetSpendReportOrdersQuery = {
  __typename?: 'Query';
  spendReports?: {
    __typename?: 'SpendReports';
    spendOrders?: {
      __typename?: 'SpendOrders';
      totalCount: number;
      items: Array<{
        __typename?: 'SpendReportOrder';
        customerNumber?: Types.Scalars['String']['input'] | null;
        datePaid: Types.Scalars['DateTime']['input'];
        grandTotal: Types.Scalars['BigDecimal']['input'];
        hash: Types.Scalars['String']['input'];
        id: string;
        numberOfItems: number;
        paymentWay: Types.PaymentWay;
        purchaseOrderNumber: Types.Scalars['String']['input'];
        store?: { __typename?: 'Store'; id: string; name: Types.Scalars['String']['input'] } | null;
        supplier?: { __typename?: 'Supplier'; id: string; name: Types.Scalars['String']['input'] } | null;
        user?: {
          __typename?: 'User';
          id: string;
          firstName: Types.Scalars['String']['input'];
          lastName: Types.Scalars['String']['input'];
        } | null;
      }>;
    } | null;
  } | null;
};

export const GetSpendReportOrdersDocument = `
    query GetSpendReportOrders($endDate: DateTime, $page: Int, $perPage: Int, $poNumber: String, $sortBy: SpendReportOrdersSortBy, $sortOrder: SortOrder, $startDate: DateTime!, $storeIds: [ID!], $supplierIds: [ID!]) {
  spendReports {
    spendOrders(
      endDate: $endDate
      page: $page
      perPage: $perPage
      poNumber: $poNumber
      sortBy: $sortBy
      sortOrder: $sortOrder
      startDate: $startDate
      storeIds: $storeIds
      supplierIds: $supplierIds
    ) {
      totalCount
      items {
        customerNumber
        datePaid
        grandTotal
        hash
        id
        numberOfItems
        paymentWay
        purchaseOrderNumber
        store {
          id
          name
        }
        supplier {
          id
          name
        }
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSpendReportOrders: build.query<GetSpendReportOrdersQuery, GetSpendReportOrdersQueryVariables>({
      query: (variables) => ({ document: GetSpendReportOrdersDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSpendReportOrdersQuery, useLazyGetSpendReportOrdersQuery } = injectedRtkApi;
