import { css } from '@emotion/react';
import { Box, GraphicTab } from '@partstech/ui';
import type { GraphicTabProps, Theme } from '@partstech/ui';
import type { PropsWithChildren } from 'react';

const styles = {
  tab: (theme: Theme) => css`
    .badge .graphic-tab-button-inner {
      max-width: ${theme.sizing(18)};
    }
  `,
};

type Props = PropsWithChildren<{
  adornmentBefore: GraphicTabProps<string>['adornmentBefore'];
  id: string;
  isDisabled?: boolean;
  isLoading: boolean;
  isSticky: boolean;
  total: number;
}>;

export const LocalInventoryTab = ({
  adornmentBefore,
  id,
  children,
  isDisabled,
  isLoading,
  isSticky,
  total,
  ...props
}: Props) => (
  <Box css={styles.tab}>
    <GraphicTab
      id={id}
      adornmentBefore={<Box pr={isSticky ? 6 : 0}>{adornmentBefore}</Box>}
      badge={{
        content: total,
        showZero: false,
        backgroundColor: 'lightBlue',
        borderColor: 'primary',
        color: 'primary',
        offsetX: isSticky ? -2 : 0,
        offsetY: isSticky ? 1.5 : 0,
        isStatic: true,
      }}
      isDisabled={isDisabled}
      isLoading={isLoading}
      variant={isSticky ? 'sticky' : 'default'}
      size={isSticky ? 'sticky' : 'small'}
      {...props}
    >
      {children}
    </GraphicTab>
  </Box>
);
