import { useMemo } from 'react';
import { usePermissions } from 'entities/permission';
import { PreferredBrandsMode } from 'shared/api';
import { useGetAutomaticPreferredBrands } from 'store/queries/currentUser/useAutomaticPreferredBrands';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useGetCustomPreferredBrands } from 'store/queries/currentUser/useGetCustomPreferredBrands';
import { usePreferredBrandsMode } from 'store/queries/currentUser/usePreferredBrandsMode';
import { unifyAutomaticBrands, unifyCustomBrands } from 'utils';
import type { BrandHookType } from 'types/preferredBrand';

type Props = {
  mode: PreferredBrandsMode;
  type: BrandHookType;
};

export const usePreferredBrands = ({ mode, type }: Props) => {
  const {
    shop: { preferredBrandsAllowed },
  } = usePermissions();
  const { hasCurrentUser } = useGetCurrentUser();
  const { partsPreferredBrandsMode, tiresPreferredBrandsMode } = usePreferredBrandsMode();

  const allowTiresFetch =
    hasCurrentUser && preferredBrandsAllowed && tiresPreferredBrandsMode !== PreferredBrandsMode.Disable;
  const allowPartsFetch = partsPreferredBrandsMode !== PreferredBrandsMode.Disable;
  const shouldFetch = mode !== PreferredBrandsMode.Disable && type === 'Tire' ? allowTiresFetch : allowPartsFetch;

  const {
    brands: customPreferredBrands,
    isLoading: isCustomLoading,
    isSuccess: isCustomLoaded,
    refetch: customBrandsRefetch,
  } = useGetCustomPreferredBrands(type, !shouldFetch);

  const {
    brands: automaticPreferredBrands,
    isSuccess: isAutomaticLoaded,
    isLoading: isAutomaticLoading,
    refetch: automaticBrandsRefetch,
  } = useGetAutomaticPreferredBrands(type, !shouldFetch);

  const isLoaded = mode === 'CUSTOM' ? isCustomLoaded : isAutomaticLoaded;
  const isLoading = mode === 'CUSTOM' ? isCustomLoading : isAutomaticLoading;

  const preferredBrands = useMemo(
    () =>
      mode === 'CUSTOM' ? unifyCustomBrands(customPreferredBrands) : unifyAutomaticBrands(automaticPreferredBrands),
    [automaticPreferredBrands, customPreferredBrands, mode]
  );

  const refetch = mode === 'CUSTOM' ? customBrandsRefetch : automaticBrandsRefetch;

  return {
    preferredBrands,
    isLoaded,
    isLoading,
    refetch,
  };
};
