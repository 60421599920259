import { Specification } from '../../../Specification';
import { AttributeTooltip } from '../AttributeTooltip';
import { TireSizeTranslation } from '../TireSizeTranslation';
import type { Attribute } from 'models';

type Props = {
  attribute: Attribute;
};

export const TireSizeAttribute = ({ attribute }: Props) => (
  <Specification
    label={attribute.label}
    value={attribute.getValue()}
    adornmentAfter={<AttributeTooltip tooltip={<TireSizeTranslation attribute={attribute} />} />}
  />
);
