import { Brand, Media, PartNumber, PartType, Product } from 'models';
import { SponsorType } from 'shared/api';
import { createAttributesFromQuery } from './createAttributesFromQuery';
import { createInterchangePart } from './createInterchangePart';
import { createLocalInventoryQuoteFromQuery } from './quote';
import type { SupplierAccount } from 'entities/supplierAccount';
import type { LocalInventoryProductFragment } from 'store/queries/searchResults/LocalInventoryProductFragment.generated';

type Params = {
  account?: SupplierAccount | null;
  isPreferred?: boolean;
  product: LocalInventoryProductFragment;
  showRetailPrice?: boolean;
  vehicleId?: string | null;
};

export const createBaseLocalInventoryProductFromQuery = ({
  account,
  isPreferred = false,
  product,
  showRetailPrice = false,
  vehicleId,
}: Params) => {
  const model = new Product({
    ...product,
    attributes: createAttributesFromQuery(product.attributes),
    bundles: [],
    coreCharge: null,
    credentialId: null,
    fetCharge: null,
    hasVehicleFitment: product.hasFitment,
    id: product.localInventoryUID,
    interchangePart: createInterchangePart(product.interchangeFor),
    lineCardId: null,
    listPrice: product.listPrice ?? null,
    localInventoryUID: product.localInventoryUID,
    mapPrice: null,
    notes: product.notes.map((note) => note.group),
    notesOem: [],
    price: product.price ?? null,
    quantityStep: 1,
    rebates: [],
    retailPrice: product.customerPrice ?? null,
    rewardPoints: null,
    shippingCharge: false,
    sortHash: product.sortHash ?? '',
    sponsoredPurchasesCount: null,
    sponsorType: SponsorType.None,
    stocked: false,
    stockMessage: null,
    variations: product.variations.map((variation) => ({
      ...variation,
      attributes: createAttributesFromQuery(variation.attributes),
    })),
    vehicleId: Number(vehicleId),
  });

  model.setMedia(
    new Media({
      images: product.images,
      image360Groups: product.image360Groups.map((group) => group.images),
      videos: product.videos,
    })
  );

  model.setShowRetailPrice(showRetailPrice);

  model.setPartNumber(new PartNumber({ partNumber: product.partNumber, id: product.partNumberId }));

  if (product.partType) {
    model.setPartType(new PartType(product.partType));
  }

  if (account?.supplier) {
    model.setSupplier(account.supplier);
  }

  if (product.brand) {
    model.setBrand(
      new Brand({
        id: product.brand.id,
        isPreferred,
        lineCode: product.brand.lineCode ?? null,
        logo: product.brand.logo ?? null,
        name: product.brand.name ?? null,
      })
    );
  }

  model.setQuote(createLocalInventoryQuoteFromQuery(product.quantity));

  return model;
};
