/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type SetDiyUserMutationVariables = Types.Exact<{ [key: string]: never }>;

export type SetDiyUserMutation = { __typename?: 'Mutation'; setDiyUser?: { __typename: 'SetDiyUserPayload' } | null };

export const SetDiyUserDocument = `
    mutation SetDiyUser {
  setDiyUser {
    __typename
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    SetDiyUser: build.mutation<SetDiyUserMutation, SetDiyUserMutationVariables | void>({
      query: (variables) => ({ document: SetDiyUserDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSetDiyUserMutation } = injectedRtkApi;
