import { useMemo } from 'react';
import { SortOrder } from 'shared/api';
import { parseTimeRange } from '../../lib/parseTimeRange';
import { api as generatedApi } from './GetPartReportPurchases.generated';
import type { GetPartReportPurchasesQuery } from './GetPartReportPurchases.generated';
import type { TimeRange } from '../../lib/useReportsFilters';
import type { TableSettings } from '../../lib/useTableSettings';
import type { PartReportSortBy } from 'shared/api';

type PartReport = NonNullable<GetPartReportPurchasesQuery['partReports']>;
export type PartReportPurchases = NonNullable<PartReport['partTypes']>;
export type PartReportPurchase = NonNullable<PartReportPurchases['partTypesStatistics']>[0];

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetPartReportPurchases: {
      providesTags: () => ['Report'],
    },
  },
});

const { useGetPartReportPurchasesQuery } = api;

type Props = {
  timeRange: TimeRange;
  locations: string[];
  tableSettings: TableSettings<PartReportSortBy>;
};

const emptyPurchases: PartReportPurchase[] = [];

export const useGetPartReportPurchases = ({ timeRange, locations, tableSettings }: Props) => {
  const { page, perPage, order, debounceSearchValue, sortBy } = tableSettings;

  const { fromDate, toDate } = useMemo(() => parseTimeRange(timeRange), [timeRange]);

  const { data, isLoading } = useGetPartReportPurchasesQuery({
    startDate: fromDate,
    endDate: toDate,
    storeIds: locations,
    page,
    perPage,
    sortBy,
    sortOrder: order === 'asc' ? SortOrder.Asc : SortOrder.Desc,
    search: debounceSearchValue,
  });

  const partPurchases = useMemo(() => data?.partReports?.partTypes?.partTypesStatistics || emptyPurchases, [data]);
  const totalCount = useMemo(() => data?.partReports?.partTypes?.totalCount || 0, [data]);

  return {
    partPurchases,
    totalCount,
    isLoading,
  };
};
