import { configureStore as configureReduxStore } from '@reduxjs/toolkit';
import { logOutListener } from 'features/logOut';
import { api, shopApi, gUploadApi, gExportApi } from 'shared/api';
import { unauthorizedMiddleware, permissionDeniedListener } from './middleware';
import type { Reducer } from '@reduxjs/toolkit';

export const configureStore = (reducer: Reducer) =>
  configureReduxStore({
    reducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false })
        .concat(unauthorizedMiddleware)
        .concat(api.middleware)
        .concat(shopApi.middleware)
        .concat(gUploadApi.middleware)
        .concat(gExportApi.middleware)
        .prepend(logOutListener.middleware)
        .prepend(permissionDeniedListener.middleware),
  });
