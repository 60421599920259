import { css } from '@emotion/react';
import { DateFormatter, DialogModal, Typography } from '@partstech/ui';
import { currency } from 'shared/lib/string';

export type MarkAsDeliveredModalProps = {
  date: string | null;
  orderId: string;
  poNumber?: string;
  totalPrice: number;
  onSubmit?: () => void;
  onClose?: () => void;
};

const styles = {
  id: css`
    word-break: break-word;
    word-wrap: break-word;
    font-weight: 700;
  `,
};

export const MarkAsDeliveredModal = ({
  date,
  orderId,
  onSubmit,
  onClose = () => {},
  poNumber,
  totalPrice,
}: MarkAsDeliveredModalProps) => {
  const displayNumber = poNumber || orderId;

  return (
    <DialogModal onClose={onClose} primaryText="Yes" onPrimary={onSubmit} secondaryText="No">
      <Typography variant="bodyText2">
        {date && <DateFormatter date={date} preset="full" />} {currency(totalPrice)}
      </Typography>

      <Typography>
        Are you sure you want to mark order <span css={styles.id}>#{displayNumber}</span>
        &nbsp; as delivered?
      </Typography>
    </DialogModal>
  );
};
