import { DateTime } from 'luxon';

export const getMonthBeforePrevious = () => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(date.getMonth() - 2);
  date.setHours(0, 0, 0, 0);

  return DateTime.fromJSDate(date).toISO() ?? '';
};
