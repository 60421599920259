import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './DeleteLaborItemsFromCart.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    DeleteLaborItemsFromCart: {
      onQueryStarted: async (input, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
            const { activeCart } = draft;

            if (!activeCart) {
              return;
            }

            if (
              data.batchDeleteActiveCartLaborItems &&
              'success' in data.batchDeleteActiveCartLaborItems &&
              data.batchDeleteActiveCartLaborItems.success &&
              typeof input.ids === 'object'
            ) {
              input.ids.forEach((id) => {
                Object.assign(activeCart, {
                  laborItems: activeCart.laborItems?.filter((item) => item.id !== id),
                });

                activeCart.orders?.forEach((order) => {
                  order.items?.forEach((orderItem) => {
                    Object.assign(orderItem, {
                      laborItems: orderItem.laborItems?.filter((item) => item.id !== id),
                    });
                  });
                });

                activeCart.localInventoryItems?.forEach((inventoryItem) => {
                  Object.assign(inventoryItem, {
                    laborItems: inventoryItem.laborItems?.filter((item) => item.id !== id),
                  });
                });
              });
            }
          })
        );
      },
    },
  },
});

const { useDeleteLaborItemsFromCartMutation } = api;

export const useDeleteLaborItemsFromCart = () => {
  const [mutate, status] = useDeleteLaborItemsFromCartMutation();

  useMutationStatus({ status });

  return [mutate, status] as const;
};
