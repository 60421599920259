import { Box, useModalHistory } from '@partstech/ui';
import { sortBy } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { ChooseLocationsSupplierCard } from 'components/ChooseLocationsSupplierCard';
import { StoreLogo } from 'entities/store';
import { useGetSupplierAccounts } from 'store/queries/currentUser/supplierAccounts/useGetSupplierAccounts';
import { SupplierExistsBanner } from '../../SupplierExistsBanner';
import type { CommonAccountFlowPaths } from '../../../types';
import type { Store } from 'entities/store';

export const ChooseLocation = () => {
  const { state: modalState, onClose, push } = useModalHistory<CommonAccountFlowPaths, 'chooseStores'>();

  const { selectedStores = [] } = modalState ?? {};

  const { getAccountsBySupplierId, getAccountByStoreId } = useGetSupplierAccounts();

  const isSupplierAccountExists = useMemo(
    () => getAccountsBySupplierId(selectedStores[0]?.supplier?.id ?? '').length > 0,
    [getAccountsBySupplierId, selectedStores]
  );

  const storeList = useMemo(
    () => sortBy(selectedStores, (additionalStore) => additionalStore.distance ?? 0),
    [selectedStores]
  );

  const handleAddAccount = useCallback(
    (selectedStore: Store) => () => {
      push('add', { selectedStore });
    },
    [push]
  );

  const handleDeleteAccount = useCallback(
    (store: Store) => () => {
      const account = getAccountByStoreId(store.id);

      if (!account) {
        return;
      }

      push('delete', { account });
    },
    [getAccountByStoreId, push]
  );

  const getSupplierAccountByStoreId = useCallback(
    (storeId: string) => getAccountByStoreId(storeId),
    [getAccountByStoreId]
  );

  if (!onClose) {
    return null;
  }

  const firstStore = selectedStores[0] ?? null;

  return (
    <Box px={3}>
      <StoreLogo store={firstStore} width={39} height={10} />

      {isSupplierAccountExists && <SupplierExistsBanner mb={2} />}

      <Box display="flex" flexDirection="column" gap={2}>
        {storeList.map((additionalStore) => (
          <ChooseLocationsSupplierCard
            key={additionalStore.id}
            store={additionalStore}
            account={getSupplierAccountByStoreId(additionalStore.id)}
            onAddClick={handleAddAccount(additionalStore)}
            onDeleteClick={handleDeleteAccount(additionalStore)}
          />
        ))}
      </Box>
    </Box>
  );
};
