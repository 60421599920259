import { isPreferredBrand } from './isPreferredBrand';
import type { PreferredBrandUnified } from 'types/preferredBrand';

export const getPreferredBrandsByProducts = <
  T extends {
    brand?: { id: string | null; name: string | null } | null;
    partTypeId?: string | null;
    partType?: { id?: string } | null;
  },
>(
  preferredBrands: PreferredBrandUnified[],
  products: T[],
  maxCount: number
) =>
  preferredBrands
    .filter((brand) =>
      products.some((product) =>
        isPreferredBrand([brand])(
          product.brand?.id ?? '',
          product.brand?.name ?? '',
          product.partType?.id ?? product.partTypeId ?? ''
        )
      )
    )
    .slice(0, maxCount);
