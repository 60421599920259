import { BaseStatus } from '../BaseStatus';
import type { AvailabilityStatusProps } from '../../AvailabilityDropdown';

type Props = AvailabilityStatusProps;

export const SpecialOrderStatus = ({ onClick, quantity, trailingIcon }: Props) => (
  <BaseStatus
    color="special"
    onClick={onClick}
    text={`${'Special Order'}${quantity ? ` (${quantity})` : ''}`}
    trailingIcon={trailingIcon}
  />
);
