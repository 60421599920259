import { useMutationCallback } from 'shared/api';
import { api as activeCartApi } from '../../queries/GetActiveCart.generated';
import { api as generatedApi } from './UpdateOrderNotes.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdateOrderNotes: {
      onQueryStarted: async ({ customNotes, orderId }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data.updateActiveCartOrderCustomNotes && 'updatedOrderId' in data.updateActiveCartOrderCustomNotes) {
          dispatch(
            activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
              draft?.activeCart?.orders?.forEach((order) => {
                if (order.id === orderId) {
                  Object.assign(order, { customNotes });
                }
              });
            })
          );
        }
      },
    },
  },
});

const { useUpdateOrderNotesMutation } = api;

export const useUpdateOrderNotes = () => useMutationCallback(useUpdateOrderNotesMutation);
