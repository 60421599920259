import { createUserFromData } from 'entities/user';
import { Member as MemberModel } from '../models/Member';
import { createMemberPermissionsFromData } from './createMemberPermissionsFromData';
import type { Member } from 'store/queries/currentUser/useGetMembers';

export const createMemberFromData = (shopMember: Member) => {
  const user = shopMember.user ? createUserFromData(shopMember.user) : null;

  if (!user) {
    return null;
  }

  const permissions = createMemberPermissionsFromData(shopMember.permissions ?? undefined);

  return new MemberModel({ ...shopMember, user, permissions });
};
