import type { ProductUrlParams } from 'types/product';

type ProductQueryParams = {
  partnumberid: string;
  part_term?: number | null;
  credentialId?: number | null;
  supplierId?: number | null;
  linecard?: number | null;
  vehicle?: number | null;
  localInventoryUID?: string;
};

export const urlParamsToQuery = (urlParams: ProductUrlParams): ProductQueryParams => ({
  partnumberid: encodeURIComponent(urlParams.partNumberId).replaceAll('%20', '+'),
  part_term: urlParams.partTypeId,
  credentialId: urlParams.credentialId,
  supplierId: urlParams.supplierId,
  linecard: urlParams.lineCardId,
  vehicle: urlParams.vehicle || undefined,
  localInventoryUID: urlParams.localInventoryUID,
});
