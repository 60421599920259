import { isNotNull } from '@partstech/ui/utils';
import { CartOrder } from 'models';
import { AccountStatus, OrderErrorType, OutageType, SupplierType } from 'shared/api';
import {
  createCartOrderItemFromActiveCart,
  createCartOrderItemFromActiveOrderItem,
  createCartOrderItemFromLocalInventory,
  createCartOrderItemFromPaidOrderLine,
} from './createCartOrderItem';
import type { Supplier } from 'entities/supplier';
import type { SupplierAccount } from 'entities/supplierAccount';
import type { Vehicle } from 'entities/vehicle';
import type { ActiveCartRawData, CartOrderFragment } from 'features/cart';
import type { ActiveOrderRawData, PaidOrder } from 'features/savedQuotes';
import type { AdditionalField, BundleDeal } from 'models';
import type {
  AdditionalFieldValue,
  AvailableAdditionalFields,
  CartBundle,
  OrderFieldValidation,
  Outage,
} from 'shared/api';

const getAdditionalFields = (
  additionalFields?: AdditionalFieldValue[] | null,
  availableAdditionalFields?: AvailableAdditionalFields[] | null
): AdditionalField[] => {
  if (!availableAdditionalFields) {
    return [];
  }

  const fieldValueMap = additionalFields?.reduce<Record<string, string>>(
    (acc, field) => ({ ...acc, [field.name]: field.value }),
    {}
  );

  return availableAdditionalFields.map((field) => ({
    default: field.default || undefined,
    label: field.label,
    name: field.name,
    options: field.options || [],
    required: field.required,
    type: field.type,
    value: fieldValueMap?.[field.name] || field.default || null,
  }));
};

const convertValidation = (validation?: OrderFieldValidation | null) => ({
  max: validation?.maxLength || null,
  min: validation?.minLength || null,
  regex: validation?.regex || null,
  message: validation?.message || null,
});

const getOrderError = (
  order: CartOrderFragment | ActiveOrderRawData,
  accountStatus?: AccountStatus,
  outage?: Outage | Supplier['outage'] | null,
  additionalFields: AdditionalField[] = [],
  orderTitle: string = ''
) => {
  if (accountStatus !== AccountStatus.Approved) {
    const supplierName =
      (order.account && 'supplier' in order.account && order.account.supplier?.name) || 'This supplier';
    return `${supplierName} is no longer connected to your PartsTech account.`;
  }

  if (outage?.type === OutageType.FullOutage) {
    return outage.message;
  }

  const quoteError = order.builtOrder?.errors.find(
    (error) => error.errorType !== OrderErrorType.AdditionalFields && error.errorType !== OrderErrorType.Shipping
  );

  if (quoteError?.message) {
    return quoteError.message;
  }

  const shippingError =
    order.builtOrder &&
    'selectedFrightTermService' in order.builtOrder &&
    (!order.builtOrder.selectedFrightTermService ||
      order.builtOrder.selectedFrightTermService.methodCode === 'hotshot_noarea')
      ? `${orderTitle} has a delivery error`
      : null;

  if (shippingError) {
    return shippingError;
  }

  const additionalFieldWithError = additionalFields.find((field) => field.required && !field.value);

  if (additionalFieldWithError) {
    return `Field ${additionalFieldWithError.label} is required`;
  }

  return null;
};

const getBundleDeal = (
  bundles: CartBundle[],
  itemId: string,
  items?: CartOrderFragment['items'] | ActiveOrderRawData['items'] | null
): BundleDeal | null => {
  const bundle = bundles.find((item) => item.items.some((i) => i.orderItemId === itemId));

  if (!bundle) {
    return null;
  }

  const bundleDealItems = bundle.items
    .map((bundleItem) => {
      const orderItem = items?.find((item) => item.id === bundleItem.orderItemId);
      if (!orderItem) {
        return null;
      }

      const itemPrice = orderItem.builtItem?.product?.price || 0;

      return {
        title: orderItem.partName,
        image: orderItem.image || '',
        partNumber: orderItem.partNumber,
        quantity: bundleItem.requiredQuantity * bundle.quantity,
        price: itemPrice,
        discountPrice: itemPrice - bundleItem.discount,
      };
    })
    .filter(isNotNull);

  const originalPrice = bundleDealItems.reduce((total, item) => total + item.price * item.quantity, 0);
  const savingsAmount = bundle.discount ?? 0;
  const paymentPrice = originalPrice - savingsAmount;

  return {
    items: bundleDealItems,
    originalPrice,
    paymentPrice,
    savingsAmount,
  };
};

const getOrderWarning = (outage?: Outage | Supplier['outage'] | null) => {
  if (outage?.type === OutageType.PartialOutage) {
    return outage.message;
  }

  return null;
};

export const createCartOrderFromLocalInventory = ({
  cart,
  vehicles = [],
  orderItemIdsToRemove = [],
}: {
  cart: ActiveCartRawData;
  vehicles?: Vehicle[];
  orderItemIdsToRemove?: string[];
}) => {
  const localInventoryItems = cart.localInventoryItems || [];
  const items = localInventoryItems
    ?.filter((item) => !orderItemIdsToRemove.includes(item.id))
    .map((item) => createCartOrderItemFromLocalInventory(item, vehicles));

  return new CartOrder({
    id: localInventoryItems[0]?.orderGroupId ?? '',
    isPaid: false,
    items,
    title: 'Inventory',
    totals: null,
  });
};

export const createCartOrderFromPaidOrder = (order: PaidOrder, vehicles: Vehicle[]) =>
  new CartOrder({
    id: order.id,
    title: order.supplier?.name || '',
    items: order.lines?.map((line) => createCartOrderItemFromPaidOrderLine(line, vehicles)) || [],
    poNumber: order.purchaseOrderNumber,
    isPaid: true,
    totals: null,
    lastTrackingDate: order.lastTrackingDate,
    trackingStatus: order.trackingStatus,
    placedDate: order.datePaid,
  });

export const createCartOrderFromActiveCart = ({
  order,
  vehicles = [],
  accounts,
  orderItemIdsToRemove = [],
}: {
  order: CartOrderFragment;
  vehicles?: Vehicle[];
  accounts: SupplierAccount[];
  orderItemIdsToRemove?: string[];
}) => {
  const supplierAccount = accounts.find((account) => account.id === order.account?.id);

  const availableShippingMethods = order.builtOrder?.availableFreightTerms?.map(({ name, frightTermService }) => ({
    name,
    charge: frightTermService?.charge || 0,
    code: frightTermService?.methodCode || '',
  }));

  const shippingMethodCode = order.builtOrder?.selectedFrightTermService?.methodCode || '';

  const shippingMethod = availableShippingMethods?.find((method) => method.code === shippingMethodCode);

  const additionalFields = getAdditionalFields(order.additionalFields, order.builtOrder?.availableAdditionalFields);

  const title = supplierAccount?.supplier?.name || order.account?.supplier?.name || '';

  const orderBundles = order.builtOrder?.bundles || [];

  const items =
    order.items
      ?.filter((item) => !orderItemIdsToRemove.includes(item.id))
      .map((item) => {
        const bundle = getBundleDeal(orderBundles, item.id, order.items);

        return createCartOrderItemFromActiveCart(item, vehicles, supplierAccount, bundle);
      }) || [];

  return new CartOrder({
    id: order.id,
    title,
    items,
    poNumber: order.purchaseOrderNumber,
    poNumberValidation: convertValidation(order.builtOrder?.purchaseOrderNumberValidation),
    notes: order.customNotes || '',
    notesValidation: convertValidation(order.builtOrder?.customNotesValidation),
    isPaid: false,
    error: getOrderError(order, supplierAccount?.status, supplierAccount?.supplier?.outage, additionalFields, title),
    warning: getOrderWarning(supplierAccount?.supplier?.outage),
    totals: {
      discountSum: order.builtOrder?.discountTotal || 0,
      shippingCharge: shippingMethod?.charge || 0,
      taxesCharge: order.builtOrder?.tax || 0,
    },
    supplierAccount,
    rewardPoint: order.builtOrder?.boschReward?.points,
    additionalFields,
    availableShippingMethods,
    shippingMethodCode,
    preferredShippingMethodCode: order.optionsPreferences?.shippingMethodCode || '',
    paymentWay: order.builtOrder?.paymentWay,
  });
};

export const createCartOrderFromActiveOrder = (order: ActiveOrderRawData, vehicles: Vehicle[]) => {
  const orderBundles = order.builtOrder?.bundles || [];

  const items =
    order.items?.map((item) => {
      const bundle = getBundleDeal(orderBundles, item.id, order.items);

      return createCartOrderItemFromActiveOrderItem(
        item,
        vehicles,
        order.supplier?.type === SupplierType.Dealer,
        order.account?.store?.id,
        order.account?.nickname,
        order.supplier?.id,
        bundle
      );
    }) || [];

  return new CartOrder({
    id: order.id,
    title: order.supplier?.name || '',
    items,
    poNumber: order.purchaseOrderNumber,
    isPaid: false,
    error: getOrderError(order, order.account?.status, order.supplier?.outageType),
    warning: getOrderWarning(order.supplier?.outageType),
    totals: {
      discountSum: order.builtOrder?.discountTotal || 0,
      shippingCharge: order.builtOrder?.shippingTotal || 0,
      taxesCharge: order.builtOrder?.tax || 0,
    },
  });
};
