import { Availability } from 'types/search';
import { createOption } from './createOption';
import type { Product } from 'models';
import type { FilterOption } from 'types/search';

const AvailabilityNames: Record<Exclude<Availability, Availability.SPECIAL_ORDER>, string> = {
  [Availability.IN_STOCK]: 'All',
  [Availability.OUT_OF_STOCK]: 'Out of Stock',
  [Availability.BACK_ORDER]: 'Backorder',
};

export const createStockOption =
  (count: number = 1) =>
  (product: Product): FilterOption => {
    if (product.quote?.isOutOfStock()) {
      return createOption(Availability.OUT_OF_STOCK, AvailabilityNames[Availability.OUT_OF_STOCK], count, 'checkbox');
    }

    if (product.quote?.isBackOrder()) {
      return createOption(Availability.BACK_ORDER, AvailabilityNames[Availability.BACK_ORDER], count, 'checkbox');
    }

    if (product.quote?.isInStock()) {
      return createOption(Availability.IN_STOCK, AvailabilityNames[Availability.IN_STOCK], count, 'radio');
    }

    return createOption('', '');
  };
