import { DateTime } from 'luxon';
import {
  getLastYear,
  getMonthBeforePrevious,
  getPreviousMonth,
  getPreviousWeek,
  getThisMonth,
  getThisWeek,
  getThisYear,
  getToday,
  getToLastYear,
  getToPreviousMonth,
  getToThisMonth,
  getToThisWeek,
  getToThisYear,
  getToToday,
  getYearBeforePrevious,
  getYesterday,
} from 'shared/lib/dates';
import type { RangeName } from './useReportsFilters';
import type { DateRangeOption } from '@partstech/ui';

export const parseTimeRange = (timeRange: DateRangeOption<RangeName>) => {
  if ('range' in timeRange) {
    return {
      fromPreviousDate: null,
      toPreviousDate: null,
      fromDate: timeRange.range.start ? (DateTime.fromJSDate(timeRange.range.start).toISO() ?? '') : getToday(),
      toDate: (timeRange.range.end && DateTime.fromJSDate(timeRange.range.end).toISO()) ?? '',
    };
  }

  switch (timeRange.rangeName) {
    case 'lastYear':
      return {
        fromPreviousDate: getYearBeforePrevious(),
        toPreviousDate: getToLastYear(),
        fromDate: getLastYear(),
        toDate: getToThisYear(),
      };
    case 'thisYear':
      return {
        fromPreviousDate: getLastYear(),
        toPreviousDate: getToThisYear(),
        fromDate: getThisYear(),
        toDate: undefined,
      };
    case 'lastMonth':
      return {
        fromPreviousDate: getMonthBeforePrevious(),
        toPreviousDate: getToPreviousMonth(),
        fromDate: getPreviousMonth(),
        toDate: getToThisMonth(),
      };
    case 'thisMonth':
      return {
        fromPreviousDate: getPreviousMonth(),
        toPreviousDate: getToThisMonth(),
        fromDate: getThisMonth(),
        toDate: undefined,
      };
    case 'lastWeek':
      return {
        fromPreviousDate: getPreviousWeek(),
        toPreviousDate: getToThisWeek(),
        fromDate: getThisWeek(),
        toDate: undefined,
      };
    default:
      return {
        fromPreviousDate: getYesterday(),
        toPreviousDate: getToToday(),
        fromDate: getToday(),
        toDate: undefined,
      };
  }
};
