/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetTireReportLocationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetTireReportLocationsQuery = {
  __typename?: 'Query';
  tireReports?: {
    __typename?: 'TireReports';
    tireSuppliers?: Array<{ __typename?: 'Supplier'; id: string; name: Types.Scalars['String']['input'] }> | null;
  } | null;
};

export const GetTireReportLocationsDocument = `
    query GetTireReportLocations {
  tireReports {
    tireSuppliers {
      id
      name
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetTireReportLocations: build.query<GetTireReportLocationsQuery, GetTireReportLocationsQueryVariables | void>({
      query: (variables) => ({ document: GetTireReportLocationsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetTireReportLocationsQuery, useLazyGetTireReportLocationsQuery } = injectedRtkApi;
