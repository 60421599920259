/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetTireReportsQueryVariables = Types.Exact<{
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  startDate: Types.Scalars['DateTime']['input'];
  grouping?: Types.InputMaybe<Types.ReportGrouping>;
  supplierIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  timeZone?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetTireReportsQuery = {
  __typename?: 'Query';
  tireReports?: {
    __typename?: 'TireReports';
    tireChart?: Array<{
      __typename?: 'ChartLine';
      day: number;
      month: number;
      tireSize: Types.Scalars['String']['input'];
      totalOrders: number;
      totalPrice: Types.Scalars['BigDecimal']['input'];
      totalQuantity: number;
      year: number;
    }> | null;
  } | null;
};

export const GetTireReportsDocument = `
    query GetTireReports($endDate: DateTime, $startDate: DateTime!, $grouping: ReportGrouping, $supplierIds: [ID!], $timeZone: String) {
  tireReports {
    tireChart(
      endDate: $endDate
      startDate: $startDate
      grouping: $grouping
      supplierIds: $supplierIds
      timeZone: $timeZone
    ) {
      day
      month
      tireSize
      totalOrders
      totalPrice
      totalQuantity
      year
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetTireReports: build.query<GetTireReportsQuery, GetTireReportsQueryVariables>({
      query: (variables) => ({ document: GetTireReportsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetTireReportsQuery, useLazyGetTireReportsQuery } = injectedRtkApi;
