import { css } from '@emotion/react';
import { Box, Button, Icon, Loader } from '@partstech/ui';
import { FormTextField, HookForm, useForm } from '@partstech/ui/forms';
import { useCallback, useEffect, useRef } from 'react';
import { VIN_ERROR } from 'constant';
import { useVehicleSearch } from '../../hooks/useVehicleSearch';
import { VehiclesList } from '../VehiclesList';
import type { Theme, BoxProps } from '@partstech/ui';
import type { Vehicle } from 'entities/vehicle';

type Props = {
  isActive?: boolean;
  defaultValues?: { vin?: string };
  onChange?: (vehicle: Vehicle | null) => void;
} & BoxProps;

type FormData = {
  vin: string;
};

const mobilePadding = 4;

const styles = {
  button: (theme: Theme) => css`
    margin-top: ${theme.sizing(2)};
    width: 100%;

    ${theme.breakpoints.down('md')} {
      position: fixed;
      left: ${theme.sizing(mobilePadding)};
      bottom: ${theme.sizing(mobilePadding)};
      width: calc(100vw - ${theme.sizing(mobilePadding * 2)});
    }
  `,
};

export const VinForm = ({ isActive, defaultValues, onChange, ...props }: Props) => {
  const ref = useRef<HTMLInputElement>(null);

  const form = useForm<FormData>({ defaultValues: { vin: defaultValues?.vin } });
  const { setError, setValue, watch } = form;

  const vin = watch('vin');

  const { vehicles, search, vehiclesNotFound, isFetching } = useVehicleSearch<FormData>({
    onChange,
  });

  const handleClear = useCallback(() => {
    if (onChange) {
      onChange(null);
    }
  }, [onChange]);

  useEffect(() => {
    if (vehiclesNotFound) {
      setError('vin', { type: 'server', message: VIN_ERROR });
    }
  }, [vehiclesNotFound, setError]);

  useEffect(() => {
    if (isActive) {
      ref.current?.focus();
    }
  }, [isActive]);

  useEffect(() => {
    setValue('vin', defaultValues?.vin ?? '');
  }, [defaultValues?.vin, setValue]);

  return (
    <Box {...props}>
      <HookForm form={form} onSubmit={search} data-testid="vinForm">
        <FormTextField
          maxLength={20}
          name="vin"
          isClearable={Boolean(vin)}
          onClear={handleClear}
          placeholder="Vehicle Identification Number (VIN)"
          adornmentAfter={vin ? undefined : <Icon name="search" />}
          ref={ref}
        />

        {isFetching && <Loader size="medium" display="flex" justifyContent="center" />}

        <Box mt={2} width="100%">
          <VehiclesList vehicles={vehicles} onItemClick={onChange} disabled={!isActive} />
        </Box>

        <div css={styles.button}>
          <Button disabled={!vin} fullWidth type="submit" data-testid="vinSearchButton">
            Search for vehicle
          </Button>
        </div>
      </HookForm>
    </Box>
  );
};
