import { Button } from '@partstech/ui';
import { useCallback } from 'react';
import { useSupportModal } from '../SupportModal';
import type { SupportFormData, SupportMode } from '../../types';
import type { ButtonProps } from '@partstech/ui';
import type { MouseEvent } from 'react';

type Props<TSupportMode extends SupportMode> = {
  mode: TSupportMode;
  defaultValues?: Omit<SupportFormData<TSupportMode>, 'notes'>;
} & ButtonProps;

export const SupportButton = <TSupportMode extends SupportMode>({
  mode = 'globalSupport' as TSupportMode,
  defaultValues,
  onClick,
  children,
  ...props
}: Props<TSupportMode>) => {
  const { open } = useSupportModal<TSupportMode>({ mode, defaultValues });

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      open();

      onClick?.(e);
    },
    [onClick, open]
  );

  return (
    <Button {...props} onClick={handleClick}>
      {children ?? 'Support'}
    </Button>
  );
};
