/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetSpendReportsQueryVariables = Types.Exact<{
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  grouping?: Types.InputMaybe<Types.ReportGrouping>;
  startDate: Types.Scalars['DateTime']['input'];
  storeIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  supplierIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  timeZone?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetSpendReportsQuery = {
  __typename?: 'Query';
  spendReports?: {
    __typename?: 'SpendReports';
    spendReport?: Array<{
      __typename?: 'SpendReport';
      day?: number | null;
      month: number;
      year: number;
      orderCount: number;
      orderSum: Types.Scalars['BigDecimal']['input'];
      totalOrders: number;
      totalPrice: Types.Scalars['BigDecimal']['input'];
      store?: { __typename?: 'Store'; id: string; name: Types.Scalars['String']['input'] } | null;
      supplier?: { __typename?: 'Supplier'; id: string; name: Types.Scalars['String']['input'] } | null;
      user?: {
        __typename?: 'User';
        id: string;
        firstName: Types.Scalars['String']['input'];
        lastName: Types.Scalars['String']['input'];
      } | null;
    }> | null;
  } | null;
};

export const GetSpendReportsDocument = `
    query GetSpendReports($endDate: DateTime, $grouping: ReportGrouping, $startDate: DateTime!, $storeIds: [ID!], $supplierIds: [ID!], $timeZone: String) {
  spendReports {
    spendReport(
      endDate: $endDate
      grouping: $grouping
      startDate: $startDate
      storeIds: $storeIds
      supplierIds: $supplierIds
      timeZone: $timeZone
    ) {
      day
      month
      year
      orderCount
      orderSum
      totalOrders
      totalPrice
      store {
        id
        name
      }
      supplier {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSpendReports: build.query<GetSpendReportsQuery, GetSpendReportsQueryVariables>({
      query: (variables) => ({ document: GetSpendReportsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSpendReportsQuery, useLazyGetSpendReportsQuery } = injectedRtkApi;
