import { RecentSearchType } from 'shared/api';
import type { Vehicle } from 'entities/vehicle';
import type {
  SearchCatalogRequestConvertable,
  SearchEntryPointInterface,
  SearchQuery,
  SearchQueryConvertable,
  SearchRequestConvertable,
  ToCatalogRequestParams,
  ToRequestParams,
} from 'features/searchForm';
import type { CatalogSearchInput, CreateRecentSearchInput, SearchInput } from 'shared/api';

const TIRE_KEYWORDS = ['tire', 'tires'];

export class KeywordSearch
  implements
    SearchEntryPointInterface,
    SearchQueryConvertable,
    SearchRequestConvertable,
    SearchCatalogRequestConvertable
{
  readonly keyword: string;

  constructor(keyword: string) {
    this.keyword = keyword;
  }

  isVehicleRequired(): boolean {
    return true;
  }

  populateInput(): string {
    return this.keyword;
  }

  get isTire(): boolean {
    return TIRE_KEYWORDS.some((tireKeyword) => tireKeyword === this.keyword.toLowerCase());
  }

  toQuery(): SearchQuery {
    return { part_text: this.keyword };
  }

  toRequest({ accountId, vehicleId, vin }: ToRequestParams): SearchInput {
    if (!vehicleId) {
      throw new Error('vehicleId is missing');
    }

    return {
      keyword: {
        accountId,
        keyword: this.keyword,
        vehicleId,
        vin,
      },
    };
  }

  toCatalogRequest({ vehicleId, vin }: ToCatalogRequestParams): CatalogSearchInput {
    if (!vehicleId) {
      throw new Error('vehicleId is missing');
    }

    return {
      keyword: {
        keyword: this.keyword,
        vehicleId,
        vin,
      },
    };
  }

  toRecentSearch(vehicle: Vehicle | null): CreateRecentSearchInput {
    return {
      type: RecentSearchType.Keyword,
      partText: { text: this.keyword, id: null },
      vehicleID: vehicle?.id,
      state: vehicle?.state,
      plate: vehicle?.plate,
      vin: vehicle?.vin,
    };
  }
}
