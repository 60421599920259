import { Button } from '@partstech/ui';
import type { ButtonProps } from '@partstech/ui';
import type { Store } from 'entities/store';

type Props = {
  store: Store;
  onClick?: () => void;
} & ButtonProps;

export const AddButton = ({ store, onClick, ...props }: Props) => (
  <Button variant="secondary" onClick={onClick} leadingIcon="add" data-testid="addButton" {...props} />
);
