import { isNotNull, sortUniq } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { api as generatedApi } from './GetSearchDiagrams.generated';
import type { Diagram } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['Diagram'],
  endpoints: {
    GetSearchDiagrams: {
      providesTags: (_response: unknown, _error: unknown, arg) => [{ type: 'Diagram', vehicleId: arg.vehicleID }],
    },
  },
});

const { useGetSearchDiagramsQuery } = api;

type Props = {
  vehicleId: string | null;
};

const emptyDiagrams: Diagram[] = [];

export const useGetSearchDiagrams = ({ vehicleId }: Props) => {
  const { data, ...props } = useGetSearchDiagramsQuery({ vehicleID: `${vehicleId}` }, { skip: !vehicleId });

  const diagrams = useMemo(() => {
    if (!vehicleId) {
      return emptyDiagrams;
    }

    return data?.vehicle?.diagrams ?? emptyDiagrams;
  }, [data?.vehicle?.diagrams, vehicleId]);

  const categories = useMemo(
    () => sortUniq(diagrams.map((diagram) => diagram.category).filter(isNotNull), 'name'),
    [diagrams]
  );

  return {
    ...props,
    categories,
    diagrams,
  };
};
