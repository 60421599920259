import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationCallback } from 'shared/api';
import { api as generatedApi } from './DeleteLocalInventoryItem.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    DeleteActiveCartLocalInventoryItem: {
      onQueryStarted: async ({ ids }, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (data?.deleteActiveCartLocalInventoryItem && 'success' in data.deleteActiveCartLocalInventoryItem) {
          dispatch(
            activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
              if (draft?.activeCart) {
                Object.assign(draft.activeCart, {
                  localInventoryItems: draft?.activeCart?.localInventoryItems?.filter((item) => !ids.includes(item.id)),
                });
              }
            })
          );
        }
      },
    },
  },
});

const { useDeleteActiveCartLocalInventoryItemMutation } = api;

export const useDeleteCartLocalInventoryItem = () => {
  const [mutate, status] = useMutationCallback(useDeleteActiveCartLocalInventoryItemMutation);

  return [mutate, status] as const;
};
