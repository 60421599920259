import { css } from '@emotion/react';
import { Box, Divider, ImageFallback, Typography, useMedia } from '@partstech/ui';
import { plural } from '@partstech/ui/utils';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { currency } from 'shared/lib/string';
import { InfoBand, InfoBandItem } from 'shared/ui';
import { parseTimeRange } from '../../lib/parseTimeRange';
import { useReportsFilters } from '../../lib/useReportsFilters';
import { ProductCardSkeleton } from './ProductCardSkeleton';
import type { PartTypeProduct } from '../../api/parts/useGetPartTypeProducts';
import type { TireProduct } from '../../api/tires/useGetTireProducts';

type Props = {
  isLoading: boolean;
  product?: PartTypeProduct | TireProduct;
};

const styles = {
  image: css`
    object-fit: contain;
  `,
};

export const ProductCard = ({ isLoading, product }: Props) => {
  const { isMobile } = useMedia();
  const { timeRange } = useReportsFilters();

  const purchasedEvery = useMemo(() => {
    if (!product) {
      return null;
    }

    if ('rangeName' in timeRange && timeRange.rangeName === 'today') {
      return `${product.totalQuantity} ${plural('purchase', product.totalQuantity)} today`;
    }

    const { fromDate, toDate } = parseTimeRange(timeRange);
    const start = DateTime.fromISO(fromDate);
    const end = toDate ? DateTime.fromISO(toDate) : DateTime.now();
    const diff = Math.ceil(end.diff(start, 'day').as('day'));
    return `${product.totalQuantity && Math.round((diff / product.totalQuantity) * 10) / 10} days`;
  }, [product, timeRange]);

  if (isLoading) {
    return <ProductCardSkeleton />;
  }

  if (!product) {
    return (
      <Box height={50} mx={6} mt={2}>
        <Typography variant="subtitle1">Most purchased</Typography>
        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
          <Typography color="mono40">Valuable metrics will appear here as you start placing orders</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box mx={isMobile ? 2 : 6} mt={isMobile ? 0 : 2}>
      <Typography variant="subtitle2">Most purchased</Typography>
      <Box display="flex" mt={2}>
        {!isMobile && (
          <Box mr={4}>
            <ImageFallback css={styles.image} width={120} height={120} src={product?.image} />
          </Box>
        )}

        <Box flexGrow={2}>
          <Box display="flex">
            {isMobile && (
              <Box mr={4}>
                <ImageFallback css={styles.image} width={80} height={80} src={product?.image} />
              </Box>
            )}

            <div>
              <Typography variant="tagline2">{product?.title}</Typography>

              <Typography variant="subtitle2" color="subtleText">
                {product.displayPartNumber || product?.partNumber}
              </Typography>
            </div>
          </Box>

          <Divider my={4} />

          <InfoBand itemsPerRow={isMobile ? 2 : 'auto'} marginBottom={5}>
            <InfoBandItem title="No. of purchases" titleVariant="bodyText2" uppercaseTitle={false}>
              <Typography variant="h3">{product?.totalQuantity}</Typography>
            </InfoBandItem>

            <InfoBandItem title="Purchased every" titleVariant="bodyText2" uppercaseTitle={false}>
              <Typography variant="h3">{purchasedEvery}</Typography>
            </InfoBandItem>

            <InfoBandItem title="Avg price" titleVariant="bodyText2" uppercaseTitle={false}>
              <Typography variant="h3">
                {product?.totalPrice && product.totalQuantity && currency(product.totalPrice / product.totalQuantity)}
              </Typography>
            </InfoBandItem>

            <InfoBandItem title="Most common supplier" titleVariant="bodyText2" uppercaseTitle={false}>
              <ImageFallback
                height={24}
                src={product.mostCommonSupplier?.logo || undefined}
                data-testid="supplierLogo"
              />
            </InfoBandItem>
          </InfoBand>
        </Box>
      </Box>
    </Box>
  );
};
