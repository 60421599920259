import { useMemo } from 'react';
import { parseTimeRange } from '../../lib/parseTimeRange';
import { api as generatedApi } from './GetPartTypeReports.generated';
import type { GetPartTypeReportsQuery } from './GetPartTypeReports.generated';
import type { TimeRange } from '../../lib/useReportsFilters';

type PartReports = NonNullable<GetPartTypeReportsQuery['partReports']>;
export type PartTypeReport = PartReports['partType'];

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetPartTypeReports: {
      providesTags: () => ['Report'],
    },
  },
});

const { useGetPartTypeReportsQuery } = api;

type Props = {
  timeRange: TimeRange;
  locations: string[];
  partTypeId: string;
};

export const useGetPartTypeReports = ({ timeRange, locations, partTypeId }: Props) => {
  const { fromDate, toDate, fromPreviousDate, toPreviousDate } = useMemo(() => parseTimeRange(timeRange), [timeRange]);

  const { data, isLoading } = useGetPartTypeReportsQuery({
    partTypeId,
    startDate: fromDate,
    endDate: toDate,
    storeIds: locations,
  });

  const skipPrev = !fromPreviousDate || !toPreviousDate;

  const { data: prevData, isLoading: isPrevLoading } = useGetPartTypeReportsQuery(
    {
      partTypeId,
      startDate: fromPreviousDate || '',
      endDate: toPreviousDate,
      storeIds: locations,
    },
    {
      skip: skipPrev,
    }
  );

  return {
    reports: data?.partReports?.partType,
    prevReports: skipPrev ? undefined : prevData?.partReports?.partType,
    isLoading: isLoading || isPrevLoading,
  };
};
