import { useMutationCallback } from 'shared/api';
import { api as generatedApi } from './DeleteLaborRate.generated';
import type { DeleteLaborRateMutation } from './DeleteLaborRate.generated';
import type { UseMutationCallbackArgs } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    DeleteLaborRate: {
      invalidatesTags: ['LaborRate'],
    },
  },
});

const { useDeleteLaborRateMutation } = api;

export const useDeleteLaborRate = (args?: UseMutationCallbackArgs<DeleteLaborRateMutation, {}>) =>
  useMutationCallback(useDeleteLaborRateMutation, args);
