import { css } from '@emotion/react';

export const styles = {
  wrapper: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    flex-grow: 1;
  `,
  mediaWrapper: (shouldAspectRatio: boolean, mediaRatio: 'fixed' | 'flexible') => css`
    overflow: hidden;
    max-height: 88dvh;
    height: 100%;
    width: 100%;
    ${shouldAspectRatio && `aspect-ratio: ${mediaRatio === 'fixed' ? '1' : '16 / 9'}`};
  `,
  video: css`
    width: 100%;
    height: 100%;
    border: 0;
  `,
  image: (hasImageViewer: boolean = false) => css`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;

    ${hasImageViewer && 'cursor: pointer;'};
  `,
};
