/**
 * Extracts the original Ambassador referral code by removing Facebook's tracking suffix.
 *
 * When referral codes are shared through Facebook, Facebook automatically appends tracking
 * parameters after an underscore (_) to monitor campaign performance. However, the Ambassador
 * platform requires the original clean referral code for proper validation and attribution.
 *
 * This function solves the integration issue between Facebook tracking and Ambassador's
 * requirements by extracting just the original part of the referral code before any underscore.
 *
 * @param {string} referralCode - The raw referral code which may contain Facebook tracking suffix
 * @returns {string} The original referral code without any tracking parameters
 *
 * @example
 * // Returns "ABC123" - Facebook added tracking ID removed
 * denormalizeReferralCode("ABC123_45678")
 *
 * @example
 * // Returns "REFERRAL2024" - Facebook prefix identifier removed
 * denormalizeReferralCode("REFERRAL2024_fb123456")
 *
 * @example
 * // Returns "SUMMER" - Already clean code remains unchanged
 * denormalizeReferralCode("SUMMER")
 */
export const denormalizeReferralCode = (referralCode: string) => referralCode.split('_')[0] ?? '';
