import { usePrevious } from '@partstech/ui/hooks';
import { memo } from 'react';
import { AuthorizedProvider } from 'app/contexts/AuthorizedProvider';
import { ErrorFallback } from 'app/ErrorBoundary/ErrorFallback';
import { useIsInvitationPage } from 'hooks/invitations';
import { useFullStory } from 'integrations/fullstory';
import { useUpdateSentryUser } from 'integrations/sentry';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useOnboardSteps } from 'store/queries/currentUser/useOnboardSteps';
import { LayoutSkeleton } from './LayoutSkeleton';
import { useLogOutListener } from './useLogOutListener';
import { useModalCloser } from './useModalCloser';
import { useRedirectToSupplierPortal } from './useRedirectToSupplierPortal';
import type { PropsWithChildren } from 'react';

export const AppProvider = memo(({ children }: PropsWithChildren) => {
  const { isSuccess, accountId, email, username, hasCurrentUser, isError, isSupplier } = useGetCurrentUser();

  const { isOnboardCompleted } = useOnboardSteps();

  const previousHasCurrentUser = usePrevious(hasCurrentUser);

  const { isIdentified: isIdentifiedFullstory } = useFullStory();

  useUpdateSentryUser(
    { accountId: accountId ?? '', email: email ?? '', username: username ?? '' },
    { skip: !isSuccess }
  );

  const isInvitationPage = useIsInvitationPage();

  useModalCloser({ skip: hasCurrentUser === previousHasCurrentUser });
  useLogOutListener();

  useRedirectToSupplierPortal();

  if (isError) {
    return <ErrorFallback askRefresh={false} showButton />;
  }

  if (!isSuccess) {
    return <LayoutSkeleton />;
  }

  if (isSupplier) {
    return null;
  }

  if ((!isSupplier && !isOnboardCompleted && hasCurrentUser) || isInvitationPage) {
    return children;
  }

  return <AuthorizedProvider isIdentifiedFullstory={isIdentifiedFullstory}>{children}</AuthorizedProvider>;
});
