import { css } from '@emotion/react';
import { Typography, DataTable, Avatar, Tooltip, Link, Box, useMedia, Card, DateFormatter } from '@partstech/ui';
import { useOpen } from '@partstech/ui/hooks';
import { useMemo } from 'react';
import { TABLE_LIMIT_OPTIONS, Routes } from 'constant';
import { useGetAddresses } from 'entities/address';
import { usePermissions } from 'entities/permission';
import { createUserFromData } from 'entities/user';
import { PaymentWay, SpendReportOrdersSortBy } from 'shared/api';
import { currency } from 'shared/lib/string';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { generateUrl } from 'utils/url';
import { MobileTableButton } from '../../MobileTableButton';
import { TableSearch } from '../../TableSearch';
import type { SpendReportOrder } from '../../../api/spend/useGetSpendReportOrders';
import type { TableSettings } from '../../../lib/useTableSettings';
import type { DataTableProps } from '@partstech/ui';

const styles = {
  cell: css`
    word-break: break-word;
  `,
};

type RowType = SpendReportOrder;

const getColumns = (isMobile: boolean, ordersPermission: boolean, userId: number | null, timeZone?: string | null) => {
  const columns: DataTableProps<RowType, SpendReportOrdersSortBy>['columns'] = [
    {
      label: 'Date/time',
      render: (row) => (
        <Typography variant={isMobile ? 'bodyText2' : 'bodyText1'}>
          <DateFormatter date={row.datePaid} timeZone={timeZone} />
        </Typography>
      ),
      sort: SpendReportOrdersSortBy.DatePaid,
      width: '2230px',
    },
    {
      label: 'Supplier',
      render: (row) => (
        <Typography variant={isMobile ? 'bodyText2' : 'bodyText1'}>
          {row.supplier?.name} - {row.store?.name}
        </Typography>
      ),
      width: '2760px',
    },
    {
      label: 'Order',
      render: (row) => {
        if (!row.user) {
          return null;
        }

        return ordersPermission || String(userId) === row.user.id ? (
          <Link
            to={generateUrl(Routes.ORDER, { userId: row.user.id, hash: row.hash })}
            variant={isMobile ? 'bodyText2' : 'bodyText1'}
            underline="hover"
            color="linkText"
            css={styles.cell}
          >
            {row.purchaseOrderNumber || row.id}
          </Link>
        ) : (
          <Tooltip tooltip="You only have permission to view your own data" verticalAlign="center" css={styles.cell}>
            {row.purchaseOrderNumber || row.id}
          </Tooltip>
        );
      },
      width: '2400px',
    },
    {
      label: 'User',
      render: (row) => {
        if (!row.user) {
          return null;
        }

        const user = createUserFromData(row.user);

        return (
          <Box display="flex">
            <Tooltip tooltip={user.getFullName()} verticalAlign="center">
              <Avatar initials={user.getInitials()} />
            </Tooltip>
          </Box>
        );
      },
      width: '910px',
    },
    {
      label: 'Payment method',
      render: (row) => (
        <Typography variant={isMobile ? 'bodyText2' : 'bodyText1'}>
          {row.paymentWay === PaymentWay.CreditCard ? 'Credit card' : 'Wholesale'}
        </Typography>
      ),
      width: '1400px',
      initiallyHidden: true,
    },
    {
      label: 'No. of items',
      render: (row) => <Typography variant={isMobile ? 'bodyText2' : 'bodyText1'}>{row.numberOfItems}</Typography>,
      width: '1000px',
      initiallyHidden: true,
    },
    {
      label: 'Total',
      render: (row) => (
        <Typography variant={isMobile ? 'bodyText2' : 'bodyText1'}>{currency(row.grandTotal)}</Typography>
      ),
      width: '1300px',
    },
  ];

  return columns;
};

type Props = {
  tableSettings: TableSettings<SpendReportOrdersSortBy>;
  orders: SpendReportOrder[];
  totalCount: number;
  isLoading: boolean;
};

export const OrderList = ({ tableSettings, orders, totalCount, isLoading }: Props) => {
  const { isMobile, isPrint } = useMedia();

  const { isOpen: showTable, open: handleOpenTable, close: handleCloseTable } = useOpen();

  const { accountId } = useGetCurrentUser();
  const { defaultAddress } = useGetAddresses();
  const {
    member: { allOrdersAllowed },
  } = usePermissions();

  const columns = useMemo(
    () => getColumns(isMobile, allOrdersAllowed, Number(accountId), defaultAddress?.timeZoneId),
    [isMobile, allOrdersAllowed, accountId, defaultAddress?.timeZoneId]
  );

  const {
    page,
    perPage,
    sortBy,
    order,
    searchValue,
    updatePage,
    updatePerPage,
    updateSearchValue,
    updateSortAndOrder,
  } = tableSettings;

  return (
    <Box mt={isMobile ? 2 : 10}>
      {isMobile && <MobileTableButton title="Orders" onClick={handleOpenTable} />}
      {!isMobile && <Typography variant="h3">Orders</Typography>}

      <Card mt={4} mb={10}>
        {(!isMobile || isPrint || showTable) && (
          <DataTable
            name="Orders"
            title="Orders"
            data-testid="table"
            columns={columns}
            isLoading={isLoading}
            error={null}
            sort={sortBy}
            order={order}
            setSortAndOrder={updateSortAndOrder}
            rows={orders}
            onToggle={handleCloseTable}
            page={page}
            tableHeader={<TableSearch value={searchValue} onChange={updateSearchValue} />}
            setPage={updatePage}
            limit={perPage}
            setLimit={updatePerPage}
            limitOptions={TABLE_LIMIT_OPTIONS}
            total={totalCount}
            noRowsMessage="Valuable metrics will appear here as you start placing orders"
            saveTableSettings
          />
        )}
      </Card>
    </Box>
  );
};
