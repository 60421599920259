import { useMedia } from '@partstech/ui';
import { Region } from 'entities/vehicle';
import { OptionsList } from '../../OptionsList';
import { useParametersList } from '../useParametersList';
import { useGetSubmodels } from './useGetSubmodels';
import type { CommonParametersListProps } from '../types';
import type { VehicleRegion } from 'shared/api';

type Option = {
  id: string;
  name: string;
  regions?: VehicleRegion[];
};

export const SubmodelsList = ({
  searchValue,
  onChange,
  isUserFocused,
  submodelId: selectedValue,
  ...props
}: CommonParametersListProps) => {
  const { isMobile } = useMedia();

  const { submodels } = useGetSubmodels({ searchValue, ...props });
  const { updateValue } = useParametersList({
    fieldName: 'submodelId',
    options: submodels,
    onChange,
    isUserFocused,
    searchValue,
    selectedValue,
  });

  return (
    <OptionsList
      options={submodels}
      adornmentAfter={(option: Option) => (option.regions ? <Region regions={option.regions} /> : null)}
      onClick={updateValue}
      columnCount={isMobile ? 1 : undefined}
    />
  );
};
