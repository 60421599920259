import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { OnboardStepType } from 'shared/api';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useOnboardSteps } from 'store/queries/currentUser/useOnboardSteps';
import { getSignUpUrl, SignUpPages } from 'utils/url';
import { useCreateShopContext } from '../CreateShopContext/CreateShopContext';

type StepConditions = Partial<Record<SignUpPages, boolean>>;

export const useLayout = () => {
  const { isStepCompleted } = useOnboardSteps();
  const { managementSystem } = useGetCurrentUser();

  const { activeStep, isDuplicatedShopStep, nextStep, shopType, navigateToNextStep } = useCreateShopContext();

  const isRestrictedPage = Boolean(useMatch(getSignUpUrl(SignUpPages.Restricted)));

  const { shop } = useGetCurrentUser();

  const nextStepName = nextStep?.name.toLowerCase() ?? '';

  const stepConditions: StepConditions = useMemo(
    () => ({
      [SignUpPages.ShopType]: Boolean(shopType) || isStepCompleted(OnboardStepType.ShopType),
      [SignUpPages.ShopDetails]: Boolean(shop),
      [SignUpPages.ManagementSystem]: Boolean(managementSystem),
      [SignUpPages.PartsSuppliers]: isStepCompleted(OnboardStepType.Parts),
      [SignUpPages.TireSuppliers]: isStepCompleted(OnboardStepType.Tires),
      [SignUpPages.Role]: isStepCompleted(OnboardStepType.PrimaryRole),
      [SignUpPages.HearAboutUs]: isStepCompleted(OnboardStepType.Referral),
    }),
    [managementSystem, shop, shopType, isStepCompleted]
  );

  const showNextButton = useMemo(() => {
    if (activeStep?.id && activeStep.id in stepConditions) {
      return stepConditions[activeStep.id];
    }

    return Boolean(nextStepName);
  }, [activeStep?.id, nextStepName, stepConditions]);

  return {
    isShowStepDescription: !isDuplicatedShopStep && !isRestrictedPage,
    nextStepName,
    navigateToNextStep,
    showNextButton,
  };
};
