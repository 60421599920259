import { useMemo } from 'react';
import { createVehicleFromQuery } from '../../lib/createVehicleFromQuery';
import { api as generatedApi } from './GetVehicleById.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetVehicleById: {
      providesTags: ['VehicleById'],
    },
  },
});

const { useGetVehicleByIdQuery } = api;

export const useGetVehicleById = (id: string | null, skip: boolean = false) => {
  const { currentData, ...props } = useGetVehicleByIdQuery({ id: String(id) }, { skip: skip || !id });

  const vehicle = useMemo(() => createVehicleFromQuery(currentData?.vehicle), [currentData?.vehicle]);

  return {
    vehicle,
    ...props,
  };
};
