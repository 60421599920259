import { entries, isNotNull } from '@partstech/ui/utils';
import { useCallback, useMemo } from 'react';
import { usePermissions } from 'entities/permission';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import type { MyShopPageAlias } from 'utils/url';

export const useMyShopAvailablePages = () => {
  const { isAllowedToModifySuppliers } = useGetCurrentUser();

  const {
    shop: { retailPricingAllowed },
    member: {
      boschRewardsAllowed,
      paymentsAllowed,
      shippingAllowed,
      retailPricingAllowed: retailPricingMemberAllowed,
      brandPreferencesAllowed,
      subscriptionAllowed,
    },
    isPrimary,
  } = usePermissions();

  const hasRetailPricing = useMemo(() => {
    const isRetailPricingAllowed = retailPricingAllowed ? retailPricingMemberAllowed : !retailPricingMemberAllowed;

    return isPrimary || isRetailPricingAllowed;
  }, [isPrimary, retailPricingMemberAllowed, retailPricingAllowed]);

  const pages: Record<MyShopPageAlias, boolean> = useMemo(
    () => ({
      details: true,
      users: isPrimary,
      'cart-shipping': shippingAllowed,
      suppliers: isAllowedToModifySuppliers,
      payments: paymentsAllowed,
      'retail-pricing': hasRetailPricing,
      'brand-preferences': isPrimary || brandPreferencesAllowed,
      rewards: boschRewardsAllowed,
      subscription: subscriptionAllowed,
    }),
    [
      boschRewardsAllowed,
      brandPreferencesAllowed,
      hasRetailPricing,
      isAllowedToModifySuppliers,
      isPrimary,
      paymentsAllowed,
      shippingAllowed,
      subscriptionAllowed,
    ]
  );

  const availablePages = useMemo(
    () =>
      entries(pages)
        .map(([alias, isAvailable]) => (isAvailable ? alias : null))
        .filter(isNotNull),
    [pages]
  );

  const isAvailable = useCallback((page: MyShopPageAlias) => Boolean(pages[page]), [pages]);

  return { availablePages, isAvailable };
};
