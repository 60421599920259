import { sortBy } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { api as generatedApi } from './GetTireReportLocations.generated';
import type { GetTireReportLocationsQuery } from './GetTireReportLocations.generated';

type Reports = NonNullable<GetTireReportLocationsQuery['tireReports']>;
export type TireReportLocation = NonNullable<Reports['tireSuppliers']>[0];
type Location = { id: string; name: string };

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetTireReportLocations: {
      providesTags: () => ['Report'],
    },
  },
});

const { useGetTireReportLocationsQuery } = api;

const emptyLocations: Location[] = [];

export const useGetTireReportLocations = () => {
  const { data, ...rest } = useGetTireReportLocationsQuery();

  const locations = useMemo<Location[]>(() => {
    const entities = data?.tireReports?.tireSuppliers;
    return entities ? sortBy(entities, ({ name }) => `${name}`.toLocaleLowerCase()) : emptyLocations;
  }, [data]);

  return {
    ...rest,
    locations,
  };
};
