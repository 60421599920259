import { useModalHistory } from '@partstech/ui';
import { useCallback, useState } from 'react';
import { useMutationCallback } from 'shared/api';
import { useChangePaymentCardMutation } from '../../../api/changeCard';
import type { PaymentCardModalPaths, PaymentCardModalData } from '../router';
import type { PaymentCard } from 'entities/paymentCard';

export const useChangePaymentMethod = () => {
  const { push, reset, goBack, onClose, data, setData, state } = useModalHistory<
    PaymentCardModalPaths,
    'changePaymentMethod',
    PaymentCardModalData
  >();

  const { paymentMethod: defaultCard } = data;

  const [selectedCard, setSelectedCard] = useState<null | PaymentCard>(defaultCard);

  const isExpired = selectedCard?.isExpired ?? false;

  const openAddNewCardModal = useCallback(() => {
    push('addCard', state);
  }, [push, state]);

  const openEditCardModal = useCallback(
    (card: PaymentCard) => {
      setData({ card, paymentMethod: defaultCard });

      push('editCard', state);
    },
    [defaultCard, push, setData, state]
  );

  const handleChangedCard = useCallback(() => {
    if (!selectedCard || !defaultCard) {
      return;
    }

    const cardForRemoving = defaultCard;

    setData({ card: selectedCard, paymentMethod: selectedCard });

    reset('paymentCardUpdated', { ...state, stripeKey: state?.stripeKey ?? '', cardForRemoving });
  }, [defaultCard, reset, selectedCard, setData, state]);

  const [changePaymentCard, { isLoading }] = useMutationCallback(useChangePaymentCardMutation, {
    onSuccess: handleChangedCard,
    onError: onClose,
  });

  const openUpdatePaymentMethodModal = useCallback(async () => {
    if (!selectedCard) {
      return;
    }

    if (defaultCard?.isDefault) {
      changePaymentCard({ input: { cardId: selectedCard.id ?? '' } });
      return;
    }

    handleChangedCard();
  }, [selectedCard, defaultCard?.isDefault, handleChangedCard, changePaymentCard]);

  const isDisabled = isLoading || selectedCard?.id === defaultCard?.id || isExpired;

  return {
    selectedCard,
    setSelectedCard,
    openAddNewCardModal,
    openEditCardModal,
    openUpdatePaymentMethodModal,
    onCancel: goBack,
    isDisabled,
    isLoading,
    showAddNewCardButton: state?.showAddNewCardButton ?? false,
  };
};
