import { gExportApi } from 'shared/api';

type Params = {
  from: string;
  to?: string | null;
  supplierIds?: string | null;
  search?: string | null;
  sortBy?: 'tireSize' | 'totalPrice' | 'totalQuantity' | null;
  order?: 'asc' | 'desc' | null;
};

const api = gExportApi.injectEndpoints({
  endpoints: (build) => ({
    GetTireReportExport: build.query<string, Params>({
      query: (params) => ({ method: 'GET', url: '/tire-report/sizes', params }),
      serializeQueryArgs: (params) => params,
      providesTags: () => ['ExportedReport'],
    }),
  }),
});

export const { useLazyGetTireReportExportQuery } = api;
