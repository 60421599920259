import { targetSupplierIds } from 'constant';
import { DealerQuote, Quote } from 'models';
import { DisplayAction, OrderType } from 'shared/api';
import { createAvailabilityLineFromQuery } from './availability';
import type { SupplierAccount } from 'entities/supplierAccount';
import type { Product } from 'models';
import type { BaseProductFragment } from 'store/queries/searchResults/BaseProductFragment.generated';

const createQuoteStatus = (
  product: BaseProductFragment,
  hasAvailabilityInStock: boolean,
  displayAction?: DisplayAction
): Quote['status'] => {
  switch (true) {
    case (product.orderType === OrderType.Regular && hasAvailabilityInStock) ||
      (product.orderType === OrderType.Backorder && hasAvailabilityInStock) ||
      (product.orderType === OrderType.None && hasAvailabilityInStock && displayAction === DisplayAction.No):
      return 'IN_STOCK';

    case product.orderType === OrderType.Backorder:
      return 'BACK_ORDER';

    case product.orderType === OrderType.SpecialOrder:
      return 'SPECIAL_ORDER';

    default:
      return 'OUT_OF_STOCK';
  }
};

const createQuoteAction = (
  status: Quote['status'],
  orderType: BaseProductFragment['orderType'],
  displayAction?: DisplayAction,
  allowStoreSelect?: boolean,
  hasAvailabilityInStock?: boolean
): Quote['action'] => {
  const isCheckAvailability = () => {
    if (!allowStoreSelect) {
      return false;
    }

    if (!hasAvailabilityInStock) {
      return false;
    }

    return orderType === OrderType.CheckAvailability;
  };

  const getActions = (): {
    addToCartAction: Quote['action'];
    outOfStockAction: Quote['action'];
  } => {
    if (isCheckAvailability()) {
      return {
        addToCartAction: 'CHECK_AVAILABILITY',
        outOfStockAction: 'CALL_AVAILABILITY',
      };
    }

    if (displayAction === DisplayAction.Yes) {
      return {
        addToCartAction: 'ADD_TO_CART',
        outOfStockAction: 'CALL_AVAILABILITY',
      };
    }

    if (displayAction === DisplayAction.No || displayAction === DisplayAction.PriceOnly) {
      return {
        addToCartAction: 'CALL_SUPPLIER',
        outOfStockAction: 'CALL_SUPPLIER',
      };
    }

    return {
      addToCartAction: 'HIDDEN',
      outOfStockAction: 'HIDDEN',
    };
  };

  const { addToCartAction, outOfStockAction } = getActions();

  switch (true) {
    case status === 'IN_STOCK':
      return addToCartAction;

    case status === 'BACK_ORDER':
      return addToCartAction;

    case status === 'SPECIAL_ORDER':
      return 'HIDDEN';

    case status === 'OUT_OF_STOCK':
      return outOfStockAction;

    default:
      return 'HIDDEN';
  }
};

export const createQuoteFromQuery = (
  product: BaseProductFragment,
  stockMessage: Product['stockMessage'],
  account?: SupplierAccount | null
) => {
  if (!account) {
    return null;
  }

  const hasAvailabilityInStock = product.availability.some((item) => item.quantity > 0);

  const status = createQuoteStatus(product, hasAvailabilityInStock, account?.supplier?.displayAction ?? undefined);

  const action = createQuoteAction(
    status,
    product.orderType,
    account?.supplier?.displayAction ?? undefined,
    account?.supplier?.allowStoreSelect,
    hasAvailabilityInStock
  );

  const variant = Quote.getVariant(
    product.availability.map((line) => line.group ?? ''),
    status,
    targetSupplierIds.includes(Number(account.supplier?.id))
  );

  const lines =
    product.availability?.map((line) =>
      createAvailabilityLineFromQuery(line, account?.store?.id, account?.nickname, variant)
    ) ?? [];

  if (account?.supplier?.isDealer) {
    return new DealerQuote({ action, status, lines, stockMessage, variant });
  }

  return new Quote({ action, status, lines, stockMessage, variant });
};
