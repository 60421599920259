import { Box, Typography } from '@partstech/ui';
import { CredentialsWrapper } from '../../CredentialsWrapper';
import { TireConnectBanner } from '../../TireConnectBanner';
import { FormActions } from './FormActions';
import type { SupplierAccount } from 'entities/supplierAccount';

type Props = {
  account: SupplierAccount;
  onCancel: () => void;
  onSubmit: () => void;
};

export const StopProcessing = ({ account, onCancel, onSubmit }: Props) => {
  const { store, supplier } = account;

  if (!supplier || !store) {
    return null;
  }

  return (
    <CredentialsWrapper store={store} actions={<FormActions onSubmit={onSubmit} onCancel={onCancel} />}>
      <Box mt={{ sm: 6, md: 0 }} mb={5}>
        <Box mb={4}>
          <Typography>
            <Typography variant="subtitle1" component="span">
              {supplier.name} {store.name ?? ''}
            </Typography>{' '}
            is reviewing your connection request, and may still approve it.
          </Typography>
        </Box>

        <Typography>
          Only click "Stop processing" if you are sure you no longer want to connect this supplier. Otherwise we'll
          reach out to you once the supplier reviews your request.
        </Typography>
      </Box>

      {supplier.isTire && <TireConnectBanner supplier={supplier} />}
    </CredentialsWrapper>
  );
};
