import { useForm } from '@partstech/ui/forms';
import { useCallback } from 'react';
import { useFormMutationCallback } from 'shared/lib/form';
import { useCreateSubscriptionHelpRequestMutation } from '../../../../../api/createSubscriptionHelpRequest/CreateSubscriptionHelpRequest.generated';
import { useCreateSupportCaseMutation } from '../../../../../api/createSupportCase/CreateSupportCase.generated';
import type { SupportMode, SupportFormData } from '../../../../../types';
import type { DefaultValues } from '@partstech/ui/forms';

type Props<TSupportMode extends SupportMode> = {
  mode: TSupportMode;
  defaultValues?: Partial<Omit<SupportFormData<TSupportMode>, 'notes'>>;
  onCancel?: () => void;
  onSubmit?: () => void;
};

export const useContactForm = <TSupportMode extends SupportMode>({
  defaultValues,
  mode,
  onSubmit,
}: Props<TSupportMode>) => {
  const form = useForm<SupportFormData<TSupportMode>>({
    defaultValues: { ...defaultValues, notes: '' } as DefaultValues<SupportFormData<TSupportMode>>,
    mode: 'onBlur',
  });

  const {
    formState: { isValid, isSubmitting },
    setError,
  } = form;

  const [createSupportCase] = useFormMutationCallback(useCreateSupportCaseMutation, {
    onValidationError: setError,
    onSuccess: onSubmit,
  });
  const [createSubscriptionHelpRequest] = useFormMutationCallback(useCreateSubscriptionHelpRequestMutation, {
    onValidationError: setError,
    onSuccess: onSubmit,
  });

  const formSubmit = useCallback(
    async (values: SupportFormData<TSupportMode>) => {
      if (mode === 'subscriptionSupport') {
        await createSubscriptionHelpRequest({
          input: values as SupportFormData<'subscriptionSupport'>,
        });

        return;
      }

      await createSupportCase({
        input: values as SupportFormData<'globalSupport'>,
      });
    },
    [createSubscriptionHelpRequest, createSupportCase, mode]
  );

  return { form, formSubmit, isValid, isSubmitting };
};
