import { css } from '@emotion/react';
import { duration, easing, getTransitions } from '@partstech/ui/utils';
import type { Theme } from '@partstech/ui';
import type { UIOffset } from 'types/uiOffset';

export const cartBarWidth = 280;

export const styles = {
  header: (offset?: Partial<UIOffset>) => (theme: Theme) => css`
    ${theme.breakpoints.up('md')} {
      border-bottom: 1px solid ${theme.color.mono17};
      transition: ${offset ? getTransitions(['left'], easing.easeInOut, duration.enteringScreen) : 'none'};
    }
  `,
  cartBar: (headerHeight: number, isCartPage: boolean) => (theme: Theme) => css`
    top: ${headerHeight}px;
    right: 0;
    height: calc(100% - ${headerHeight}px);
    border-left: 1px solid ${theme.color.mono17};

    ${theme.breakpoints.down('md')} {
      z-index: ${theme.zIndex.popover};
      top: ${headerHeight - 51}px;
      height: calc(100% - ${headerHeight - 51}px);
      border-top: 1px solid ${theme.color.mono17};
      border-left: none;
    }

    ${theme.breakpoints.between('md', 'xl')} {
      z-index: ${isCartPage ? theme.zIndex.overview : theme.zIndex.drawer};
      top: ${isCartPage ? headerHeight : 0}px;
      right: ${!isCartPage ? `${-cartBarWidth}px` : 0};
      height: ${isCartPage ? `calc(100% - ${headerHeight}px)` : '100%'};
    }
  `,

  supportButton: (theme: Theme) => css`
    border-radius: ${theme.sizing(5)};
    line-height: 15px;
    display: none;

    .icon {
      vertical-align: middle;
      margin-right: 5px;
    }
  `,
};
