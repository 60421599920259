import { Box, Tooltip, Typography } from '@partstech/ui';
import { useMemo } from 'react';
import snowedMountains from 'images/icons/snowed-mountains.svg';
import { Chip } from '../Chip';
import type { Attribute } from 'models';

type Props = {
  attribute: Attribute;
};

const MOUNTAIN_SNOWFLAKE = '3 Peak Mountain Snowflake';

export const Certification = ({ attribute }: Props) => {
  const containsSnowflake = attribute.values.includes(MOUNTAIN_SNOWFLAKE);
  const filteredValues = useMemo(() => attribute.values.filter((value) => value !== MOUNTAIN_SNOWFLAKE), [attribute]);

  if (!attribute.getValue()) {
    return null;
  }

  return (
    <Tooltip tooltip={attribute.getValue()} disabled={!containsSnowflake} allowAutoPositioning>
      <Box display="flex" gap={1}>
        {containsSnowflake && (
          <Chip background="primaryLight10">
            <img src={snowedMountains} alt={MOUNTAIN_SNOWFLAKE} />
          </Chip>
        )}

        {attribute.getValue() !== MOUNTAIN_SNOWFLAKE &&
          filteredValues.map((value) => (
            <Chip key={value} background="primaryLight10">
              <Typography variant="overline" color="white" textTransform="uppercase">
                {value}
              </Typography>
            </Chip>
          ))}
      </Box>
    </Tooltip>
  );
};
