import { ProtectedRoute } from 'app/AppRouter/ProtectedRoute';
import { SignUpLayout } from 'components/Layout/SignUpLayout';
import { CreateShopContext } from 'components/Layout/SignUpLayout/CreateShopContext';
import { Layout } from 'components/Layout/SignUpLayout/Layout';
import { PagePermission } from 'constant';
import { SignUpPages } from 'utils/url';
import { HearAboutUs } from './HearAboutUs';
import { ManagementSystem } from './ManagementSystem';
import { PartsSuppliers } from './PartsSuppliers';
import { PhoneNumber } from './PhoneNumber';
import { PrimaryRole } from './PrimaryRole';
import { Restricted } from './Restricted';
import { ShopDetails } from './ShopDetails';
import { ShopType } from './ShopType';
import { TireSuppliers } from './TireSuppliers';
import type { RouteObject } from 'react-router-dom';

const createAccountPage = {
  path: SignUpPages.CreateAccount,
  lazy: () => import('./CreateAccount').then((module) => ({ Component: module.CreateAccount })),
};

const shopTypePage = {
  path: SignUpPages.ShopType,
  element: <ShopType />,
};

const shopDetailsPage = {
  path: SignUpPages.ShopDetails,
  element: <ShopDetails />,
};

const managementSystemPage = {
  path: SignUpPages.ManagementSystem,
  element: <ManagementSystem />,
};

const partSupplierPage = {
  path: SignUpPages.PartsSuppliers,
  element: <PartsSuppliers />,
};

const tireSupplierPage = {
  path: SignUpPages.TireSuppliers,
  element: <TireSuppliers />,
};

const rolePage = {
  path: SignUpPages.Role,
  element: <PrimaryRole />,
};

const hearAboutUsPage = {
  path: SignUpPages.HearAboutUs,
  element: <HearAboutUs />,
};

const phoneNumberPage = {
  path: SignUpPages.PhoneNumber,
  element: <PhoneNumber />,
};

const restrictedPage = {
  path: SignUpPages.Restricted,
  element: <Restricted />,
};

export const signUpPages: RouteObject = {
  path: '/sign-up',
  children: [
    {
      element: <ProtectedRoute permission={PagePermission.UNAUTHORIZED} />,
      children: [
        {
          element: <SignUpLayout />,
          children: [createAccountPage],
        },
      ],
    },

    {
      element: (
        <ProtectedRoute permission={PagePermission.AUTHORIZED_NO_SHOP}>
          <CreateShopContext>
            <Layout />
          </CreateShopContext>
        </ProtectedRoute>
      ),
      children: [
        shopTypePage,
        shopDetailsPage,
        managementSystemPage,
        partSupplierPage,
        tireSupplierPage,
        rolePage,
        hearAboutUsPage,
        phoneNumberPage,
        restrictedPage,
      ],
    },
  ],
};
