import { Box } from '@partstech/ui';
import { ErrorCode } from 'shared/api';
import { AccountDisabled } from './AccountDisabled';
import { AccountNotFound } from './AccountNotFound';
import { BadCredentials } from './BadCredentials';
import { GoogleInvalidToken } from './GoogleInvalidToken';
import type { BoxProps } from '@partstech/ui';

const errorComponents: Partial<Record<ErrorCode, JSX.Element>> = {
  [ErrorCode.ACCOUNT_NOT_FOUND]: <AccountNotFound />,
  [ErrorCode.ACCOUNT_DISABLED]: <AccountDisabled />,
  [ErrorCode.BAD_CREDENTIALS]: <BadCredentials />,
  [ErrorCode.GOOGLE_INVALID_TOKEN]: <GoogleInvalidToken />,
};

type Props = BoxProps & {
  errorCode: ErrorCode | null;
};

export const ErrorCodeMessage = ({ errorCode, ...props }: Props) => {
  if (errorCode === null) {
    return null;
  }

  return (
    <Box textAlign="center" data-testid="errorCodeMessage" {...props}>
      {errorComponents[errorCode]}
    </Box>
  );
};
