import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import type { LDFlagSet } from 'types/launchdarkly';

export const useGetLaunchDarklyFlags = () => {
  const {
    ambassadorReferralProgram,
    attributeBasedSearch,
    categoriesTab,
    categoriesSearch,
    customJobs,
    diySignUpFlow,
    failedOrdersVisibility,
    jobsVisibility,
    localInventory,
    locationsToGraphQl,
    newOrders,
    newRetailPricing,
    optionsPreferences,
    productPageRedesign,
    promoCode,
    saveLaborInCart,
    selectedFiltersChip,
    splitDealersFromAftermarket,
    vehicleSpecificJobs,
    oecLogo,
  } = useFlags();

  const [flags, setFlags] = useState<LDFlagSet>({});

  useEffect(() => {
    setFlags({
      ambassadorReferralProgram,
      attributeBasedSearch,
      categoriesSearch,
      categoriesTab,
      customJobs,
      diySignUpFlow,
      failedOrdersVisibility,
      jobsVisibility,
      localInventory,
      locationsToGraphQl,
      newOrders,
      newRetailPricing,
      optionsPreferences,
      productPageRedesign,
      promoCode,
      saveLaborInCart,
      selectedFiltersChip,
      splitDealersFromAftermarket,
      vehicleSpecificJobs,
      oecLogo,
    });
  }, [
    ambassadorReferralProgram,
    attributeBasedSearch,
    categoriesSearch,
    categoriesTab,
    customJobs,
    diySignUpFlow,
    failedOrdersVisibility,
    jobsVisibility,
    localInventory,
    locationsToGraphQl,
    newOrders,
    newRetailPricing,
    optionsPreferences,
    productPageRedesign,
    promoCode,
    saveLaborInCart,
    selectedFiltersChip,
    splitDealersFromAftermarket,
    vehicleSpecificJobs,
    oecLogo,
  ]);

  return flags;
};
