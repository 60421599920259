import { useCallback } from 'react';
import { shopApi } from 'shared/api';
import { useAppDispatch } from 'store';
import { useGetAppConfig } from 'store/queries/appConfig/useGetAppConfig';
import { useGetCurrentUser, api } from 'store/queries/currentUser/useGetCurrentUser';
import type { ErrorCode } from 'shared/api';
import type { LoginRequest, LoginResponse } from 'shared/api/rest/gen/shop';

const injectedRtkApi = shopApi.injectEndpoints({
  endpoints: (build) => ({
    Login: build.mutation<LoginResponse, LoginRequest>({
      query: (body) => ({ url: '/login', method: 'POST', body }),
    }),
  }),
});

const { useLoginMutation } = injectedRtkApi;

type Args = {
  onError?: (code: ErrorCode) => Promise<void> | void;
  onSuccess?: () => Promise<void> | void;
};

export const useLogin = ({ onError, onSuccess }: Args = {}) => {
  const dispatch = useAppDispatch();
  const [mutation, status] = useLoginMutation();

  const { isLoading: isLoadingCurrentUser } = useGetCurrentUser();
  const { isLoading: isAppConfigLoading } = useGetAppConfig();

  const login = useCallback(
    async (values: LoginRequest) => {
      try {
        await mutation(values).unwrap();

        await dispatch(api.util.invalidateTags(['CurrentUser', 'AppConfig']));

        await onSuccess?.();
      } catch (error) {
        await onError?.(error.errors[0]?.code);
      }
    },
    [dispatch, mutation, onError, onSuccess]
  );

  return [login, { ...status, isLoading: status.isLoading || isLoadingCurrentUser || isAppConfigLoading }] as const;
};
