import { datadogRum } from '@datadog/browser-rum';
import type { LDEvaluationDetail, LDInspection } from 'launchdarkly-react-client-sdk';

export const dataDogLaunchDarklyInspector = (): LDInspection => ({
  type: 'flag-used',
  name: 'dd-inspector',
  method: (key: string, detail: LDEvaluationDetail) => {
    datadogRum.addFeatureFlagEvaluation(key, detail.value);
  },
});
