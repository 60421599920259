import { Certification } from '../../Certification';
import { Specification } from '../../Specification';
import { TireQualityAttribute } from './TireQualityAttribute';
import { TireSizeAttribute } from './TireSizeAttribute';
import type { Attribute as AttributeModel } from 'models';

type Props = {
  attribute: AttributeModel | null;
};

export const Attribute = ({ attribute }: Props) => {
  if (!attribute) {
    return null;
  }

  switch (attribute.name) {
    case 'TiresSize':
      return <TireSizeAttribute attribute={attribute} />;

    case 'TiresQuality':
      return <TireQualityAttribute attribute={attribute} />;

    case 'WinterCertification':
      return <Certification attribute={attribute} />;

    default:
      return <Specification label={attribute.label} value={attribute.getValue()} />;
  }
};
