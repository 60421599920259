import { type PropsWithChildren } from 'react';
import { AmbassadorProvider } from 'integrations/ambassador';
import { useIdentifyDatadog } from 'integrations/datadog';
import { FullStoryProvider } from 'integrations/fullstory';
import { LaunchDarklyProvider } from 'integrations/launchDarkly';
import { useGetDatadogContext } from './useDatadogContext';
import { useGetFullStoryContext } from './useGetFullStoryContext';
import { useGetLaunchDarklyContext } from './useGetLaunchDarklyContext';

export const IntegrationWrapper = ({ children }: PropsWithChildren) => {
  const launchDarklyContext = useGetLaunchDarklyContext();

  const fullstoryContext = useGetFullStoryContext();

  const datadogContext = useGetDatadogContext();
  useIdentifyDatadog(datadogContext);

  return (
    <LaunchDarklyProvider userContext={launchDarklyContext}>
      <FullStoryProvider userContext={fullstoryContext}>
        <AmbassadorProvider>{children}</AmbassadorProvider>
      </FullStoryProvider>
    </LaunchDarklyProvider>
  );
};
