/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type ChangeCartLocalInventoryItemQuantityMutationVariables = Types.Exact<{
  itemId: Types.Scalars['ID']['input'];
  quantity: Types.Scalars['Int']['input'];
}>;

export type ChangeCartLocalInventoryItemQuantityMutation = {
  __typename?: 'Mutation';
  updateActiveCartLocalInventoryItemQuantity?:
    | {
        __typename?: 'UpdateActiveCartLocalInventoryItemQuantityErrorPayload';
        error: Types.UpdateActiveCartLocalInventoryItemQuantityError;
        errorMessage: Types.Scalars['String']['input'];
      }
    | {
        __typename?: 'UpdateActiveCartLocalInventoryItemQuantitySuccessPayload';
        item: { __typename?: 'CartLocalInventoryItem'; id: string };
      }
    | null;
};

export const ChangeCartLocalInventoryItemQuantityDocument = `
    mutation ChangeCartLocalInventoryItemQuantity($itemId: ID!, $quantity: Int!) {
  updateActiveCartLocalInventoryItemQuantity(
    input: {itemId: $itemId, quantity: $quantity}
  ) {
    ... on UpdateActiveCartLocalInventoryItemQuantityErrorPayload {
      error
      errorMessage
    }
    ... on UpdateActiveCartLocalInventoryItemQuantitySuccessPayload {
      item {
        id
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ChangeCartLocalInventoryItemQuantity: build.mutation<
      ChangeCartLocalInventoryItemQuantityMutation,
      ChangeCartLocalInventoryItemQuantityMutationVariables
    >({
      query: (variables) => ({ document: ChangeCartLocalInventoryItemQuantityDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useChangeCartLocalInventoryItemQuantityMutation } = injectedRtkApi;
