import { Box, Button, Link, Result, Typography } from '@partstech/ui';
import { useLogOut } from 'features/logOut';
import { SUPPORT_PHONE, SALES_EMAIL } from 'shared/constant';

export const Restricted = () => {
  const [logOut, { isLoading }] = useLogOut();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={4} px={4}>
      <Result
        variant="neutral"
        title="PartsTech doesn't work for personal purchases at this time."
        message={
          <Typography color="subtleText">
            Part and tire suppliers on PartsTech require you to have a business wholesale account in order to get
            connected. We will reach out to the email you registered with once PartsTech enables an experience for DIY
            mechanics like yourself. <br />
            <br />
            If you do have wholesale accounts with suppliers, contact a sales representative via the email or phone
            number below and they will get you set up in no time.
          </Typography>
        }
        p={0}
      />

      <Box
        display="flex"
        flexDirection={{ sm: 'column', md: 'row' }}
        gap={2}
        alignItems="center"
        justifyContent="center"
      >
        <Button
          variant="text"
          leadingIcon="email"
          iconColor="primary"
          as={<Link variant="subtitle2" to={`mailto:${SALES_EMAIL}`} />}
          noHover
        >
          {SALES_EMAIL}
        </Button>

        <Button
          variant="text"
          leadingIcon="call"
          iconColor="primary"
          as={<Link variant="subtitle2" to={`tel:${SUPPORT_PHONE}`} />}
          noHover
        >
          {SUPPORT_PHONE}
        </Button>
      </Box>

      <Box flex="1 1 100%" textAlign="center">
        <Button variant="secondary" onClick={logOut} disabled={isLoading}>
          Close
        </Button>
      </Box>
    </Box>
  );
};
