import { Typography } from '@partstech/ui';
import { PermissionsForm } from 'features/permission';

export const Permissions = () => (
  <>
    <Typography>
      In addition to offering unlimited users, PartsTech Complete offers these user permissions to help organize your
      crew.
    </Typography>

    <PermissionsForm previewMode />
  </>
);
