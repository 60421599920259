import { useModalHistory } from '@partstech/ui';
import { useCallback } from 'react';
import { EditForm } from './EditForm';
import { WithoutCredentials } from './WithoutCredentials';
import type { CommonAccountFlowPaths } from '../../../types';
import type { Store } from 'entities/store';

export const Edit = () => {
  const {
    state: modalState,
    onClose,
    goBack,
    reset,
    push,
    hasGoBack,
  } = useModalHistory<CommonAccountFlowPaths, 'edit'>();

  const { account, selectedStore } = modalState ?? {};

  const store = account?.store ?? selectedStore ?? null;

  const handleSubmit = useCallback((currentStore: Store) => reset('submitted', { store: currentStore }), [reset]);

  const handleDeleteClick = useCallback(() => {
    if (!account) {
      return;
    }

    push('delete', { account });
  }, [account, push]);

  const handleCancel = useCallback(() => {
    if (hasGoBack) {
      goBack();
      return;
    }

    onClose();
  }, [goBack, hasGoBack, onClose]);

  if (store?.supplier?.isTireWithoutCredentials && account) {
    return <WithoutCredentials account={account} onSubmit={handleDeleteClick} onCancel={handleCancel} />;
  }

  return <EditForm account={account} store={selectedStore} onSubmit={handleSubmit} onCancel={handleCancel} />;
};
