import { isMatchingString } from 'shared/lib/string';
import { SearchSequenceType } from 'types/search';
import type { PartType } from './PartType';
import type { Vehicle } from 'entities/vehicle';
import type {
  SearchEntryPointInterface,
  SearchQueryConvertable,
  PartTypeQuery,
  SearchRequestConvertable,
  SearchCatalogRequestConvertable,
  ToRequestParams,
  ToCatalogRequestParams,
  SearchLocalInventoryRequestConvertable,
  ToLocalInventoryResourcesParams,
} from 'features/searchForm';
import type { SearchSequenceCreatorInterface } from 'features/searchSequence';
import type { CatalogSearchInput, LocalInventorySearchInput, SearchInput } from 'shared/api';
import type { SearchSequence } from 'types/search';

export class LegacyJob
  implements
    SearchEntryPointInterface,
    SearchQueryConvertable,
    SearchRequestConvertable,
    SearchCatalogRequestConvertable,
    SearchSequenceCreatorInterface,
    SearchLocalInventoryRequestConvertable
{
  id: string;

  name: string;

  aliases: string[];

  partTypes: PartType[];

  constructor(legacyJob: Pick<LegacyJob, 'id' | 'name' | 'aliases' | 'partTypes'>) {
    this.id = legacyJob.id;
    this.name = legacyJob.name;
    this.aliases = legacyJob.aliases;
    this.partTypes = legacyJob.partTypes ?? [];
  }

  createSearchSequence(vehicle: Vehicle | null): SearchSequence {
    return {
      tasks: this.partTypes.map((partType) => ({
        searchParams: {
          vehicle: vehicle?.id,
          vin: vehicle?.vin ?? undefined,
          state: vehicle?.state ?? undefined,
          plate: vehicle?.plate ?? undefined,
          part_text: partType.name,
          part_text_id: partType.id,
        },
        title: partType.name,
        isCompleted: false,
        optional: false,
      })),
      title: this.name,
      id: this.id,
      type: SearchSequenceType.LegacyJob,
    };
  }

  toString() {
    return this.name;
  }

  getColumnTitle(): string {
    return 'part types';
  }

  isVehicleRequired(): boolean {
    return true;
  }

  populateInput(): string {
    return this.name;
  }

  hasAliasMatch(search: string): boolean {
    return this.aliases.some((alias) => isMatchingString(alias, search));
  }

  toQuery(): PartTypeQuery {
    return {
      part_text_id: this.id,
      part_text: this.name,
    };
  }

  static isLegacyJobId(id: string): boolean {
    return id.startsWith('j_');
  }

  toRequest({ accountId, attributes, vehicleId, vin }: ToRequestParams): SearchInput {
    return {
      partTypeAttribute: {
        accountId,
        attributes,
        partTypeIds: this.partTypes.map((partType) => partType.id),
        vehicleId,
        vin,
      },
    };
  }

  toCatalogRequest({ attributes, vehicleId, vin }: ToCatalogRequestParams): CatalogSearchInput {
    return {
      partTypeAttribute: {
        attributes,
        partTypeIds: this.partTypes.map((partType) => partType.id),
        vehicleId,
        vin,
      },
    };
  }

  toLocalInventoryRequest({ attributes, vehicleId }: ToLocalInventoryResourcesParams): LocalInventorySearchInput {
    return {
      partTypeAttribute: {
        attributes,
        vehicleId,
        partTypeIds: this.partTypes.map((partType) => partType.id),
      },
    };
  }
}
