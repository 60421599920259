import { useCallback } from 'react';
import { isDevEnvironment } from 'app/config';
import { GOOGLE_ONE_TAP_CONTAINER_ID, useGoogleOneTapLogin } from 'integrations/google';
import { useMutationCallback } from 'shared/api';
import { useUTMParams } from 'shared/lib/analytics';
import { useCreateUserByGoogleOneTapMutation } from 'store/mutations/createUserByGoogleOneTap/CreateUserByGoogleOneTap.generated';
import type { IGoogleEndPointResponse } from 'integrations/google';
import type { UserAccountStatus } from 'shared/api';
import type { CreateUserByGoogleOneTapMutation } from 'store/mutations/createUserByGoogleOneTap/CreateUserByGoogleOneTap.generated';

type Args = {
  clientId: string;
  onSuccess: (accountStatus?: UserAccountStatus) => Promise<void>;
};

export const useGoogleOneTap = ({ clientId, onSuccess }: Args) => {
  const { utmEntries } = useUTMParams();

  const handleCreateUserSuccess = useCallback(
    (data: CreateUserByGoogleOneTapMutation) => onSuccess(data?.createUser?.accountStatus ?? undefined),
    [onSuccess]
  );

  const [createUser] = useMutationCallback(useCreateUserByGoogleOneTapMutation, {
    onSuccess: handleCreateUserSuccess,
  });

  const handleCreateUser = useCallback(
    (response: IGoogleEndPointResponse) => {
      const firstName = response.given_name ?? response.email.split('@')[0] ?? '';

      createUser({
        input: {
          email: response.email,
          firstName,
          lastName: response.family_name ?? null,
          utmParams: utmEntries,
        },
      });
    },
    [createUser, utmEntries]
  );

  useGoogleOneTapLogin({
    disabled: isDevEnvironment(),
    googleAccountConfigs: {
      client_id: clientId,
      prompt_parent_id: GOOGLE_ONE_TAP_CONTAINER_ID,
      cancel_on_tap_outside: false,
      context: 'signup',
    },
    onSuccess: handleCreateUser,
  });
};
