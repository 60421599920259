import { ProductInterchangeType } from 'shared/api';

export const createInterchangePart = (
  interchangeFor?: {
    brandName?: string | null;
    partNumber: string;
    type: 'ALTERNATE' | 'INTERCHANGE' | 'SIBLING' | 'SUPERSEDE';
  } | null
) => {
  const getType = (
    type: 'ALTERNATE' | 'INTERCHANGE' | 'SIBLING' | 'SUPERSEDE' | undefined
  ): 'INTCH' | 'SUPERSEDE' | 'ALTERNATE' => {
    switch (type) {
      case ProductInterchangeType.Alternate:
        return 'ALTERNATE';

      case ProductInterchangeType.Interchange:
        return 'INTCH';

      case ProductInterchangeType.Supersede:
        return 'SUPERSEDE';

      default:
        return 'INTCH';
    }
  };

  if (!interchangeFor) {
    return null;
  }

  return {
    type: getType(interchangeFor?.type),
    partNumber: interchangeFor?.partNumber ?? '',
    brand: interchangeFor?.brandName ?? '',
  };
};
