import { createAttributesFromQuery } from './createAttributesFromQuery';
import { createBaseProductFromQuery } from './createBaseProductFromQuery';
import type { SupplierAccount } from 'entities/supplierAccount';
import type { Product } from 'models';
import type { FullProductFragment } from 'store/queries/product/FullProductFragment.generated';

type Params = {
  account?: SupplierAccount | null;
  product: FullProductFragment;
  vehicleId?: string | null;
  showRetailPrice?: boolean;
  isPreferred?: boolean;
};

export const createFullProductFromQuery = (params: Params): Product => {
  const model = createBaseProductFromQuery(params);

  model.setProductAttributes(createAttributesFromQuery(params.product.productAttributes, true));

  return model;
};
