import { useMutationCallback } from 'shared/api';
import { api as generatedApi } from './CreateLaborRate.generated';
import type { CreateLaborRateMutation } from './CreateLaborRate.generated';
import type { UseMutationCallbackArgs } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreateLaborRate: {
      invalidatesTags: ['LaborRate'],
    },
  },
});

const { useCreateLaborRateMutation } = api;

export const useCreateLaborRate = (args?: UseMutationCallbackArgs<CreateLaborRateMutation, {}>) =>
  useMutationCallback(useCreateLaborRateMutation, args);
