import { Box, Typography } from '@partstech/ui';
import { useMemo } from 'react';
import { SupplierConnectionTime } from 'entities/supplier';
import type { BoxProps } from '@partstech/ui';
import type { ConnectionTime } from 'shared/api';

type Props = BoxProps & {
  connectionTime: ConnectionTime;
  supplierName: string;
};

export const ConnectionTimeDescription = ({ connectionTime, supplierName, ...props }: Props) => {
  const description = useMemo(() => {
    if (connectionTime === 'LESS_ONE_MIN') {
      return `Connecting to ${supplierName} is instant if your credentials match their records, allowing you to search their inventory on PartsTech right away. If they don’t match, the ${supplierName} representative will manually review your request.`;
    }

    return `${supplierName} representative will manually review your request. The supplier may reach out if your credentials don’t match the record they have on file.`;
  }, [connectionTime, supplierName]);

  if (connectionTime === 'NA') {
    return null;
  }

  return (
    <Box {...props}>
      <SupplierConnectionTime connectionTime={connectionTime} mb={1} />

      <Typography variant="bodyText2">{description}</Typography>
    </Box>
  );
};
