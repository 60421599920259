/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../../../shared/api/graphql/graphql';

export type ReportLocationFragment = {
  __typename?: 'Store';
  id: string;
  name: Types.Scalars['String']['input'];
  supplier?: { __typename?: 'Supplier'; id: string; name: Types.Scalars['String']['input'] } | null;
};

export const ReportLocationFragmentDoc = `
    fragment ReportLocation on Store {
  id
  name
  supplier {
    id
    name
  }
}
    `;
