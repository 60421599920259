import { isNotNull, uniq } from '@partstech/ui/utils';
import { PartSubCategory } from 'models';
import type { PartType } from 'models';

export const getSubCategoriesByPartTypes = (
  partTypes: PartType[],
  findSubCategory: (subCategoryId: string) => PartSubCategory | undefined
) => {
  const subCategoriesIds = uniq(partTypes.map((partType) => partType.subCategoryId)).filter(isNotNull);

  return subCategoriesIds
    .map((subCategoryId) => {
      const rawSubCategory = findSubCategory(subCategoryId || '');

      if (!rawSubCategory) {
        return null;
      }

      return new PartSubCategory(
        rawSubCategory.id,
        rawSubCategory.name,
        rawSubCategory.categoryId,
        partTypes.filter((partType) => partType.subCategoryId === subCategoryId)
      );
    })
    .filter(isNotNull);
};
