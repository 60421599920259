import { Box, Icon, IconButton, Typography } from '@partstech/ui';
import { useCallback } from 'react';
import { CategoriesList } from './CategoriesList';
import { OptionsWrapper } from './OptionList/OptionsWrapper';
import { PartTypes } from './OptionList/PartTypes';
import { SubCategories } from './OptionList/SubCategories';
import type { IconName } from '@partstech/ui';
import type { PartCategory, PartSubCategory, PartType } from 'models';

export type PartTaxonomySelectorProps = {
  categories: PartCategory[];
  onTogglePartType: (partType: PartType) => void;
  onToggleSubCategory: (subCategory: PartSubCategory) => void;
  onGoBack: () => void;
  onOpen: (option: PartCategory | PartSubCategory) => void;
  partTypes: PartType[];
  showSectionTitles: boolean;
  subCategories: PartSubCategory[];
  renderTag?: (option: PartSubCategory) => string;
  title?: string;
  value: PartType[];
  highlightedText?: string;
  disabledPartTypeIds?: string[];
  selectableSubCategories?: boolean;
};

const formatSubCategoryPartTypesCount = (selectedPartTypes: PartType[]) => (subCategory: PartSubCategory) => {
  const selectedPartTypesInSubCategory = subCategory.partTypes.filter((partType) =>
    selectedPartTypes.some((selectedPartType) => selectedPartType.id === partType.id)
  );

  return selectedPartTypesInSubCategory.length > 0
    ? `${selectedPartTypesInSubCategory.length} of ${subCategory.getPartTypesCount()} selected`
    : `${subCategory.getPartTypesCount()} part types`;
};

export const PartTaxonomySelector = ({
  categories,
  onTogglePartType,
  onToggleSubCategory,
  onGoBack,
  onOpen,
  partTypes,
  showSectionTitles = false,
  subCategories,
  title,
  value = [],
  highlightedText,
  disabledPartTypeIds = [],
  renderTag,
  selectableSubCategories,
}: PartTaxonomySelectorProps) => {
  const checkedIcon = useCallback(
    (option: PartSubCategory): IconName => {
      const selectedPartTypesInsideSubCategory = option.partTypes.filter((partType) =>
        value.some((selectedPartType) => selectedPartType.id === partType.id)
      );

      if (
        selectedPartTypesInsideSubCategory.length > 0 &&
        selectedPartTypesInsideSubCategory.length < option.partTypes.length
      ) {
        return 'check_box_indeterminate';
      }
      return 'check_box';
    },
    [value]
  );

  return (
    <>
      {title && (
        <Box display="flex" alignItems="center" position="sticky" top="0" left="0" zIndex="default" background="white">
          <IconButton variant="text" onClick={onGoBack}>
            <Icon name="arrow_back" />
          </IconButton>
          <Typography variant="subtitle1">{title}</Typography>
        </Box>
      )}

      {categories.length > 0 && (
        <CategoriesList
          highlightedText={highlightedText}
          overlineText={showSectionTitles ? 'CATEGORIES' : ''}
          categories={categories}
          onClick={onOpen}
        />
      )}

      {subCategories.length > 0 && (
        <OptionsWrapper title={showSectionTitles ? 'SUB CATEGORIES' : ''}>
          <SubCategories
            disabledPartTypeIds={disabledPartTypeIds}
            options={subCategories}
            onOpen={onOpen}
            value={value}
            onSelect={onToggleSubCategory}
            renderSecondaryText={formatSubCategoryPartTypesCount(value)}
            renderTag={renderTag}
            highlightedText={highlightedText}
            checkedIcon={checkedIcon}
            disabledText="Sub category is already used by another matrix"
            selectable={selectableSubCategories}
          />
        </OptionsWrapper>
      )}

      {partTypes.length > 0 && (
        <OptionsWrapper title={showSectionTitles ? 'PART TYPES' : ''}>
          <PartTypes
            options={partTypes}
            value={value}
            onSelect={onTogglePartType}
            highlightedText={highlightedText}
            disabledText="Part type is already used by another matrix"
          />
        </OptionsWrapper>
      )}
    </>
  );
};
