/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { CartLaborItemFragmentDoc } from '../../../../shared/api/graphql/fragment/CartLaborItem.generated';
import { VehicleFragmentDoc } from '../../../../shared/api/graphql/fragment/VehicleFragment.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetSavedCartQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type GetSavedCartQuery = {
  __typename?: 'Query';
  cart?: {
    __typename?: 'Cart';
    active: Types.Scalars['Boolean']['input'];
    customerEmail?: Types.Scalars['String']['input'] | null;
    id: string;
    improvedStatus: Types.CartImprovedStatus;
    notes?: Types.Scalars['String']['input'] | null;
    quoteType: Types.CartQuoteType;
    repairOrderNumber?: Types.Scalars['String']['input'] | null;
    showRetailPricing: Types.Scalars['Boolean']['input'];
    status: Types.CartStatus;
    updatedAt: Types.Scalars['DateTime']['input'];
    laborRate?: Types.Scalars['Float']['input'] | null;
    user?: {
      __typename?: 'User';
      id: string;
      firstName: Types.Scalars['String']['input'];
      lastName: Types.Scalars['String']['input'];
    } | null;
    paidOrders?: Array<{
      __typename?: 'Order';
      id: string;
      purchaseOrderNumber: Types.Scalars['String']['input'];
      lastTrackingDate?: Types.Scalars['DateTime']['input'] | null;
      trackingStatus: Types.TrackingStatus;
      datePaid?: Types.Scalars['DateTime']['input'] | null;
      supplier?: { __typename?: 'Supplier'; id: string; name: Types.Scalars['String']['input'] } | null;
      lines?: Array<{
        __typename?: 'OrderItem';
        id: string;
        image: Types.Scalars['String']['input'];
        coreCharge: Types.Scalars['BigDecimal']['input'];
        partName: Types.Scalars['String']['input'];
        partNumber: Types.Scalars['String']['input'];
        displayPartNumber?: Types.Scalars['String']['input'] | null;
        price: Types.Scalars['BigDecimal']['input'];
        quantity: number;
        subtotal: Types.Scalars['BigDecimal']['input'];
        tireSize?: Types.Scalars['String']['input'] | null;
        totalDiscount: Types.Scalars['BigDecimal']['input'];
        status: Types.OrderItemStatus;
        expectedAvailabilityType?: Types.ExpectedAvailabilityType | null;
        partType?: { __typename?: 'PartType'; id: string } | null;
        vehicle?: { __typename?: 'Vehicle'; id: string } | null;
        etas: Array<{
          __typename?: 'OrderItemETA';
          label?: Types.Scalars['String']['input'] | null;
          text?: Types.Scalars['String']['input'] | null;
        }>;
        orderedLaborItems?: Array<{
          __typename?: 'CartLaborItem';
          cartLocalInventoryItemId?: string | null;
          id: string;
          hours: Types.Scalars['Float']['input'];
          defaultHours: Types.Scalars['Float']['input'];
          vin?: Types.Scalars['String']['input'] | null;
          orderItemId?: string | null;
          name: Types.Scalars['String']['input'];
          notes?: Types.Scalars['String']['input'] | null;
          skill: Types.Scalars['String']['input'];
          identifiers?:
            | { __typename?: 'Mitchell1Identifiers' }
            | { __typename?: 'MotorIdentifiers'; id: string; motorLaborSource?: Types.MotorLaborSource | null }
            | null;
          vehicle?: { __typename?: 'Vehicle'; id: string } | null;
          taxonomy?: {
            __typename?: 'LaborTaxonomy';
            action?: Types.Scalars['String']['input'] | null;
            id: string;
            name: Types.Scalars['String']['input'];
          } | null;
        }> | null;
      }> | null;
    }> | null;
    orders?: Array<{
      __typename?: 'CartOrder';
      id: string;
      purchaseOrderNumber?: Types.Scalars['String']['input'] | null;
      builtOrder?: {
        __typename?: 'BuiltOrder';
        coreChargeTotal: Types.Scalars['Float']['input'];
        discountTotal: Types.Scalars['Float']['input'];
        fetChargeTotal: Types.Scalars['Float']['input'];
        priceTotal: Types.Scalars['Float']['input'];
        shippingTotal: Types.Scalars['Float']['input'];
        tax: Types.Scalars['Float']['input'];
        bundles: Array<{
          __typename?: 'CartBundle';
          discount?: Types.Scalars['Float']['input'] | null;
          name: Types.Scalars['String']['input'];
          quantity: number;
          items: Array<{
            __typename?: 'CartBundleItem';
            discount: Types.Scalars['Float']['input'];
            orderItemId: string;
            requiredQuantity: number;
          }>;
        }>;
        errors: Array<{
          __typename?: 'OrderError';
          errorType: Types.OrderErrorType;
          message: Types.Scalars['String']['input'];
        }>;
      } | null;
      account?: {
        __typename?: 'Account';
        id: string;
        nickname?: Types.Scalars['String']['input'] | null;
        status: Types.AccountStatus;
        store?: { __typename?: 'Store'; id: string } | null;
      } | null;
      supplier?: {
        __typename?: 'Supplier';
        id: string;
        name: Types.Scalars['String']['input'];
        type: Types.SupplierType;
        outageType?: {
          __typename?: 'Outage';
          message?: Types.Scalars['String']['input'] | null;
          type: Types.OutageType;
        } | null;
      } | null;
      items?: Array<{
        __typename?: 'CartOrderItem';
        id: string;
        image?: Types.Scalars['String']['input'] | null;
        partName: Types.Scalars['String']['input'];
        partNumber: Types.Scalars['String']['input'];
        quantity: number;
        tireSize?: Types.Scalars['String']['input'] | null;
        vin?: Types.Scalars['String']['input'] | null;
        builtItem?: {
          __typename?: 'BuiltItem';
          bundled: Types.Scalars['Boolean']['input'];
          errors: Array<{ __typename?: 'OrderItemError'; message: Types.Scalars['String']['input'] }>;
          product?: {
            __typename?: 'OrderItemProduct';
            price: Types.Scalars['Float']['input'];
            customerPrice?: Types.Scalars['Float']['input'] | null;
            coreCharge?: Types.Scalars['Float']['input'] | null;
            fetCharge?: Types.Scalars['Float']['input'] | null;
            backOrderable?: Types.Scalars['Boolean']['input'] | null;
            specialOrder?: Types.Scalars['Boolean']['input'] | null;
            availabilityLines: Array<{
              __typename?: 'OrderItemProductAvailabilityLine';
              address?: Types.Scalars['String']['input'] | null;
              group?: Types.Scalars['String']['input'] | null;
              id?: number | null;
              name: Types.Scalars['String']['input'];
              quantity: number;
              type: Types.OrderItemProductAvailabilityLineType;
            }>;
            deliveryNotes: Array<{
              __typename?: 'OrderItemProductDeliveryNotes';
              label?: Types.Scalars['String']['input'] | null;
              text?: Types.Scalars['String']['input'] | null;
            }>;
          } | null;
        } | null;
        partType?: { __typename?: 'PartType'; id: string } | null;
        laborItems?: Array<{
          __typename?: 'CartLaborItem';
          cartLocalInventoryItemId?: string | null;
          id: string;
          hours: Types.Scalars['Float']['input'];
          defaultHours: Types.Scalars['Float']['input'];
          vin?: Types.Scalars['String']['input'] | null;
          orderItemId?: string | null;
          name: Types.Scalars['String']['input'];
          notes?: Types.Scalars['String']['input'] | null;
          skill: Types.Scalars['String']['input'];
          identifiers?:
            | { __typename?: 'Mitchell1Identifiers' }
            | { __typename?: 'MotorIdentifiers'; id: string; motorLaborSource?: Types.MotorLaborSource | null }
            | null;
          vehicle?: { __typename?: 'Vehicle'; id: string } | null;
          taxonomy?: {
            __typename?: 'LaborTaxonomy';
            action?: Types.Scalars['String']['input'] | null;
            id: string;
            name: Types.Scalars['String']['input'];
          } | null;
        }> | null;
        vehicle?: { __typename?: 'Vehicle'; id: string } | null;
      }> | null;
    }> | null;
    laborItems?: Array<{
      __typename?: 'CartLaborItem';
      cartLocalInventoryItemId?: string | null;
      id: string;
      hours: Types.Scalars['Float']['input'];
      defaultHours: Types.Scalars['Float']['input'];
      vin?: Types.Scalars['String']['input'] | null;
      orderItemId?: string | null;
      name: Types.Scalars['String']['input'];
      notes?: Types.Scalars['String']['input'] | null;
      skill: Types.Scalars['String']['input'];
      identifiers?:
        | { __typename?: 'Mitchell1Identifiers' }
        | { __typename?: 'MotorIdentifiers'; id: string; motorLaborSource?: Types.MotorLaborSource | null }
        | null;
      vehicle?: { __typename?: 'Vehicle'; id: string } | null;
      taxonomy?: {
        __typename?: 'LaborTaxonomy';
        action?: Types.Scalars['String']['input'] | null;
        id: string;
        name: Types.Scalars['String']['input'];
      } | null;
    }> | null;
    vehicles?: Array<{
      __typename?: 'CartLinkedVehicle';
      id: string;
      vin?: Types.Scalars['String']['input'] | null;
      vehicle?: {
        __typename?: 'Vehicle';
        id: string;
        type: Types.VehicleType;
        regions?: Array<Types.VehicleRegion> | null;
        year: number;
        make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
        model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
        subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
        engine: { __typename?: 'VehicleEngine'; id: string; name: Types.Scalars['String']['input'] };
      } | null;
    }> | null;
    orderedLaborItems?: Array<{
      __typename?: 'CartLaborItem';
      cartLocalInventoryItemId?: string | null;
      id: string;
      hours: Types.Scalars['Float']['input'];
      defaultHours: Types.Scalars['Float']['input'];
      vin?: Types.Scalars['String']['input'] | null;
      orderItemId?: string | null;
      name: Types.Scalars['String']['input'];
      notes?: Types.Scalars['String']['input'] | null;
      skill: Types.Scalars['String']['input'];
      identifiers?:
        | { __typename?: 'Mitchell1Identifiers' }
        | { __typename?: 'MotorIdentifiers'; id: string; motorLaborSource?: Types.MotorLaborSource | null }
        | null;
      vehicle?: { __typename?: 'Vehicle'; id: string } | null;
      taxonomy?: {
        __typename?: 'LaborTaxonomy';
        action?: Types.Scalars['String']['input'] | null;
        id: string;
        name: Types.Scalars['String']['input'];
      } | null;
    }> | null;
  } | null;
};

export const GetSavedCartDocument = `
    query GetSavedCart($id: ID!) {
  cart(id: $id) {
    active
    customerEmail
    id
    improvedStatus
    notes
    user {
      id
      firstName
      lastName
    }
    paidOrders {
      id
      purchaseOrderNumber
      supplier {
        id
        name
      }
      lastTrackingDate
      trackingStatus
      datePaid
      lines {
        id
        image
        coreCharge
        partName
        partNumber
        partType {
          id
        }
        displayPartNumber
        price
        quantity
        subtotal
        tireSize
        totalDiscount
        vehicle {
          id
        }
        status
        expectedAvailabilityType
        etas {
          label
          text
        }
        orderedLaborItems {
          ...CartLaborItem
        }
      }
    }
    orders {
      id
      builtOrder {
        coreChargeTotal
        discountTotal
        bundles {
          discount
          items {
            discount
            orderItemId
            requiredQuantity
          }
          name
          quantity
        }
        errors {
          errorType
          message
        }
        fetChargeTotal
        priceTotal
        shippingTotal
        tax
      }
      purchaseOrderNumber
      account {
        id
        nickname
        status
        store {
          id
        }
      }
      supplier {
        id
        name
        type
        outageType {
          message
          type
        }
      }
      items {
        id
        builtItem {
          errors {
            message
          }
          bundled
          product {
            price
            customerPrice
            coreCharge
            fetCharge
            backOrderable
            specialOrder
            availabilityLines {
              address
              group
              id
              name
              quantity
              type
            }
            deliveryNotes {
              label
              text
            }
          }
        }
        image
        partName
        partNumber
        partType {
          id
        }
        quantity
        tireSize
        laborItems {
          ...CartLaborItem
        }
        vehicle {
          id
        }
        vin
      }
    }
    quoteType
    repairOrderNumber
    showRetailPricing
    status
    updatedAt
    laborRate
    laborItems {
      ...CartLaborItem
    }
    vehicles {
      id
      vin
      vehicle {
        ...Vehicle
      }
    }
    orderedLaborItems {
      ...CartLaborItem
    }
  }
}
    ${CartLaborItemFragmentDoc}
${VehicleFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSavedCart: build.query<GetSavedCartQuery, GetSavedCartQueryVariables>({
      query: (variables) => ({ document: GetSavedCartDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSavedCartQuery, useLazyGetSavedCartQuery } = injectedRtkApi;
