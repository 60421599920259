import { environment } from 'app/config';

export const openWidget = (): void => {
  mbsy.activeRAFWidget = `${environment}-rafWidget`;
};

export const closeWidget = (): void => {
  mbsy.activeRAFWidget = undefined;
};

export type WidgetStatus = {
  isVisible: boolean;
  isLoading: boolean;
};

export const subscribeWidgetStatus = (onStatusChange: (status: WidgetStatus) => void): (() => void) => {
  let status: WidgetStatus = { isVisible: false, isLoading: false };

  mbsy.on('visibility', (_event, { showing: isVisible }) => {
    status = { isLoading: false, isVisible };

    if (isVisible) {
      openWidget();
    } else {
      closeWidget();
    }

    onStatusChange(status);
  });

  mbsy.on('load', () => {
    status = { isVisible: true, isLoading: false };

    onStatusChange(status);
  });

  mbsy.on('trigger', () => {
    status = { ...status, isLoading: true };

    onStatusChange(status);
  });

  // Return unsubscribe function (placeholder as mbsy doesn't support unsubscribing)
  return () => {
    // In a real implementation, we would remove event listeners here
    // Currently not supported by mbsy API
  };
};
