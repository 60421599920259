import { Box, Button, ButtonGroup, Typography, useModalHistory } from '@partstech/ui';
import type { ContactSupportPaths, SupportMode } from '../../../../types';

export const Success = <TSupportMode extends SupportMode>() => {
  const { onClose } = useModalHistory<ContactSupportPaths<TSupportMode>, 'success'>();

  return (
    <Box display="flex" flexDirection="column" flexWrap="wrap">
      <Box mb={6}>
        <Typography>Thank you for reaching out!</Typography>

        <Box mt={2}>
          <Typography>
            We will review your request and a PartsTech support representative will contact you within 24-48 hours.
          </Typography>
        </Box>
      </Box>

      <ButtonGroup>
        <Button onClick={onClose} variant="text">
          Close window
        </Button>
      </ButtonGroup>
    </Box>
  );
};
