import { api as generatedApi } from './CreateShop.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreateShop: {
      invalidatesTags: ['AppConfig'],
    },
  },
});

export const { useCreateShopMutation } = api;
