import { useMemo } from 'react';
import { usePermissions } from 'entities/permission';
import { ExProfileType } from 'shared/api';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { getFeaturesEX } from '../utils/getFeaturesEX';

export const useSubscriptionEX = () => {
  const { exProfileType, managementSystem } = useGetCurrentUser();
  const { shop } = usePermissions();

  const featuresEX = useMemo(() => getFeaturesEX(shop).filter((feature) => feature.isEnabled), [shop]);

  const isDynamic = exProfileType === ExProfileType.TypeDynamic;
  const isStatic = exProfileType === ExProfileType.TypeStatic;

  const isRestrictedEX = isDynamic || isStatic;
  const isSubscriptionEX = exProfileType !== null;

  return {
    featuresEX,
    isDynamic,
    isRestrictedEX,
    isStatic,
    isSubscriptionEX,
    managementSystem,
  };
};
