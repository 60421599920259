import { useCallback } from 'react';
import { api as activeCartApi, type GetActiveCartQuery } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { useAppDispatch } from 'store';
import { createOrderItemData, getAddToCartInput } from '../../../utils';
import { api } from './AddItemToCart.generated';
import type { AddToCartPayload } from '../../../types';

const { useAddItemToCartMutation } = api;

export const useAddItemToCart = () => {
  const dispatch = useAppDispatch();

  const [mutate, status] = useAddItemToCartMutation();

  useMutationStatus({ status });

  const addToCart = useCallback(
    async (payload: AddToCartPayload) => {
      const res = await mutate({ item: getAddToCartInput(payload) });
      const { data } = res;

      if (data?.addItemToActiveCart && 'orderItemId' in data.addItemToActiveCart) {
        const { orderId, orderItemId } = data.addItemToActiveCart;
        const orderItemData = createOrderItemData(orderItemId, payload.quantity, payload.product);

        dispatch(
          activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
            if (!draft?.activeCart) {
              Object.assign(draft, {
                activeCart: {
                  id: 'activeCart',
                  orders: [
                    {
                      id: orderId,
                      items: [orderItemData],
                      account: {
                        id: String(payload.product.credentialId),
                      },
                    },
                  ],
                  localInventoryItems: [],
                },
              } satisfies GetActiveCartQuery);

              return;
            }

            const order = draft.activeCart.orders?.find((item) => item.id === orderId);

            if (order) {
              const orderItem = order.items?.find((item) => item.id === orderItemId);

              if (orderItem) {
                Object.assign(orderItem, { quantity: orderItem.quantity + payload.quantity });
              } else {
                order.items?.push(orderItemData);
              }
            } else if (!draft.activeCart.orders) {
              Object.assign(draft.activeCart, { orders: [orderItemData] });
            } else {
              draft.activeCart.orders.push({
                id: orderId,
                items: [orderItemData],
                account: {
                  id: String(payload.product.credentialId),
                },
              });
            }
          })
        );
      }

      return res;
    },
    [mutate, dispatch]
  );

  return [addToCart, status] as const;
};
