import { Typography } from '@partstech/ui';
import type { Supplier } from 'entities/supplier';

type Props = {
  supplier: Supplier;
};

export const SupplierConnectionStatus = ({ supplier }: Props) => {
  const isTireStoreSupplier = Boolean(supplier?.isTire);

  const isCatalogSupplier = Boolean(supplier?.hasCredentials);
  const isWithoutCredentials = Boolean(supplier?.isTireWithoutCredentials);

  if (isCatalogSupplier || (isTireStoreSupplier && isWithoutCredentials)) {
    return (
      <>
        <Typography>We're working with the supplier to get you connected.</Typography>

        <Typography>
          While setup is happening, you'll be able to see the supplier's status on its individual tile on the Suppliers
          page.
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography>You are now all set up with {supplier?.name ?? ''}.</Typography>

      <Typography>You will now see them as an option on the Search results page when ordering parts.</Typography>
    </>
  );
};
