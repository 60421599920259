import { useSnackbar } from '@partstech/ui';
import { useDebouncedCallback } from '@partstech/ui/hooks';
import { useCallback, useMemo, useRef, useState } from 'react';
import { api as activeCartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { minMaxLength } from 'shared/lib/form';
import { useAppDispatch } from 'store';
import { useUpdateCartPoNumber } from '../api/mutations/updateCartPoNumber';
import { useUpdateOrderPoNumber } from '../api/mutations/updateOrderPoNumber';
import type { CartOrder } from 'models';

const emptyOrders: CartOrder[] = [];

type Props = {
  orders?: CartOrder[];
};

export const usePoNumbers = ({ orders = emptyOrders }: Props) => {
  const dispatch = useAppDispatch();
  const { addSnackbar } = useSnackbar();

  const statusRef = useRef<'typing' | 'loading' | 'ready'>('ready');

  const [isReady, setIsReady] = useState(true);

  const [updateOrderPoNumberMutation] = useUpdateOrderPoNumber();
  const [updateCartPoNumberMutation] = useUpdateCartPoNumber();

  const filteredOrders = useMemo(
    () => orders.filter((order) => order.supplierAccount?.supplier?.allowCustomPurchaseOrderNumbers),
    [orders]
  );

  const errors = useMemo(
    () =>
      filteredOrders.reduce(
        (acc, order) => ({
          ...acc,
          [order.id]: minMaxLength(order.poNumber, order.poNumberValidation || undefined),
        }),
        {}
      ),
    [filteredOrders]
  );

  const updateCartData = useCallback(
    (poNumber: string, orderId: string) => {
      dispatch(
        activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
          draft?.activeCart?.orders?.forEach((draftOrder) => {
            if (draftOrder.id === orderId) {
              Object.assign(draftOrder, {
                purchaseOrderNumber: poNumber,
              });
            }
          });
        })
      );
    },
    [dispatch]
  );

  const updateOrderPoNumber = useCallback(
    async (poNumber: string, orderId: string) => {
      const res = await updateOrderPoNumberMutation({ purchaseOrderNumber: poNumber, orderId });

      const errorMessage =
        res &&
        'updateActiveCartOrderPurchaseOrderNumber' in res &&
        res.updateActiveCartOrderPurchaseOrderNumber &&
        'errorMessage' in res.updateActiveCartOrderPurchaseOrderNumber
          ? res.updateActiveCartOrderPurchaseOrderNumber.errorMessage
          : null;

      if (errorMessage) {
        addSnackbar({ label: errorMessage });
      }
    },
    [addSnackbar, updateOrderPoNumberMutation]
  );

  const updateCartPoNumber = useCallback(
    async (poNumber: string) => {
      const res = await updateCartPoNumberMutation({ purchaseOrderNumber: poNumber });

      const errorMessage =
        res &&
        'updateActiveCartPurchaseOrderNumber' in res &&
        res.updateActiveCartPurchaseOrderNumber &&
        'errorMessage' in res.updateActiveCartPurchaseOrderNumber
          ? res.updateActiveCartPurchaseOrderNumber.errorMessage
          : null;

      if (errorMessage) {
        addSnackbar({ label: errorMessage });
      }
    },
    [addSnackbar, updateCartPoNumberMutation]
  );

  const changePoNumber = useDebouncedCallback(async (newValue: string, order?: CartOrder) => {
    statusRef.current = 'loading';

    if (order) {
      const error = newValue ? minMaxLength(newValue, order.poNumberValidation || undefined) : null;

      if (!error) {
        await updateOrderPoNumber(newValue, order.id);
      }
    } else {
      const hasError = newValue
        ? filteredOrders.some((item) => minMaxLength(newValue, item.poNumberValidation || undefined))
        : false;

      if (!hasError) {
        await updateCartPoNumber(newValue);
      }
    }

    if (statusRef.current === 'loading') {
      statusRef.current = 'ready';
      setIsReady(true);

      if (order) {
        updateCartData(newValue, order.id);
      } else {
        filteredOrders.forEach((item) => updateCartData(newValue, item.id));
      }
    }
  }, 400);

  const handleChangePoNumber = useCallback(
    (newValue: string, order?: CartOrder) => {
      statusRef.current = 'typing';
      setIsReady(false);
      changePoNumber(newValue, order);
    },
    [changePoNumber]
  );

  return {
    changePoNumber: handleChangePoNumber,
    errors,
    isReady,
  };
};
