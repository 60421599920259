/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { AddressFragmentDoc } from './Address.generated';
import { CoordinatesFragmentDoc } from './Coordinates.generated';
import type * as Types from '../graphql';

export type StoreFragment = {
  __typename?: 'Store';
  name: Types.Scalars['String']['input'];
  id: string;
  logo?: Types.Scalars['String']['input'] | null;
  dropship: Types.Scalars['Boolean']['input'];
  hotshot: number;
  phone?: Types.Scalars['String']['input'] | null;
  pickUp: Types.Scalars['Boolean']['input'];
  warehouse: Types.Scalars['Boolean']['input'];
  address: {
    __typename?: 'Address';
    address1: Types.Scalars['String']['input'];
    address2?: Types.Scalars['String']['input'] | null;
    city: Types.Scalars['String']['input'];
    country: Types.Country;
    state: Types.Scalars['String']['input'];
    zipCode: Types.Scalars['String']['input'];
  };
  coordinates?: {
    __typename?: 'Coordinates';
    longitude: Types.Scalars['Float']['input'];
    latitude: Types.Scalars['Float']['input'];
  } | null;
};

export const StoreFragmentDoc = `
    fragment Store on Store {
  name
  id
  logo
  address {
    ...Address
  }
  coordinates {
    ...Coordinates
  }
  dropship
  hotshot
  phone
  pickUp
  warehouse
}
    ${AddressFragmentDoc}
${CoordinatesFragmentDoc}`;
