import { useMemo } from 'react';
import { formatDiagrams } from 'entities/diagram';
import { useGetSearchDiagrams } from '../api/getDiagrams/useGetSearchDiagrams';

type Props = {
  vehicleId: string | null;
  categoryId: string | null;
  diagramId?: string | null;
};

export const useDiagramsByCategory = ({ vehicleId, categoryId, diagramId }: Props) => {
  const { diagrams } = useGetSearchDiagrams({ vehicleId });

  const diagramsByCategory = useMemo(() => {
    if (!categoryId) {
      return [];
    }

    return formatDiagrams(diagrams).filter((diagram) => diagram.category?.id === categoryId);
  }, [categoryId, diagrams]);

  const activeDiagram = useMemo(() => diagrams.find((diagram) => diagram.id === diagramId), [diagramId, diagrams]);

  return {
    diagrams: diagramsByCategory,
    activeDiagram,
  };
};
