import { targetSupplierIds } from 'constant';
import { Availability, AvailabilityType } from 'types/search';
import { SupplierId } from 'types/supplier';
import type { AvailabilityLine, Product } from 'models';
import type { CheckedFilters } from 'types/search';

const isMatchingAvailabilityLine = (line: AvailabilityLine, values: CheckedFilters['availability']): boolean =>
  line.group
    ? values?.some((value) => line.isMatchingGroup({ group: value }))
    : values?.some((value) => line.isEqual({ id: Number(value), name: value }));

const getSortOrder = (supplierId: string): string[] => {
  const isATD = [SupplierId.ATD, SupplierId.ATD_TIRECONNECT].includes(Number(supplierId));
  if (isATD) {
    return ['local', 'local plus', 'nation wide'];
  }

  const isTireHub = [SupplierId.TIRE_HUB, SupplierId.TIRE_HUB_TIRECONNECT].includes(Number(supplierId));
  if (isTireHub) {
    return ['today', 'tomorrow', 'within 2 days', 'within 3 days', 'more than 3 days'];
  }

  if (targetSupplierIds.includes(Number(supplierId))) {
    return [
      'home store',
      'pick up',
      'within 2 hours',
      'within 4 hours',
      'same day',
      'next day',
      '2+ days',
      'call for eta',
    ];
  }

  return ['main', 'network'];
};

const fastestDeliveryGroups = ['Home Store', 'Local', 'Today'];
const secondaryFastestGroups = ['Tomorrow', 'Within 2 hours'];

export const filterByAvailability = (
  product: Product,
  values: CheckedFilters['availability'],
  products: Product[],
  supplierId?: string
): boolean => {
  const { quote } = product;
  const hasOnlyMainAvailability = products.some((item) => item.quote?.isInStock() && !item.quote?.isInNetwork());
  const hasMatchingLinesInProducts = products.some((prod) =>
    prod.quote?.lines?.some((line) => isMatchingAvailabilityLine(line, values))
  );
  const hasMatchingFastestDeliveryLinesInProducts = products.some((prod) =>
    prod.quote?.lines?.some((line) => isMatchingAvailabilityLine(line, fastestDeliveryGroups))
  );
  const hasMatchingSecondaryDeliveryLinesInProducts = products.some((prod) =>
    prod.quote?.lines?.some((line) => isMatchingAvailabilityLine(line, secondaryFastestGroups))
  );
  const hasClosestLinesInProducts = products.some((prod) => prod.quote?.lines.some((line) => line.isClosest()));

  const order = supplierId ? getSortOrder(supplierId) : [];

  const filterPriorityObject = order.reduce<Record<string, number>>(
    (result, value, index) => ({
      ...result,
      [value.toLowerCase()]: index,
    }),
    {}
  );

  const maxPriorityIndex = values.reduce((minIndex, val) => {
    const priorityIndex = filterPriorityObject[val.toLowerCase()];

    return priorityIndex !== undefined && priorityIndex < minIndex ? priorityIndex : minIndex;
  }, order.length);

  return (
    values.some((value) => {
      if (value === Availability.OUT_OF_STOCK) {
        return quote?.isOutOfStock();
      }

      if (value === Availability.BACK_ORDER) {
        return quote?.isBackOrder();
      }

      if (value === Availability.IN_STOCK) {
        return quote?.isInStock();
      }

      if (value === AvailabilityType.FastestDelivery) {
        if (hasMatchingFastestDeliveryLinesInProducts) {
          return quote?.lines?.some((line) => isMatchingAvailabilityLine(line, fastestDeliveryGroups));
        }

        if (hasMatchingSecondaryDeliveryLinesInProducts || !hasOnlyMainAvailability) {
          return quote?.isInStock();
        }

        return quote?.isFastestDelivery();
      }

      if (value === AvailabilityType.InNetwork) {
        return quote?.isNetworkDelivery();
      }

      if (value === AvailabilityType.DirectDelivery) {
        return hasClosestLinesInProducts ? quote?.isDirectDelivery() : quote?.isInStock();
      }

      return !hasMatchingLinesInProducts
        ? quote?.isInStock()
        : quote?.lines?.some((line) => {
            const lineValue = line.group?.toLowerCase() || line.name?.toLowerCase();
            const linePriorityIndex = filterPriorityObject[lineValue];

            return (
              isMatchingAvailabilityLine(line, values) ||
              (linePriorityIndex !== undefined && linePriorityIndex <= maxPriorityIndex)
            );
          });
    }) ?? false
  );
};
