import { api as cartApi } from 'features/cart/api/queries/GetActiveCart.generated';
import { useMutationStatus } from 'shared/api';
import { api as generatedApi } from './CreateCart.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreateCart: {
      invalidatesTags: ['ActiveCart'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const res = await queryFulfilled;
        const id = res.data.createCart?.cart?.id;

        if (id) {
          dispatch(
            cartApi.util.updateQueryData('GetActiveCart', {}, () => ({
              activeCart: {
                id,
              },
            }))
          );
        }
      },
    },
  },
});

const { useCreateCartMutation } = api;

/** @todo move it to entities/cart */
export const useCreateCart = () => {
  const [createCart, status] = useCreateCartMutation();

  useMutationStatus({ status });

  return [createCart, status] as const;
};
