import { css } from '@emotion/react';
import { Box, Button, Dropdown, ImageFallback, Menu, TextCut, Typography, useMedia } from '@partstech/ui';
import { SupplierAccountList } from './components/SupplierAccountList';
import { useSupplierAccountSelector } from './hooks/useSupplierAccountSelector';
import type { SupplierAccount } from 'entities/supplierAccount';

const styles = {
  image: () => css`
    object-fit: contain;
  `,
};

export type SupplierAccountSelectorProps = {
  canChangeSupplierType: boolean;
  isLoading: boolean;
  onChange: (supplierAccount: SupplierAccount) => void;
  supplierAccounts: SupplierAccount[];
  selectedSupplierAccountId: string | null;
};

export const SupplierAccountSelector = ({
  canChangeSupplierType,
  isLoading,
  onChange,
  selectedSupplierAccountId,
  supplierAccounts,
}: SupplierAccountSelectorProps) => {
  const { isMobile } = useMedia();

  const { selectSupplier, selectedSupplierAccount, openModal } = useSupplierAccountSelector({
    canChangeSupplierType,
    isLoading,
    onChange,
    selectedSupplierAccountId,
    supplierAccounts,
  });

  return (
    <Box data-testid="supplierSelectBlock" display="flex" gap={2} onClick={isMobile ? openModal : undefined}>
      <ImageFallback src={selectedSupplierAccount?.supplier?.logo || ''} width={56} height={40} css={styles.image} />

      <Box width="calc(100% - 64px)">
        <Typography variant="subtitle1">
          <TextCut linesCount={1}>{selectedSupplierAccount?.supplier?.name || ''}</TextCut>
        </Typography>

        <Dropdown
          renderButton={({ toggleHandler }) => (
            <Box width={33}>
              <Button
                data-testid="changeSupplierButton"
                disabled={isLoading}
                fill
                iconColor="mono40"
                iconSize="large"
                noHover
                onClick={isMobile ? undefined : toggleHandler}
                trailingIcon="expand_more"
                variant="text"
              >
                <Typography color="linkText" variant="overline">
                  CHANGE SUPPLIER
                </Typography>
              </Button>
            </Box>
          )}
        >
          {({ closeHandler }) => (
            <Menu elevation={12}>
              <SupplierAccountList
                canChangeSupplierType={canChangeSupplierType}
                onChange={selectSupplier(closeHandler)}
                supplierAccounts={supplierAccounts}
                value={selectedSupplierAccount}
              />
            </Menu>
          )}
        </Dropdown>
      </Box>
    </Box>
  );
};
