import { useCallback, useMemo } from 'react';
import { useGetSupplierAccounts } from 'store/queries/currentUser/supplierAccounts/useGetSupplierAccounts';
import { filterSupplierAccounts } from 'utils';
import type { SupplierAccount } from 'entities/supplierAccount';
import type { AccountStatus } from 'shared/api';

type Props = {
  ids?: string[];
  supplierType?: 'dealers' | 'aftermarket';
  type?: 'parts' | 'tires';
  isStockOrdersAllowed?: boolean;
  status?: AccountStatus;
};

type Params = {
  skip?: boolean;
  pollingInterval?: number;
};

export const useSupplierAccounts = (
  { supplierType, type, isStockOrdersAllowed, status, ids }: Props = {},
  { skip, pollingInterval }: Params = {}
) => {
  const {
    accounts: supplierAccounts,
    refetch,
    isSuccess,
    isFetching,
  } = useGetSupplierAccounts({ ids }, { skip, pollingInterval });

  const areSupplierAccountsReadyToSearch = isSuccess && !isFetching;

  const accounts = useMemo(() => {
    let filteredAccounts = supplierAccounts;

    if (type) {
      filteredAccounts = filterSupplierAccounts(filteredAccounts, type);
    }

    if (supplierType) {
      filteredAccounts = filteredAccounts.filter((account) => {
        if (type === 'tires') {
          return true;
        }

        const { supplier } = account;

        if (!supplier) {
          return false;
        }

        return supplierType === 'dealers' ? supplier.isDealer : supplier.isAfterMarket;
      });
    }

    if (isStockOrdersAllowed) {
      filteredAccounts = filteredAccounts.filter((account) => account.supplier?.isStockOrdersAllowed);
    }

    if (status) {
      filteredAccounts = filteredAccounts.filter((account) => account.status === status);
    }

    return filteredAccounts;
  }, [isStockOrdersAllowed, status, supplierAccounts, supplierType, type]);

  const accountIds = useMemo(() => accounts.map((account) => account.id), [accounts]);

  const getAccountByStoreId = useCallback(
    (id: NonNullable<SupplierAccount['store']>['id']) => {
      if (id === null) {
        return null;
      }

      return accounts.find((account) => account.store?.id === id) ?? null;
    },
    [accounts]
  );

  const getAccountBySupplierId = useCallback(
    (id: NonNullable<SupplierAccount['supplier']>['id']) => {
      if (id === null) {
        return null;
      }

      return accounts.find((account) => account.supplier?.id === id) ?? null;
    },
    [accounts]
  );

  return {
    accounts,
    accountIds,
    hasAccounts: accounts.length > 0,
    refetch,
    getAccountByStoreId,
    getAccountBySupplierId,
    isFetching,
    isSuccess,
    areSupplierAccountsReadyToSearch,
  };
};
