import { sortBy } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { parseTimeRange } from '../../lib/parseTimeRange';
import { api as generatedApi } from './GetPartTypeProducts.generated';
import type { GetPartTypeProductsQuery } from './GetPartTypeProducts.generated';
import type { TimeRange } from '../../lib/useReportsFilters';

type PartReport = NonNullable<GetPartTypeProductsQuery['partReports']>;
export type PartTypeProduct = NonNullable<PartReport['partProducts']>[0];

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetPartTypeProducts: {
      providesTags: () => ['Report'],
    },
  },
});

const { useGetPartTypeProductsQuery } = api;

type Props = {
  timeRange: TimeRange;
  locations: string[];
  partTypeId: string;
};

const emptyProducts: PartTypeProduct[] = [];

export const useGetPartTypeProducts = ({ timeRange, locations, partTypeId }: Props) => {
  const { fromDate, toDate } = useMemo(() => parseTimeRange(timeRange), [timeRange]);

  const { data, isLoading } = useGetPartTypeProductsQuery({
    startDate: fromDate,
    endDate: toDate,
    storeIds: locations,
    partTypeId,
    perPage: 1000,
  });

  const products = useMemo(() => data?.partReports?.partProducts || emptyProducts, [data]);
  const mostPopularProduct = useMemo(() => sortBy(products, (product) => -product.totalQuantity)[0], [products]);

  return {
    products,
    mostPopularProduct,
    isLoading,
  };
};
