import { Box } from '@partstech/ui';
import { useCallback, useRef } from 'react';
import { useSupplierSelector } from 'components/SupplierSelector/SupplierSelectorContext';
import { SupplierSelectorSkeleton } from 'components/SupplierSelector/SupplierSelectorSkeleton';
import { SupplierTabs } from 'components/SupplierSelector/SupplierTabs';
import { SupplierType } from 'components/SupplierSelector/SupplierType';
import { SupplierTypeProvider } from 'components/SupplierSelector/SupplierTypeProvider';
import { useHasCatalog, useDynamicAttributes, useSearchParams, useHasLocalInventory } from 'hooks/search';
import { useActiveSupplierAccount, useSupplierAccounts } from 'hooks/supplierAccounts';
import { useSearchResults } from 'pages/SearchResults/SearchResultsContext';

export const SupplierSelector = () => {
  const { isSticky } = useSupplierSelector();
  const { isTiresSearch, isDealersSearch } = useSearchParams();
  const { loadedIds } = useSearchResults();
  const { hasLocalInventory } = useHasLocalInventory();

  const hasCatalog = useHasCatalog();
  const { selectedId, selectSupplier } = useActiveSupplierAccount();
  const { accounts, isFetching } = useSupplierAccounts({
    type: isTiresSearch ? 'tires' : 'parts',
    supplierType: isDealersSearch ? 'dealers' : 'aftermarket',
  });

  const { shouldPickAttributes } = useDynamicAttributes();

  const ref = useRef<HTMLDivElement | null>(null);

  const isLoaded = useCallback((id: string) => (loadedIds ? loadedIds.includes(Number(id)) : true), [loadedIds]);

  const handleSelectSupplier = useCallback(
    (supplierId: string) => {
      if (!isLoaded(supplierId)) {
        return;
      }

      selectSupplier(supplierId, true);
    },
    [isLoaded, selectSupplier]
  );

  const shouldShowSupplierType = !isTiresSearch && !isFetching && !isSticky;

  if (accounts.length === 0 && !hasCatalog) {
    return null;
  }

  return (
    <Box pb={isSticky ? 0 : 2} mt={1} ref={ref}>
      {isFetching && <SupplierSelectorSkeleton />}

      <SupplierTypeProvider>{shouldShowSupplierType && <SupplierType />}</SupplierTypeProvider>

      <Box display="flex" width="100%">
        <Box width="100%">
          {!isFetching && (
            <SupplierTabs
              supplierAccounts={accounts}
              selectedId={selectedId}
              onSupplierSelect={handleSelectSupplier}
              isDisabled={shouldPickAttributes}
              hasCatalog={hasCatalog}
              hasLocalInventory={hasLocalInventory}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
