import { useCallback, useMemo } from 'react';
import { useGetAddresses } from 'entities/address';
import { createStoreFromQueryData } from 'entities/store';
import { createSupplierFromQueryData } from 'entities/supplier';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useLazyGetPartStoresQuery } from './api';
import type { Store } from 'entities/store';
import type { PartStoreInput } from 'shared/api';

const emptyStores: Store[] = [];

export const useLazyGetPartStores = () => {
  const { shop, activeMember } = useGetCurrentUser();
  const { defaultAddress } = useGetAddresses({ skip: !activeMember });

  const [trigger, status] = useLazyGetPartStoresQuery();

  const getPartStores = useCallback(
    async (input: PartStoreInput) => {
      const action = await trigger({ input });

      return {
        ...action,
        stores:
          action.data?.stores?.map((store) => {
            const supplier = store.supplier ? createSupplierFromQueryData(store.supplier) : null;

            return createStoreFromQueryData(store, supplier, defaultAddress?.coordinates ?? null, shop?.pickupRadius);
          }) ?? emptyStores,
      };
    },
    [defaultAddress?.coordinates, shop?.pickupRadius, trigger]
  );

  const stores = useMemo(
    () =>
      status.data?.stores?.map((store) => {
        const supplier = store.supplier ? createSupplierFromQueryData(store.supplier) : null;

        return createStoreFromQueryData(store, supplier, defaultAddress?.coordinates ?? null, shop?.pickupRadius);
      }) ?? emptyStores,
    [defaultAddress?.coordinates, shop?.pickupRadius, status.data?.stores]
  );

  return useMemo(() => [getPartStores, { ...status, stores }] as const, [getPartStores, status, stores]);
};
