import { css } from '@emotion/react';
import { Box, Breadcrumbs, Icon } from '@partstech/ui';
import { OptionsByColumns } from 'components/OptionsByColumns';
import { useCategorySelection, SelectedPartTypes } from 'features/taxonomy';
import { PartType } from 'models';
import type { Theme, BoxProps } from '@partstech/ui';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { PartCategory, PartTypeList } from 'models';

type Props = BoxProps & {
  categories: PartCategory[];
  onChange?: (value: PartTypeList) => void;
  onSelect?: (value: SearchEntryPointInterface) => void;
  isKeyboardDisabled?: boolean;
};

const styles = {
  options: css`
    flex: 1;
  `,
  tags: (theme: Theme) => css`
    max-height: ${theme.sizing(27)};
  `,
};

export const Categories = ({ categories, onChange, onSelect, isKeyboardDisabled, ...props }: Props) => {
  const {
    breadcrumbs,
    options,
    selectedCategory,
    selectedPartTypes,
    selectOption,
    removePartType,
    isSelectedPartType,
  } = useCategorySelection({ categories, searchValue: '' });

  return (
    <Box data-testid="categoriesBlock" width="100%" display="flex" flexDirection="column" {...props}>
      {selectedCategory && (
        <Box display="flex">
          <Breadcrumbs breadcrumbs={breadcrumbs} background="mono2" px={4} py={2} mb={4} />
        </Box>
      )}

      <OptionsByColumns
        data-testid="content"
        className="ps-categories"
        options={options}
        onChange={selectOption}
        onSelect={onSelect}
        maxColumns={2}
        isKeyboardDisabled={isKeyboardDisabled}
        adornmentBefore={(option) =>
          option instanceof PartType ? (
            <Icon name={isSelectedPartType(option) ? 'check_box' : 'check_box_blank'} />
          ) : null
        }
        css={styles.options}
      />

      <SelectedPartTypes partTypes={selectedPartTypes} onRemove={removePartType} onChange={onChange} pt={4} />
    </Box>
  );
};
