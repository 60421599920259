/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../graphql';

export type ConfigurationFieldFragment = {
  __typename?: 'ConfigurationField';
  helper?: Types.Scalars['String']['input'] | null;
  internalName: Types.Scalars['String']['input'];
  label: Types.Scalars['String']['input'];
  link?: Types.Scalars['String']['input'] | null;
  options: Array<Types.Scalars['String']['input']>;
  required: Types.Scalars['Boolean']['input'];
  type: Types.ConfigurationFieldType;
  validationRules?: {
    __typename?: 'ValidationRule';
    inputType: Types.ValidationInputType;
    length?: Types.Scalars['String']['input'] | null;
  } | null;
};

export const ConfigurationFieldFragmentDoc = `
    fragment ConfigurationField on ConfigurationField {
  helper
  internalName
  label
  link
  options
  required
  type
  validationRules {
    inputType
    length
  }
}
    `;
