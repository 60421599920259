/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { ReportLocationFragmentDoc } from '../ReportLocationFragment.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetPartReportLocationsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetPartReportLocationsQuery = {
  __typename?: 'Query';
  partReports?: {
    __typename?: 'PartReports';
    partStores?: Array<{
      __typename?: 'Store';
      id: string;
      name: Types.Scalars['String']['input'];
      supplier?: { __typename?: 'Supplier'; id: string; name: Types.Scalars['String']['input'] } | null;
    }> | null;
  } | null;
};

export const GetPartReportLocationsDocument = `
    query GetPartReportLocations {
  partReports {
    partStores {
      ...ReportLocation
    }
  }
}
    ${ReportLocationFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetPartReportLocations: build.query<GetPartReportLocationsQuery, GetPartReportLocationsQueryVariables | void>({
      query: (variables) => ({ document: GetPartReportLocationsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPartReportLocationsQuery, useLazyGetPartReportLocationsQuery } = injectedRtkApi;
