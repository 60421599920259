import { Box, ImageFallback, Typography } from '@partstech/ui';
import customJobsGif from 'images/content/customJobs/customJobs.gif';

export const CustomJobs = () => (
  <Box textAlign="center">
    <Box mb={6} width="100%" px={{ md: 9 }}>
      <ImageFallback src={customJobsGif} width="100%" />
    </Box>

    <Box mb={4}>
      <Typography variant="bodyText1">
        With custom jobs you can create a tailored list of jobs and assign specific parts to each one. Once set up, you
        ca easily search for all parts in a job. This feature allows you to customize your job lists to match your
        shop’s unique needs, ensuring a seamless workflow.
      </Typography>
    </Box>

    <Box mb={2} px={1}>
      <Typography variant="bodyText1">Custom Jobs is only available with a PartsTech Plus subscription.</Typography>
    </Box>
  </Box>
);
