import { css } from '@emotion/react';
import { Box, Button, Card, Dropdown, useMedia } from '@partstech/ui';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { cartBarWidth } from 'components/Layout/Layout.styles';
import { UniversalPartType } from 'models';
import { SearchTypes } from 'types/search';
import { useTypeaheadSearch } from '../../hooks/typeahead/useTypeaheadSearch';
import { SearchInputSize, SearchTabs } from '../../types';
import { SearchFormContent } from './SearchFormContent';
import { SearchInput } from './SearchInput';
import { useSearchFormInputValue } from './useSearchFormInputValue';
import type { CardProps, DropdownRef, TextFieldProps } from '@partstech/ui';
import type { Vehicle } from 'entities/vehicle';
import type { SearchEntryPointInterface } from 'features/searchForm';

const styles = {
  dropdown: css`
    float: right;
  `,
};

export type SearchFormProps = Pick<TextFieldProps, 'adornmentBefore' | 'adornmentAfter'> & {
  attributeBasedSearch?: boolean;
  cardProps?: CardProps;
  extendsOnFocus?: boolean;
  fullSize?: boolean;
  offset?: number;
  onChange: (values: SearchEntryPointInterface) => void;
  onClear?: () => void;
  onSelectVehicleClick?: () => void;
  onAdditionalButtonClick?: () => void;
  onCreateJobPageOpen?: () => void;
  onTabChange: (type: SearchTabs) => void;
  onTypeChange?: (type: SearchTypes) => void;
  placeholder?: string;
  shouldShowCancelButton?: boolean;
  size?: SearchInputSize;
  suggestionFilter?: (suggestion: SearchEntryPointInterface) => boolean;
  tab: SearchTabs;
  type?: SearchTypes;
  value?: SearchEntryPointInterface | null;
  vehicle: Vehicle | null;
  withSearchEntryPoints?: boolean;
  withEnhancedCategories?: boolean;
  withJobsPriority?: boolean;
  withSearchByPartTypes?: boolean;
  withCustomJobs?: boolean;
  usePortal?: boolean;
};

export type SearchFormRef = {
  clear: () => void;
  close: () => void;
  focus: () => void;
  open: () => void;
  submit: () => void;
  input: string;
};

export const SearchForm = forwardRef<SearchFormRef, SearchFormProps>(
  (
    {
      adornmentAfter,
      adornmentBefore,
      attributeBasedSearch = false,
      cardProps,
      extendsOnFocus = true,
      fullSize = true,
      offset,
      onSelectVehicleClick,
      onAdditionalButtonClick,
      onCreateJobPageOpen,
      onChange,
      onClear,
      onTabChange,
      onTypeChange,
      placeholder,
      shouldShowCancelButton = false,
      size = SearchInputSize.DEFAULT,
      suggestionFilter,
      tab,
      type = SearchTypes.PARTS,
      value,
      vehicle,
      usePortal,
      withEnhancedCategories = false,
      withJobsPriority = false,
      withSearchEntryPoints = true,
      withSearchByPartTypes,
      withCustomJobs = false,
    }: SearchFormProps,
    ref
  ) => {
    const { isMobile, isWide } = useMedia();

    const dropdown = useRef<DropdownRef | null>(null);
    const searchInput = useRef<HTMLInputElement | null>(null);

    const [isTypeaheadOptionsEmpty, setIsTypeaheadOptionsEmpty] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const { value: inputValue, changeValue: changeInputValue, clearValue } = useSearchFormInputValue(value);

    const openDropdown = useCallback(() => {
      if (isTypeaheadOptionsEmpty) {
        return;
      }

      searchInput.current?.focus();
      dropdown.current?.open();
    }, [isTypeaheadOptionsEmpty]);

    const closeDropdown = useCallback(() => {
      dropdown.current?.close();
      searchInput.current?.blur();
    }, []);

    const clearInputAndFocus = useCallback(() => {
      clearValue();
      searchInput.current?.focus();
      onClear && onClear();

      if (!withSearchEntryPoints) {
        dropdown.current?.close();
      } else {
        dropdown.current?.open();
      }
    }, [withSearchEntryPoints, clearValue, onClear, searchInput]);

    const submit = useCallback(
      (submitValue: SearchEntryPointInterface) => {
        if ('isTire' in submitValue && submitValue.isTire) {
          onTypeChange && onTypeChange(SearchTypes.TIRES);
          changeInputValue('');

          return;
        }

        if (submitValue instanceof UniversalPartType) {
          onChange(submitValue);

          if (submitValue.shouldPickAttributes(Boolean(vehicle))) {
            onTabChange(SearchTabs.FLUIDS_CHEMICALS);
          } else {
            closeDropdown();
          }

          return;
        }

        changeInputValue(submitValue.populateInput());
        onChange(submitValue);
        closeDropdown();
      },
      [changeInputValue, onChange, closeDropdown, onTypeChange, vehicle, onTabChange]
    );

    const handleSelectVehicleClick = useCallback(() => {
      closeDropdown();
      onSelectVehicleClick && onSelectVehicleClick();
    }, [closeDropdown, onSelectVehicleClick]);

    const {
      options: typeaheadOptions,
      submitString,
      enableSearch,
      disableSearch,
      isSearchEnabled,
      isVisible,
      show: showTypeahead,
      hide: hideTypeahead,
      isTypeaheadFetching,
    } = useTypeaheadSearch(inputValue, { onSelect: submit, vehicle, searchType: type, attributeBasedSearch });

    const filteredTypeheadOptions = useMemo(
      () => (suggestionFilter ? typeaheadOptions.filter(suggestionFilter) : typeaheadOptions),
      [suggestionFilter, typeaheadOptions]
    );

    /**
     * @param shouldShowTypeahead - if true, typeahead will be shown after selecting entry point
     */
    const handleSelect = useCallback(
      (shouldShowTypeahead: boolean) => (entryPoint: SearchEntryPointInterface) => {
        shouldShowTypeahead ? showTypeahead() : hideTypeahead();

        disableSearch();
        changeInputValue(entryPoint.populateInput());
      },
      [changeInputValue, disableSearch, hideTypeahead, showTypeahead]
    );

    const dropdownHeight = useMemo(() => {
      const defaultHeight = withEnhancedCategories ? 150 : 129;

      if (isMobile && searchInput.current) {
        return `calc(100dvh - ${searchInput.current.getBoundingClientRect().bottom}px)`;
      }

      return defaultHeight;
    }, [isMobile, withEnhancedCategories]);

    const handleInputSubmit = useCallback(() => {
      if (!isSearchEnabled) {
        return;
      }

      submitString();
      searchInput.current?.blur();
    }, [isSearchEnabled, submitString]);

    const handleSearchInputChange = useCallback(
      (enteredValue: string) => {
        if (enteredValue.length > 0) {
          openDropdown();
        } else if (!withSearchEntryPoints) {
          dropdown.current?.close();
        }

        showTypeahead();
        enableSearch();
        changeInputValue(enteredValue);
      },
      [changeInputValue, enableSearch, openDropdown, showTypeahead, withSearchEntryPoints]
    );

    const handleInputFocus = useCallback(() => {
      setIsFocused(true);
      enableSearch();

      if (withSearchEntryPoints || (inputValue.length && typeaheadOptions.length)) {
        openDropdown();
        showTypeahead();
      }
    }, [enableSearch, openDropdown, showTypeahead, withSearchEntryPoints, inputValue, typeaheadOptions]);

    const handleInputBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    useEffect(() => {
      if (isTypeaheadFetching) {
        return;
      }

      setIsTypeaheadOptionsEmpty(Boolean(inputValue.length && !filteredTypeheadOptions.length));
    }, [inputValue, filteredTypeheadOptions, isTypeaheadFetching]);

    useEffect(() => {
      if ((!inputValue.length && !withSearchEntryPoints) || !isVisible || isTypeaheadFetching || !isFocused) {
        return;
      }

      if (isTypeaheadOptionsEmpty) {
        dropdown.current?.close();
      } else {
        dropdown.current?.open();
      }
    }, [isTypeaheadOptionsEmpty, withSearchEntryPoints, inputValue, isVisible, isTypeaheadFetching, isFocused]);

    useImperativeHandle(ref, () => ({
      clear: clearInputAndFocus,
      close: closeDropdown,
      focus: () => searchInput.current?.focus(),
      open: openDropdown,
      submit: submitString,
      input: inputValue,
    }));

    const handleAdditionalButtonClick = useCallback(() => {
      closeDropdown();
      onAdditionalButtonClick?.();
    }, [closeDropdown, onAdditionalButtonClick]);

    const handleCreateJobPageOpen = useCallback(() => {
      closeDropdown();
      onCreateJobPageOpen?.();
    }, [closeDropdown, onCreateJobPageOpen]);

    return (
      <Dropdown
        ref={dropdown}
        data-testid="searchForm"
        fullWidth
        disableAnimation={isMobile}
        shrinkContentHeight
        stretchContent={!fullSize}
        usePortal={usePortal}
        offset={offset}
        preferPlacement={isWide ? 'bottom-end' : 'bottom-start'}
        isFixed
        renderButton={({ isOpened, closeHandler }) => (
          <Box display="flex" width="100%" gap={2}>
            <SearchInput
              suffix={adornmentAfter}
              prefix={adornmentBefore}
              onChange={handleSearchInputChange}
              onClear={clearInputAndFocus}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onSubmit={handleInputSubmit}
              placeholder={placeholder}
              ref={searchInput}
              size={size}
              value={inputValue}
              extendsOnFocus={extendsOnFocus}
            />
            {isOpened && shouldShowCancelButton && isMobile && (
              <Button onClick={closeHandler} variant="text">
                Cancel
              </Button>
            )}
          </Box>
        )}
      >
        {() => (
          <Card
            elevation={isMobile ? 0 : 6}
            borderWidth={0}
            radius={isMobile ? 0 : 8}
            p={4}
            width={fullSize ? { sm: '100vw', md: '100vw', xl: '100vw' } : '100%'}
            maxWidth={
              fullSize
                ? {
                    md: `calc(100dvw - 90px)`,
                    xl: `calc(100% - 346px - ${cartBarWidth}px)`,
                    xxl: `calc(100% - 386px - ${cartBarWidth}px)`,
                  }
                : 'auto'
            }
            ml={{ md: '-56px', xl: 0 }}
            height={dropdownHeight}
            data-testid="searchDropdown"
            css={styles.dropdown}
            {...cardProps}
          >
            <SearchFormContent
              inputValue={inputValue}
              isVisible={isVisible}
              onRecentSearchSelect={closeDropdown}
              onSelect={handleSelect}
              onSelectVehicleClick={handleSelectVehicleClick}
              onAdditionalButtonClick={handleAdditionalButtonClick}
              onCreateJobPageOpen={handleCreateJobPageOpen}
              onSubmit={submit}
              onTabChange={onTabChange}
              options={filteredTypeheadOptions}
              tab={tab}
              value={value}
              vehicle={vehicle}
              withJobsPriority={withJobsPriority}
              withAttributeBasedSearch={attributeBasedSearch}
              withEnhancedCategories={withEnhancedCategories}
              withSearchByPartTypes={withSearchByPartTypes}
              withCustomJobs={withCustomJobs}
            />
          </Card>
        )}
      </Dropdown>
    );
  }
);
