import { useCallback, useMemo } from 'react';
import { createCategoryFromQuery } from 'factories';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useGetCategoriesQuery } from './GetCategories.generated';
import type { PartCategory } from 'models';
import type { PartSubCategory } from 'shared/api';

const emptySubCategories: PartSubCategory[] = [];
const emptyCategories: PartCategory[] = [];

type Props = {
  categoryId?: string;
  skip?: boolean;
};

export const useCategories = ({ categoryId, skip }: Props = {}) => {
  const { hasCurrentUser } = useGetCurrentUser();
  const { data, ...props } = useGetCategoriesQuery({}, { skip: !hasCurrentUser || skip });

  const categories = useMemo(
    () => data?.categories?.map(createCategoryFromQuery) ?? emptyCategories,
    [data?.categories]
  );

  const subCategories = useMemo(
    () => data?.categories?.find((category) => category.id === categoryId)?.subCategories ?? emptySubCategories,
    [categoryId, data?.categories]
  );

  const findCategory = useCallback(
    (partCategoryId: string) => categories.find((category) => category.id === partCategoryId),
    [categories]
  );

  const findSubCategory = useCallback(
    (subCategoryId: string) => {
      const foundSubCategory = categories
        .flatMap((category) => category.subCategories)
        .find((subCategory) => subCategory.id === subCategoryId);

      return foundSubCategory;
    },
    [categories]
  );

  return {
    categories,
    subCategories,
    findCategory,
    findSubCategory,
    ...props,
  };
};
