import { useMedia, useSnackbar } from '@partstech/ui';
import { usePrevious } from '@partstech/ui/hooks';
import { useEffect, useMemo } from 'react';
import type { Cart } from 'models';

export const useBundleCompletionSnackbar = (cart: Cart | null) => {
  const { isMobile } = useMedia();
  const { addSnackbar } = useSnackbar();

  const currentBundledCount = useMemo(
    () =>
      cart?.orders.reduce(
        (total, order) =>
          total +
          order.items.reduce(
            (count, item) => count + (item.bundled && item.bundle && item.bundle.savingsAmount > 0 ? 1 : 0),
            0
          ),
        0
      ) || 0,
    [cart]
  );

  const previousBundledCount = usePrevious(currentBundledCount) || 0;
  const previousCartIsEmpty = usePrevious(cart?.isEmpty) ?? true;

  useEffect(() => {
    if (currentBundledCount > previousBundledCount && !previousCartIsEmpty) {
      addSnackbar({
        variant: 'success',
        header: 'Great news!',
        label: "You've added eligible parts and unlocked a bundle discount. Check your cart to view your savings.",
        position: { vertical: 'bottom', horizontal: 'middle' },
        duration: 1.5,
        fullWidth: isMobile,
      });
    }
  }, [addSnackbar, currentBundledCount, isMobile, previousBundledCount, previousCartIsEmpty]);
};
