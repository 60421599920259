import { Icon, IconText, Tooltip } from '@partstech/ui';
import { plural } from '@partstech/ui/utils';

type Props = {
  points?: number | null;
  isPromotion?: boolean;
};

export const RewardPoints = ({ points, isPromotion = false }: Props) => {
  if (!points || points === 0) {
    return null;
  }

  return (
    <Tooltip
      offsetX={-176}
      offsetY={4}
      tooltip="Buy this part today to get more points than normally"
      isHidden={!isPromotion}
    >
      {isPromotion && <Icon name="star" size="medium" color="negative" />}
      <IconText icon="database" color="negative" textTransform="uppercase">
        {`${points} ${plural('point', points)}`}
      </IconText>
    </Tooltip>
  );
};
