import { useSnackbar } from '@partstech/ui';
import { isNotNull } from '@partstech/ui/utils';
import { useCallback, useState } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { Routes } from 'constant';
import { createCartOrderFromActiveCart } from 'factories';
import { getSubmittedLabor } from 'features/labor';
import { useSupplierAccounts } from 'hooks/supplierAccounts';
import { useBuyCart } from '../api/mutations/buyCart';
import { useSubmitCart } from '../api/mutations/submitCart';
import { useSubmitOrderTracking } from './useSubmitOrderTracking';
import type { Mitchell1ShoppingCart } from 'integrations/mitchell1';
import type { CartOrder, CartTotals, LaborApplication } from 'models';

type Props = {
  orders?: CartOrder[];
  totals?: CartTotals;
  laborApplications?: LaborApplication[];
  localInventory?: CartOrder | null;
  refetchCart: () => void;
};

export const useBuyOrSubmitCart = ({ orders, totals, laborApplications = [], localInventory, refetchCart }: Props) => {
  const navigate = useAppNavigate();
  const { addSnackbar } = useSnackbar();
  const { accounts } = useSupplierAccounts();
  const { trackCartSubmitOrder } = useSubmitOrderTracking();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [paidOrders, setPaidOrders] = useState<CartOrder[]>([]);
  const [mitchell1Payload, setMitchell1Payload] = useState<Mitchell1ShoppingCart | null>(null);

  const [buyCart] = useBuyCart();
  const [submitCart] = useSubmitCart();

  const buyOrSubmit = useCallback(
    async (action: 'buy' | 'submit') => {
      if (!orders || !totals) {
        return;
      }

      setIsSubmitting(true);

      const submittedLabor = getSubmittedLabor(laborApplications);

      const laborItemIds = laborApplications.map((item) => item.customId).filter(isNotNull);

      const localInventoryItemIds = localInventory?.items.map((item) => item.id).filter(isNotNull);

      const res = await (action === 'buy' ? buyCart : submitCart)(
        orders,
        laborItemIds,
        submittedLabor,
        localInventoryItemIds
      );

      if (res.error) {
        addSnackbar({ label: res.error });
        refetchCart();
        setIsSubmitting(false);
        return;
      }

      if (action === 'buy') {
        trackCartSubmitOrder({ orders, totals, laborApplications });
      }

      if (res.redirectUrl) {
        window.location.href = res.redirectUrl;
        return;
      }

      if (res.mitchell1Payload) {
        setMitchell1Payload(res.mitchell1Payload);
        navigate(Routes.CART_TRANSFER);
        return;
      }

      if (res.orders) {
        setPaidOrders(res.orders.map((order) => createCartOrderFromActiveCart({ order, accounts })));
      }

      navigate(Routes.CART);
      refetchCart();
      setIsSubmitting(false);
    },
    [
      orders,
      laborApplications,
      localInventory?.items,
      buyCart,
      submitCart,
      navigate,
      refetchCart,
      addSnackbar,
      trackCartSubmitOrder,
      totals,
      accounts,
    ]
  );

  const buy = useCallback(() => {
    buyOrSubmit('buy');
  }, [buyOrSubmit]);

  const submit = useCallback(() => {
    buyOrSubmit('submit');
  }, [buyOrSubmit]);

  const clearPaidOrders = useCallback(() => {
    setPaidOrders([]);
  }, []);

  return {
    isSubmitting,
    paidOrders,
    mitchell1Payload,
    buy,
    submit,
    clearPaidOrders,
  };
};
