import { Box, Typography, useMedia } from '@partstech/ui';
import { memo, useMemo } from 'react';
import { usePermissions } from 'entities/permission';
import { usePriceMatrices } from 'hooks/retailPricing';
import { currency } from 'shared/lib/string';
import { getActualPrice } from 'utils';
import { AdditionalPrices } from './AdditionalPrices';
import type { BoxProps } from '@partstech/ui';
import type { PricingFlags } from 'types/product';

type Props = {
  coreCharge?: number | null;
  fetCharge?: number | null;
  hasPricingInfo?: boolean;
  listPrice?: number | null;
  price: number;
  retailPrice?: number | null;
  mapPrice?: number | null;
  size?: 'default' | 'small';
  priceAlign?: BoxProps['textAlign'];
  fetPricePosition?: 'left' | 'right';
  discount?: number;
} & Partial<PricingFlags>;

export const Price: React.FunctionComponent<Props> = memo(
  ({
    coreCharge,
    price,
    fetCharge,
    listPrice,
    retailPrice,
    mapPrice,
    size = 'default',
    hasPricingInfo = false,
    hasSavings = false,
    showOnlyRetailPrice = false,
    isTire = false,
    priceAlign = 'right',
    fetPricePosition = 'left',
    discount,
  }) => {
    const { isMobile } = useMedia();

    const {
      shop: { retailPricingAllowed },
    } = usePermissions();

    const { hasSomeMatrix } = usePriceMatrices();

    const flags = {
      showOnlyRetailPrice: retailPricingAllowed && showOnlyRetailPrice,
      isTire,
      hasSavings,
    };

    const actualPrice = getActualPrice(retailPrice, price, flags);
    const actualPriceWithDiscount = useMemo(() => actualPrice - (discount || 0), [actualPrice, discount]);

    return (
      <Box className="price" textAlign={priceAlign} mb={hasPricingInfo || isMobile ? 0 : 2}>
        <Box display={discount ? 'flex' : 'block'} alignItems="flex-end" gap={1}>
          {Boolean(discount) && (
            <Typography color="subtleText" variant="bodyText2" lineThrough>
              {currency(actualPrice)}
            </Typography>
          )}

          <Typography
            variant={size === 'small' ? 'h3' : 'tagline2'}
            color="black"
            bold
            component="span"
            data-testid="price"
          >
            {currency(actualPriceWithDiscount)}
          </Typography>
        </Box>

        <AdditionalPrices
          coreCharge={coreCharge}
          price={price}
          fetCharge={fetCharge}
          listPrice={listPrice}
          retailPrice={retailPrice}
          mapPrice={mapPrice}
          size={size}
          hasSavings={hasSavings}
          showOnlyRetailPrice={retailPricingAllowed && showOnlyRetailPrice}
          isTire={isTire}
          fetPricePosition={fetPricePosition}
          retailPricingAllowed={retailPricingAllowed && hasSomeMatrix}
        />
      </Box>
    );
  }
);
