import { useCallback } from 'react';
import { useMutationCallback } from 'shared/api';
import { api as generatedApi } from './DeleteCustomJob.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    DeleteCustomJob: {
      invalidatesTags: ['CustomJob', 'VehicleById', 'VehicleByPlateVin'],
    },
  },
});

const { useDeleteCustomJobMutation } = api;

export const useDeleteCustomJob = () => {
  const [mutation, status] = useMutationCallback(useDeleteCustomJobMutation);

  const deleteCustomJob = useCallback(
    (id: string) => {
      mutation({ input: { id } });
    },
    [mutation]
  );

  return [deleteCustomJob, status] as const;
};
