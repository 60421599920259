import { useCallback, useMemo } from 'react';
import { useGetAddresses } from 'entities/address';
import { createStoreFromQueryData } from 'entities/store';
import { createSupplierAccountFromData } from 'entities/supplierAccount';
import { useUpdateSupplierAccountMutation } from 'store/mutations/currentUser/supplierAccounts/updateAccount';
import { useUpdateSupplierAccountWithoutStoreMutation } from 'store/mutations/currentUser/supplierAccounts/updateAccountWithoutStore';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { convertFormFieldsToAccountField } from 'utils';
import type { SupplierAccount } from 'entities/supplierAccount';
import type { Exact, UpdateShopAccountInput } from 'shared/api';

const denormilizeFormData = (payload: UpdateShopAccountPayload): Exact<{ input: UpdateShopAccountInput }> => ({
  input: {
    ...payload,
    additionalInformation: payload.additionalInformation
      ? convertFormFieldsToAccountField(payload.additionalInformation)
      : undefined,
    credentials: payload.credentials ? convertFormFieldsToAccountField(payload.credentials) : undefined,
  },
});

type UpdateShopAccountPayload = {
  accountId: string;
  additionalInformation?: Record<string, string>;
  credentials?: Record<string, string>;
  nickname?: string;
  storeId?: string;
};

export const useUpdateSupplierAccount = (needsFullUpdate: boolean = false) => {
  const { defaultAddress } = useGetAddresses();
  const { shop } = useGetCurrentUser();

  const [updateFullAccount, statusFullUpdate] = useUpdateSupplierAccountMutation();
  const [updateAccountWithoutStore, statusUpdateWithoutStore] = useUpdateSupplierAccountWithoutStoreMutation();

  const updateFullSupplierAccount = useCallback(
    async (payload: UpdateShopAccountPayload, account: SupplierAccount) => {
      const response = await updateFullAccount(denormilizeFormData(payload));

      if ('error' in response) {
        return null;
      }

      const modifiedAccount = response.data.updateShopAccount?.account;

      if (!modifiedAccount) {
        return null;
      }

      const store = modifiedAccount?.store
        ? createStoreFromQueryData(
            modifiedAccount.store,
            account.supplier,
            defaultAddress?.coordinates ?? null,
            shop?.pickupRadius
          )
        : null;

      return createSupplierAccountFromData(modifiedAccount, account.supplier, store);
    },
    [updateFullAccount, defaultAddress?.coordinates, shop?.pickupRadius]
  );

  const updateSupplierAccountWithoutStore = useCallback(
    async (payload: UpdateShopAccountPayload, account: SupplierAccount) => {
      const response = await updateAccountWithoutStore(denormilizeFormData(payload));

      if ('error' in response) {
        return null;
      }

      const modifiedAccount = response.data.updateShopAccount?.account;

      if (!modifiedAccount) {
        return null;
      }

      return createSupplierAccountFromData(modifiedAccount, account.supplier, account.store);
    },
    [updateAccountWithoutStore]
  );

  return useMemo(() => {
    if (needsFullUpdate) {
      return [updateFullSupplierAccount, statusFullUpdate] as const;
    }

    return [updateSupplierAccountWithoutStore, statusUpdateWithoutStore] as const;
  }, [
    needsFullUpdate,
    statusFullUpdate,
    statusUpdateWithoutStore,
    updateSupplierAccountWithoutStore,
    updateFullSupplierAccount,
  ]);
};
