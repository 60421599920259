import { api as activeCartApi } from './GetActiveCart.generated';
import { api as generatedApi } from './GetActiveCartLocalInventoryItems.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetActiveLocalInventoryItems: {
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          activeCartApi.util.updateQueryData('GetActiveCart', undefined, (draft) => {
            if (!data.activeCart || !draft.activeCart) {
              return;
            }

            if (data.activeCart.vehicles) {
              Object.assign(draft.activeCart, { vehicles: data.activeCart.vehicles });
            }

            if (data.activeCart.localInventoryItems) {
              data.activeCart.localInventoryItems.forEach((item) => {
                const inventoryItem = draft.activeCart?.localInventoryItems?.find(
                  (draftItem) => draftItem.localInventoryUID === item.localInventoryUID
                );

                if (inventoryItem) {
                  Object.assign(inventoryItem, item);
                } else {
                  draft.activeCart?.localInventoryItems?.push(item);
                }
              });
            }
          })
        );
      },
    },
  },
});

export const { useLazyGetActiveLocalInventoryItemsQuery } = api;
