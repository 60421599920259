import { sortBy } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { api as generatedApi } from './GetSpendReportLocations.generated';
import type { GetSpendReportLocationsQuery } from './GetSpendReportLocations.generated';

type Reports = NonNullable<GetSpendReportLocationsQuery['spendReports']>;
export type SpendReportLocation = NonNullable<Reports['spendStores']>[0];
type Location = { id: string; name: string };

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetSpendReportLocations: {
      providesTags: () => ['Report'],
    },
  },
});

const { useGetSpendReportLocationsQuery } = api;

const emptyLocations: Location[] = [];

export const useGetSpendReportLocations = () => {
  const { data, ...rest } = useGetSpendReportLocationsQuery();

  const locations = useMemo<Location[]>(() => {
    const entities = data?.spendReports?.spendStores?.map((store) => ({
      id: store.id,
      name: `${store.supplier?.name} - ${store.name}`,
    }));

    return entities ? sortBy(entities, ({ name }) => `${name}`.toLocaleLowerCase()) : emptyLocations;
  }, [data]);

  return {
    ...rest,
    locations,
  };
};
