import { css } from '@emotion/react';
import { Box, Divider, ImageFallback, Link, Tooltip, Typography, useMedia } from '@partstech/ui';
import { Routes } from 'constant';

const styles = {
  link: css`
    &:hover {
      text-decoration: underline;
    }
  `,
};

type Props = {
  title: string;
  linkText?: string;
  linkIcon?: string;
  tooltip?: string;
};

export const CartBarOrderHeader = ({ title, linkText, tooltip, linkIcon }: Props) => {
  const { isWide } = useMedia();

  return (
    <Box position="sticky" top={0} background="mono2" zIndex="initial">
      <Link to={Routes.CART} underline="none" data-testid="orderHeader">
        <Tooltip display="block" tooltip={tooltip} isHidden={!isWide || !tooltip} offsetX={130}>
          <Box display="flex" alignItems="center" justifyContent="space-between" px={3} py={1}>
            <Typography variant="subtitle2" color="dark" bold>
              {title}
            </Typography>

            {isWide && (
              <>
                {linkText && !linkIcon && (
                  <Typography variant="bodyText2" color="linkText" css={styles.link}>
                    {linkText}
                  </Typography>
                )}
                {linkIcon && <ImageFallback src={linkIcon} height={20} width={18} />}
              </>
            )}
          </Box>

          <Divider />
        </Tooltip>
      </Link>
    </Box>
  );
};
