import { useCallback } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { useCreateShopContext } from 'components/Layout/SignUpLayout/CreateShopContext/CreateShopContext';
import { useSignupQueryParams } from 'hooks/signup';
import { useLaunchDarkly } from 'integrations/launchDarkly';
import { OnboardStepType, ShopType } from 'shared/api';
import { useSetDiyUserMutation } from 'store/mutations/currentUser/setDiyUser';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { useOnboardSteps } from 'store/queries/currentUser/useOnboardSteps';
import { SignUpPages, getSignUpUrl } from 'utils/url';
import type { MouseEvent } from 'react';

export const useShopType = () => {
  const navigate = useAppNavigate();

  const {
    flags: { diySignUpFlow },
  } = useLaunchDarkly();

  const { firstName } = useGetCurrentUser();
  const { getValueByStep } = useOnboardSteps();
  const searchParams = useSignupQueryParams();
  const { shopType, setShopType } = useCreateShopContext();

  const [selectDIYShop] = useSetDiyUserMutation();

  const handleSelect = useCallback(
    (option: ShopType) => {
      setShopType(option);

      navigate(getSignUpUrl(SignUpPages.ShopDetails, searchParams));
    },
    [searchParams, setShopType, navigate]
  );

  const handleSelectDIY = useCallback(
    async (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      if (diySignUpFlow) {
        await selectDIYShop();

        navigate(getSignUpUrl(SignUpPages.Restricted));

        return;
      }

      handleSelect(ShopType.Diy);
    },
    [diySignUpFlow, handleSelect, navigate, selectDIYShop]
  );

  return {
    firstName,
    defaultOption: shopType ?? (getValueByStep(OnboardStepType.ShopType) as ShopType) ?? undefined,
    selectOption: handleSelect,
    selectDIY: handleSelectDIY,
  };
};
