import { css } from '@emotion/react';
import { Box, Typography } from '@partstech/ui';
import type { BoxProps, Theme } from '@partstech/ui';

const styles = {
  button: (isActive: boolean) => (theme: Theme) => css`
    cursor: pointer;
    border: ${isActive ? '2px solid' : '1px solid'};
    border-radius: ${theme.sizing(2)};
    background-color: ${isActive ? theme.color.lightBlue : theme.color.white};
    border-color: ${isActive ? theme.color.primary : theme.color.mono6};

    &:hover {
      cursor: ${isActive ? 'default' : 'pointer'};
      background-color: ${isActive ? theme.color.lightBlue : theme.color.mono6};
    }
  `,
};

type Props = {
  icon: string;
  title: string;
  isActive: boolean;
  onClick: () => void;
} & BoxProps;

export const CarouselAction = ({ icon, title, isActive, onClick, ...boxProps }: Props) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    width={17}
    height={16}
    p={isActive ? 0.75 : 1}
    className={isActive ? 'active' : undefined}
    css={styles.button(isActive)}
    onClick={onClick}
    {...boxProps}
  >
    <img src={icon} alt={`${title}`} />
    <Typography variant="caption" color="primary">
      {title}
    </Typography>
  </Box>
);
