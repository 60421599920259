import { Box, Button, Icon, Menu, MenuItem, Popover, Typography, useMedia } from '@partstech/ui';
import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { EditHoursModal } from '../../EditHoursModal';
import { styles } from '../styles';
import type { LaborApplication as LaborApplicationModel } from 'models';

type Props = {
  laborApplication: LaborApplicationModel;
  customDuration?: number;
  onRemove: (laborApplication: LaborApplicationModel) => void;
  onChangeCustomDuration: (laborApplicationId: string, customDuration: number) => void;
};

export const LaborReviewLine = ({ laborApplication, customDuration, onRemove, onChangeCustomDuration }: Props) => {
  const { isMobile } = useMedia();
  const { open, close } = useCreateModal(
    EditHoursModal,
    {
      size: 'dialog',
      canBeBottomSheet: true,
    },
    { customId: 'editHours' }
  );

  const handleSaveHours = useCallback(
    (value: number) => {
      onChangeCustomDuration(laborApplication.id, value);
      close();
    },
    [close, laborApplication.id, onChangeCustomDuration]
  );

  const handleRemove = useCallback(() => {
    onRemove(laborApplication);
  }, [laborApplication, onRemove]);

  const handleClickEdit = useCallback(() => {
    open({
      customDuration,
      laborApplication,
      onSave: handleSaveHours,
    });
  }, [customDuration, handleSaveHours, laborApplication, open]);

  return (
    <Box pl={1} mt={1} display="flex" alignItems="center" gap={2} data-testid="reviewLine" css={styles.line}>
      <Box flexGrow={1}>
        <Typography variant="bodyText2">{laborApplication.title}</Typography>
        {laborApplication.label && (
          <Typography variant="caption" color="subtleText">
            {laborApplication.label}
          </Typography>
        )}
      </Box>

      <Box display="flex" alignItems="center" flexShrink={0}>
        <Typography variant="bodyText2">{(customDuration || laborApplication.duration).toFixed(2)} hours</Typography>

        <Popover
          hideArrow
          preferPlacement="bottom"
          padding={0}
          withCloseButton={isMobile}
          targetElement={<Button leadingIcon="more_vert" size="dense" variant="light" data-testid="editHoursButton" />}
        >
          {({ closeHandler }) => (
            <Box width={{ sm: 'auto', md: 36 }}>
              <Menu onSelect={closeHandler} fullWidth builtIn>
                <MenuItem onClick={handleClickEdit} prefix={<Icon name="edit" color="subtleText" />}>
                  Edit hours
                </MenuItem>
                <MenuItem onClick={handleRemove} prefix={<Icon name="delete" color="negative" />} color="negative">
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Popover>
      </Box>
    </Box>
  );
};
