import { useMemo } from 'react';
import { createFullProductFromQuery } from 'factories';
import { createBaseLocalInventoryProductFromQuery } from 'factories/product';
import { useFailedProductTracking } from 'hooks/product';
import { useToggleRetailPrice } from 'hooks/retailPricing';
import { useSearchPreferredBrands } from 'hooks/search';
import { useSupplierAccounts } from 'hooks/supplierAccounts';
import { api as generatedApi } from 'store/queries/product/GetProduct.generated';
import { getPreferredBrandsByProducts, isPreferredBrand } from 'utils';
import { useGetLocalInventoryProductQuery } from './GetLocalInventoryProduct.generated';
import type { FullLocalInventoryProductFragment } from './FullLocalInventoryProductFragment.generated';
import type { FullProductFragment } from './FullProductFragment.generated';
import type { PreferredBrandUnified } from 'types/preferredBrand';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetProduct: {
      providesTags: ['Product'],
    },
  },
});

const { useGetProductQuery } = api;

type Props = {
  accountId?: string | null;
  lineCardId?: string | null;
  localInventoryUID?: string;
  partNumberId: string;
  partTypeId?: string | null;
  vehicleId?: string | null;
  vin?: string | null;
};

const isPreferredProductBrand = (
  product: FullProductFragment | FullLocalInventoryProductFragment,
  preferredBrands: PreferredBrandUnified[],
  count: number
) => {
  const productsPreferredBrands = getPreferredBrandsByProducts(preferredBrands, [product], count);

  return isPreferredBrand(productsPreferredBrands)(
    product.brand?.id ?? '',
    product.brand?.name ?? '',
    product.partType?.id ?? ''
  );
};

export const useProduct = (props: Props, skip: boolean = false) => {
  const { accounts } = useSupplierAccounts();

  const { isShown: showRetailPrice } = useToggleRetailPrice();
  const { preferredBrands, maxPreferredBrandsCount } = useSearchPreferredBrands();

  const isLocalInventoryProduct = Boolean(props.localInventoryUID);

  const productQuery = useGetProductQuery(props, {
    skip: skip || isLocalInventoryProduct,
  });

  const localInventoryProductQuery = useGetLocalInventoryProductQuery(
    { ...props, localInventoryUID: props.localInventoryUID ?? '' },
    { skip: skip || !isLocalInventoryProduct }
  );

  const data = useMemo(() => {
    if (isLocalInventoryProduct) {
      return localInventoryProductQuery;
    }

    return productQuery;
  }, [isLocalInventoryProduct, localInventoryProductQuery, productQuery]);

  const account = useMemo(() => accounts.find((item) => item.id === props.accountId), [accounts, props.accountId]);

  const product = useMemo(() => {
    const params = { account, vehicleId: props.vehicleId, showRetailPrice };

    if (localInventoryProductQuery.currentData?.localInventoryProduct) {
      const localInventoryProduct = localInventoryProductQuery.currentData?.localInventoryProduct;

      return createBaseLocalInventoryProductFromQuery({
        ...params,
        product: localInventoryProduct,
        isPreferred: isPreferredProductBrand(localInventoryProduct, preferredBrands, maxPreferredBrandsCount),
      });
    }

    if (productQuery.currentData?.product) {
      const fullProduct = productQuery.currentData.product;

      return createFullProductFromQuery({
        ...params,
        product: fullProduct,
        isPreferred: isPreferredProductBrand(fullProduct, preferredBrands, maxPreferredBrandsCount),
      });
    }

    return null;
  }, [
    account,
    props.vehicleId,
    showRetailPrice,
    localInventoryProductQuery.currentData?.localInventoryProduct,
    productQuery.currentData?.product,
    preferredBrands,
    maxPreferredBrandsCount,
  ]);

  useFailedProductTracking(data.isError, {
    partnumberid: props?.partNumberId ?? '',
    credentialId: props?.accountId ? Number(props.accountId) : null,
    linecard: props?.lineCardId ? Number(props.lineCardId) : null,
    part_term: props?.partTypeId ? Number(props.partTypeId) : null,
    vehicle: props?.vehicleId ? Number(props.vehicleId) : null,
    vin: props?.vin,
  });

  return useMemo(
    () => ({
      product,
      isLoading: data.isFetching,
      isSuccess: data.isSuccess,
      isFailed: data.isError,
      refetch: data.refetch,
    }),
    [data.isError, data.isFetching, data.isSuccess, data.refetch, product]
  );
};
