import { keys, uniqBy } from '@partstech/ui/utils';
import { PREDEFINED_FILTERS } from 'constant';
import { createAvailabilityOptions } from './createAvailabilityOptions';
import { createBrandOption } from './createBrandOption';
import { createOption } from './createOption';
import { createPartTypeOption } from './createPartTypeOption';
import { createRebateOption } from './createRebateOption';
import { createStockOption } from './createStockOption';
import { MATCHING_SETS_VALUE } from './getMatchingSets';
import { makeAttributeFilterOptions } from './makeAttributeFilterOptions';
import type { AttributeName } from 'constant';
import type { Product } from 'models';
import type { CheckedFilters, CreateFiltersConfig, Filter, FilterOption } from 'types/search';

const getOptionCreator = (name: Filter['name']) => {
  if (name === 'parts') {
    return createPartTypeOption(0);
  }

  if (name === 'HasRebate') {
    return createRebateOption(0);
  }

  return createBrandOption(0);
};

export const getCheckedOptions = (
  products: Product[],
  checkedFilters: Partial<CheckedFilters>,
  config: CreateFiltersConfig
) => {
  const names = keys(checkedFilters);

  return names.reduce(
    (filters: Record<Filter['name'], FilterOption[]>, name) => {
      const checkedValues = checkedFilters[name] ?? [];

      let options: FilterOption[] = [];

      switch (true) {
        case name === 'availability':
          options = [...products.map(createStockOption(0)), ...createAvailabilityOptions(products, config)];
          break;

        case PREDEFINED_FILTERS.includes(name):
          options = products.map(getOptionCreator(name));

          break;

        case name === 'Position': {
          const positionOptions = makeAttributeFilterOptions(name, products, []);

          options = checkedValues.includes(MATCHING_SETS_VALUE)
            ? [createOption(MATCHING_SETS_VALUE, 'Matching Sets', 0), ...positionOptions]
            : positionOptions;

          break;
        }
        default: {
          const attributeName = name.split('.')[1] as AttributeName;
          const checkedAttributeOptions = checkedValues.map((checkedValue) =>
            createOption(checkedValue, checkedValue, 0)
          );

          options = makeAttributeFilterOptions(attributeName, products, checkedAttributeOptions);

          break;
        }
      }

      const isChecked = (option: FilterOption) => checkedValues.includes(option.value);

      return {
        ...filters,
        [name]: uniqBy(options, 'value')
          .filter(isChecked)
          .map((option) => ({ ...option, count: 0, checked: true })),
      };
    },
    {} as Record<Filter['name'], FilterOption[]>
  );
};
