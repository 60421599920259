import { type IconName, Menu } from '@partstech/ui';
import { useCallback } from 'react';
import { SubCategoryOption } from './Option/SubCategoryOption';
import type { PartSubCategory, PartType } from 'models';

type Props = {
  checkedIcon?: (option: PartSubCategory) => IconName;
  disabledPartTypeIds: string[];
  highlightedText?: string;
  onSelect: (subCategory: PartSubCategory) => void;
  onOpen: (option: PartSubCategory) => void;
  options: PartSubCategory[];
  renderSecondaryText?: (option: PartSubCategory) => string;
  value: PartType[];
  disabledText?: string;
  renderTag?: (option: PartSubCategory) => string;
  selectable?: boolean;
};

export const SubCategories = ({
  checkedIcon,
  disabledPartTypeIds,
  highlightedText,
  onSelect,
  onOpen,
  options,
  renderSecondaryText = () => '',
  value,
  disabledText,
  selectable,
  renderTag = () => '',
}: Props) => {
  const isSelected = useCallback(
    (option: PartSubCategory) =>
      value.some((selectedPartType) => option.partTypes.some((partType) => partType.id === selectedPartType.id)),
    [value]
  );

  return (
    <Menu builtIn>
      {options.map((option, index) => (
        <SubCategoryOption
          option={option}
          disabledPartTypeIds={disabledPartTypeIds}
          highlightedText={highlightedText}
          key={`${option.id}-${index}`}
          isSelected={isSelected}
          onSelect={onSelect}
          onOpen={onOpen}
          renderSecondaryText={renderSecondaryText}
          checkedIcon={checkedIcon}
          disabledText={disabledText}
          renderTag={renderTag}
          hasPrefix={selectable}
        />
      ))}
    </Menu>
  );
};
