import { useMemo } from 'react';
import { useGetVehicleById } from './getVehicleById/useGetVehicleById';
import { useGetVehiclesByPlateVin } from './getVehiclesByPlateVin/useGetVehiclesByPlateVin';
import type { LicensePlate } from 'shared/api';

type Props = Partial<{
  id: string;
  vin: string;
  plate: LicensePlate;
  skip: boolean;
}>;

export const useGetVehicle = ({ id, vin, plate, skip }: Props) => {
  const searchBy = useMemo(() => {
    switch (true) {
      case Boolean(plate || vin):
        return 'plateVin';
      case Boolean(id):
        return 'id';
      default:
        return 'unknown';
    }
  }, [id, plate, vin]);

  const responseByPlateVin = useGetVehiclesByPlateVin({ plate, vin }, searchBy !== 'plateVin' || skip);

  const responseById = useGetVehicleById(id ?? null, searchBy !== 'id' || skip);

  return useMemo(() => {
    if (searchBy === 'plateVin') {
      const { vehicles: vehiclesByPlate, isFetching, isSuccess } = responseByPlateVin;

      const vehicle =
        (vehiclesByPlate.length === 1 ? vehiclesByPlate[0] : vehiclesByPlate.find((entity) => entity.id === id)) ??
        null;

      return { vehicle, isFetching, isSuccess: isSuccess && !isFetching };
    }

    if (searchBy === 'id') {
      const { vehicle: vehicleById, isFetching, isSuccess } = responseById;

      return { vehicle: vehicleById, isFetching, isSuccess: isSuccess && !isFetching };
    }

    return { vehicle: null, isFetching: false, isSuccess: false };
  }, [id, responseById, responseByPlateVin, searchBy]);
};
