import { useEffect, useMemo, useState } from 'react';
import { subscribeWidgetStatus, closeWidget, openWidget } from './widget';
import type { WidgetStatus } from './widget';

export const useWidget = () => {
  const [status, setStatus] = useState<WidgetStatus>({ isVisible: false, isLoading: false });

  useEffect(() => {
    const unsubscribe = subscribeWidgetStatus(setStatus);

    return unsubscribe;
  }, []);

  return useMemo(() => ({ ...status, open: openWidget, close: closeWidget }) as const, [status]);
};
