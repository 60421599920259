import { useMounted } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import { Redirect } from 'app/AppRouter/Redirect';
import { useLogOut } from 'features/logOut';
import { DuplicatedShop } from 'features/shop';
import { useCreateShopManagementSystemMutation } from 'features/shop/api/createShopManagementSystem';
import { useSelectedSmsFromQuery } from 'hooks/shopManagementSystems';
import { useSignupQueryParams } from 'hooks/signup';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { SignUpPages, getSignUpUrl } from 'utils/url';
import { CreateShop } from './CreateShop';
import { useSelectShopType } from './useSelectShopType';
import { useShopDetails } from './useShopDetails';

export const ShopDetails = () => {
  const [logOut] = useLogOut();

  const isMounted = useMounted();

  const searchParams = useSignupQueryParams();
  const { shopManagementSystem } = useSelectedSmsFromQuery();

  const { managementSystem } = useGetCurrentUser();

  const { isLoaded, isStepCompleted, shopType, selectShopType } = useSelectShopType();

  const [createManagementSystem, { isLoading: isLoadingCreateManagementSystem }] =
    useCreateShopManagementSystemMutation();

  const selectManagementSystem = useCallback(async () => {
    if (shopManagementSystem?.id) {
      await createManagementSystem({ input: { managementSystemId: shopManagementSystem.id } });
      return;
    }

    if (managementSystem?.id) {
      await createManagementSystem({ input: { managementSystemId: managementSystem.id } });
    }
  }, [createManagementSystem, shopManagementSystem?.id, managementSystem?.id]);

  const { selectedShop, selectShop, setSelectedShop, isDuplicatedShopStep, createDuplicatedShop, isFetching, hasShop } =
    useShopDetails({ selectManagementSystem, selectShopType });

  // TODO: move to useGetRedirect
  if (!isMounted && isLoaded && !isStepCompleted && !shopType) {
    return <Redirect to={getSignUpUrl(SignUpPages.ShopType, searchParams)} replace />;
  }

  if (isDuplicatedShopStep) {
    return (
      <DuplicatedShop
        pt={0}
        px={{ sm: 4 }}
        width={{ sm: '100%', md: 180 }}
        onSubmit={createDuplicatedShop}
        onLogOut={logOut}
      />
    );
  }

  return (
    <CreateShop
      defaultValues={selectedShop ?? undefined}
      onSubmit={selectShop}
      onSelect={setSelectedShop}
      submitButtonText={shopManagementSystem ? 'Continue setup' : 'Next - Select your Management System'}
      isLoading={isFetching || isLoadingCreateManagementSystem}
      hasShop={hasShop}
    />
  );
};
