import { keys, uniqBy } from '@partstech/ui/utils';
import { TIRES_SERVICE_DESCRIPTION_ATTRIBUTES, TIRES_SPECIFICATION_ATTRIBUTES } from 'constant';
import { Attribute } from './Attribute';
import type { AttributeUsageSection } from 'constant';

// TODO: Add dynamic attributes and get rid of corresponding utils.
export class AttributeCollection {
  attributes: Attribute[];

  constructor(attributes: Attribute[]) {
    this.attributes = attributes;
  }

  addTechnicalAttributes(techAttributes: AttributeCollection) {
    this.attributes = [...this.attributes, ...techAttributes.attributes];
  }

  private getAttributesUsageBySection(section: AttributeUsageSection, attributes?: Attribute[]) {
    const uniqAttributes = uniqBy(attributes ?? this.getImportant(), 'name');
    const nonConfigAttributes = uniqAttributes.filter((attribute) => !Attribute.getOptionsByName(attribute.name));
    const productAttributes = uniqAttributes
      .filter((attribute) => keys(attribute.usage).includes(section))
      .sort((aAttr, bAttr) => {
        const sortA = aAttr.usage[section] ?? 0;
        const sortB = bAttr.usage[section] ?? 0;

        return sortA - sortB;
      });

    return [...productAttributes, ...nonConfigAttributes];
  }

  private getAttributesByNames(names: Array<Attribute['name']>) {
    return names.map((name) => this.findAttributeByName(name) || Attribute.createEmptyFromName(name));
  }

  findAttributeByName(attributeName: Attribute['name']) {
    return this.attributes.find((attribute) => attribute.isNameEquals(attributeName)) ?? null;
  }

  getImportant() {
    return this.attributes.filter((attribute) => !attribute.isTechnical);
  }

  getCustomerViewUsage(attributes?: Attribute[]) {
    return this.getAttributesUsageBySection('customerView', attributes);
  }

  getFiltersUsage(attributes?: Attribute[]) {
    return this.getAttributesUsageBySection('filters', attributes);
  }

  getProductUsage(attributes?: Attribute[]) {
    return this.getAttributesUsageBySection('product', attributes);
  }

  getSearchUsage(attributes?: Attribute[]) {
    return this.getAttributesUsageBySection('search', attributes);
  }

  getServiceDescriptionRange() {
    return this.getAttributesByNames(TIRES_SERVICE_DESCRIPTION_ATTRIBUTES);
  }

  getSpecificationRange() {
    return this.getAttributesByNames(TIRES_SPECIFICATION_ATTRIBUTES);
  }

  getTechnical() {
    return this.attributes.filter((attribute) => attribute.isTechnical);
  }

  hasAttributeWithName(attributeName: Attribute['name']) {
    return this.getImportant().some((attribute) => attribute.isNameEquals(attributeName));
  }
}
