import { LDProvider } from 'launchdarkly-react-client-sdk';
import { memo, useMemo } from 'react';
import { launchDarklyKey } from 'app/config';
import { dataDogLaunchDarklyInspector } from 'integrations/launchDarkly/lib/dataDogLaunchDarklyInspector';
import { useLaunchDarklyStatus } from 'integrations/launchDarkly/lib/useLaunchDarklyStatus';
import { useGetLaunchDarklyFlags } from '../../lib/useGetLaunchDarklyFlags';
import { useLaunchDarklySessionClose } from '../../lib/useLaunchDarklySessionClose';
import { useSendLaunchDarklyEvent } from '../../lib/useSendLaunchDarklyEvent';
import { LaunchDarklyContextProvider } from './LaunchDarklyContext';
import type { LDOptions } from 'launchdarkly-react-client-sdk';
import type { PropsWithChildren } from 'react';

const LD_CLIENT_ID = '6559f1f194a34a13154f0184';

const clientSideID = launchDarklyKey === 'ld-key' ? LD_CLIENT_ID : launchDarklyKey;

const LDContext = memo(({ children }: PropsWithChildren) => {
  const flags = useGetLaunchDarklyFlags();
  const sendEvent = useSendLaunchDarklyEvent();
  const { isLoaded } = useLaunchDarklyStatus();

  const launchDarklyContext = useMemo(
    () => ({
      flags,
      sendEvent,
      isLoaded,
    }),
    [flags, isLoaded, sendEvent]
  );

  useLaunchDarklySessionClose();

  return <LaunchDarklyContextProvider value={launchDarklyContext}>{children}</LaunchDarklyContextProvider>;
});

type Props = PropsWithChildren<{
  userContext?: {
    kind: string;
    anonymous?: boolean;
    key?: string;
    user?: { kind: string; key: string };
    shop?: {
      kind: string;
      key: string;
      name: string;
      demo: boolean;
      suppliers: string[];
      shippingZipCode: string | null;
      totalPaidOrders: number;
      usersCount: number;
    };
  };
}>;

export const LaunchDarklyProvider = memo(({ userContext, children }: Props) => {
  const options: LDOptions = useMemo(
    () => ({
      sendEventsOnlyForVariation: true,
      inspectors: [dataDogLaunchDarklyInspector()],
    }),
    []
  );

  return (
    <LDProvider
      key={userContext?.shop?.key ?? 'key'}
      clientSideID={clientSideID}
      context={userContext}
      options={options}
      deferInitialization
    >
      <LDContext>{children}</LDContext>
    </LDProvider>
  );
});
