import { useMemo } from 'react';
import { CURRENT_TIMEZONE } from 'shared/lib/dates';
import { parseTimeRange } from '../../lib/parseTimeRange';
import { api as generatedApi } from './GetSpendReports.generated';
import type { GetSpendReportsQuery } from './GetSpendReports.generated';
import type { TimeRange } from '../../lib/useReportsFilters';
import type { ReportGrouping } from 'shared/api';

type SpendReports = NonNullable<GetSpendReportsQuery['spendReports']>;
export type SpendReport = NonNullable<SpendReports['spendReport']>[0];

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetSpendReports: {
      providesTags: () => ['Report'],
    },
  },
});

const { useGetSpendReportsQuery } = api;

type Props = {
  timeRange: TimeRange;
  locations: string[];
  grouping: ReportGrouping;
};

const empryReports: SpendReport[] = [];

export const useGetSpendReports = ({ timeRange, locations, grouping }: Props) => {
  const { fromDate, toDate, fromPreviousDate, toPreviousDate } = useMemo(() => parseTimeRange(timeRange), [timeRange]);

  const { data, isLoading } = useGetSpendReportsQuery({
    startDate: fromDate,
    endDate: toDate,
    storeIds: locations,
    grouping,
    timeZone: CURRENT_TIMEZONE,
  });

  const { data: prevData, isLoading: isPrevLoading } = useGetSpendReportsQuery(
    {
      startDate: fromPreviousDate || '',
      endDate: toPreviousDate,
      storeIds: locations,
      grouping,
      timeZone: CURRENT_TIMEZONE,
    },
    {
      skip: !fromPreviousDate || !toPreviousDate,
    }
  );

  const reports = useMemo(() => data?.spendReports?.spendReport || empryReports, [data?.spendReports?.spendReport]);

  const prevReports = useMemo(
    () => prevData?.spendReports?.spendReport || undefined,
    [prevData?.spendReports?.spendReport]
  );

  return {
    reports,
    prevReports: fromPreviousDate && toPreviousDate ? prevReports : undefined,
    isLoading: isLoading || isPrevLoading,
  };
};
