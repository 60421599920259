import { Dropdown } from '@partstech/ui';
import { useCallback, useRef } from 'react';
import { AvailabilityMenu } from '../AvailabilityMenu';
import type { DropdownRef } from '@partstech/ui';
import type { AvailabilityLine } from 'models';

export type AvailabilityStatusProps = {
  trailingIcon?: 'expand_less' | 'expand_more';
  onClick?: () => void;
  quantity?: string;
  store?: AvailabilityLine;
};

type Props = {
  allowSelect: boolean;
  onStoreChange?: (store: AvailabilityLine) => void;
  status: React.JSXElementConstructor<AvailabilityStatusProps>;
  store?: AvailabilityLine;
  stores: AvailabilityLine[];
  message?: string;
  usePortal?: boolean;
  disableAutoPlacement?: boolean;
};

export const AvailabilityDropdown = ({
  allowSelect,
  onStoreChange,
  status: Status,
  store,
  stores,
  message,
  usePortal = false,
  disableAutoPlacement = true,
}: Props) => {
  const dropdownRef = useRef<DropdownRef>(null);

  const handleItemClick = useCallback(
    (item: AvailabilityLine) => {
      onStoreChange && onStoreChange(item);

      dropdownRef.current?.close();
    },
    [onStoreChange]
  );

  return (
    <Dropdown
      fullWidth
      renderButton={({ toggleHandler, isOpened }) => (
        <Status
          onClick={toggleHandler}
          trailingIcon={isOpened ? 'expand_less' : 'expand_more'}
          store={store}
          quantity={store?.formattedMaxQuantity}
        />
      )}
      ref={dropdownRef}
      usePortal={usePortal}
      disableAutoPlacement={disableAutoPlacement}
    >
      {({ closeHandler }) => (
        <AvailabilityMenu
          allowSelect={allowSelect}
          onCloseClick={closeHandler}
          onItemClick={handleItemClick}
          store={store}
          stores={stores}
          message={message}
        />
      )}
    </Dropdown>
  );
};
