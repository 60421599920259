import { css } from '@emotion/react';
import { Box, Loader, Scrollbar, type Theme, useMedia } from '@partstech/ui';
import { usePreventScroll } from '@partstech/ui/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetSearchDiagrams } from '../../api/getDiagrams/useGetSearchDiagrams';
import { DiagramsCategoriesList } from './DiagramsCategoriesList';
import { DiagramsList } from './DiagramsList';
import { EmptyState } from './EmptyState';
import type { Diagram } from 'shared/api';

const styles = {
  scrollBar: (theme: Theme) => css`
    ${theme.breakpoints.down('md')} {
      flex-direction: column;
    }
  `,
};

type Props = {
  onClose: () => void;
  onSelectVehicleClick?: () => void;
  defaultCategoryId?: string | null;
  vehicleId: string | null;
  onDiagramSelect: (diagram: Diagram, vehicleId: string) => void;
};

export const VehicleDiagrams = ({
  defaultCategoryId,
  onClose,
  onDiagramSelect,
  onSelectVehicleClick,
  vehicleId,
}: Props) => {
  const { isMobile } = useMedia();

  const [categoryId, setCategoryId] = useState(defaultCategoryId);

  const { categories, diagrams, isLoading } = useGetSearchDiagrams({ vehicleId });

  const hasVehicle = Boolean(vehicleId);

  const handleSelectVehicleClick = useCallback(() => {
    onSelectVehicleClick?.();
    onClose();
  }, [onClose, onSelectVehicleClick]);

  const selectDiagram = useCallback(
    (diagram: Diagram) => {
      if (!vehicleId) {
        return;
      }

      onClose();

      onDiagramSelect(diagram, vehicleId);
    },
    [vehicleId, onClose, onDiagramSelect]
  );

  const handleCategoryClick = useCallback((id: string) => {
    setCategoryId(id);
  }, []);

  const handleCategoryReset = useCallback(() => {
    setCategoryId(null);
  }, []);

  useEffect(() => {
    setCategoryId(defaultCategoryId);
  }, [defaultCategoryId]);

  const message = useMemo(() => {
    if (!hasVehicle) {
      return 'A vehicle must be selected in order to view diagrams.';
    }

    return '';
  }, [hasVehicle]);

  const subMessage = useMemo(() => {
    if (!hasVehicle) {
      return '';
    }

    if (diagrams.length === 0) {
      return 'There are currently no diagrams available for the vehicle you selected.';
    }

    return '';
  }, [hasVehicle, diagrams.length]);

  const isShowEmptyState = useMemo(
    () => (message || subMessage || !hasVehicle) && !isLoading,
    [hasVehicle, isLoading, message, subMessage]
  );

  const activeCategoryName = useMemo(
    () => categories.find((category) => category.id === categoryId)?.name ?? '',
    [categories, categoryId]
  );

  usePreventScroll({ selector: 'body', applyCondition: isMobile });

  return (
    <Scrollbar css={styles.scrollBar}>
      <Box
        width="100%"
        maxHeight={{ sm: 'calc(100 * var(--vh) - 194px)', md: isShowEmptyState ? 90 : 95 }}
        display={isShowEmptyState ? 'flex' : 'block'}
        alignItems="center"
        justifyContent="center"
        data-testid="diagrams"
      >
        {isLoading && <Loader display="flex" justifyContent="center" />}

        {!isLoading && hasVehicle && !categoryId && !isShowEmptyState && (
          <Box display="flex" flexWrap="wrap" width="100%">
            <DiagramsCategoriesList vehicleId={vehicleId} onCategoryClick={handleCategoryClick} />
          </Box>
        )}

        {categoryId && (
          <DiagramsList
            categoryId={categoryId}
            vehicleId={vehicleId}
            categoryName={activeCategoryName}
            onAllClick={handleCategoryReset}
            onDiagramClick={selectDiagram}
          />
        )}

        {isShowEmptyState && (
          <EmptyState
            message={message}
            subMessage={subMessage}
            hasVehicle={hasVehicle}
            onSelectVehicleClick={handleSelectVehicleClick}
          />
        )}
      </Box>
    </Scrollbar>
  );
};
