import { useMemo } from 'react';
import { defaultPricePackage } from 'constant';
import { usePermissions } from 'entities/permission';
import { PackageItemType } from 'shared/api';
import { api as generatedApi } from 'store/queries/currentUser/retailPricing/getRetailPricing/GetRetailPricing.generated';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { getPercentageAmount } from 'utils';
import type { Matrix } from 'shared/api';
import type { GetRetailPricingQuery } from 'store/queries/currentUser/retailPricing/getRetailPricing/GetRetailPricing.generated';
import type { PriceLine, PricePackage } from 'types/pricePackage';

type CurrentUser = NonNullable<GetRetailPricingQuery['currentUser']>;
type ActiveMember = NonNullable<CurrentUser['activeMember']>;
type Shop = NonNullable<ActiveMember['shop']>;
export type RetailPricing = NonNullable<Shop['retailPricing']>;
type Packages = NonNullable<RetailPricing['packages']>;

const getRetailPriceLine = (priceLine: Packages[number]['items'][number]): PriceLine => {
  const { __typename: priceLineType } = priceLine;
  if (priceLineType === 'Addon') {
    return {
      ...priceLine,
      type: PackageItemType.Addon,
      calculatedBy: priceLine.addonCalculatedBy,
      percentageAmount: getPercentageAmount(priceLine.percentageAmount),
      description: priceLine.description ?? null,
    };
  }
  if (priceLineType === 'Fee') {
    return {
      ...priceLine,
      type: PackageItemType.Fee,
      calculatedBy: priceLine.feeCalculatedBy,
      percentageAmount: getPercentageAmount(priceLine.percentageAmount),
      description: priceLine.description ?? null,
    };
  }
  if (priceLineType === 'Labor') {
    return {
      ...priceLine,
      type: PackageItemType.Labor,
      description: priceLine.description ?? null,
    };
  }
  if (priceLineType === 'Disposal') {
    return {
      ...priceLine,
      type: PackageItemType.Disposal,
      percentageAmount: getPercentageAmount(priceLine.percentageAmount),
      description: priceLine.description ?? null,
    };
  }
  return {
    ...priceLine,
    type: PackageItemType.Discount,
    calculatedBy: priceLine.discountCalculatedBy,
    percentageAmount: getPercentageAmount(priceLine.percentageAmount),
    description: priceLine.description ?? null,
  };
};

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['PricePackage', 'Matrix', 'TaxRate'],
  endpoints: {
    GetRetailPricing: {
      providesTags: (response: GetRetailPricingQuery | undefined) => {
        const shop = response?.currentUser?.activeMember?.shop;

        if (!shop) {
          return [];
        }

        const packages =
          shop?.retailPricing?.packages?.map(() => ({
            type: 'PricePackage' as const,
          })) ?? [];

        const matrices =
          shop?.retailPricing?.matrices?.map(() => ({
            type: 'Matrix' as const,
          })) ?? [];

        const taxRate = [{ type: 'TaxRate' as const }];

        return ['PricePackage', 'Matrix', 'TaxRate', ...packages, ...matrices, ...taxRate];
      },
    },
  },
});

const { useGetRetailPricingQuery } = api;

const defaultPackages = [defaultPricePackage];

export const useGetRetailPricing = () => {
  const { hasCurrentUser } = useGetCurrentUser();

  const {
    shop: { retailPricingAllowed },
  } = usePermissions();

  const { data, ...props } = useGetRetailPricingQuery(undefined, { skip: !hasCurrentUser || !retailPricingAllowed });

  const packages: PricePackage[] = useMemo(() => {
    const rawPricePackages =
      data?.currentUser?.activeMember?.shop?.retailPricing?.packages?.map((pricePackage) => ({
        id: Number(pricePackage.id),
        name: pricePackage.name,
        isDefault: pricePackage.isDefault,
        items: pricePackage.items.map(getRetailPriceLine),
      })) ?? [];

    return rawPricePackages.length > 0 ? rawPricePackages : defaultPackages;
  }, [data?.currentUser?.activeMember?.shop?.retailPricing?.packages]);

  const matrices: Matrix[] = useMemo(
    () =>
      data?.currentUser?.activeMember?.shop?.retailPricing?.matrices?.map((matrix) => ({
        id: matrix.id,
        name: matrix.name,
        items: matrix.items,
        type: matrix.type,
        subCategories: matrix.subCategories ?? [],
        brands: matrix.brands ?? [],
        createdAt: matrix.createdAt,
      })) ?? [],
    [data?.currentUser?.activeMember?.shop?.retailPricing?.matrices]
  );

  return {
    taxRate: data?.currentUser?.activeMember?.shop?.retailPricing?.taxRate ?? null,
    pricePackages: packages,
    priceMatrices: matrices,
    ...props,
  };
};
