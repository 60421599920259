import { useCallback } from 'react';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
// eslint-disable-next-line no-restricted-imports
import { useGetSearchUrl } from 'hooks/search/useGetSearchUrl';
// eslint-disable-next-line no-restricted-imports
import { useSearchParams } from 'hooks/search/useSearchParams';
import { adaptSearchParamsToSearchValues } from 'utils';
import { isSearchSequenceCreator } from '../models';
import { useSearchSequenceStorage } from './useSearchSequenceStorage';
import type { Vehicle } from 'entities/vehicle';
import type { SearchSequenceCreatorInterface } from 'features/searchSequence';
import type { SearchSequence, SearchSequenceType, SearchTask } from 'types/search';

export const useSearchSequence = () => {
  const navigate = useAppNavigate();

  const { searchParams, selectedSupplierId, isDealersSearch } = useSearchParams();
  const { getSearchUrlBySearchValues } = useGetSearchUrl();

  const {
    searchSequence,
    totalTask,
    isJobSearchSequence,
    isDiagramSearchSequence,
    markTaskAsCompleted,
    setSearchSequence,
    reset,
  } = useSearchSequenceStorage();

  const launchSearchTask = useCallback(
    (task: SearchTask, step: number, searchSequenceType?: SearchSequenceType) => {
      const taskSearchParams = {
        ...task.searchParams,
        selected_distributor: selectedSupplierId ?? undefined,
        dealers: isDealersSearch ? 1 : undefined,
      };

      const searchValues = {
        ...adaptSearchParamsToSearchValues(taskSearchParams),
        ...taskSearchParams.attributes,
        parts: taskSearchParams.parts,
      };

      navigate(
        getSearchUrlBySearchValues({
          searchValues,
          selectedSupplier: taskSearchParams.selected_distributor,
          dealers: Boolean(taskSearchParams.dealers),
          fitOnly: null,
          jobId: taskSearchParams.job_id,
          step,
          taskType: searchSequenceType ?? searchSequence.type,
          prefill: 'required',
        })
      );
    },
    [selectedSupplierId, isDealersSearch, navigate, getSearchUrlBySearchValues, searchSequence.type]
  );

  const activeStep = searchParams.step ? Number(searchParams.step) : 1;
  const activeTaskIndex = activeStep - 1;
  const activeTask = searchSequence.tasks[activeTaskIndex] ?? null;

  const launchNext = useCallback(
    (markCurrentTaskAsCompleted: boolean = true) => {
      if (markCurrentTaskAsCompleted && activeTask) {
        markTaskAsCompleted(activeTask);
      }

      const nextTask = searchSequence.tasks.slice(activeStep).find((item) => !item.isCompleted);
      const nextTaskIndex = nextTask ? searchSequence.tasks.indexOf(nextTask) : null;

      if (!nextTask || !nextTaskIndex) {
        return;
      }

      launchSearchTask(
        {
          ...nextTask,
          searchParams: {
            ...nextTask.searchParams,
            selected_distributor: selectedSupplierId ?? undefined,
            dealers: isDealersSearch ? 1 : undefined,
          },
        },
        nextTaskIndex + 1
      );
    },
    [
      activeStep,
      activeTask,
      isDealersSearch,
      launchSearchTask,
      markTaskAsCompleted,
      selectedSupplierId,
      searchSequence.tasks,
    ]
  );

  const startSearchSequence = useCallback(
    (taskList: SearchSequence | SearchSequenceCreatorInterface, vehicle?: Vehicle | null) => {
      const newSearchSequence = isSearchSequenceCreator(taskList)
        ? taskList.createSearchSequence(vehicle ?? null)
        : taskList;

      setSearchSequence(newSearchSequence);

      const task = newSearchSequence.tasks[0];

      if (task) {
        launchSearchTask(task, 1, newSearchSequence.type ?? undefined);
      }
    },
    [setSearchSequence, launchSearchTask]
  );

  return {
    activeTask,
    activeStep,
    reset,
    launchSearchTask,
    launchNext,
    searchSequence,
    startSearchSequence,
    totalTask,
    isJobSearchSequence,
    isDiagramSearchSequence,
  };
};
