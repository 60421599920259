import { useCallback } from 'react';
import { useCreateShopContext } from 'components/Layout/SignUpLayout/CreateShopContext/CreateShopContext';
import { useGetAddresses } from 'entities/address';
import { useCreateShopMutation, type ShopFormData } from 'features/shop';
import { useMutationCallback } from 'shared/api';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';

type Args = {
  selectManagementSystem: () => Promise<void>;
  selectShopType: () => void;
};

export const useShopDetails = ({ selectManagementSystem, selectShopType }: Args) => {
  const {
    isDuplicatedShopStep,
    navigateToNextStep: navigateToNextSignUpStep,
    selectedShop,
    setIsDuplicatedShopStep,
    setSelectedShop,
  } = useCreateShopContext();

  const { activeMember, shop, isFetching, refetch } = useGetCurrentUser();
  const { refetch: refetchAddresses } = useGetAddresses({ skip: !activeMember });

  const navigateToNextStep = useCallback(
    async (shouldUpdateUserInfo: boolean = true) => {
      if (activeMember && shouldUpdateUserInfo) {
        await refetchAddresses();
      }

      await selectManagementSystem();

      selectShopType();

      if (shouldUpdateUserInfo) {
        await refetch();
      }

      navigateToNextSignUpStep();
    },
    [activeMember, navigateToNextSignUpStep, refetch, refetchAddresses, selectManagementSystem, selectShopType]
  );

  const handleCreateShopSuccess = useCallback(async () => {
    await navigateToNextStep();

    setIsDuplicatedShopStep(false);
  }, [navigateToNextStep, setIsDuplicatedShopStep]);

  const [createShop] = useMutationCallback(useCreateShopMutation, { onSuccess: handleCreateShopSuccess });

  const selectShop = useCallback(
    async (
      values: ShopFormData,
      { hasChangedShopInfo, hasDuplicates }: Partial<{ hasDuplicates: boolean; hasChangedShopInfo: boolean }> = {}
    ) => {
      if (hasDuplicates) {
        setIsDuplicatedShopStep(true);
        setSelectedShop(values);
        return;
      }

      await navigateToNextStep(hasChangedShopInfo);
    },
    [navigateToNextStep, setIsDuplicatedShopStep, setSelectedShop]
  );

  const createDuplicatedShop = useCallback(async () => {
    if (!selectedShop) {
      return;
    }

    await createShop({ input: selectedShop });
  }, [createShop, selectedShop]);

  return {
    selectedShop,
    selectShop,
    setSelectedShop,
    isDuplicatedShopStep,
    createDuplicatedShop,
    isFetching,
    hasShop: Boolean(shop),
  };
};
