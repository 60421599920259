import { useDebounce } from '@partstech/ui/hooks';
import { uniqBy } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { SuggestedPartTypeOption } from '../../models';
import { useAttributeSearch } from './useAttributeSearch';
import { useDiagramSearch } from './useDiagramSearch';
import { useGetTypeaheadSuggestions } from './useGetTypeaheadSuggestions';
import { useJobSearch } from './useJobSearch';
import type { Vehicle } from 'entities/vehicle';
import type { Diagram, Job, LegacyJob, PartNumber, PartType } from 'models';

type Props = {
  attributeBasedSearch: boolean;
  search: string;
  vehicle: Vehicle | null;
  skip?: boolean;
};

type Result = {
  options: Array<PartType | LegacyJob | Diagram | PartNumber | Job>;
  isPartNumber: boolean;
  isFetching: boolean;
  isSuccess: boolean;
};

const emptyDiagrams: Diagram[] = [];
const emptyJobs: Job[] = [];

export const usePartsTypeahead = ({ attributeBasedSearch, vehicle, search, skip = false }: Props): Result => {
  const debouncedValue = useDebounce(search, 200);

  const {
    options: suggestions,
    isFetching: isSuggestionsFetching,
    isSuccess: isSuggestionsSuccess,
    isPartNumber,
  } = useGetTypeaheadSuggestions({ search: debouncedValue, skip });

  const { jobs } = useJobSearch({ jobs: vehicle?.jobs ?? emptyJobs, search: debouncedValue, skip });
  const { diagrams } = useDiagramSearch({ diagrams: vehicle?.diagrams ?? emptyDiagrams, search: debouncedValue, skip });
  const { partTypes: partTypesWithAttributes } = useAttributeSearch({ search, skip: !attributeBasedSearch });

  const options = useMemo(() => {
    const partTypes = uniqBy(
      partTypesWithAttributes.length > 0
        ? [
            ...partTypesWithAttributes,
            ...suggestions.filter((suggestion) => !(suggestion instanceof SuggestedPartTypeOption)),
          ]
        : suggestions,
      (option) => `${option.id}:${option.toString()}`
    );

    return [...partTypes, ...jobs, ...diagrams];
  }, [diagrams, jobs, partTypesWithAttributes, suggestions]);

  return {
    options,
    isPartNumber,
    isFetching: isSuggestionsFetching,
    isSuccess: isSuggestionsSuccess,
  };
};
