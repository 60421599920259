import { useCallback } from 'react';
import { configRoutingHosts } from 'app/config';
import { useMutationStatus } from 'shared/api';
import { useChangeActiveSupplierMembershipMutation } from 'store/mutations/currentUser/memberships/changeActiveSupplierMembership/ChangeActiveSupplierMembership.generated';

export const useChangeActiveSupplierMembership = () => {
  const [changeActiveMembership, status] = useChangeActiveSupplierMembershipMutation();

  const handleChangeSuccess = useCallback(() => window.location.replace(`//${configRoutingHosts.supplier}/`), []);

  const changeActiveSupplierMembership = useCallback(
    async (membershipID: string) => {
      await changeActiveMembership({ input: { membershipID } });
    },
    [changeActiveMembership]
  );

  useMutationStatus({
    status,
    onSuccess: handleChangeSuccess,
  });

  return [changeActiveSupplierMembership] as const;
};
