import { memo, useCallback, useMemo } from 'react';
import { useIdentify } from '../../lib/useIdentify';
import { useReferrerInfo } from '../../lib/useReferrerInfo';
import { useWidget } from '../../lib/useWidget';
import { AmbassadorContextProvider } from './AmbassadorContext';
import type { IdentifyTraits } from '../../lib/identify';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{}>;

export const AmbassadorProvider = memo(({ children }: Props) => {
  const [identify, { isSuccess, error, isLoading }] = useIdentify();
  const { isVisible: isVisibleWidget, isLoading: isLoadingWidget, close: closeWidget, open: openWidget } = useWidget();
  const referrerInfo = useReferrerInfo();

  const identifyUser = useCallback(
    async (traits: IdentifyTraits) => {
      if (!traits) {
        return false;
      }

      if (isSuccess) {
        return true;
      }

      return identify(traits);
    },
    [identify, isSuccess]
  );

  const handleOpenWidget = useCallback(
    async (traits: IdentifyTraits) => {
      const isIdentified = await identifyUser(traits);

      if (!isIdentified) {
        return;
      }

      openWidget();
    },
    [identifyUser, openWidget]
  );

  const isIdentified = isSuccess || Boolean(error);

  const contextValue = useMemo(
    () => ({
      identify: identifyUser,
      isIdentified,
      error,

      widget: {
        isVisible: isVisibleWidget,
        isLoading: isLoadingWidget || isLoading,
        close: closeWidget,
        open: handleOpenWidget,
      },

      referrerInfo,
    }),
    [
      closeWidget,
      error,
      handleOpenWidget,
      identifyUser,
      isIdentified,
      isLoading,
      isLoadingWidget,
      isVisibleWidget,
      referrerInfo,
    ]
  );

  return <AmbassadorContextProvider value={contextValue}>{children}</AmbassadorContextProvider>;
});
