import { api as generatedApi } from './GetTaxRate.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetTaxRate: {
      providesTags: ['TaxRate'],
    },
  },
});

const { useGetTaxRateQuery } = api;

export const useGetTaxRate = () => {
  const { data, ...props } = useGetTaxRateQuery();

  const taxRate = data?.currentUser?.activeMember?.shop?.retailPricing?.taxRate || 0;

  return {
    taxRate,
    ...props,
  };
};
