import { uniq, isNotNull } from '@partstech/ui/utils';
import { useCallback } from 'react';
import { FullStoryEventNames, useFullStory } from 'integrations/fullstory';
import { CartOrderItemOrigin } from 'shared/api';
import type { CartOrder, CartTotals, LaborApplication } from 'models';

type SubmitOrderEvent = {
  orders: CartOrder[];
  laborApplications: LaborApplication[];
  totals: CartTotals;
};

export const useSubmitOrderTracking = () => {
  const { sendEvent } = useFullStory();

  const trackCartSubmitOrder = useCallback(
    ({ orders, totals, laborApplications }: SubmitOrderEvent) => {
      const orderItems = orders.flatMap((order) => order.items);

      const products = orders.flatMap((order) =>
        order.items.map((orderItem) => ({
          part_number_id: orderItem.partNumberId,
          part_type_id: orderItem.partTypeId,
          brand: orderItem.brandName,
          category: orderItem.categoryName,
          name: orderItem.title,
          price: orderItem.price,
          quantity: orderItem.quantity,
          sku: orderItem.partNumber,
          supplier: order.supplierAccount?.supplier?.name,
          url: orderItem.productUrl,
          purchased_dealers: Boolean(order.supplierAccount?.supplier?.isDealer),
        }))
      );

      const sponsoredProductOrdered = orderItems.some((item) => item.sponsorType === 'SPONSORED');

      const suppliers = uniq(orders.map((order) => order.supplierAccount?.supplier?.name).filter(isNotNull)).join(', ');
      const categories = uniq(orderItems.map((orderItem) => orderItem.categoryName).filter(isNotNull)).join(', ');

      const hasTires = orderItems.some((orderItem) => orderItem.isTire);
      const hasParts = orderItems.some((orderItem) => !orderItem.isTire);
      const orderContains = [hasTires ? 'tires' : null, hasParts ? 'parts' : null].filter(isNotNull);

      const vehiclesIds = uniq(orderItems.map((orderItem) => orderItem.vehicle?.id || null).filter(isNotNull));
      const vehiclesEngines = uniq(
        orderItems.map((orderItem) => orderItem.vehicle?.engine.name || null).filter(isNotNull)
      );
      const vehiclesSubmodels = uniq(
        orderItems.map((orderItem) => orderItem.vehicle?.subModel.name || null).filter(isNotNull)
      );
      const vehiclesYMM = uniq(orderItems.map((orderItem) => orderItem.vehicle?.shortName || null).filter(isNotNull));

      sendEvent(FullStoryEventNames.ORDER_COMPLETED, {
        currency: 'USD',
        orderType: 'UI',
        orderContains,
        partType: uniq(orderItems.map((orderItem) => orderItem.partTypeName).filter(isNotNull)),
        category: categories,
        shipping: totals.shippingSum,
        subtotal: totals.subtotalSum,
        tax: totals.taxesSum,
        total: totals.totalSum,
        containsLabor: laborApplications.length > 0,
        laborTitle: laborApplications.map((labor) => labor.title),
        laborDescription: laborApplications.map((labor) => labor.description).filter(isNotNull),
        laborHours: laborApplications.map((labor) => labor.defaultDuration || labor.duration),
        laborRate: totals.laborRate,
        supplier: suppliers,
        quantity: orderItems.reduce((acc, orderItem) => acc + orderItem.quantity, 0),
        dealOrdered: orderItems.some((orderItem) => orderItem.bundled),
        isStockOrder: orderItems.some((orderItem) => orderItem.origin === CartOrderItemOrigin.StockOrder),
        ptVehicleId: vehiclesIds.length > 0 ? vehiclesIds : null,
        engine: vehiclesEngines.length > 0 ? vehiclesEngines : null,
        submodel: vehiclesSubmodels.length > 0 ? vehiclesSubmodels : null,
        YMM: vehiclesYMM.length > 0 ? vehiclesYMM : null,
        products,
        sponsoredProductOrdered,
      });
    },
    [sendEvent]
  );

  return { trackCartSubmitOrder };
};
