import { ATTRIBUTE_OPTIONS } from 'constant';
import type { AttributeName } from 'constant';
import type { DynamicAttributeName } from 'types/search';

type AttributeUsageSection = 'product' | 'filters' | 'search' | 'customerView';

export class Attribute {
  emptyValue: string;

  isCollapsed: boolean;

  isHidden: boolean;

  label: string;

  name: AttributeName;

  rangeStep: Partial<Record<AttributeUsageSection, string>> | null;

  values: string[];

  usage: Partial<Record<AttributeUsageSection, number>>;

  children?: Attribute[] | null;

  isTechnical?: boolean = false;

  constructor({
    emptyValue,
    isCollapsed,
    isHidden,
    isTechnical,
    name,
    label,
    rangeStep,
    values,
    usage,
    children,
  }: Pick<
    Attribute,
    | 'isCollapsed'
    | 'isHidden'
    | 'isTechnical'
    | 'label'
    | 'name'
    | 'values'
    | 'emptyValue'
    | 'usage'
    | 'rangeStep'
    | 'children'
  >) {
    this.emptyValue = emptyValue;
    this.isCollapsed = isCollapsed;
    this.isHidden = isHidden;
    this.isTechnical = isTechnical;
    this.label = label;
    this.name = name;
    this.rangeStep = rangeStep;
    this.values = values;
    this.usage = usage;
    this.children = children;
  }

  static creteDebugFromSortHash(sortHash: string) {
    return new Attribute({
      name: 'Debug' as AttributeName,
      label: 'SortHash',
      values: [sortHash],
      emptyValue: '',
      usage: {},
      rangeStep: null,
      isCollapsed: false,
      isHidden: false,
      isTechnical: false,
    });
  }

  static createEmptyFromName(attributeName: Attribute['name']) {
    return new Attribute({
      name: attributeName,
      label: Attribute.getOptionsByName(attributeName)?.label ?? attributeName,
      values: [''],
      emptyValue: Attribute.getEmptyValue(attributeName),
      usage: Attribute.getOptionsByName(attributeName)?.usage ?? {},
      rangeStep: null,
      isCollapsed: false,
      isHidden: false,
      isTechnical: false,
    });
  }

  private findChildByName(attributeName: AttributeName) {
    return this.children?.find((child) => child.isNameEquals(attributeName));
  }

  static formatName(attributeName: AttributeName) {
    return attributeName.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  getValue() {
    return this.values.join(', ');
  }

  getChildValue(attributeName: AttributeName) {
    return this.findChildByName(attributeName)?.getValue();
  }

  static getEmptyValue(attributeName: AttributeName | DynamicAttributeName) {
    return Attribute.getOptionsByName(attributeName)?.emptyValue ?? 'Not Specified';
  }

  static getOptionsByName(attributeName: AttributeName | DynamicAttributeName) {
    return ATTRIBUTE_OPTIONS.find((attribute) => attribute.name === attributeName);
  }

  isNameEquals(attributeName: AttributeName) {
    return this.name === attributeName;
  }
}
