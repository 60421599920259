import { BottomSheet } from '@partstech/ui';
import { useCallback } from 'react';
import { SupplierAccountList } from '../SupplierAccountList';
import type { SupplierSelectorListProps } from '../SupplierAccountList';
import type { ModalComponentProps } from '@partstech/ui';
import type { SupplierAccount } from 'entities/supplierAccount';

export const ModalWrapper = ({
  onClose,
  onChange,
  supplierAccounts,
  value,
  canChangeSupplierType,
  ...rest
}: ModalComponentProps<SupplierSelectorListProps>) => {
  const handleSelect = useCallback(
    (supplierAccount: SupplierAccount) => {
      onChange(supplierAccount);
      onClose();
    },
    [onClose, onChange]
  );

  return (
    <BottomSheet onClose={onClose} isClosable isMaxHeight title="Change supplier" {...rest} px={0} pt={0}>
      <SupplierAccountList
        supplierAccounts={supplierAccounts}
        onChange={handleSelect}
        value={value}
        canChangeSupplierType={canChangeSupplierType}
      />
    </BottomSheet>
  );
};
