import { isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { createVehicleFromQuery } from '../../lib/createVehicleFromQuery';
import { useLazyGetVehiclesByPlateVinQuery } from './GetVehiclesByPlateVin.generated';
import type { Vehicle } from '../../model/Vehicle';

const emptyVehicles: Vehicle[] = [];

export const useLazyGetVehiclesByPlateVin = () => {
  const [trigger, status] = useLazyGetVehiclesByPlateVinQuery();
  const { currentData } = status;

  const vehicles = useMemo(
    () => (currentData?.vehicles ? currentData?.vehicles.map(createVehicleFromQuery).filter(isNotNull) : emptyVehicles),
    [currentData?.vehicles]
  );

  return {
    search: trigger,
    vehicles,
    status,
  };
};
