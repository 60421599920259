import { DateTime } from 'luxon';

export const getToPreviousMonth = () => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(date.getMonth() - 1);
  date.setDate(0);
  date.setHours(23, 59, 59, 999);

  return DateTime.fromJSDate(date).toISO() ?? '';
};
