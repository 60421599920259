import { useOpen } from '@partstech/ui/hooks';
import { useCallback, useEffect, useState } from 'react';
import { SearchTypes } from 'types/search';
import { usePartsTypeahead } from './usePartsTypeahead';
import { useSubmitOnSuggestionMatch } from './useSubmitOnSuggestionMatch';
import { useTireSizeSearch } from './useTireSizeSearch';
import type { SearchEntryPointInterface } from '../../models';
import type { Vehicle } from 'entities/vehicle';

type TypeaheadSettings = {
  attributeBasedSearch: boolean;
  onSelect: (option: SearchEntryPointInterface) => void;
  vehicle?: Vehicle | null;
  searchType?: SearchTypes;
  skip?: boolean;
};

const useEnteredValueOnSearchDisable = (searchValue: string, isSearchEnabled: boolean) => {
  const [enteredValue, setEnteredValue] = useState(searchValue);

  useEffect(() => {
    if (isSearchEnabled) {
      setEnteredValue(searchValue);
    }
  }, [isSearchEnabled, searchValue]);

  return { enteredValue };
};

export const useTypeaheadSearch = (
  searchValue: string,
  { onSelect, vehicle = null, searchType = SearchTypes.PARTS, attributeBasedSearch }: TypeaheadSettings
) => {
  const { isOpen: isVisible, open: show, close: hide } = useOpen(false);
  const { isOpen: isSearchEnabled, open: enableSearch, close: disableSearch } = useOpen(true);

  const { enteredValue } = useEnteredValueOnSearchDisable(searchValue, isSearchEnabled);

  const {
    options: partsOptions,
    isFetching: isPartsFetching,
    isSuccess: isPartsSuccess,
    isPartNumber,
  } = usePartsTypeahead({
    search: enteredValue,
    vehicle,
    skip: searchType === SearchTypes.TIRES,
    attributeBasedSearch,
  });

  const { options: tiresOptions } = useTireSizeSearch({
    search: enteredValue,
    skip: searchType === SearchTypes.PARTS,
  });

  const suggestions = searchType === SearchTypes.TIRES ? tiresOptions : partsOptions;

  const { setDelayedValue } = useSubmitOnSuggestionMatch({
    attributeBasedSearch,
    suggestions,
    isPartNumber,
    searchType,
    isLoading: (!isPartsSuccess || isPartsFetching) && searchType === SearchTypes.PARTS,
    onSubmit: onSelect,
  });

  const submitString = useCallback(() => {
    setDelayedValue(searchValue);
  }, [searchValue, setDelayedValue]);

  return {
    options: suggestions,
    submitString,
    disableSearch,
    enableSearch,
    isSearchEnabled,
    isVisible,
    show,
    hide,
    isTypeaheadFetching: isPartsFetching,
  };
};
