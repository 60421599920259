import { useMedia } from '@partstech/ui';
import { useKeyboardInteractions } from '@partstech/ui/hooks';
import { useCallback } from 'react';
import diagramsImg from 'images/icons/search-tabs/diagrams.svg';
import synchronizeIcon from 'images/icons/synchronize.svg';
import { FilesCarousel } from 'shared/ui';
import { CarouselAction } from './CarouselAction';
import type { MediaFile } from 'models';
import type { ReactNode } from 'react';

type Props = {
  media: MediaFile[];
  current: number;
  has360Images: boolean;
  hasDiagrams: boolean;
  isVertical?: boolean;
  adornmentAfter?: ReactNode;
  onNextClick: () => void;
  onPrevClick: () => void;
  onCurrentClick: (index: number) => void;
  onMediaClick?: (index: number) => void;
  onDiagramClick?: () => void;
};

export const ProductMediaCarousel = ({
  media,
  current,
  has360Images,
  hasDiagrams,
  isVertical = false,
  adornmentAfter,
  onNextClick,
  onPrevClick,
  onMediaClick,
  onCurrentClick,
  onDiagramClick,
}: Props) => {
  const { isMobile } = useMedia();

  const handleCarouselFileClick = useCallback(
    (currentIndex: number) => {
      onCurrentClick(currentIndex);
    },
    [onCurrentClick]
  );

  const handleAdornmentClick = useCallback(
    (index: number, actionType: 'view360' | 'diagram') => () => {
      if (actionType === 'view360' && !isMobile) {
        onMediaClick?.(index);
        return;
      }

      if (actionType === 'diagram') {
        onDiagramClick?.();
        return;
      }

      onCurrentClick?.(index);
    },
    [isMobile, onCurrentClick, onDiagramClick, onMediaClick]
  );

  useKeyboardInteractions({ onRightArrow: onNextClick, onLeftArrow: onPrevClick });

  return (
    <>
      <FilesCarousel
        files={media}
        currentIndex={current}
        hasMediaControls={has360Images || hasDiagrams}
        isVertical={isVertical}
        adornmentAfter={
          <>
            {has360Images && (
              <CarouselAction
                ml={isVertical ? 0.5 : 2}
                my={isVertical ? 1 : 0.5}
                icon={synchronizeIcon}
                title="View 360"
                data-testid="view360"
                isActive={current === media.length}
                onClick={handleAdornmentClick(media.length, 'view360')}
              />
            )}

            {hasDiagrams && (
              <CarouselAction
                ml={isVertical ? 0.5 : 2}
                my={isVertical ? 1 : 0.5}
                icon={diagramsImg}
                title="Diagrams"
                data-testid="diagrams"
                isActive={current === media.length + 1}
                onClick={handleAdornmentClick(media.length + 1, 'diagram')}
              />
            )}
          </>
        }
        onClick={handleCarouselFileClick}
      />

      {adornmentAfter && adornmentAfter}
    </>
  );
};
