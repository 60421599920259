/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type DeleteActiveCartLocalInventoryItemMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;

export type DeleteActiveCartLocalInventoryItemMutation = {
  __typename?: 'Mutation';
  deleteActiveCartLocalInventoryItem?:
    | {
        __typename?: 'DeleteActiveCartLocalInventoryItemErrorPayload';
        error: Types.DeleteActiveCartLocalInventoryItemError;
        errorMessage: Types.Scalars['String']['input'];
      }
    | { __typename?: 'DeleteActiveCartLocalInventoryItemSuccessPayload'; success: Types.Scalars['Boolean']['input'] }
    | null;
};

export const DeleteActiveCartLocalInventoryItemDocument = `
    mutation DeleteActiveCartLocalInventoryItem($ids: [ID!]!) {
  deleteActiveCartLocalInventoryItem(input: {ids: $ids}) {
    ... on DeleteActiveCartLocalInventoryItemErrorPayload {
      error
      errorMessage
    }
    ... on DeleteActiveCartLocalInventoryItemSuccessPayload {
      success
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DeleteActiveCartLocalInventoryItem: build.mutation<
      DeleteActiveCartLocalInventoryItemMutation,
      DeleteActiveCartLocalInventoryItemMutationVariables
    >({
      query: (variables) => ({ document: DeleteActiveCartLocalInventoryItemDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeleteActiveCartLocalInventoryItemMutation } = injectedRtkApi;
