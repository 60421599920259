/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type UpdateTaxRateMutationVariables = Types.Exact<{
  input: Types.UpdateRetailPricingInput;
}>;

export type UpdateTaxRateMutation = {
  __typename?: 'Mutation';
  updateRetailPricing?: {
    __typename?: 'UpdateRetailPricingPayload';
    retailPricing?: { __typename?: 'RetailPricing'; taxRate: Types.Scalars['BigDecimal']['input'] } | null;
  } | null;
};

export const UpdateTaxRateDocument = `
    mutation UpdateTaxRate($input: UpdateRetailPricingInput!) {
  updateRetailPricing(input: $input) {
    retailPricing {
      taxRate
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateTaxRate: build.mutation<UpdateTaxRateMutation, UpdateTaxRateMutationVariables>({
      query: (variables) => ({ document: UpdateTaxRateDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateTaxRateMutation } = injectedRtkApi;
