/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../../../shared/api/graphql/graphql';

export type ReportProductFragment = {
  __typename?: 'ReportProduct';
  dateOfFirstPurchase: Types.Scalars['DateTime']['input'];
  dateOfLastPurchase: Types.Scalars['DateTime']['input'];
  image: Types.Scalars['String']['input'];
  lineCardId?: number | null;
  displayPartNumber?: Types.Scalars['String']['input'] | null;
  partNumber: Types.Scalars['String']['input'];
  partNumberId: Types.Scalars['String']['input'];
  title: Types.Scalars['String']['input'];
  totalOrders: number;
  totalPrice: Types.Scalars['BigDecimal']['input'];
  totalQuantity: number;
  account?: { __typename?: 'Account'; id: string } | null;
  brand?: { __typename?: 'Brand'; name: Types.Scalars['String']['input'] } | null;
  mostCommonSupplier?: { __typename?: 'Supplier'; logo: Types.Scalars['String']['input'] } | null;
  supplier?: { __typename?: 'Supplier'; id: string } | null;
  vehicle?: { __typename?: 'Vehicle'; id: string } | null;
};

export const ReportProductFragmentDoc = `
    fragment ReportProduct on ReportProduct {
  account {
    id
  }
  brand {
    name
  }
  dateOfFirstPurchase
  dateOfLastPurchase
  image
  lineCardId
  mostCommonSupplier {
    logo
  }
  displayPartNumber
  partNumber
  partNumberId
  supplier {
    id
  }
  title
  totalOrders
  totalPrice
  totalQuantity
  vehicle {
    id
  }
}
    `;
