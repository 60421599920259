import { isEmpty } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useDependentAttribute } from 'features/dynamicAttribute/api/useDependentAttribute';
import { UniversalPartType } from 'models';
import { formatPartTypesQuery, getApplicationPartTypeId } from 'utils';
import { useSearchParams } from './useSearchParams';
import type { DynamicAttributes } from 'types/search';

type UseDynamicAttributesReturn = {
  attributes: DynamicAttributes;
  hasDependentAttribute: boolean;
  isReady: boolean;
  shouldPickAttributes: boolean;
};

export const useDynamicAttributes = (): UseDynamicAttributesReturn => {
  const { searchParams, partType, isReady, vehicle } = useSearchParams();

  const universalPartType = partType instanceof UniversalPartType ? partType : null;

  const shouldCheckDependentAttribute = useMemo(
    () => Boolean(universalPartType && vehicle && universalPartType.isSingleAttribute()),
    [universalPartType, vehicle]
  );

  const { dependentAttribute, isSuccess } = useDependentAttribute({
    partType: universalPartType,
    skip: !shouldCheckDependentAttribute,
    activeAttributeName: universalPartType?.getFirstAttribute()?.name ?? '',
    previousAttributes: [],
    vehicleId: vehicle?.id ?? null,
  });

  const partTypeId = useMemo(
    () => getApplicationPartTypeId(searchParams.part_text_id, formatPartTypesQuery(searchParams.part_types ?? [])),
    [searchParams.part_text_id, searchParams.part_types]
  );

  const isDynamicAttributeReady = useMemo(() => {
    if (!isReady) {
      return false;
    }

    if (shouldCheckDependentAttribute) {
      return isSuccess;
    }

    return isReady;
  }, [isReady, isSuccess, shouldCheckDependentAttribute]);

  const hasDependentAttribute = useMemo(
    () => (dependentAttribute?.values?.length ?? 0) > 0,
    [dependentAttribute?.values?.length]
  );

  const shouldPickAttributes = useMemo(() => {
    if (partTypeId && isReady && !partType) {
      return true;
    }

    if (!universalPartType) {
      return false;
    }

    if (!universalPartType.attributes) {
      return false;
    }

    if (universalPartType.shouldPickAttributes(Boolean(searchParams.vehicle))) {
      return !universalPartType.isAllAttributesFilled();
    }

    if (shouldCheckDependentAttribute && isDynamicAttributeReady) {
      if (hasDependentAttribute && searchParams.prefill === 'required') {
        return false;
      }

      if (searchParams.prefill === 'skip') {
        return !universalPartType.isAllAttributesFilled();
      }

      return searchParams.prefill === 'none'
        ? false
        : !hasDependentAttribute && !universalPartType.isAllAttributesFilled();
    }

    if (universalPartType.isUniversalFitted && searchParams.vehicle && isEmpty(universalPartType.attributes)) {
      return false;
    }

    if (universalPartType.isUniversalFitted && !searchParams.vehicle) {
      return !searchParams.attributes;
    }

    return false;
  }, [
    partTypeId,
    isReady,
    partType,
    universalPartType,
    searchParams.vehicle,
    searchParams.prefill,
    searchParams.attributes,
    shouldCheckDependentAttribute,
    isDynamicAttributeReady,
    hasDependentAttribute,
  ]);

  const dynamicAttributes = useMemo(
    () =>
      partType?.attributes
        ?.filter((attr) => attr.required)
        .reduce<DynamicAttributes>(
          (result, attribute) => ({ ...result, [attribute.name]: attribute.values ?? [] }),
          {}
        ) ?? {},
    [partType?.attributes]
  );

  return {
    attributes: dynamicAttributes,
    hasDependentAttribute,
    isReady: isDynamicAttributeReady,
    shouldPickAttributes,
  };
};
