/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type UpdateCartPoNumberMutationVariables = Types.Exact<{
  purchaseOrderNumber: Types.Scalars['String']['input'];
}>;

export type UpdateCartPoNumberMutation = {
  __typename?: 'Mutation';
  updateActiveCartPurchaseOrderNumber?:
    | { __typename?: 'UpdateCartPurchaseOrderNumberErrorPayload'; errorMessage: Types.Scalars['String']['input'] }
    | {
        __typename?: 'UpdateCartPurchaseOrderNumberSuccessPayload';
        cart?: {
          __typename?: 'Cart';
          orders?: Array<{
            __typename?: 'CartOrder';
            id: string;
            purchaseOrderNumber?: Types.Scalars['String']['input'] | null;
          }> | null;
        } | null;
      }
    | null;
};

export const UpdateCartPoNumberDocument = `
    mutation UpdateCartPoNumber($purchaseOrderNumber: String!) {
  updateActiveCartPurchaseOrderNumber(
    input: {purchaseOrderNumber: $purchaseOrderNumber}
  ) {
    ... on UpdateCartPurchaseOrderNumberErrorPayload {
      errorMessage
    }
    ... on UpdateCartPurchaseOrderNumberSuccessPayload {
      cart {
        orders {
          id
          purchaseOrderNumber
        }
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateCartPoNumber: build.mutation<UpdateCartPoNumberMutation, UpdateCartPoNumberMutationVariables>({
      query: (variables) => ({ document: UpdateCartPoNumberDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateCartPoNumberMutation } = injectedRtkApi;
