import { isCanadian } from '../lib/isCanadian';
import { Vehicle } from './Vehicle';
import type { VehicleRegion, VehicleType } from 'shared/api';

type VehicleSuggestionData = {
  id?: string | null;
  year: number;
  make: {
    id: string;
    name: string;
    priority: number;
  };
  model: {
    id: string;
    name: string;
  };
  subModel?: {
    id: string;
    name: string;
  } | null;
  engine?: {
    id: string;
    name: string;
  } | null;
  type?: VehicleType | null;
  regions?: VehicleRegion[] | null;
};

const emptyRegions: VehicleRegion[] = [];

export class VehicleSuggestion {
  protected suggestion: VehicleSuggestionData;

  constructor(suggest: VehicleSuggestionData) {
    this.suggestion = suggest;
  }

  get id(): VehicleSuggestionData['id'] {
    return this.suggestion.id;
  }

  get year(): number {
    return this.suggestion.year;
  }

  get make(): VehicleSuggestionData['make'] {
    return this.suggestion.make;
  }

  get model(): VehicleSuggestionData['model'] {
    return this.suggestion.model;
  }

  get subModel(): VehicleSuggestionData['subModel'] {
    return this.suggestion.subModel;
  }

  get engine(): VehicleSuggestionData['engine'] {
    return this.suggestion.engine;
  }

  get type(): VehicleSuggestionData['type'] {
    return this.suggestion.type;
  }

  get regions(): NonNullable<VehicleSuggestionData['regions']> {
    return this.suggestion.regions ?? emptyRegions;
  }

  get name(): string {
    const { year, make, model, subModel, engine } = this.suggestion;

    return [year, make.name, model.name, subModel?.name ?? '', engine?.name ?? ''].join(' ').trim();
  }

  toString(): string {
    return this.name;
  }

  get isCanadian(): boolean {
    return isCanadian(this.regions);
  }

  public toVehicle(): Vehicle | null {
    if (!this.id || this.engine === null) {
      return null;
    }

    return new Vehicle({
      ...this.suggestion,
      id: this.id ?? '',
      subModel: this.subModel as NonNullable<VehicleSuggestion['subModel']>,
      engine: this.engine as NonNullable<VehicleSuggestion['engine']>,
      type: this.type as NonNullable<VehicleSuggestion['type']>,
      regions: this?.regions,
    });
  }
}
