import { GraphicTab, Tabs, useMedia } from '@partstech/ui';
import { isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { usePermissions } from 'entities/permission';
import categoriesImg from 'images/icons/search-tabs/categories.svg';
import diagramsImg from 'images/icons/search-tabs/diagrams.svg';
import fluidImg from 'images/icons/search-tabs/fluid.svg';
import jobsImg from 'images/icons/search-tabs/jobs.svg';
import maintenanceImg from 'images/icons/search-tabs/maintenance.svg';
import popularImg from 'images/icons/search-tabs/popular.svg';
import tiresImg from 'images/icons/search-tabs/tires.svg';
import { SearchTabs } from '../../../types';
import type { BadgeProps, IconName } from '@partstech/ui';

type Props = {
  tab: SearchTabs;
  onChange: (tab: SearchTabs) => void;
  withJobsPriority: boolean;
};

type TabOptions = { name: string; id: SearchTabs; image?: string; icon?: IconName; badge?: BadgeProps };

export const PartsSearchTabs = ({ onChange, tab, withJobsPriority }: Props) => {
  const { isMobile } = useMedia();

  const {
    shop: { schedulesAllowed, specsAllowed, laborAllowed },
  } = usePermissions();

  const tabs: Array<TabOptions> = useMemo(
    () =>
      [
        { name: withJobsPriority ? 'Popular' : 'Popular Parts', id: SearchTabs.POPULAR, image: popularImg },
        { name: 'Tires', id: SearchTabs.TIRES, image: tiresImg },
        {
          name: 'Fluids & Chemicals',
          id: SearchTabs.FLUIDS_CHEMICALS,
          image: fluidImg,
          badge: { backgroundColor: 'positive' as const, content: 'NEW', offsetX: 2 },
        },
        { name: 'Jobs', id: SearchTabs.JOBS, image: jobsImg },
        { name: 'Vehicle diagrams', id: SearchTabs.DIAGRAMS, image: diagramsImg },
        { name: 'Categories', id: SearchTabs.CATEGORIES, image: categoriesImg },
        schedulesAllowed || specsAllowed || laborAllowed
          ? {
              name: 'Service Guides',
              id: SearchTabs.SERVICE_GUIDES,
              image: maintenanceImg,
            }
          : null,
        isMobile ? { name: 'Recent Searches', id: SearchTabs.RECENT_SEARCHES, icon: 'history' as IconName } : null,
      ].filter(isNotNull),
    [isMobile, withJobsPriority, laborAllowed, schedulesAllowed, specsAllowed]
  );

  return (
    <Tabs<SearchTabs> onChange={onChange} scrollButtonVariant="round" activeTab={tab} scrollByPage>
      {tabs.map((tabOption) => (
        <GraphicTab
          variant="feature"
          size={isMobile ? 'medium' : 'large'}
          key={tabOption.name}
          id={tabOption.id}
          image={tabOption.image ? { src: tabOption.image ?? '', alt: tabOption.name } : undefined}
          icon={tabOption.icon}
          badge={tabOption.badge}
        >
          {tabOption.name}
        </GraphicTab>
      ))}
    </Tabs>
  );
};
