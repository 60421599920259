import { useCallback } from 'react';
import { useMutationCallback } from 'shared/api';
import { useLogOutMutation } from '../api/logOut';

export const useLogOut = () => {
  const [logOutTrigger, status] = useMutationCallback(useLogOutMutation);

  const logOut = useCallback(() => logOutTrigger(), [logOutTrigger]);

  return [logOut, status] as const;
};
