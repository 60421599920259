import { useLocalStorage } from '@partstech/ui/hooks';
import { useMemo } from 'react';
import { referralInfoLocalStorageKey } from '../constant';
import { denormalizeReferralCode } from './denormalizeReferralCode';

export type ReferrerInfo = {
  code: string;
  campaign: number;
  source: string;
};

export const useReferrerInfo = () => {
  const [referrerInfo] = useLocalStorage<ReferrerStorageData>(referralInfoLocalStorageKey);

  return useMemo<ReferrerInfo | null>(() => {
    if (!referrerInfo?.value) {
      return null;
    }

    const { mbsy_cookie_code: referralCode, mbsy_source: source, msby_cookie_campaign: campaign } = referrerInfo.value;

    return { code: denormalizeReferralCode(referralCode), campaign, source };
  }, [referrerInfo]);
};
