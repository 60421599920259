import { css } from '@emotion/react';
import { Box } from '@partstech/ui';
import type { Theme } from '@emotion/react';
import type { PropsWithChildren } from 'react';

const styles = {
  wrapper: (itemsPerRow: number | 'auto', marginBottom: number, maxWidth?: number) => (theme: Theme) => css`
    > div {
      width: ${itemsPerRow === 'auto' ? 'auto' : `calc(100% / ${itemsPerRow})`};
      max-width: ${maxWidth ? theme.sizing(maxWidth) : 'none'};
      margin-bottom: ${theme.sizing(marginBottom)};
      padding-right: ${theme.sizing(4)};
    }

    ${itemsPerRow === 'auto'
      ? `> div:nth-last-of-type(1) {
            padding-right: 0;
          }`
      : `> div:nth-of-type(${itemsPerRow}n) {
            padding-right: 0;
          }`}

    & h4 {
      line-height: 24px;
    }
  `,
};

type Props = PropsWithChildren<{
  itemsPerRow?: number | 'auto';
  marginBottom?: number;
  maxWidth?: number;
}>;

export const InfoBand = ({ itemsPerRow = 'auto', marginBottom = 0, maxWidth, children, ...props }: Props) => (
  <Box
    display="flex"
    flexWrap="wrap"
    justifyContent={itemsPerRow === 'auto' ? 'space-between' : 'flex-start'}
    css={styles.wrapper(itemsPerRow, marginBottom, maxWidth)}
    {...props}
  >
    {children}
  </Box>
);
