import { createAddressFromData } from 'entities/address/@x/store';
import { calcDistanceFromCoordinates } from 'shared/lib/coordinates';
import { Store } from '../model/Store';
import type { Supplier } from 'entities/supplier/@x/store';
import type { Store as StoreType, Coordinates } from 'shared/api';

export const createStoreFromQueryData = (
  payloadStore: Omit<StoreType, 'supplier'>,
  supplier: Supplier | null,
  shipplingCoordinates: Coordinates | null = null,
  pickupRadius: number = 0
) => {
  const address = createAddressFromData(payloadStore.address);
  const coordinates: Coordinates | null = payloadStore.coordinates
    ? {
        latitude: payloadStore.coordinates?.latitude,
        longitude: payloadStore.coordinates?.longitude,
      }
    : null;

  const distance = calcDistanceFromCoordinates(shipplingCoordinates, coordinates);
  const hotshot = payloadStore.hotshot > (distance ?? 0);
  const pickUp = payloadStore.pickUp && pickupRadius > (distance ?? 0);
  const hotshotOutOfRange = payloadStore.hotshot > 0 && (distance ?? 0) >= payloadStore.hotshot;

  const store = new Store({ ...payloadStore, address, distance, hotshot, pickUp, hotshotOutOfRange });

  if (supplier) {
    store.setSupplier(supplier);
  }

  return store;
};
